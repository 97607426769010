import styled from "@emotion/styled";
import { Box } from "@otrium/core";

interface Props {
  color?: string;
  height?: number;
}

const Divider = styled(Box)<Props>`
  width: 100%;
  background-color: ${({ color }) => (color ? color : "#1d1e20")};
  height: ${({ height }) => (height ? height : 1)}px;
`;

export default Divider;
