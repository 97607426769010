import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import hashCode from "src/utils/hashCode";
import Cookies from "js-cookie";
import { useAlerts } from "src/hooks/useAlerts";
import { getGenderId } from "src/modules/page";
import useDiscountTimerBar from "./useDiscountTimerBar";
import { AlertBar } from "src/types/graphql.d";

interface RibbonState {
  showRibbon: boolean;
  toggleShowRibbon: () => void;
  combinedAlertBars: AlertBar[];
  discountMessage: AlertBar | undefined;
}
function useRibbon(): RibbonState {
  const [hash, setHash] = useState(null);
  const [showRibbon, setShowRibbon] = useState(false);
  const genderId = useSelector(getGenderId) || "women";
  const { data } = useAlerts(genderId);
  const { discountMessage } = useDiscountTimerBar({ shop_type: genderId });
  const alertBars = data?.alertBar?.alert;
  const combinedAlertBars: AlertBar[] = [
    ...(alertBars || []),
    ...(discountMessage ? [discountMessage as AlertBar] : []),
  ];
  const alertContent =
    alertBars && alertBars.find(({ content }) => Boolean(content))?.content;

  const alertBarCookie = Cookies.get("otrium_cookie-alertbar") || "";

  useEffect(() => {
    if (hash && !alertBarCookie && hash !== parseInt(alertBarCookie, 10)) {
      setShowRibbon(true);
    }
    if (alertContent) {
      // @ts-ignore
      setHash(hashCode(alertContent));
    }
  }, [hash, alertContent, alertBarCookie]);

  const toggleShowRibbon = useCallback(() => {
    if (hash) {
      Cookies.set("otrium_cookie-alertbar", `${hash}`);
      setShowRibbon(false);
    }
  }, [hash]);

  return {
    showRibbon,
    toggleShowRibbon,
    combinedAlertBars,
    discountMessage: discountMessage as AlertBar,
  };
}

export { useRibbon };
