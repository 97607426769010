import { Flex } from "@otrium/core";
import styled from "@emotion/styled";
import { Button } from "src/atoms/Button";
import Flag from "src/atoms/FlagRound";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledButton = styled(Button)`
  font-size: 13px;
  font-weight: 400;
  border: none;

  span {
    text-transform: uppercase;
  }
  span:first-of-type {
    font-weight: 600;
  }
`;

const StyledFlag = styled(Flag)`
  width: 24px;
  height: 24px;
`;

const NavIcon = styled(Flex)`
  width: 32px;
`;

export { Wrapper, StyledButton, StyledFlag, NavIcon };
