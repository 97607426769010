import { FC, SVGAttributes } from "react";

const ShoppingCart: FC<SVGAttributes<SVGSVGElement>> = ({
  color = "#000",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="22"
    viewBox="4.5 4 22 24"
    {...props}
  >
    <title>Shopping cart</title>
    <g fill="none" fillRule="evenodd" stroke={color} strokeWidth="2">
      <path d="M8.86702 12H23.133C23.63 12 24.053 12.366 24.123 12.859L25.148 20.035C25.616 23.315 23.337 26.355 20.057 26.823C19.92 26.843 19.782 26.857 19.644 26.867C18.429 26.956 17.214 27 16 27C14.785 27 13.57 26.956 12.356 26.867C9.05103 26.627 6.56603 23.752 6.80803 20.447C6.81803 20.309 6.83303 20.171 6.85203 20.035L7.87703 12.859C7.94703 12.366 8.37002 12 8.86702 12V12Z" />
      <path
        strokeLinejoin="round"
        d="M11 12C12.333 7.333 14 5 16 5C18 5 19.667 7.333 21 12"
      />
    </g>
  </svg>
);

export { ShoppingCart };
