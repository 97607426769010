import { useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { DiscountTimerBar } from "src/types/graphql.d";
import { calculateRemainingTime } from "src/utils/dateTimeHelper/dateTimeHelper";

interface DiscountTimerBarVars {
  shop_type: string;
}

interface DiscountTimerBarData {
  discountTimerBar: DiscountTimerBar | undefined;
}

interface DiscountTimerBarResult {
  loading: boolean;
  error: any;
  discountMessage: DiscountTimerBar | undefined;
  hours: number;
  days: number;
  minutes: number;
}

export const GET_DISCOUNT_TIMER_BAR = gql`
  query discountTimerBar($shop_type: String!) {
    discountTimerBar(shop_type: $shop_type) {
      message {
        bg_color
        text_color
        content
        valid_till
        link
      }
    }
  }
`;

const useDiscountTimerBar = ({
  shop_type,
}: DiscountTimerBarVars): DiscountTimerBarResult => {
  const [remainingDays, setRemainingDays] = useState(0);
  const [remainingHours, setRemainingHours] = useState(0);
  const [remainingMinutes, setRemainingMinutes] = useState(0);
  const [discountMessage, setDiscountMessage] = useState<DiscountTimerBar>();
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const { loading, error, data } = useQuery<
    DiscountTimerBarData,
    DiscountTimerBarVars
  >(GET_DISCOUNT_TIMER_BAR, {
    variables: {
      shop_type,
    },
    skip: !shop_type,
  });
  const message = data?.discountTimerBar?.message;
  const SET_TIME_INTERVAL = 60000;

  const clearTimeInterval = () => {
    if (timerRef.current) clearInterval(timerRef.current);
  };

  useEffect(() => {
    setDiscountMessage(message as DiscountTimerBar);

    if (message) {
      updateRemainingTime();
    }

    timerRef.current = setInterval(
      updateRemainingTime,
      SET_TIME_INTERVAL
    ) as unknown as NodeJS.Timeout;

    return () => {
      clearTimeInterval();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const updateRemainingTime = () => {
    const validTill = message?.valid_till
      ? new Date(message?.valid_till)
      : null;
    const currentDate = new Date();

    if (validTill) {
      const difference = validTill.getTime() - currentDate.getTime();
      const { days, hours, minutes } = calculateRemainingTime(difference);

      if (difference < 0 || minutes < 1) {
        clearTimeInterval();
        setDiscountMessage(undefined);
        return;
      }

      setRemainingDays(days);
      setRemainingHours(hours);
      setRemainingMinutes(minutes);
    }
  };

  return {
    loading,
    error,
    discountMessage,
    hours: remainingHours,
    days: remainingDays,
    minutes: remainingMinutes,
  };
};

export default useDiscountTimerBar;
