import { ApolloError } from "@apollo/client";
import { ExecutionResult } from "graphql";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { useCallback } from "react";
import { MutationOptInArgs, OptInResult } from "src/types/graphql.d";

interface OptInData {
  optIn: OptInResult;
}

interface OptInHook {
  optIn: (variables: MutationOptInArgs) => Promise<ExecutionResult<OptInData>>;
  data: OptInData | null | undefined;
  loading: boolean;
  error: ApolloError | undefined;
}

const OPT_IN = gql`
  mutation optIn(
    $email: String!
    $segment_anonymous_id: String
    $metadata: MetaData
  ) {
    optIn(
      email: $email
      segment_anonymous_id: $segment_anonymous_id
      metadata: $metadata
    ) {
      token
    }
  }
`;

const useOptIn = ({
  onCompleted,
}: {
  onCompleted?: (data: OptInData) => void;
} = {}): OptInHook => {
  const [optInMutation, { loading, data, error }] = useMutation<
    OptInData,
    MutationOptInArgs
  >(OPT_IN, { onCompleted });

  const optIn = useCallback(
    (variables: MutationOptInArgs) => {
      return optInMutation({ variables });
    },
    [optInMutation]
  );

  return { optIn, loading, data, error };
};

export { useOptIn };
