import { SearchClient } from "algoliasearch";
import { createContext } from "react";

export interface AlgoliaState {
  client: SearchClient | undefined;
}

const AlgoliaContext = createContext<AlgoliaState>({
  client: undefined,
});

AlgoliaContext.displayName = "AlgoliaContext";

export { AlgoliaContext };
