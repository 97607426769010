import { Flex } from "@otrium/core";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/css";

const animation = keyframes`
    0%, 80% {    
      opacity: 0;
      transform: scaleY(0);
    }
    100% {
      opacity: 1;
      transform: scaleY(1);
    }
`;

const Wrapper = styled(Flex)<{ duration?: number; shouldBeHidden?: boolean }>`
  justify-content: center;
  height: 64px;
  ${({ duration }) =>
    duration &&
    `
  animation: ${animation} ${duration + 400}ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: transform ${
    duration * 2
  }ms ease-in-out, opacity ${duration}ms ease-in-out;
  `}
  ${({ shouldBeHidden }) =>
    shouldBeHidden && "transform: scaleY(0); opacity: 0;"}
`;

const GenderButtons = styled.div`
  display: grid;
  background-color: ${({ theme }) => theme.colors.tone.sand};
  border-radius: 8px;
  height: 32px;
  width: 100%;
  position: relative;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-items: center;

  &:after {
    position: absolute;
    content: "";
    height: 16px;
    width: calc(100% / 3);
    border-left: 1px solid ${({ theme }) => theme.colors.primary.platinumLight};
    border-right: 1px solid ${({ theme }) => theme.colors.primary.platinumLight};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const GenderButton = styled.a<{ active: boolean }>`
  cursor: pointer;
  text-transform: capitalize;
  width: 80%;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes[1]}px;
  line-height: 20.11px;
  color: ${({ theme, active }) =>
    active ? theme.colors.tone.black : theme.colors.tone.anthraciteLight};
  ${({ active }) => active && "font-weight: 600;"}
  z-index: 1;
`;

export { GenderButtons, GenderButton, Wrapper };
