import { FC } from "react";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { useTheme } from "@emotion/react";

const StyledOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.overlay};

  width: 100%;
  height: 100%;
`;

interface Props {
  isOpen: boolean;
  onClick?: () => void;
}

const Overlay: FC<Props> = ({ isOpen, onClick }) => {
  const theme = useTheme();
  const overlayVariants = {
    open: {
      backgroundColor: `${theme?.colors?.tone?.black}88`,
    },
    closed: {
      backgroundColor: `${theme?.colors?.tone?.black}00`,
    },
  };

  return isOpen ? (
    <StyledOverlay
      key="overlay"
      initial="closed"
      animate="open"
      exit="closed"
      variants={overlayVariants}
      transition={{ duration: 0.225, ease: "easeInOut" }}
      onClick={onClick}
    />
  ) : null;
};

export { Overlay };
