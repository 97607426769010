import { signIn } from "src/utils/nextAuth";
import { AuthProvider } from "pages/api/auth/getUserTokenFromApi";

const guestLogin = async (): Promise<void> => {
  await signIn(AuthProvider.GUEST_LOGIN, {
    assumedAuthType: "sign_up",
    redirect: false,
  });
};

export { guestLogin };
