export const setStorageItem = (key: string, value: string): void => {
  if (typeof window !== "undefined") {
    window.localStorage.setItem(key, value);
  }
};

export const getStorageItem = (key: string): string | null => {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem(key);
  }
  return null;
};

export const removeStorageItem = (key: string): void => {
  if (typeof window !== "undefined") {
    window.localStorage.removeItem(key);
  }
};

export const setStorageJSON = (key: string, value: unknown): void => {
  if (typeof window !== "undefined") {
    const jsonString = JSON.stringify(value);
    window.localStorage.setItem(key, jsonString);
  }
};

export const getStorageJSON = <T>(key: string): T | null => {
  if (typeof window !== "undefined") {
    const jsonString = window.localStorage.getItem(key);
    if (jsonString) {
      try {
        return JSON.parse(jsonString) as T;
      } catch (error) {
        console.error(`Error parsing JSON for key "${key}":`, error);
        return null;
      }
    }
  }
  return null;
};

export const parseJSON = <T>(jsonString: string): T | null => {
  try {
    return JSON.parse(jsonString) as T;
  } catch (error) {
    console.error("Error parsing JSON string:", error);
    return null;
  }
};
