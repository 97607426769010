import { Box } from "@otrium/core";
import styled from "@emotion/styled";

const Content = styled(Box)`
  max-width: 1264px;
  margin: 0 auto;
  padding: ${({ theme }) => `${theme.space.space0} ${theme.space.space16}`};
`;

const ContentMobileFullscreen = styled(Content)`
  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding: 0;
    margin-bottom: ${({ theme }) => theme.space.space16};
  }
`;

export { Content, ContentMobileFullscreen };
