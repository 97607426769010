import Cookies from "js-cookie";
import { WishlistItemType } from "src/types/graphql.d";

interface WishlistQueue {
  itemType: WishlistItemType;
  itemId: string;
}

const COOKIE_NAME = "otrium_cookie-wishlist-item";

export const addPostLoginWishlistItem = (
  itemType: WishlistItemType,
  itemId: string
): boolean => !!Cookies.set(COOKIE_NAME, { itemType, itemId });

export const popPostLoginWishlistItem = (): WishlistQueue | undefined => {
  const data = Cookies.getJSON(COOKIE_NAME) as WishlistQueue;
  Cookies.remove(COOKIE_NAME);
  return data;
};
