import { FC, SVGAttributes } from "react";

const SofortIconWhite: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="96"
    height="40"
    viewBox="0 0 96 40"
    {...props}
  >
    <title>Sofort</title>
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF" fillRule="nonzero">
        <path
          d="M57.794 4.417c-.389 1.057-1.087 1.712-2.416 1.712h-.005l-.687-.002 1.175-3.2h.668c1.223.002 1.68.362 1.265 1.49m3.526-2.923C60.722.625 59.506.198 57.6.197h-4.41l-4.77 13.23h4.053l1.733-4.657c.085.191 1.108 4.658 1.108 4.658h4.259s-.775-3.282-1.344-4.608c-.147-.34-.3-.723-.503-1.041 1.203-.452 2.946-1.42 3.667-3.386.444-1.207.418-2.182-.074-2.899M20.61.19H13.1c-1.185 0-2.335 0-3.366.528-1.03.528-1.94 1.586-2.641 3.7-.152.455-.232.846-.243 1.186-.01.342.048.631.176.883.158.307.413.597.733.841.32.243.702.442 1.115.569l.495.142c.254.074.508.15.762.226.113.035.238.081.351.15.113.067.214.158.278.285.042.085.065.178.067.273.003.099-.013.205-.052.317-.096.288-.242.472-.52.586-.276.112-.685.149-1.304.154H1.222L0 13.419h8.274c.778 0 2.201 0 3.569-.587 1.368-.587 2.68-1.76 3.235-4.107.209-.883.145-1.601-.198-2.166-.343-.565-.964-.973-1.868-1.235l-.44-.13-.651-.187c-.232-.067-.409-.203-.514-.375-.105-.172-.137-.38-.077-.594.062-.22.212-.398.429-.52.218-.122.504-.188.837-.188h4.81c.033-.05.063-.103.098-.153.878-1.255 1.941-2.27 3.106-2.988"
          transform="translate(-1056 -960) translate(1056 960) translate(11 13)"
        />
        <path
          d="M24.138 6.743c-1.122 3.103-1.937 3.885-3.164 3.885h-.002c-1.195-.002-1.614-.766-.495-3.854.834-2.306 1.916-3.78 3.223-3.78h.001c1.245.001 1.53.73.437 3.75m3.852-5.316C27.335.483 26.215.002 24.657 0c-1.02 0-2.01.237-2.938.679-1.326.634-2.53 1.692-3.512 3.097-.637.911-1.183 1.967-1.611 3.149-.807 2.23-.797 4.04.027 5.233.468.674 1.439 1.48 3.414 1.48h.008c3.598 0 6.285-2.381 7.986-7.08.45-1.244 1.042-3.564-.04-5.13M42.37.188h-7.656c-2.14 0-3.732 1.094-4.485 3.142l-3.636 10.089h3.983l1.868-5.187 3.52.003 1.001-2.778-3.508-.002.565-1.609c.118-.288.4-.517.811-.517l4.15.001c.035-.05.066-.103.1-.153.878-1.255 2.122-2.27 3.286-2.988m3.482 6.554c-1.122 3.103-1.937 3.885-3.163 3.885h-.003c-1.195-.002-1.612-.766-.495-3.854.834-2.306 1.916-3.78 3.224-3.78h.001c1.244.001 1.53.73.436 3.75m3.853-5.316C49.049.483 47.93.002 46.37 0c-1.019 0-2.008.237-2.936.679-1.327.634-2.53 1.692-3.512 3.097-.639.911-1.184 1.967-1.612 3.149-.807 2.23-.797 4.04.028 5.233.467.674 1.438 1.48 3.415 1.48h.005c3.6 0 6.287-2.381 7.987-7.08.45-1.244 1.043-3.564-.04-5.13M61.501.196c.256.205.477.439.662.706.47.686.651 1.502.544 2.436h2.412L61.482 13.43h3.634l3.637-10.092h4.668L74.553.196H61.5z"
          transform="translate(-1056 -960) translate(1056 960) translate(11 13)"
        />
      </g>
    </g>
  </svg>
);

export { SofortIconWhite };
