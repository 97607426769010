import { getLineItemsDiscountPrice } from "src/utils/ct/discountCodeHelper";
import { getRegularPrice } from "src/utils/getRegularPrice";
import {
  BaseMoney,
  Cart,
  DiscountCodeInfo,
  DiscountCodeState,
  LineItem,
  Money,
} from "src/types/ctgraphql.d";
import { StoreConfig } from "src/utils/storeConfig";
import { Currency } from "src/types/graphql.d";

export const lineItemsTotalPrice = (lineItems: LineItem[]): Money => {
  const total = Number(
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    lineItems.reduce(
      (sum, item) => sum + item?.price?.value?.centAmount * item?.quantity,
      0
    )
  );
  const price = lineItems[0].price;
  const money = {
    centAmount: total,
    currencyCode: price.value.currencyCode as Currency,
    fractionDigits: price.value.fractionDigits,
    type: price.value.type,
  };

  return money;
};

export const lineItemsWithDiscountCodesTotalPrice = (
  lineItems: LineItem[],
  discountCodes: DiscountCodeInfo[]
): Money => {
  const discountCodesTotalCentAmount =
    (getLineItemsDiscountPrice(discountCodes, lineItems)
      ?.centAmount as number) || 0;

  const total =
    lineItemsTotalPrice(lineItems).centAmount - discountCodesTotalCentAmount;
  const price = lineItems[0].price;
  const money = {
    centAmount: total,
    currencyCode: price.value.currencyCode as Currency,
    fractionDigits: price.value.fractionDigits,
    type: price.value.type,
  };

  return money;
};

export const getTotalSaved = (
  cart: Cart | null,
  storeConfig: StoreConfig
): BaseMoney | null => {
  if (!cart?.lineItems.length) {
    return null;
  }

  const lineItemsSavedPriceCentAmount = cart.lineItems.reduce(
    (lineItemsSavedPriceCentAmountAcc: number, lineItem: LineItem): number => {
      const regularPrice = getRegularPrice(lineItem, storeConfig);

      const lineItemSavedPriceCentAmount =
        (regularPrice.centAmount - lineItem.price.value.centAmount) *
        lineItem.quantity;

      return lineItemsSavedPriceCentAmountAcc + lineItemSavedPriceCentAmount;
    },
    0
  );

  const lineItemsDiscountsPriceCentAmount =
    (getLineItemsDiscountPrice(cart.discountCodes, cart.lineItems)
      ?.centAmount as number) || 0;

  return {
    ...cart.lineItems[0].price.value,
    centAmount:
      lineItemsSavedPriceCentAmount + lineItemsDiscountsPriceCentAmount,
  };
};

export function getDiscountCodeIds(
  discountCodes: DiscountCodeInfo[]
): string[] {
  if (discountCodes.length === 0) {
    return [];
  }

  return discountCodes
    .filter((code) => code.state === DiscountCodeState.MatchesCart)
    .flatMap(
      (item) =>
        item.discountCode?.cartDiscounts.map(
          (cartDiscount) => cartDiscount.id
        ) || []
    );
}

export function getOtriumSavings(
  lineItems: LineItem[],
  discountCodes: DiscountCodeInfo[]
): BaseMoney | null {
  if (lineItems.length === 0) {
    return null;
  }

  const discountCodeIds = getDiscountCodeIds(discountCodes);

  const lineItemsWithAOC = lineItems.filter((item) =>
    item.discountedPricePerQuantity.some((discountItem) => {
      return discountItem.discountedPrice.includedDiscounts.some(
        (includedDiscount) => {
          return !discountCodeIds?.includes(
            includedDiscount.discount?.id || ""
          );
        }
      );
    })
  );

  const lineItemsAOCAmounts = lineItemsWithAOC.flatMap((lineItem) =>
    lineItem.discountedPricePerQuantity.flatMap((discount) =>
      discount.discountedPrice.includedDiscounts
        .filter(
          (includedDiscount) =>
            !discountCodeIds?.includes(includedDiscount.discount?.id || "")
        )
        .flatMap((discountPrice) => ({
          discountAmount: discountPrice.discountedAmount,
          quantity: discount.quantity as number,
        }))
    )
  );

  const otriumSavings = lineItemsAOCAmounts.reduce(
    (lineItemTotalDiscount, discountPerQuantity) => {
      const totalDiscount = (lineItemTotalDiscount.centAmount as number) || 0;

      return {
        ...lineItemsWithAOC[0].discountedPricePerQuantity[0].discountedPrice
          .value,
        centAmount:
          totalDiscount +
            (discountPerQuantity.discountAmount.centAmount as number) *
              discountPerQuantity.quantity || 0,
      };
    },
    {} as BaseMoney
  );

  return otriumSavings;
}
