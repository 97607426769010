import { RequestOptions } from "@algolia/transporter";
import { useEffect, useState } from "react";
import { SearchIndex } from "algoliasearch";
import { SearchOptions, SearchResponse } from "@algolia/client-search";
import {
  DEFAULT_ANALYTICS_TAGS,
  DEFAULT_HITS_PER_PAGE,
  DEFAULT_RULE_CONTEXTS,
} from "src/utils/algolia/defaultSettings";

interface AlgoliaSearchIndexVars {
  index?: SearchIndex;
  query?: string;
  searchParameters?: SearchOptions;
  requestOptions?: RequestOptions;
}

interface AlgoliaSearchIndexHook<T> {
  data: SearchResponse<T> | undefined;
  loading: boolean;
  error: unknown;
}

const useAlgoliaSearchIndex = <T>({
  index,
  query = "",
  searchParameters,
  requestOptions,
}: AlgoliaSearchIndexVars): AlgoliaSearchIndexHook<T> => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>(null);
  const [searchResults, setSearchResults] = useState<
    SearchResponse<T> | undefined
  >();

  useEffect(() => {
    if (index) {
      const getResponse = async () => {
        try {
          setLoading(true);
          const response = await index.search<T>(query, {
            ...searchParameters,
            ...requestOptions,
            ruleContexts: [
              ...DEFAULT_RULE_CONTEXTS,
              ...(searchParameters?.ruleContexts || []),
            ],
            analyticsTags: [
              ...DEFAULT_ANALYTICS_TAGS,
              ...(searchParameters?.analyticsTags || []),
            ],
            hitsPerPage: searchParameters?.hitsPerPage || DEFAULT_HITS_PER_PAGE,
          });
          setSearchResults(response);
        } catch (e) {
          setError(e);
        } finally {
          setLoading(false);
        }
      };
      void getResponse();
    }
  }, [index, query, requestOptions, searchParameters]);

  return {
    data: searchResults,
    loading,
    error,
  };
};

export default useAlgoliaSearchIndex;
