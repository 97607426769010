import { Reducer } from "redux";
import { State } from "src/redux";

enum HeaderActionTypes {
  OPEN_NAVIGATION_MENU = "header/open-navigation-menu",
  CLOSE_NAVIGATION_MENU = "header/close-navigation-menu",
  OPEN_SEARCH = "header/open-search",
  CLOSE_SEARCH = "header/close-search",
}

type OpenNavigationMenuAction = {
  type: HeaderActionTypes.OPEN_NAVIGATION_MENU;
};

type CloseNavigationMenuAction = {
  type: HeaderActionTypes.CLOSE_NAVIGATION_MENU;
};

type OpenSearchAction = {
  type: HeaderActionTypes.OPEN_SEARCH;
};

type CloseSearchAction = {
  type: HeaderActionTypes.CLOSE_SEARCH;
};

type HeaderAction =
  | OpenNavigationMenuAction
  | CloseNavigationMenuAction
  | OpenSearchAction
  | CloseSearchAction;

const openNavigationMenu = (): OpenNavigationMenuAction => ({
  type: HeaderActionTypes.OPEN_NAVIGATION_MENU,
});

const closeNavigationMenu = (): CloseNavigationMenuAction => ({
  type: HeaderActionTypes.CLOSE_NAVIGATION_MENU,
});

const openSearch = (): OpenSearchAction => ({
  type: HeaderActionTypes.OPEN_SEARCH,
});

const closeSearch = (): CloseSearchAction => ({
  type: HeaderActionTypes.CLOSE_SEARCH,
});

interface HeaderState {
  isOpenNavigationMenu: boolean;
  isOpenSearch: boolean;
}

const initialState: HeaderState = {
  isOpenNavigationMenu: false,
  isOpenSearch: false,
};

const reducer: Reducer<HeaderState, HeaderAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case HeaderActionTypes.OPEN_NAVIGATION_MENU:
      return {
        ...state,
        isOpenNavigationMenu: true,
      };
    case HeaderActionTypes.CLOSE_NAVIGATION_MENU:
      return {
        ...state,
        isOpenNavigationMenu: false,
      };
    case HeaderActionTypes.OPEN_SEARCH:
      return {
        ...state,
        isOpenSearch: true,
      };
    case HeaderActionTypes.CLOSE_SEARCH:
      return {
        ...state,
        isOpenSearch: false,
      };
    default:
      return state;
  }
};

const getIsOpenNavigationMenu = (state: State): boolean =>
  state.header.isOpenNavigationMenu;

const getIsOpenSearch = (state: State): boolean => state.header.isOpenSearch;

export {
  openNavigationMenu,
  closeNavigationMenu,
  openSearch,
  closeSearch,
  reducer,
  getIsOpenNavigationMenu,
  getIsOpenSearch,
};
