import Link from "next/link";
import { Text } from "@otrium/atoms";
import { Box, Flex } from "@otrium/core";
import { formatName } from "src/utils/formatName";
import { Category } from "src/types/Category.d";

type CategoryCardSize = "l" | "m" | "s";

export type CategoryCardColor = "tone.white" | "tone.sand";

const FONT_SIZES = {
  l: 2,
  m: 1,
  s: 0,
};

export default function CategoryCard({
  category,
  size,
  link,
  color = "tone.sand",
}: {
  category: Category;
  size: CategoryCardSize;
  link: string;
  color?: CategoryCardColor;
}): JSX.Element {
  return (
    <Link href={link} passHref>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}

      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        bg={color}
        sx={{
          height: "100%",
          transition: "opacity .3s ease-in-out",
          cursor: "pointer",
          ":hover": { opacity: 0.6 },
        }}
        pt={3}
        pb={3}
      >
        <Box
          px="space16"
          sx={{
            flex: 1,
            minHeight: 0,
            textAlign: "center",
          }}
        >
          <img
            alt={
              "name" in category
                ? formatName(category.name)
                : formatName(category.title)
            }
            src={category.thumbnail}
            style={{ maxHeight: "100%" }}
          />
        </Box>
        <Box sx={{ textAlign: "center", wordBreak: "break-word" }}>
          <Text
            fontSize={[0, 0, 0, FONT_SIZES[size]]}
            color="tone.black"
            fontWeight={600}
            pt={["space0", "space0", "space8", "space8", "space8"]}
          >
            {"name" in category
              ? formatName(category.name)
              : formatName(category.title)}
          </Text>
        </Box>
      </Flex>
    </Link>
  );
}
