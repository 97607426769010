import { FC, PropsWithChildren, ReactNode, useRef } from "react";
import cn from "classnames";
import styled from "@emotion/styled";
import { useScrollHeight } from "src/hooks/useScrollHeight";

const StyledExpandable = styled.div<{
  alwaysVisible: boolean;
  isExpanded?: boolean;
}>`
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s, top 0.3s;
  position: relative;

  &:after {
    content: "";
    background: linear-gradient(0, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 48px;
    display: ${({ alwaysVisible, isExpanded }) =>
      alwaysVisible && !isExpanded ? "block" : "none"};
  }
`;

interface Props extends PropsWithChildren {
  className?: string;
  isExpanded?: boolean;
  children: ReactNode;
  isUpward?: boolean;
  minHeight?: number;
  id?: string;
}

const Expandable: FC<Props> = ({
  id,
  className,
  isExpanded,
  minHeight = 0,
  isUpward,
  children,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const scrollHeight = useScrollHeight(contentRef, [children]);

  return (
    <StyledExpandable
      id={id}
      data-testid="expandable"
      ref={contentRef}
      className={cn(className, { Expandable_expanded: isExpanded })}
      alwaysVisible={minHeight > 0}
      isExpanded={isExpanded}
      style={{
        maxHeight: isExpanded ? scrollHeight : minHeight,
        visibility: isExpanded || minHeight > 0 ? "visible" : "hidden",
        ...(isUpward ? { top: -28 - (isExpanded ? scrollHeight : 0) } : {}),
      }}
    >
      {children}
    </StyledExpandable>
  );
};

export { Expandable };
