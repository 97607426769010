import { NoSearchResults } from "src/molecules/NoSearchResults";
import useGetAlgoliaRecommendedProducts from "src/organisms/Search/useGetAlgoliaRecommendedProducts";
import { SearchWidget } from "src/molecules/SearchWidget";
import { Box } from "@otrium/core";
import { ProductCardWishlist } from "src/organisms/ProductCardWishlist";
import { Trans } from "@lingui/macro";
import { CatalogBrand, ShopType } from "src/types/graphql.d";
import getFullIndexName from "src/utils/algolia/getFullIndexName";
import { useContext } from "react";
import { AppCtx } from "src/contexts/app.context";
import { useSegmentProductList } from "src/hooks/useSegmentProductList";
import {
  SEGMENT_LIST_FORMAT,
  SEGMENT_LIST_ID,
  SEGMENT_LIST_TYPE,
} from "src/segment";

interface NoSearchOverlayResults {
  searchTerm: string;
  shopType: ShopType;
}

const NoSearchOverlayResults = ({
  searchTerm,
  shopType,
}: NoSearchOverlayResults): JSX.Element => {
  const { isGhostDomain, locale } = useContext(AppCtx);
  const prefix = isGhostDomain ? "zz" : locale;
  const productsIndexName = getFullIndexName({ indexName: "products", prefix });
  const { data } = useGetAlgoliaRecommendedProducts(shopType);
  const recommendedProducts = data?.results || [];

  // segment section
  const listFormat = SEGMENT_LIST_FORMAT.search;
  const listType = SEGMENT_LIST_TYPE.products;
  const listId = SEGMENT_LIST_ID.search;
  const { segmentProductOnClick, setVisibleProducts } = useSegmentProductList({
    algoliaIndex: productsIndexName,
    listFormat,
    listId,
    listType,
    numberOfItems: 0,
    queryID: data?.meta.queryID,
    shopType,
  });

  return (
    <>
      <NoSearchResults term={searchTerm} />
      {recommendedProducts.length > 0 && (
        <SearchWidget
          data-testid="search-recommended-products"
          header={<Trans>Recommended for you</Trans>}
          pt={5}
          pb={3}
        >
          {recommendedProducts.map((product, index) => (
            <Box px={[2, 2, 2, 2, 0]} key={product.objectID}>
              <ProductCardWishlist
                {...product}
                product={{
                  ...product,
                  id: product.objectID,
                  name: product.name,
                  price: product.price,
                  regular_price: product.regular_price,
                  brand: {
                    name: product.brand_name || "",
                  } as CatalogBrand,
                  thumbnail: product.image || "",
                  labels: [],
                  available_sizes_label: "",
                }}
                position={index}
                segmentProductOnClick={segmentProductOnClick}
                setVisibleProducts={setVisibleProducts}
                queryID={data?.meta.queryID}
                algoliaIndex={productsIndexName}
                noGateLevel
              />
            </Box>
          ))}
        </SearchWidget>
      )}
    </>
  );
};

export default NoSearchOverlayResults;
