import { FC, SVGAttributes } from "react";

interface Props {
  color?: string;
}

const InfoItalic: FC<SVGAttributes<SVGSVGElement> & Props> = ({
  color = "#FFF",
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" {...props}>
    <title>Information</title>
    <g fill="none" fillRule="evenodd" transform="translate(3 3)">
      <circle cx="9" cy="9" r="8.24" stroke={color} strokeWidth="1.52" />
      <path
        fill={color}
        fillRule="nonzero"
        d="M8.573 7.545c.457 0 .809.11 1.057.329.246.219.37.504.37.854 0 .072-.008.2-.025.383s-.049.35-.095.503l-.473 1.663a4.931 4.931 0 00-.104.457c-.03.17-.046.301-.046.389 0 .22.05.372.15.452.098.08.271.12.516.12.115 0 .245-.02.39-.06.145-.04.25-.075.317-.106l-.127.514c-.38.149-.682.261-.908.34a2.42 2.42 0 01-.788.117c-.46 0-.817-.112-1.072-.335a1.074 1.074 0 01-.381-.848c0-.133.009-.27.028-.409.02-.139.05-.295.091-.471l.476-1.668c.041-.16.078-.313.106-.454.029-.143.043-.274.043-.393 0-.212-.045-.361-.133-.445-.089-.084-.257-.125-.507-.125-.122 0-.249.018-.378.056-.128.04-.239.075-.33.11l.125-.514c.311-.126.61-.234.893-.324.284-.09.553-.135.805-.135zM9.623 4.5c.31 0 .576.102.796.308.22.206.331.454.331.743a.97.97 0 01-.33.74 1.133 1.133 0 01-.797.306c-.31 0-.576-.102-.799-.306-.22-.203-.332-.451-.332-.74s.112-.537.332-.743c.223-.206.49-.308.8-.308z"
      />
    </g>
  </svg>
);

export { InfoItalic };
