import { AlgoliaIndex, SortBy } from "src/types/Algolia.d";

const getFullIndexName = ({
  indexName,
  sortBy,
  defaultSort,
  prefix,
}: AlgoliaIndex & { prefix: string }): string => {
  let fullIndexName;
  if (sortBy) {
    fullIndexName =
      sortBy === SortBy.POPULAR
        ? `${prefix}_${indexName}`
        : `${prefix}_${indexName}${sortBy}`;
  } else {
    fullIndexName = defaultSort
      ? `${prefix}_${indexName}${defaultSort}`
      : `${prefix}_${indexName}`;
  }

  return fullIndexName;
};

export default getFullIndexName;
