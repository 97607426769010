import { identity, sortBy } from "lodash-es";
import {
  StyledProductLabels,
  StyledLabelWrapper,
} from "./ProductLabels.styled";
import { Label } from "src/atoms/Label";
import {
  BrandLabel,
  HomePageSingleBrandLabel,
  ProductLabel,
} from "src/types/graphql.d";
import { FC } from "react";

const QUANTITY_LEFT_TYPE = "quantity_left";
const ICONS = "icons";

interface Props {
  className?: string;
  labels: (ProductLabel | BrandLabel | HomePageSingleBrandLabel)[];
  hideQuantityLabel?: boolean;
  hideIconsLabel?: boolean;
}

const ProductLabels: FC<Props> = ({
  className,
  labels,
  hideQuantityLabel,
  hideIconsLabel,
}) => {
  const filterLabels = (
    label: BrandLabel | ProductLabel | HomePageSingleBrandLabel
  ) => {
    if (hideQuantityLabel) {
      return label.type !== QUANTITY_LEFT_TYPE;
    }
    if (hideIconsLabel) {
      return label.type !== ICONS;
    }
    return true;
  };

  return (
    <StyledProductLabels className={className}>
      {sortBy(labels, identity("priority"))
        .filter(filterLabels)
        .map((label) => {
          const { type, value, color, text_color } = label;
          return (
            <StyledLabelWrapper key={value}>
              <Label
                type={type}
                value={value}
                color={color}
                textColor={text_color}
                backgroundColor={
                  "background_color" in label
                    ? label.background_color
                    : undefined
                }
              />
            </StyledLabelWrapper>
          );
        })}
    </StyledProductLabels>
  );
};

export default ProductLabels;
