import { JSX, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useSession } from "next-auth/client";
import { t, Trans } from "@lingui/macro";
import { Heading, Text } from "@otrium/atoms";
import { Box } from "@otrium/core";
import { FormikProps, useFormik } from "formik";
import * as Yup from "yup";
import { Theme, useTheme } from "@emotion/react";
import { useOptIn } from "src/hooks/useOptIn";
import { useSegment } from "src/hooks/useSegment";
import { Container } from "src/molecules/Footer/Footer.styled";
import Input from "src/atoms/Input";
import FORM_ERROR_MESSAGES from "src/messages/form";
import { Divider } from "src/atoms/Divider";
import {
  InputWrapper,
  OptInBox,
  OptInButton,
  OptInRow,
  Wrapper,
} from "./FooterOptIn.styled";
import { AuthProvider } from "pages/api/auth/getUserTokenFromApi";
import { updateSession } from "src/utils/updateSession";
import { Origin } from "src/types/graphql.d";
import { useLingui } from "@lingui/react";

interface FormikValues {
  email: string;
}

const FooterOptIn = (): JSX.Element => {
  const { _, i18n } = useLingui();
  const router = useRouter();
  const [session] = useSession();
  const signInPlatform = session?.user.signInPlatform;
  const theme: Theme = useTheme();
  const { user, segmentOptIn } = useSegment();
  const { optIn, error: optInError } = useOptIn({
    onCompleted: (data) => {
      if (data.optIn) {
        setEmailSent(true);
        if (data.optIn?.token && signInPlatform !== AuthProvider.GUEST_LOGIN) {
          void updateSession({ token: data.optIn.token });
        }
      }
    },
  });
  const [error, setError] = useState<string | null>(null);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const formik: FormikProps<FormikValues> = useFormik<FormikValues>({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required(FORM_ERROR_MESSAGES.emailRequired)
        .email(FORM_ERROR_MESSAGES.email),
    }),
    onSubmit: async (values) => {
      setError(null);
      const htUser = await user;
      void optIn({
        email: values.email,
        segment_anonymous_id: htUser?.anonymousId?.() ?? "",
        metadata: {
          origin: Origin.Footer,
        },
      });
      void segmentOptIn(true, values.email, "footer");
    },
  });

  useEffect(() => {
    if (optInError) {
      setError(optInError.message || "");
    } else {
      setError(null);
    }
  }, [optInError]);

  useEffect(() => {
    router.events.on("routeChangeStart", formik.resetForm);
    return () => {
      router.events.off("routeChangeStart", formik.resetForm);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper>
      <Container>
        <OptInRow>
          <OptInBox>
            <Heading
              as="h3"
              variant="h3"
              color="White"
              pb={0}
              sx={{
                letterSpacing: "unset",
                fontFamily: "body",
                fontWeight: "heading",
              }}
            >
              {emailSent ? (
                <Trans>You’re in!</Trans>
              ) : (
                <Trans>Subscribe to Our Newsletter</Trans>
              )}
            </Heading>
            <Text color="tone.anthraciteLight">
              {emailSent ? (
                <Trans>The Otrium Experience coming to your inbox</Trans>
              ) : (
                <Trans>Get the newest discounts, brands, and drops.</Trans>
              )}
            </Text>
          </OptInBox>
          {!emailSent && (
            <OptInBox>
              <form noValidate onSubmit={formik.handleSubmit}>
                <OptInRow>
                  <InputWrapper>
                    <Input
                      {...formik.getFieldProps("email")}
                      fullWidth
                      my={3}
                      type="text"
                      placeholder={t(i18n)`Your email here`}
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      isError={
                        formik.touched.email &&
                        formik.errors.email !== undefined
                      }
                      aria-invalid={
                        formik.touched.email &&
                        formik.errors.email !== undefined
                      }
                      aria-describedby="opt-in-email-error-alert"
                    />
                    {formik.errors.email && formik.touched.email && (
                      <Text
                        as="p"
                        mt={-2}
                        color="semantic.red"
                        textAlign="left"
                        fontSize={0}
                        lineHeight="1.43"
                        letterSpacing="normal"
                      >
                        <span role="alert" id="opt-in-email-error-alert">
                          {_(formik.errors.email)}
                        </span>
                      </Text>
                    )}
                    {error && (
                      <Text my={2} color="semantic.red">
                        {error ? (
                          <Trans>{error}</Trans>
                        ) : (
                          <Trans>Something went wrong</Trans>
                        )}
                      </Text>
                    )}
                  </InputWrapper>
                  <OptInButton
                    disabled={
                      !(formik.isValid && formik.dirty) || formik.isSubmitting
                    }
                    data-testid="footer-optin-button"
                    variant="secondary"
                    colorScheme="light"
                    type="submit"
                  >
                    <Trans>Sign Up</Trans>
                  </OptInButton>
                </OptInRow>
              </form>
            </OptInBox>
          )}
        </OptInRow>
        <Box mt={4} mb={0}>
          <Divider color={theme.colors.tone.anthraciteDark} />
        </Box>
      </Container>
    </Wrapper>
  );
};

export default FooterOptIn;
