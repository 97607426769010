import { FC, SVGAttributes } from "react";

const Promotional: FC<SVGAttributes<SVGSVGElement>> = ({
  color = "#000",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-472 -828) translate(448 628) translate(24 200) translate(1 4)">
        <circle cx="6" cy="6" r="1.5" stroke={color} />
        <circle cx="10" cy="10" r="1.5" stroke={color} />
        <path
          stroke={color}
          strokeWidth="2"
          d="M21 1v3.535c-.502.29-.935.686-1.268 1.158C19.272 6.345 19 7.141 19 8c0 .86.271 1.655.732 2.307.333.472.766.868 1.268 1.158h0V15H1V1h20z"
        />
        <path stroke={color} d="M5 11L11 5" />
        <rect width="1" height="1" x="15" y="8" fill={color} rx=".5" />
        <rect width="1" height="1" x="15" y="6" fill={color} rx=".5" />
        <rect width="1" height="1" x="15" y="4" fill={color} rx=".5" />
        <rect width="1" height="1" x="15" y="2" fill={color} rx=".5" />
        <rect width="1" height="1" x="15" y="10" fill={color} rx=".5" />
        <rect width="1" height="1" x="15" y="12" fill={color} rx=".5" />
      </g>
    </g>
  </svg>
);

export { Promotional };
