import { FC, SVGAttributes } from "react";

const VisaIconWhite: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="96"
    height="40"
    viewBox="0 0 96 40"
    {...props}
  >
    <title>Visa</title>
    <defs>
      <path
        id="hu1ypr416a"
        d="M41.4 0c1.97 0 3.555.42 4.786.855l-.896 4.103c-2.102-.946-3.976-.963-4.838-.887l-.21.022c-1.478.188-2.154.927-2.17 1.62-.047 2.28 7.192 2.564 7.169 7.668C45.225 17.403 41.708 20 36.33 20c-2.285-.024-4.492-.49-5.69-1.027l.93-4.24c1.189.542 2.68 1.262 5.256 1.222 1.468-.021 3.046-.596 3.06-1.893.01-.847-.66-1.456-2.655-2.404l-.196-.094c-1.909-.938-4.313-2.478-4.282-5.17C32.78 2.628 36.34 0 41.4 0zM31.358.353l-4.23 19.343h-5.119L26.241.353h5.117zm27.841 0l4.134 19.343h-4.732l-.62-2.888h-6.565l-1.067 2.888h-5.374l7.679-17.925C53.02.911 53.85.353 54.834.353h4.365zm-50.37 0c1.11 0 2.107.722 2.361 1.971l2.156 11.194L18.669.353h5.377l-8.292 19.343h-5.41L6.265 4.26C6.017 3.31 5.8 2.961 5.049 2.56 3.818 1.906 1.786 1.295 0 .915L.12.353h8.71zM55.583 5.58l-2.694 7.265h4.244l-1.55-7.265z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-1056 -1008) translate(1056 1008) translate(16 10)">
            <use fill="#FFF" xlinkHref="#hu1ypr416a" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export { VisaIconWhite };
