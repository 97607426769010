// NOTE: Keep this file in sync with middlewares/utils/isLoggedIn.js

import { AuthProvider } from "pages/api/auth/getUserTokenFromApi";

// NOTE: Removes warings about 'any' type. 'next-auth' and '@types/next-auth' don't export Session type to use here

/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-member-access */
const isLoggedIn = (session: any): boolean => {
  return (
    !(session?.user?.signInPlatform === AuthProvider.CREDENTIALS_SOFT_LOGIN) &&
    !(session?.user?.signInPlatform === AuthProvider.GUEST_LOGIN) &&
    !!session?.accessToken
  );
};

export default isLoggedIn;
