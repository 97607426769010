import { FC, SVGAttributes } from "react";

const CheckIcon: FC<SVGAttributes<SVGSVGElement>> = ({ stroke = "black" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M1 7.07681L5.94853 12L15 3" stroke={stroke} strokeWidth="2" />
      </g>
    </svg>
  );
};

export { CheckIcon };
