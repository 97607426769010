import cn from "classnames";
import { FC, useRef, ReactNode, useEffect, PropsWithChildren } from "react";
import { Box } from "@otrium/core";
import { useClientRect } from "src/hooks/useClientRect";
import { useSticky } from "src/hooks/useSticky";
import { StyledSticky } from "./Sticky.styled";

interface Props {
  children: ReactNode;
  offsetTop?: number;
  onChange?: (mode: boolean) => void;
  top?: number;
  boxshadowMode?: string;
  isHeader?: boolean;
}

const Sticky: FC<PropsWithChildren<Props>> = ({
  offsetTop = 0,
  onChange,
  top,
  boxshadowMode,
  children,
  isHeader,
}) => {
  const stickyRef = useRef<HTMLDivElement>(null);
  const sticky = useSticky(stickyRef, offsetTop);
  const clientRect = useClientRect(stickyRef);
  const contentHeight = clientRect.bottom - clientRect.top;
  const stickyRefHeight = stickyRef.current?.clientHeight || 0;

  const getStyles = () => {
    if (typeof window !== undefined && sticky) {
      return { opacity: 1 };
    }
    return {};
  };
  useEffect(() => {
    if (onChange) {
      onChange(sticky);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sticky]);

  return (
    <StyledSticky
      className={cn({ Sticky_sticky: sticky })}
      height={contentHeight}
      ref={stickyRef}
      top={top}
      boxshadowMode={boxshadowMode}
      isHeader={isHeader}
    >
      <div className="Sticky__content" style={{ ...getStyles() }}>
        {children}
      </div>
      {isHeader && sticky && (
        <Box
          sx={{
            position: "fixed",
            top: `${
              stickyRefHeight && top ? top + stickyRefHeight : stickyRefHeight
            }px`,
            left: 0,
            zIndex: 1199,
            marginTop: "-11px",
            height: "10px",
            width: "100%",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
          }}
        ></Box>
      )}
    </StyledSticky>
  );
};

export default Sticky;
