import { Maybe, ProductVariation } from "src/types/graphql.d";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

const getSumStockQuantity = (variations: ProductVariation[]): number =>
  (variations || [])
    .map(({ stock_quantity }) => ({ stock: stock_quantity || 0 }))
    .reduce((acc, { stock }) => acc + stock, 0);

const getChooseSizeText = (size_schema?: Maybe<string>): string => {
  const { i18n } = useLingui();
  switch (size_schema) {
    case "IT":
      return t(i18n)`Choose size IT`;
    case "FR":
      return t(i18n)`Choose size FR`;
    case "EU":
      return t(i18n)`Choose size EU`;
    case "UK":
      return t(i18n)`Choose size UK`;
    case "US":
      return t(i18n)`Choose size US`;
    default:
      return t(i18n)`Choose size`;
  }
};

export const sanitizeString = (text: string): string => {
  if (typeof text !== "string") return text;

  return text.replace(/\s\s+/g, " ").trim(); // Replace multiple spaces with a single space and trim the string
};

export const convertToHyphenated = (text: string): string => {
  const lowerCaseString = text.toLowerCase();

  return sanitizeString(lowerCaseString).replace(/ /g, "-");
};

export { getSumStockQuantity, getChooseSizeText };
