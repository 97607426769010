import { FC, SVGAttributes } from "react";

interface Props extends SVGAttributes<SVGSVGElement> {
  svgPrefixId: string;
}

const FlagNlRound: FC<Props> = ({ svgPrefixId, ...rest }) => {
  const id = `${svgPrefixId}-flag-dk-round`;
  const circleId = `circle-${id}`;
  const maskId = `mask-${id}`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...rest}
    >
      <defs>
        <circle id={circleId} cx="10" cy="10" r="10" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-506 -1502) translate(302 1502) translate(204)">
          <g>
            <mask id={maskId} fill="#fff">
              <use xlinkHref={`#${circleId}`} />
            </mask>
            <use fill="#FFF" xlinkHref={`#${circleId}`} />
            <path
              fill="#DA022E"
              d="M0 0H20V6.667H0z"
              mask={`url(#${maskId})`}
            />
            <path
              fill="#343997"
              d="M0 13.333H20V20H0z"
              mask={`url(#${maskId})`}
            />
          </g>
          <circle
            cx="10"
            cy="10"
            r="9.75"
            stroke="#000"
            strokeOpacity=".2"
            strokeWidth=".5"
          />
        </g>
      </g>
    </svg>
  );
};

export { FlagNlRound };
