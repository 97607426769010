import cn from "classnames";
import {
  ButtonHTMLAttributes,
  Children,
  cloneElement,
  ElementType,
  FC,
  isValidElement,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from "react";
import styled from "@emotion/styled";
import { useOnClickOutside } from "src/hooks/useOnClickOutside";
import { Expandable } from "src/atoms/Expandable";
import { useEscapePress } from "src/hooks/useEscapePress";

const StyledMenu = styled.div`
  .Menu__button {
    width: 100%;
    height: 100%;
  }
  .Menu__list {
    z-index: ${({ theme }) => theme.zIndex.popup};
    margin-bottom: 30px; // value from woo we don't use 30px in spacings
  }
  .Menu__listContent {
    background: ${({ theme }) => theme.colors.tone.white};
    border: 1px solid ${({ theme }) => theme.colors.primary.platinumLight};
    box-shadow: 0 1px 0 ${({ theme }) => theme.colors.tone.anthraciteLight};
  }
`;

interface Props {
  className?: string;
  children: ReactNode;
}

const Menu: FC<Props> & {
  Button: typeof MenuButton;
  List: typeof MenuList;
} = ({ className, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggleMenu = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const handleClose = useCallback(() => setIsOpen(false), []);

  const containerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(containerRef, handleClose);
  useEscapePress(handleClose);

  return (
    <StyledMenu className={className} ref={containerRef}>
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          if (child.type === "p") {
            return child;
          } else {
            return cloneElement<any>(child, {
              isOpen,
              onToggle: handleToggleMenu,
              onClose: handleClose,
            });
          }
        }

        return child;
      })}
    </StyledMenu>
  );
};

// @ts-expect-error Investigation required
interface MenuButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isOpen?: boolean;
  component?: ElementType;
  onToggle?: () => void;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  children: MenuButtonFunctionChildren;
}

type MenuButtonFunctionChildren = (props: {
  isOpen?: boolean;
  onToggle: () => void;
}) => ReactNode;

const MenuButton: FC<MenuButtonProps> = ({ isOpen, onToggle, children }) => {
  if (!onToggle) {
    throw new Error("Menu.Button must be direct child of Menu component");
  }

  return <>{children({ isOpen, onToggle })}</>;
};

export interface MenuListProps {
  className?: string;
  isOpen?: boolean;
  onToggle?: () => void;
  onClose?: () => void;
  children?: ReactNode | MenuListFunctionChildren;
  isUpward?: boolean;
}

type MenuListFunctionChildren = (props: {
  onToggle: () => void;
  isOpen?: boolean;
}) => ReactNode;

const MenuList: FC<MenuListProps> = ({
  className,
  isOpen,
  onToggle,
  children,
  isUpward,
}) => {
  if (!onToggle) {
    throw new Error("Menu.List must be direct child of Menu component");
  }

  return (
    <Expandable
      className={cn(className, "Menu__list")}
      isExpanded={isOpen}
      isUpward={isUpward}
    >
      <div className="Menu__listContent">
        {/* @ts-expect-error Children is unstable, migration is required */}
        {Children.map(children, (child) => {
          if (isValidElement(child)) {
            return cloneElement<any>(child, { onToggle });
          }

          return child;
        })}
      </div>
    </Expandable>
  );
};

Menu.Button = MenuButton;
Menu.List = MenuList;

export { Menu };
