import { RefObject, useEffect } from "react";

const useFocusFirstFocusable = (
  elementRef: RefObject<HTMLElement>,
  focus: boolean
): void => {
  useEffect(() => {
    if (!focus || !elementRef.current) {
      return;
    }

    const focusableElements = elementRef.current.querySelectorAll<
      | HTMLButtonElement
      | HTMLAnchorElement
      | HTMLInputElement
      | HTMLSelectElement
      | HTMLTextAreaElement
    >(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );

    const firstFocusable = focusableElements[0];

    if (firstFocusable && typeof firstFocusable.focus === "function") {
      firstFocusable.focus();
    }
  }, [elementRef, focus]);
};

export { useFocusFirstFocusable };
