import { Reducer } from "redux";
import { Gender } from ".";
import { localeGenderMap } from "const/localeGenderMap";
import { Locale } from "../../../lib/Intl";
import { State } from "../../redux";
import { localeFix } from "src/utils/genderLocale/genderLocale";

enum PageActionTypes {
  SET_GENDER = "page/set-gender",
  SET_LOCALE = "page/set-locale",
}

type SetGenderAction = {
  type: PageActionTypes.SET_GENDER;
  payload: { genderId: Gender };
};

type SetLocaleAction = {
  type: PageActionTypes.SET_LOCALE;
  payload: { locale: Locale };
};

type PageActions = SetGenderAction | SetLocaleAction;

const setGender = (gender: string): SetGenderAction => ({
  type: PageActionTypes.SET_GENDER,
  payload: { genderId: localeFix({ gender }) as Gender },
});

const setLocale = (locale: Locale): SetLocaleAction => ({
  type: PageActionTypes.SET_LOCALE,
  payload: {
    locale,
  },
});

interface PageState {
  genderId: Gender;
  locale: Locale;
}

const initialProps: PageState = {
  genderId: undefined,
  locale: "en",
};

const reducer: Reducer<PageState, PageActions> = (
  state = initialProps,
  action
) => {
  switch (action.type) {
    case PageActionTypes.SET_GENDER:
      return {
        ...state,
        genderId: action.payload.genderId,
      };

    case PageActionTypes.SET_LOCALE:
      return {
        ...state,
        locale: action.payload.locale,
      };

    default:
      return state;
  }
};

const getGenderId = (state: State) => state.page.genderId;

const getLocaleGenderId = (state: State) => {
  const { genderId, locale = "en" } = state.page;

  return genderId && localeGenderMap[locale][genderId];
};

const getLocaleGenderIds = (
  state: State
): { women: string; men: string; kids: string } => {
  const { locale } = state.page;

  return localeGenderMap[locale];
};

const getLocale = (state: State) => state.page.locale;

export {
  setGender,
  setLocale,
  getGenderId,
  getLocaleGenderId,
  getLocaleGenderIds,
  getLocale,
  reducer,
};
