import { getI18nRoute } from "src/utils/i18nRoutes";
import { ShopType } from "src/types/graphql.d";
import { Locale } from "lib/Intl";

const getBrandPageRoute = (
  shopType: ShopType,
  slug: string,
  locale: Locale
): string => {
  const localeGenderId = getI18nRoute({
    route: `/${shopType}`,
    locale,
  });
  return `${getI18nRoute({
    route: `/brands`,
    locale,
  })}/${slug}${localeGenderId}`;
};

export default getBrandPageRoute;
