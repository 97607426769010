import styled from "@emotion/styled";

const StyledRatioContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 150%;
`;

const StyledPhotoContainer = styled.div<{
  zoomImageOnHover?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  max-height: 624px;

  img {
    transition: transform 0.3s ease;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    max-height: 370px;
  }

  ${({ zoomImageOnHover }) =>
    zoomImageOnHover &&
    `
      overflow: hidden;

      img:hover {
        transform: scale(1.05);
      }
    `}
`;

export { StyledRatioContainer, StyledPhotoContainer };
