import { FC, SyntheticEvent, useContext, useMemo } from "react";
import { Currency, Item, ItemLink, Wrapper } from "./LanguageMenuNew.styled";
import { MenuOptionId } from "./LanguageMenuOptions";
import { useHandleRedirect } from "./useHandleRedirect";
import { AppCtx } from "src/contexts/app.context";
import { Locale } from "lib/Intl";
import getTranslatedMenuOptions, {
  translatedLanguageMap,
} from "./getTranslatedMenuOptions";
import { useLingui } from "@lingui/react";

export interface WrapperProps {
  limitHeight?: boolean;
  onScroll?: (e: SyntheticEvent<HTMLUListElement, UIEvent>) => void;
}

interface BaseProps extends WrapperProps {
  svgPrefixId: string;
  showCurrency?: boolean;
  showRegion?: boolean;
  activeID?: string;
  onClick?: (id: MenuOptionId) => void;
  currentDomain?: Locale;
}

type Props = Omit<BaseProps, "showCurrency" | "activeID">;

export const BaseLanguageMenu: FC<BaseProps> = ({
  activeID,
  svgPrefixId,
  limitHeight,
  showCurrency = true,
  showRegion = false,
  onClick,
  currentDomain,
  ...rest
}) => {
  const { i18n, _ } = useLingui();
  const translatedMenuOptions = useMemo(
    () =>
      getTranslatedMenuOptions(i18n)
        .filter(({ id }) => id !== "en")
        .sort((a, b) => a.region.localeCompare(b.region))
        .concat(
          getTranslatedMenuOptions(i18n).find(({ id }) => id === "en") || []
        ),
    [i18n]
  );

  return (
    <Wrapper
      limitHeight={limitHeight}
      role="listbox"
      aria-orientation="vertical"
      aria-label="Languages"
      {...rest}
    >
      {translatedMenuOptions.map(
        ({ id, language, currency, FlagRound, region }) => (
          <Item key={id} isActive={Boolean(activeID === id)}>
            <ItemLink
              onClick={() => onClick?.(id)}
              as="a"
              hrefLang={language}
              tabIndex={0}
            >
              <FlagRound svgPrefixId={svgPrefixId + id} />
              {!showRegion && _(translatedLanguageMap[id])}
              {showRegion && region}
              {showCurrency && <Currency>{currency}</Currency>}
            </ItemLink>
          </Item>
        )
      )}
    </Wrapper>
  );
};

const LanguageMenu: FC<Props> = ({ svgPrefixId, limitHeight, ...rest }) => {
  const { handleRedirect } = useHandleRedirect();
  const { locale } = useContext(AppCtx);

  return (
    <BaseLanguageMenu
      svgPrefixId={svgPrefixId}
      limitHeight={limitHeight}
      onClick={(id) => handleRedirect(id)}
      activeID={locale}
      {...rest}
    />
  );
};

export default LanguageMenu;
