import { FC, JSX, useContext } from "react";
import { Trans } from "@lingui/macro";
import { Flex } from "@otrium/core";

import { AppCtx } from "src/contexts/app.context";
import { getPrettyLocale } from "src/utils/prettyLocale";
import { ArrowRightIcon } from "src/icons/ArrowRightIcon";
import { NavIcon, StyledFlag } from "./LanguageSelector.styled";

interface Props {
  onClick: () => void;
}

const LanguageSelector: FC<Props> = ({ onClick }): JSX.Element => {
  const { locale } = useContext(AppCtx);

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      onClick={onClick}
    >
      <Flex variant="centered" color="tone.black">
        <NavIcon>
          <StyledFlag svgPrefixId="language-selector" />
        </NavIcon>
        <Trans>Language</Trans>:{getPrettyLocale(locale)}
      </Flex>
      <ArrowRightIcon />
    </Flex>
  );
};

export default LanguageSelector;
