export const genderTranslations = [
  "women",
  "femme",
  "damen",
  "dames",
  "kobiety",
  "mujer",
  "donna",
  "dame",
  "kvinna",
  "kids",
  "enfant",
  "kinder",
  "kids",
  "ninos",
  "bambini",
  "dzieci",
  "born",
  "barn",
  "men",
  "harren",
  "herren",
  "homme",
  "heren",
  "hombre",
  "uomo",
  "man",
  "mezczyzni",
];

export const brandTranslations = {
  en: "brands",
  pl: "marki",
  be: "merken",
  fr: "marques",
  it: "marche",
  de: "marken",
  nl: "merken",
  com: "brands",
  us: "brands",
  dk: "maerker",
  se: "varumarken",
  es: "marcas",
  at: "marken",
  "nl-be": "merken",
  "it-it": "marche",
  "es-es": "marcas",
  "pl-pl": "marki",
  "de-at": "marken",
};
