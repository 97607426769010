/// returns pretty format locale
interface PrettyLocale {
  [key: string]: string;
}
const prettylocales: PrettyLocale = {
  "en-gb": "en",
  "de-at": "at",
  "nl-be": "be",
  "en-dk": "dk",
  "en-se": "se",
  "pl-pl": "pl",
  "es-es": "es",
  "it-it": "it",
  "en-us": "us",
};

const getPrettyLocale = (locale: string): string =>
  prettylocales[locale] || locale;

export { getPrettyLocale };
