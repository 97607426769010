import { BaseMoney, Money } from "src/types/ctgraphql.d";
/**
 * Get ct price and convert to regular number
 * @param price
 * @returns
 */
export const formatStandardPrice = (price?: Money | BaseMoney): number => {
  if (!price) return 0;

  return Number(price.centAmount / Math.pow(10, price.fractionDigits));
};
