import { FC } from "react";

export const AppleIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="32"
    viewBox="0 0 26 32"
  >
    <title>Apple</title>
    <path
      fill="#FFF"
      d="M20.985 16.885c-.037-3.92 3.178-5.83 3.324-5.918-1.82-2.682-4.64-3.048-5.63-3.079-2.368-.251-4.666 1.434-5.872 1.434-1.233 0-3.09-1.407-5.092-1.366-2.574.04-4.986 1.547-6.311 3.89-2.727 4.774-.691 11.79 1.923 15.647 1.305 1.89 2.833 4.001 4.833 3.927 1.956-.084 2.687-1.262 5.052-1.262 2.338 0 3.023 1.262 5.065 1.212 2.1-.034 3.422-1.897 4.684-3.803 1.511-2.169 2.119-4.3 2.142-4.411-.05-.017-4.079-1.568-4.118-6.271M17.131 5.351c1.053-1.329 1.774-3.138 1.571-4.978-1.521.07-3.428 1.068-4.524 2.367-.97 1.148-1.837 3.028-1.614 4.793 1.71.128 3.468-.872 4.567-2.182"
    />
  </svg>
);
