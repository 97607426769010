import { FC } from "react";
import { StyledLabel } from "./Label.styled";
import { getLabelProps } from "./utils";

interface Props {
  type: string;
  value: string;
  color: string;
  textColor?: string | null;
  backgroundColor?: string | null;
  showBackground?: boolean;
}

const Label: FC<Props> = ({
  type,
  value,
  color,
  textColor,
  backgroundColor,
  showBackground = true,
}) => {
  const { styleTextColor, styleBackgroundColor } = getLabelProps({
    type,
    textColor,
    color,
    backgroundColor,
  });

  return (
    <StyledLabel
      color={styleTextColor}
      background={styleBackgroundColor}
      showBackground={showBackground}
    >
      {value}
    </StyledLabel>
  );
};

export { Label };
