import { useCallback, useEffect, useRef } from "react";
import { useSegment } from "./useSegment";
import {
  SegmentProduct,
  SEGMENT_LIST_FORMAT,
  SEGMENT_LIST_ID,
  SEGMENT_LIST_TYPE,
} from "src/segment";
import { ContextKey } from "src/utils/algolia/contextKeys";
import { RankingInfo } from "src/contexts/app.context";

export interface useProductListViewedData {
  algoliaIndex?: string;
  defaultSortBy?: string;
  isPersonalised?: boolean;
  listFormat: SEGMENT_LIST_FORMAT;
  listId: SEGMENT_LIST_ID | string;
  listType: SEGMENT_LIST_TYPE | ContextKey;
  numberOfItems: number;
  queryID?: string;
  shopType?: string;
  rankingInfo?: RankingInfo;
}

export interface UseProductListViewed {
  addProductToQueue: (product: SegmentProduct) => void;
}

interface IProductListViewedQueue {
  addItemToQueue: (product: SegmentProduct) => void;
  clearQueue: () => SegmentProduct[];
  getQueue: () => SegmentProduct[];
  setCallback: (cb: (products: SegmentProduct[]) => void) => void;
}

class ProductListViewedQueue implements IProductListViewedQueue {
  private limit: number;
  private queue: SegmentProduct[] = [];
  private callback: (products: SegmentProduct[]) => void;

  constructor(cb: (products: SegmentProduct[]) => void, limit = 12) {
    this.callback = cb;
    this.limit = limit;
  }

  setCallback(cb: (products: SegmentProduct[]) => void) {
    this.callback = cb;
  }

  getQueue() {
    return this.queue;
  }

  clearQueue() {
    const q = [...this.queue];
    this.queue = [];
    return q;
  }

  addItemToQueue(product: SegmentProduct) {
    this.queue.push(product);
    if (this.queue.length === this.limit) {
      this.callback([...this.queue]);
      this.queue = [];
    }
  }
}

const useProductListViewed = ({
  algoliaIndex,
  defaultSortBy,
  isPersonalised,
  listFormat,
  listId,
  listType,
  shopType,
  numberOfItems,
  queryID,
  rankingInfo,
}: useProductListViewedData): UseProductListViewed => {
  const { segmentProductListViewed } = useSegment();

  const sendDataToSegment = useCallback(
    async (products: SegmentProduct[]) => {
      if (products.length === 0) return;
      return segmentProductListViewed({
        algoliaIndex,
        defaultSortBy,
        isPersonalised,
        productListMeta: {
          listFormat,
          listId,
          listType,
          shopType,
        },
        numberOfItems,
        products,
        queryID,
        rankingInfo,
      });
    },
    [
      segmentProductListViewed,
      algoliaIndex,
      defaultSortBy,
      isPersonalised,
      listFormat,
      listId,
      listType,
      shopType,
      numberOfItems,
      queryID,
      rankingInfo,
    ]
  );

  const queue = useRef(
    new ProductListViewedQueue((products: SegmentProduct[]) => {
      void sendDataToSegment(products);
    }, 100)
  );

  const dispatchData = useRef(async () => {
    await sendDataToSegment(queue.current.getQueue());
  });

  useEffect(() => {
    queue.current.setCallback((products) => {
      void sendDataToSegment(products);
    });

    dispatchData.current = async () => {
      await sendDataToSegment(queue.current.getQueue());
    };
  }, [sendDataToSegment]);

  useEffect(() => {
    if (queryID) {
      void (async () => {
        await sendDataToSegment(queue.current.clearQueue());
      })();
    }
  }, [queryID]);

  useEffect(() => () => void dispatchData.current(), []);

  useEffect(() => {
    window.addEventListener("beforeunload", () => void dispatchData.current());
    return () => {
      window.removeEventListener(
        "beforeunload",
        () => void dispatchData.current()
      );
    };
  }, []);

  const addProductToQueue = (product: SegmentProduct) => {
    queue.current.addItemToQueue(product);
  };

  return {
    addProductToQueue,
  };
};

export default useProductListViewed;
