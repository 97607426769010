import { ButtonColorScheme, ButtonVariants } from "./types";

export const buttonVariants: Record<string, ButtonVariants> = {
  primary: "primary",
  secondary: "secondary",
  tertiary: "tertiary",
};

export const colorVariants: Record<string, ButtonColorScheme> = {
  dark: "dark",
  light: "light",
};
