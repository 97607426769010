// NOTE: This require will be replaced with `@sentry/browser`
// client side thanks to the webpack config in next.config.js
const Sentry = require("@sentry/node");
const Cookie = require("js-cookie");

module.exports = (release = process.env.SENTRY_RELEASE) => {
  const sentryOptions = {
    dsn: process.env.SENTRY_DSN,
    environment: process.env.RELEASE_STAGE,
    release,
    token: process.env.SENTRY_TOKEN,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
  };

  // When we're developing locally
  // and when we are testing prod bundle locally or in ci
  if (process.env.NODE_ENV === "development") {
    // Don't actually send the errors to Sentry
    // @ts-ignore
    sentryOptions.beforeSend = () => null;
  }

  Sentry.init(sentryOptions);

  return {
    Sentry,
    // @ts-ignore
    captureException: (err, ctx) => {
      Sentry.configureScope((scope) => {
        if (err && err.message) {
          // De-duplication currently doesn't work correctly for SSR / browser errors
          // so we force deduplication by error message if it is present
          scope.setFingerprint([err.message]);
          Sentry.captureMessage(err.message);
        }

        if (err && err.errorClass) {
          scope.setExtra("errorClass", err.errorClass);
        }
        if (err && err.graphQLErrors) {
          Sentry.setTag("graphql", err.networkError);
          scope.setExtra("graphQLErrors", err.graphQLErrors);
        }
        if (err && err.networkError) {
          scope.setExtra("networkError", err.networkError);
        }
        if (err && err.response) {
          scope.setExtra("response", err.response);
        }
        if (err && err.operation) {
          scope.setExtra("operation", err.operation);
        }

        if (ctx) {
          const { req, res, errorInfo, query, pathname } = ctx;

          if (res && res.statusCode) {
            scope.setExtra("statusCode", res.statusCode);
          }

          if (typeof window !== "undefined") {
            scope.setTag("ssr", false);
            scope.setExtra("query", query);
            scope.setExtra("pathname", pathname);

            // On client-side we use js-cookie package to fetch it
            const sessionId = Cookie.get("sid");
            if (sessionId) {
              scope.setUser({ id: sessionId });
            }
          } else {
            scope.setTag("ssr", true);
            req && req.url && scope.setExtra("url", req.url);
            req && req.method && scope.setExtra("method", req.method);
            req && req.headers && scope.setExtra("headers", req.headers);
            req && req.params && scope.setExtra("params", req.params);
            req && req.query && scope.setExtra("query", req.query);

            // On server-side we take session cookie directly from request
            if (req && req.cookies && req.cookies.sid) {
              scope.setUser({ id: req.cookies.sid });
            }
          }

          if (errorInfo) {
            Object.keys(errorInfo).forEach((key) =>
              scope.setExtra(key, errorInfo[key])
            );
          }
        }
      });
      err && err.message && Sentry.captureMessage(err && err.message);
      return Sentry.captureException(err);
    },
  };
};
