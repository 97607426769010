import Cookies from "js-cookie";
import { MyOrdersData } from "src/hooks/useMyOrders";

export enum VisitorType {
  CUSTOMER = "customer",
  PROSPECT = "prospect",
}

const SHORT_COOKIE_NAME = "otrium_cookie-pvc-short";
const LONG_COOKIE_NAME = "otrium_cookie-pvc-long";

// Expiration is set in days
const SHORT_COOKIE_EXP = (1 / 24) * 0.5; // 30 minutes
const LONG_COOKIE_EXP = 2 * 365; // 2 years

export function setShortCookie(visitorType: VisitorType): void {
  Cookies.set(SHORT_COOKIE_NAME, visitorType, { expires: SHORT_COOKIE_EXP });
}

export function setLongCookie(visitorType: VisitorType): void {
  Cookies.set(LONG_COOKIE_NAME, visitorType, { expires: LONG_COOKIE_EXP });
}

export function getShortCookie(): VisitorType | undefined {
  return Cookies.get(SHORT_COOKIE_NAME) as VisitorType | undefined;
}

export function getLongCookie(): VisitorType | undefined {
  return Cookies.get(SHORT_COOKIE_NAME) as VisitorType | undefined;
}

export function hasShortCookie(): boolean {
  return !!Cookies.get(SHORT_COOKIE_NAME);
}

export function hasLongCookie(): boolean {
  return !!Cookies.get(SHORT_COOKIE_NAME);
}

export function getVisitorTypeFromOrders({
  me: {
    orders: { results },
  },
}: MyOrdersData): VisitorType {
  return results.length > 0 ? VisitorType.CUSTOMER : VisitorType.PROSPECT;
}
