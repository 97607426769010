import {
  FC,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import {
  StyledSubMenu,
  StyledLink,
  SubMenuContent,
  SubmenuContainer,
  SubmenuLink,
  StyledFlex,
  StyledContainer,
} from "./SubMenu.styled";
import { Locale } from "lib/Intl";
import Link from "next/link";
import { Overlay } from "src/molecules/Overlay";
import { AlgoliaSearch } from "src/organisms/Search";
import { closeSearch, getIsOpenSearch } from "./duck";
import { SearchBar } from "src/molecules/SearchBar";
import { NavMenuSkeleton } from "src/organisms/Header/NavMenuSkeleton";
import { getI18nRoute } from "src/utils/i18nRoutes";
import { useSegment } from "src/hooks/useSegment";
import NavigationContext from "src/contexts/navigation.context";
import { useIsScrollAtPosition } from "src/hooks/useIsScrollAtPosition";
import { convertToHyphenated, sanitizeString } from "../ProductOrder/utils";
import { useRouter } from "next/router";

interface Props {
  locale: Locale;
  genderId: string;
  searchAnimationTime: number;
  gendersRef?: RefObject<HTMLDivElement>;
}
const SubMenu: FC<Props> = ({
  locale,
  genderId,
  gendersRef,
  searchAnimationTime,
}) => {
  const router = useRouter();
  const { navigationItems, loading: navigationItemsLoading } =
    useContext(NavigationContext);
  const dispatch = useDispatch();
  const [searchTerm, setsearchTerm] = useState("");
  const [debouncedsearchTerm] = useDebounce(searchTerm, 400);

  const showSearchOverlay = useIsScrollAtPosition({
    scrollDirection: "vertical",
    validator: (scrollPos) => scrollPos > 48,
  });

  const isOpenSearch = useSelector(getIsOpenSearch);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCloseSearch = useCallback(() => dispatch(closeSearch()), []);
  const [isSearchOverlayOpened, setIsSearchOverlayOpened] =
    useState(isOpenSearch);

  const {
    segmentSearchOverlayOpened,
    segmentSearchOverlayClosed,
    segmentMenuNavClicked,
  } = useSegment();

  const handleCloseSearchOverlay = useCallback(() => {
    setIsSearchOverlayOpened(false);
    setsearchTerm("");
  }, []);

  useEffect(() => {
    router.events.on("routeChangeStart", handleCloseSearch);
    return () => {
      router.events.off("routeChangeStart", handleCloseSearch);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const inputRef = useRef<HTMLInputElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const isLinkActive = (url: string, name: string): boolean => {
    if (name === "hot deals" && router.asPath.includes("hot-deals")) {
      return true;
    }
    return router.asPath.startsWith(url);
  };

  const topLevelCategories = useMemo(
    () => navigationItems?.top_level_categories.slice(0, 4) || [],
    [navigationItems?.top_level_categories]
  );
  const navigationLinks = useMemo(
    () =>
      navigationItems?.navigation_links
        .filter((link) => link.show_on_top)
        .slice(0, 5) || [],
    [navigationItems?.navigation_links]
  );

  useEffect(() => {
    setsearchTerm("");
  }, [genderId]);

  useEffect(() => {
    if (isOpenSearch && !isSearchOverlayOpened) {
      setIsSearchOverlayOpened(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenSearch]);

  useEffect(() => {
    if (isSearchOverlayOpened) {
      void segmentSearchOverlayOpened();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchOverlayOpened]);

  const handleNavItemItemClick = useCallback(
    ({
      menuClickType,
      menuClickName,
    }: {
      menuClickType: string;
      menuClickName: string;
    }) => {
      const menuType = "desktop";
      void segmentMenuNavClicked(menuType, menuClickType, menuClickName);
    },
    [segmentMenuNavClicked]
  );

  return (
    <>
      <StyledSubMenu isOpenSearch={isSearchOverlayOpened}>
        <SubMenuContent as="nav" aria-labelledby="categories-select-label">
          <p id="categories-select-label" className="sr-only">
            Categories
          </p>
          <SubmenuContainer
            as="ul"
            data-testid="submenu-items"
            moveLeft={isOpenSearch}
            duration={searchAnimationTime}
          >
            {navigationItemsLoading ? (
              <NavMenuSkeleton />
            ) : (
              navigationLinks
                .map((item, index) => {
                  return (
                    <StyledLink
                      key={`submenu-${index}`}
                      hideOnDesktop={false}
                      hideOnMobile={false}
                      onClick={() => {
                        handleNavItemItemClick({
                          menuClickType: "new page",
                          menuClickName: item.title_en,
                        });
                      }}
                    >
                      {item.link && item.title && (
                        <Link
                          href={item.link}
                          as={item.link}
                          passHref
                          legacyBehavior
                        >
                          <SubmenuLink
                            color={item.colour}
                            active={isLinkActive(item.link, item.title)}
                            as="a"
                            data-testid={`menu-item-${convertToHyphenated(
                              item.title
                            )}`}
                          >
                            {sanitizeString(item.title)}
                          </SubmenuLink>
                        </Link>
                      )}
                    </StyledLink>
                  );
                })
                .concat(
                  topLevelCategories.map((item, index) => {
                    const link = `${getI18nRoute({
                      route: `/${genderId}`,
                      locale,
                    })}/${item.slug}`;
                    return (
                      <StyledLink
                        key={`submenu-category-${index}`}
                        hideOnDesktop={false}
                        hideOnMobile={false}
                        onClick={() => {
                          handleNavItemItemClick({
                            menuClickType: "new page",
                            menuClickName: item.name_en,
                          });
                        }}
                      >
                        {item.name && (
                          <Link href={link} passHref legacyBehavior>
                            <SubmenuLink
                              color={item.colour}
                              as="a"
                              active={isLinkActive(link, item.name)}
                              data-testid={`menu-item-${convertToHyphenated(
                                item.name
                              )}`}
                            >
                              {sanitizeString(item.name)}
                            </SubmenuLink>
                          </Link>
                        )}
                      </StyledLink>
                    );
                  })
                )
            )}
          </SubmenuContainer>
          {!showSearchOverlay ? (
            <SearchBar
              isOpenSearch={isOpenSearch}
              searchAnimationTime={searchAnimationTime}
              searchTerm={searchTerm}
              ref={inputRef}
              setSearchTerm={setsearchTerm}
              handleCloseSearch={() => {
                void segmentSearchOverlayClosed("click x");
                handleCloseSearch();
              }}
              closeButtonRef={closeButtonRef}
            />
          ) : null}
        </SubMenuContent>
      </StyledSubMenu>
      {showSearchOverlay && isSearchOverlayOpened ? (
        <StyledFlex>
          <StyledContainer>
            <SearchBar
              isOpenSearch={isOpenSearch}
              searchAnimationTime={searchAnimationTime}
              searchTerm={searchTerm}
              ref={inputRef}
              setSearchTerm={setsearchTerm}
              handleCloseSearch={() => {
                void segmentSearchOverlayClosed("click x");
                handleCloseSearch();
              }}
              closeButtonRef={closeButtonRef}
            />
          </StyledContainer>
        </StyledFlex>
      ) : null}
      <Overlay
        isOpen={isSearchOverlayOpened}
        isActive={isOpenSearch}
        onClose={() => {
          void segmentSearchOverlayClosed("click outside");
          handleCloseSearch();
        }}
        excludeFromOutside={[
          gendersRef as RefObject<HTMLDivElement>,
          inputRef,
          closeButtonRef,
        ]}
        mobileTop={110}
        tabletTop={173 - (showSearchOverlay ? 48 : 0)}
        delay={searchAnimationTime}
      >
        <AlgoliaSearch
          onClose={handleCloseSearch}
          onCloseOverlay={handleCloseSearchOverlay}
          isSearchOpened={isOpenSearch}
          searchTerm={debouncedsearchTerm}
          gender={genderId}
          animationTime={searchAnimationTime}
        />
      </Overlay>
    </>
  );
};

export { SubMenu };
