import { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AlertBars } from "src/types/graphql.d";

interface AlertsData {
  alertBar: AlertBars;
}

interface AlertsVars {
  shop_type: string;
}

export const GET_ALERTS = gql`
  query alertBar($shop_type: String!) {
    alertBar(shop_type: $shop_type) {
      alert {
        content
        bg_color
        link
        text_color
      }
    }
  }
`;

const useAlerts = (shop_type: string) => {
  const [completed, setCompleted] = useState(false);

  const { loading, error, data } = useQuery<AlertsData, AlertsVars>(
    GET_ALERTS,
    {
      variables: {
        shop_type,
      },
      skip: !shop_type,
      ssr: true,
      onCompleted: () => setCompleted(true),
    }
  );
  return { loading, error, data, completed };
};

export { useAlerts };
