import { forwardRef } from "react";
import {
  LeftIcon,
  RightIcon,
  StyledButton,
  StyledButtonContent,
  StyledSpinner,
} from "./Button.styled";
import { ButtonElement, ButtonProps } from "src/atoms/Button/types";

const Button = forwardRef<ButtonElement, ButtonProps>(
  (
    { fullHeight = true, children, loading, leftIcon, rightIcon, ...props },
    ref
  ) => (
    <StyledButton
      ref={ref}
      data-testid={props["data-testid"] || "button"}
      {...props}
    >
      {loading && <StyledSpinner data-testid="spinner" />}
      {!loading && leftIcon && (
        <LeftIcon data-testid="left-icon">{leftIcon}</LeftIcon>
      )}
      {!loading && (
        <StyledButtonContent
          data-testid="button-content"
          fullHeight={fullHeight}
        >
          {children}
        </StyledButtonContent>
      )}
      {!loading && rightIcon && (
        <RightIcon data-testid="right-icon">{rightIcon}</RightIcon>
      )}
    </StyledButton>
  )
);

Button.displayName = "Button";

export default Button;
