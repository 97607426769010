import { useQuery } from "@apollo/react-hooks";
import { ApolloError } from "@apollo/client";
import gql from "graphql-tag";
import { Miscellaneous } from "src/types/graphql.d";

const MISCELLANEOUS = gql`
  query miscellaneous {
    miscellaneous {
      welcomePopup {
        title
        subTitle
        cta
        termsAndConditions
        desktopImage
        mobileImage
      }
    }
  }
`;

interface MiscellaneousData {
  miscellaneous: Pick<Miscellaneous, "welcomePopup" | "__typename">;
}

interface useMiscellaneousProps {
  data: MiscellaneousData | undefined;
  loading: boolean;
  error: ApolloError | undefined;
}

const useWelcomePopup = (): useMiscellaneousProps => {
  const { data, loading, error } = useQuery<MiscellaneousData>(MISCELLANEOUS, {
    ssr: true,
    fetchPolicy: "cache-first",
  });

  return { data, loading, error };
};

export { useWelcomePopup };
