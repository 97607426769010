import { FC, ReactNode } from "react";
import {
  StyledRatioContainer,
  StyledPhotoContainer,
} from "./ProductPhoto.styled";
interface Props {
  className?: string;
  zoomImageOnHover?: boolean;
  children: ReactNode;
}

const ProductPhoto: FC<Props> = ({
  className,
  zoomImageOnHover,
  children,
  ...rest
}) => {
  return (
    <div className={className}>
      <StyledRatioContainer>
        <StyledPhotoContainer zoomImageOnHover={zoomImageOnHover} {...rest}>
          {children}
        </StyledPhotoContainer>
      </StyledRatioContainer>
    </div>
  );
};

export default ProductPhoto;
