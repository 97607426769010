import { FC, SVGAttributes } from "react";

const CloseSmall: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    {...props}
  >
    <g fill="none" fillRule="evenodd" strokeLinecap="round">
      <g stroke="#999" strokeWidth="2">
        <path
          d="M0 0l10 10m0-10L0 10"
          transform="translate(-376 -124) translate(32 106) translate(326) translate(18 18) translate(1 1)"
        />
      </g>
    </g>
  </svg>
);

export { CloseSmall };
