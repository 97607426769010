import React, { FC, ReactNode, useEffect } from "react";
import Head from "next/head";
import useSeoContent from "src/hooks/useSeoContent";
import { useRouter } from "next/router";

interface Props {
  title?: string;
  deeplink?: string;
  metaTags?: ReactNode;
  description?: string;
  scripts?: ReactNode;
  noindex?: boolean;
  isPDP?: boolean;
}

const PageHead: FC<Props> = ({
  title,
  deeplink,
  metaTags,
  description,
  noindex = true,
  scripts,
  isPDP,
}) => {
  const metaRobotsContent = `follow${noindex ? ",noindex" : ""}`;
  const router = useRouter();
  const { data } = useSeoContent({
    page_url_slug: router.asPath,
  });
  const seoContent = data?.getSeoContent;

  const robotsTagValue =
    seoContent &&
    (seoContent?.robots_tag_value ||
      (isPDP ? "follow,index" : metaRobotsContent));

  const removeMetaTags = (selector: string) => {
    const existingCanonicals = document.querySelectorAll(selector);
    existingCanonicals.forEach((el) => el.remove());
  };

  useEffect(() => {
    if (seoContent?.canonical_tags) {
      removeMetaTags('link[rel="canonical"]');
    }
    if (seoContent?.meta_description) {
      removeMetaTags('meta[name="description"]');
      removeMetaTags('meta[property="og:description"]');
    }
  }, [seoContent]);

  return (
    <Head>
      <title>{seoContent?.meta_title || title}</title>
      <meta
        name="description"
        content={seoContent?.meta_description || description}
      />
      <meta
        property="og:description"
        content={seoContent?.meta_description || description}
      />
      {seoContent?.canonical_tags && (
        <link rel="canonical" href={seoContent.canonical_tags} />
      )}
      {robotsTagValue && <meta name="robots" content={robotsTagValue} />}
      {!isPDP && <meta name="googlebot" content={metaRobotsContent} />}
      <meta
        name="og:image"
        content="https://www.otrium.nl/wp-content/themes/otrium/images/otrium_nl_2.png"
      />
      {deeplink && (
        <>
          <meta property="al:android:url" content={deeplink} />
          <meta property="al:ios:url" content={deeplink} />
        </>
      )}
      {metaTags && (
        <>
          <React.Fragment>{metaTags}</React.Fragment>
        </>
      )}
      {scripts && scripts}
    </Head>
  );
};
export default PageHead;
