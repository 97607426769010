import { useContext, useEffect, useCallback, createRef, useMemo } from "react";
import { useRouter } from "next/router";
import { MainWrapperDesktop } from "./AddedToCart.styled";
import { Modal } from "src/atoms/Modal";
import AddedToCartContent from "./AddedToCartContent";
import { useOnClickOutside } from "src/hooks/useOnClickOutside";
import { AppCtx } from "src/contexts/app.context";
import { useBreakpoints } from "@otrium/core";

const AddedToCart = (): any => {
  const {
    addedProdToCart,
    setAddedProdToCart,
    cartMinLineItemsTotalPriceDiff,
  } = useContext(AppCtx);

  const { isMobile } = useBreakpoints();
  const router = useRouter();

  const popupRef = createRef<HTMLDivElement>();

  const isOpen = Boolean(addedProdToCart);

  const handleClose = useCallback((): void => {
    setAddedProdToCart(null);
  }, [setAddedProdToCart]);

  const hideActionButtons = useMemo(
    () => router.pathname.startsWith("/cart"),
    [router.pathname]
  );

  useOnClickOutside(popupRef, handleClose);

  useEffect(() => {
    const handleRouteChange = () => {
      handleClose();
    };

    if (isOpen) {
      router.events.on("routeChangeStart", handleRouteChange);
    }

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [handleClose, router.events, isOpen]);

  useEffect((): void | (() => void) => {
    if (isOpen && isMobile) {
      const timer = setTimeout(() => {
        handleClose();
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [handleClose, isOpen, isMobile]);

  if (!isOpen || !addedProdToCart) return null;

  if (isMobile) {
    return (
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        fullWidth
        hasMobileBottomAlign
        fullHeight={false}
      >
        <AddedToCartContent
          {...addedProdToCart}
          hideActionButtons={hideActionButtons}
          cartMinLineItemsTotalPriceDiff={cartMinLineItemsTotalPriceDiff}
          onClose={handleClose}
        />
      </Modal>
    );
  }

  return (
    <MainWrapperDesktop ref={popupRef} isOpen={isOpen}>
      <AddedToCartContent
        {...addedProdToCart}
        hideActionButtons={hideActionButtons}
        cartMinLineItemsTotalPriceDiff={cartMinLineItemsTotalPriceDiff}
        onClose={handleClose}
      />
    </MainWrapperDesktop>
  );
};

export default AddedToCart;
