import {
  Cart,
  LineItem,
  DiscountCodeState,
  DiscountCodeInfo,
  BaseMoney,
} from "src/types/ctgraphql.d";
import { getDiscountCodeIds } from "./totalPrice";

export const getIsDiscountCodeInLineItems = (
  lineItems: LineItem[],
  discountCode: DiscountCodeInfo
): boolean =>
  !!lineItems.find(({ discountedPricePerQuantity }) =>
    discountedPricePerQuantity.find(
      ({ discountedPrice: { includedDiscounts } }) =>
        includedDiscounts.find(({ discount }) =>
          discountCode.discountCode?.cartDiscounts.find(
            ({ id }) => id === discount?.id
          )
        )
    )
  );

export const getDiscountCodeDoesntMatch = (
  cart: Cart | undefined | null
): boolean =>
  !!cart?.discountCodes.find(
    ({ state }) => state !== DiscountCodeState.MatchesCart
  );

export const getLineItemsDiscountPrice = (
  discountCodes: DiscountCodeInfo[],
  lineItems: LineItem[]
): BaseMoney | null => {
  const discountCodeIds = getDiscountCodeIds(discountCodes);
  const lineItemsWithDiscountCode = lineItems.filter((item) =>
    item.discountedPricePerQuantity.some((discountItem) => {
      return discountItem.discountedPrice.includedDiscounts.some(
        (includedDiscount) => {
          return discountCodeIds?.includes(includedDiscount.discount?.id || "");
        }
      );
    })
  );

  if (lineItemsWithDiscountCode.length === 0) {
    return null;
  }

  const lineDiscountPerQuantity = lineItemsWithDiscountCode.flatMap(
    (lineItem) =>
      lineItem.discountedPricePerQuantity.flatMap((discount) =>
        discount.discountedPrice.includedDiscounts
          .filter((includedDiscount) =>
            discountCodeIds?.includes(includedDiscount.discount?.id || "")
          )
          .flatMap((discountPrice) => ({
            discountAmount: discountPrice.discountedAmount,
            quantity: discount.quantity as number,
          }))
      )
  );

  const discountValue = lineDiscountPerQuantity.reduce(
    (lineItemTotalDiscount, discountPerQuantity) => {
      const totalDiscount = (lineItemTotalDiscount.centAmount as number) || 0;

      return {
        ...lineItemsWithDiscountCode[0].discountedPricePerQuantity[0]
          .discountedPrice.value,
        centAmount:
          totalDiscount +
            (discountPerQuantity.discountAmount.centAmount as number) *
              discountPerQuantity.quantity || 0,
      };
    },
    {} as BaseMoney
  );

  return discountValue;
};
