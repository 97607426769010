import { FC, PropsWithChildren, useContext } from "react";
import { Box } from "@otrium/core";
import Link from "next/link";
import { useTheme, Theme } from "@emotion/react";
import { t, Trans } from "@lingui/macro";
import { getI18nRoute } from "src/utils/i18nRoutes";
import { Label } from "src/atoms/Label";
import { AppCtx } from "src/contexts/app.context";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import { Wrapper } from "./FooterLinks.styled";
import { FooterLinksBox } from "./FooterLinksBox";
import { Title } from "./Footer.styled";
import { useLingui } from "@lingui/react";

const TitleContent: FC<PropsWithChildren> = ({ children }) => (
  <Title as="h3" mb={3} fontSize={[2, 2, 2, 2, 3]}>
    {children}
  </Title>
);

export const FooterLinks: FC<PropsWithChildren> = () => {
  const { locale, featureFlags } = useContext(AppCtx);
  const isLoggedIn = useIsLoggedIn();
  const theme: Theme = useTheme();
  const { i18n } = useLingui();

  const routes: Record<string, string> = {
    aboutUs: getI18nRoute({ route: "/about-us", locale }),
    partnerships: getI18nRoute({ route: "/partnerships", locale }),
    press: "https://press.otrium.com/",
    conscious: getI18nRoute({ route: "/conscious", locale }),
    ethicalGovernance: "/ethicalgovernance",
    careers: "https://otrium.homerun.co/?lang=en",
    blog: "/blog",
    faq: getI18nRoute({ route: "/faq", locale }),
    shippingDelivery: getI18nRoute({ route: "/shipping-delivery", locale }),
    returnsRefunds: getI18nRoute({ route: "/returns-refunds", locale }),
    paymentPromotions: getI18nRoute({ route: "/payment-promotions", locale }),
    myProfile: "/my-profile",
    myOrders: "/my-orders",
    favourites: "/favourites",
    freeShopping: "/refer-a-friend",
    codeOfConduct:
      "https://lookbook.otrium.com/reports/sustainability/Code-Of-Conduct.pdf",
    modernSlaveryStatement:
      "https://lookbook.otrium.com/files/2024_modern_slavery_statement_signed.pdf",
  };

  return (
    <Wrapper mt={[5, 5, "48px"]} mb={5} as="nav">
      <FooterLinksBox
        title={<TitleContent>Otrium</TitleContent>}
        isTightColumn={isLoggedIn}
        data-testid="footer-desktop-box-1"
      >
        <li>
          <Box as="a" href={routes.aboutUs}>
            <Trans>About us</Trans>
          </Box>
        </li>
        <li>
          <Box as="a" href={routes.conscious}>
            Ethical impact
          </Box>
        </li>
        <li>
          <Box as="a" href={routes.blog}>
            <Trans>Blog</Trans>
          </Box>
        </li>
        <li>
          <Box as="a" href={routes.careers} mr={2}>
            <Trans>Career</Trans>
          </Box>
          <Label
            type="themed_label"
            color={theme.colors.primary.gold}
            textColor={theme.colors.tone.white}
            value={t(i18n)`We're hiring`}
          />
        </li>
        <li>
          <Box as="a" href={routes.partnerships}>
            <Trans>Partnership</Trans>
          </Box>
        </li>
        <li>
          <Box as="a" href={routes.press} target="_blank">
            <Trans>Press</Trans>
          </Box>
        </li>
        <li>
          <Box as="a" href={routes.ethicalGovernance}>
            Ethical Governance
          </Box>
        </li>
        <li>
          <Box as="a" href={routes.codeOfConduct} target="_blank">
            <Trans>Code of conduct</Trans>
          </Box>
        </li>
        <li>
          <Box as="a" href={routes.modernSlaveryStatement} target="_blank">
            Modern Slavery Statement
          </Box>
        </li>
      </FooterLinksBox>
      {isLoggedIn && (
        <FooterLinksBox
          title={
            <TitleContent>
              <Trans>My account</Trans>
            </TitleContent>
          }
          isTightColumn={isLoggedIn}
          wrapperProps={{
            ml: [0, 0, 0, 0, 0, 5],
          }}
          data-testid="footer-desktop-box-2"
        >
          <li>
            <Link href={routes.myProfile} passHref legacyBehavior>
              <Box as="a">
                <Trans>My profile</Trans>
              </Box>
            </Link>
          </li>
          <li>
            <Box as="a" href={routes.myOrders}>
              <Trans>My orders</Trans>
            </Box>
          </li>
          <li>
            <Link passHref href={routes.favourites} legacyBehavior>
              <Box as="a">
                <Trans>Favourites</Trans>
              </Box>
            </Link>
          </li>
          {featureFlags?.referAFriend && (
            <li>
              <Link passHref href={routes.freeShopping} legacyBehavior>
                <Box as="a">
                  <Trans>Free shopping</Trans>
                </Box>
              </Link>
            </li>
          )}
        </FooterLinksBox>
      )}
      <FooterLinksBox
        title={
          <TitleContent>
            <Trans>Customer service</Trans>
          </TitleContent>
        }
        isTightColumn={isLoggedIn}
        data-testid="footer-desktop-box-3"
        wrapperProps={{
          ml: [0, 0, 0, 0, 0, 5],
        }}
      >
        {[
          {
            href: routes.shippingDelivery,
            child: <Trans>Delivery</Trans>,
          },
          {
            href: routes.returnsRefunds,
            child: <Trans>Returns</Trans>,
          },
          {
            href: routes.paymentPromotions,
            child: <Trans>Payment information</Trans>,
          },
          {
            href: routes.faq,
            child: <Trans>Help center</Trans>,
          },
        ].map(({ href, child }, i) => (
          <li key={`links-box-3-${i}`}>
            <Box as="a" href={href}>
              {child}
            </Box>
          </li>
        ))}
      </FooterLinksBox>
    </Wrapper>
  );
};
