import { FC, InputHTMLAttributes } from "react";
import { Input, Slider, Wrapper } from "./Toggle.styled";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  light?: boolean;
}

const Toggle: FC<Props> = ({ light, ...rest }) => {
  return (
    <Wrapper light={light}>
      <Input type="checkbox" {...rest} />
      <Slider light={light} />
    </Wrapper>
  );
};

export default Toggle;
