/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { useState, useEffect } from "react";
import { useUserWishlistMeta } from "./useUserWishlistMeta";

interface hookData {
  addedToWishlist: boolean | undefined;
}

const useToggleWishlistBrandItem = (brandId: string | undefined): hookData => {
  const [addedToWishlist, setAddedToWishlist] = useState<boolean | undefined>(
    undefined
  );
  const { data: wishlistData } = useUserWishlistMeta();
  const dataWishListBrands =
    wishlistData?.userWishlistMetaData?.wishlist_brand_ids;

  useEffect(() => {
    if (!brandId) {
      setAddedToWishlist(undefined);
    }
    const defaultStateAdded =
      dataWishListBrands && brandId && dataWishListBrands?.indexOf(brandId);

    if (defaultStateAdded === undefined || defaultStateAdded === null) {
      return;
    }
    setAddedToWishlist(defaultStateAdded !== -1);
  }, [dataWishListBrands, brandId]);

  return { addedToWishlist };
};

const useToggleWishlistProductItem = (
  productId: string | undefined
): hookData => {
  const { data: wishlistData } = useUserWishlistMeta();

  const dataWishListProducts =
    wishlistData?.userWishlistMetaData?.wishlist_product_ids;

  const [addedToWishlist, setAddedToWishlist] = useState<boolean | undefined>(
    undefined
  );
  useEffect(() => {
    if (!productId) {
      setAddedToWishlist(undefined);
    }
    const defaultStateAdded =
      productId &&
      dataWishListProducts &&
      dataWishListProducts?.indexOf(productId);

    if (defaultStateAdded === undefined || defaultStateAdded === null) {
      return;
    }
    setAddedToWishlist(defaultStateAdded !== -1);
  }, [dataWishListProducts, productId]);

  return { addedToWishlist };
};

export { useToggleWishlistProductItem, useToggleWishlistBrandItem };
