import gql from "graphql-tag";
import { CarouselResult, ShopType } from "src/types/graphql.d";
import { useQuery } from "@apollo/react-hooks";
import { ApolloError } from "@apollo/client";

interface DefaultSearchCarouselsVars {
  shop_type: ShopType;
}

interface DefaultSearchCarouselsResponse {
  defaultSearchCarousels: CarouselResult | undefined;
}

interface DefaultSearchCarouselsHook {
  data: DefaultSearchCarouselsResponse | undefined;
  loading: boolean;
  error: ApolloError | undefined;
}

export const DEFAULT_SEARCH_CAROUSELS_QUERY = gql`
  query defaultSearchCarousels($shop_type: ProductGender!) {
    defaultSearchCarousels(shop_type: $shop_type) {
      edit_carousels {
        title
        view_all_url
        position
        items {
          id
          name
          image
          url
        }
      }
      brand_carousels {
        title
        view_all_url
        position
        items {
          id
          logo
          slug
          name
        }
      }
    }
  }
`;

const useDefaultSearchCarousels = ({
  shop_type,
}: DefaultSearchCarouselsVars): DefaultSearchCarouselsHook => {
  const { data, loading, error } = useQuery<
    DefaultSearchCarouselsResponse,
    DefaultSearchCarouselsVars
  >(DEFAULT_SEARCH_CAROUSELS_QUERY, {
    variables: {
      shop_type,
    },
    fetchPolicy: "cache-and-network",
  });

  return {
    data,
    loading,
    error,
  };
};

export default useDefaultSearchCarousels;
