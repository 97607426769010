import { FC } from "react";
import { BaseMoney, Money } from "src/types/ctgraphql.d";
import { formatStandardPrice } from "src/utils/ct/priceHelper";
import { Price } from "src/atoms/Price";

interface Props {
  price: Money | BaseMoney;
}

const CTPrice: FC<Props> = ({ price }) => {
  const formattedPrice = formatStandardPrice(price);

  return <Price price={formattedPrice} />;
};

export default CTPrice;
