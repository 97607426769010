const slugWomen = [
  "dame",
  "damen",
  "dames",
  "donna",
  "femme",
  "femme",
  "kobiety",
  "kvinna",
  "mujer",
  "women",
];

const slugMen = [
  "heren",
  "herre",
  "herren",
  "hombre",
  "homme",
  "man",
  "men",
  "mezczyzni",
  "uomo",
  "panowie",
];

const slugKids = [
  "kids",
  "kids-en",
  "kinder",
  "enfant",
  "ninos",
  "bambini",
  "born",
  "barn",
  "dzieci",
  "kinder",
  "enfant",
];

module.exports = {
  slugKids,
  slugMen,
  slugWomen,
};
