import styled from "@emotion/styled";
import { Box } from "@otrium/core";

const Wrapper = styled(Box)`
  width: 100%;
  background: #1d1e20;
  margin-left: -1px;
  padding: 24px 32px 24px;
  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding: 32px 0 8px;
  }
`;

const IconWrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(96px, 1fr));
  grid-row-gap: ${({ theme }) => theme.space[3]}px;
  justify-items: center;
`;

export { Wrapper, IconWrapper };
