import { ReactNode, ReactPortal, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

interface Props {
  selector: string;
  children: ReactNode;
}

// It works only client side because on server side we can't query dom
const Portal = ({ children, selector }: Props): ReactPortal | null => {
  const ref = useRef<Element | DocumentFragment | null>();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector<HTMLDivElement>(selector);
    setMounted(true);
  }, [selector]);

  return mounted && ref.current ? createPortal(children, ref.current) : null;
};

export { Portal };
