import Input from "src/atoms/Input";
import { t, Trans } from "@lingui/macro";
import { Text } from "@otrium/atoms";
import { Box, Flex } from "@otrium/core";
import { FC, useEffect, useState } from "react";
import { FormikProps, useFormik } from "formik";
import * as Yup from "yup";
import FORM_ERROR_MESSAGES from "src/messages/form";
import { useOptIn } from "src/hooks/useOptIn";
import { useSegment } from "src/hooks/useSegment";
import { Button } from "src/atoms/Button";
import { LoadingSpinner } from "src/atoms/LoadingSpinner";
import { Origin } from "src/types/graphql.d";
import { useLingui } from "@lingui/react";

interface FormikValues {
  email: string;
}

interface Props {
  onSubmit: () => void;
}

const SubscribeForm: FC<Props> = ({ onSubmit }) => {
  const { _, i18n } = useLingui();
  const { user, segmentOptIn } = useSegment();
  const [error, setError] = useState<string | null>(null);
  const { optIn, error: optInError } = useOptIn();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (optInError) {
      setError(optInError.message || "Something went wrong");
    } else {
      setError(null);
    }
  }, [optInError]);

  const formik: FormikProps<FormikValues> = useFormik<FormikValues>({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required(FORM_ERROR_MESSAGES.emailRequired)
        .email(FORM_ERROR_MESSAGES.email),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      setError(null);
      const htUser = await user;
      try {
        await optIn({
          email: values.email,
          segment_anonymous_id: htUser?.anonymousId?.(),
          metadata: {
            origin: Origin.GhostWelcomeoptin,
          },
        });
        void segmentOptIn(true, values.email, "ghost-welcomeoptin");
        setLoading(false);
        onSubmit();
      } catch (e) {
        setError((e as Error)?.message || "Something went wrong");
        setLoading(false);
      }
    },
  });
  return loading ? (
    <Flex
      bg="White"
      height="100px"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <LoadingSpinner />
    </Flex>
  ) : (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Box>
        <Input
          {...formik.getFieldProps("email")}
          mb={3}
          type="text"
          placeholder={t(i18n)`Email`}
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          isError={formik.touched.email && formik.errors.email !== undefined}
          aria-invalid={
            formik.touched.email && formik.errors.email !== undefined
          }
          aria-describedby="subscribe-form-email-error-alert"
        />
        {formik.errors.email && formik.touched.email && (
          <Text
            as="p"
            mt={-3}
            mb={2}
            color="semantic.red"
            textAlign="left"
            fontSize={0}
            lineHeight="1.43"
            letterSpacing="normal"
          >
            <span role="alert" id="subscribe-form-email-error-alert">
              {_(formik.errors.email)}
            </span>
          </Text>
        )}
        {error && (
          <Text my={2} color="semantic.red">
            <Trans>{error}</Trans>
          </Text>
        )}
      </Box>
      <Button
        disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
        data-testid="subscribe-form-button"
        variant="primary"
        type="submit"
        fullWidth
      >
        <Trans>Subscribe now</Trans>
      </Button>
    </form>
  );
};

export default SubscribeForm;
