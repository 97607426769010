import { SearchConfiguration } from "src/types/graphql.d";
import { useEffect, useState } from "react";

interface SearchConfigurationResponse {
  searchConfigurationsWeb: SearchConfiguration;
}

const fetcher = (url: string) => fetch(url).then((res) => res.json());

const useSearchConfiguration = (): {
  loading: boolean;
  error: unknown;
  data?: SearchConfigurationResponse;
} => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>(null);
  const [data, setData] = useState<SearchConfigurationResponse>();

  useEffect(() => {
    const getResponse = async () => {
      try {
        setLoading(true);
        const response = (await fetcher("/api/search/config")) as {
          data: SearchConfigurationResponse;
        };
        setData(response.data);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };
    void getResponse();
  }, []);

  return {
    data,
    error,
    loading,
  };
};

export default useSearchConfiguration;
