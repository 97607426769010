import { FC, SVGAttributes } from "react";

const ChevronRightSmall: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="12"
    viewBox="0 0 8 12"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g stroke="#999" strokeWidth="2">
        <path
          d="M8 8L4 4 0 8"
          transform="translate(-390 -196) translate(16 186) translate(326) translate(48 10) rotate(90 4 6)"
        />
      </g>
    </g>
  </svg>
);

export { ChevronRightSmall };
