import { FC, SVGAttributes } from "react";

const Logo: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    width="102"
    height="24"
    viewBox="0 0 102 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_94_1002)">
      <path
        d="M32.6041 9.12503L33.1908 7.90595H29.8543V1.58722H29.4151L21.7906 8.58942V9.12352H23.8852V18.4885C23.8852 21.0926 25.4143 23.8853 28.8283 23.8853C31.2512 23.8853 32.4764 22.4369 32.9978 21.5588L32.7713 21.328C32.3916 21.529 31.9673 21.6327 31.537 21.6297C30.6858 21.6297 29.8543 20.8165 29.8543 19.4616V9.12503H32.6041Z"
        fill="black"
      />
      <path
        d="M52.3277 0C51.4598 0.000357061 50.6272 0.340645 50.0107 0.946917C49.3942 1.55319 49.0437 2.37646 49.0353 3.23782C49.0353 5.53115 51.4385 7.28736 53.8705 6.14974C54.4878 5.86131 54.9795 5.36147 55.2552 4.74206C56.3406 2.31445 54.626 0 52.3277 0Z"
        fill="black"
      />
      <path
        d="M48.9121 7.90594C48.9121 7.90594 49.7421 8.75086 49.7421 10.9235V23.5655H55.4331V7.90594H48.9121Z"
        fill="black"
      />
      <path
        d="M45.2315 7.43677C41.6564 7.43677 40.5346 11.34 40.5346 11.34V7.90599H34.2021C34.2021 7.90599 35.0321 8.75091 35.0321 10.9235V23.5655H40.7216V13.8068C40.6702 12.8388 40.9433 11.8811 41.4983 11.0835C41.9665 10.3623 42.9499 10.495 43.108 11.1695C43.3223 12.1155 43.9942 13.0388 45.4504 13.0539C47.2531 13.0735 48.3688 11.7156 48.2731 10.1314C48.1697 8.57891 46.8913 7.43677 45.2315 7.43677Z"
        fill="black"
      />
      <path
        d="M67.1331 7.90594C67.1331 7.90594 67.963 8.75085 67.963 10.9235V19.9761C67.963 19.9761 66.9112 21.6735 65.4641 21.6735C64.6023 21.6735 63.6705 20.8059 63.6705 18.7193V7.90594H57.107C57.107 7.90594 57.9369 8.75085 57.9369 10.9235V19.273C57.9369 21.0156 58.1239 21.9164 58.6255 22.6014C59.2518 23.4402 60.5377 24 61.8525 24C63.1065 24 64.5795 23.5338 65.8046 22.7251C66.3716 22.3705 67.8186 21.331 67.9539 21.2344C67.919 22.8941 67.9646 23.5655 67.9646 23.5655H73.6586L73.6996 7.8999H67.1331V7.90594Z"
        fill="black"
      />
      <path
        d="M101.316 8.8354C100.69 7.99652 99.4037 7.43677 98.0873 7.43677C94.4102 7.43677 91.9613 10.231 91.9613 10.231C91.8851 9.74276 91.6974 9.27829 91.4125 8.87312C90.7878 8.03424 89.5094 7.48656 88.199 7.47449C84.5508 7.43979 82.6127 9.49624 82.1004 10.2416C82.1354 8.58192 82.0913 7.91052 82.0913 7.91052H75.5746C75.5746 7.91052 76.3423 8.69508 76.397 10.7017L76.3545 23.5821L82.0883 23.5746V11.5044C82.0883 11.5044 83.1402 9.80704 84.5858 9.80704C85.4492 9.80704 86.381 10.6761 86.381 12.7627V23.5806H92.1148L92.1011 11.3158C92.4173 10.8798 93.3172 9.79799 94.4755 9.79799C95.3389 9.79799 96.2708 10.667 96.2708 12.7522V23.5806L101.964 23.5731L101.998 12.1637C101.998 10.4226 101.818 9.52038 101.316 8.8354Z"
        fill="black"
      />
      <path
        d="M10.7456 2.55432C8.61999 2.55432 6.54215 3.17993 4.7748 4.35204C3.00745 5.52414 1.62997 7.1901 0.816552 9.13924C0.00313063 11.0884 -0.209697 13.2332 0.204981 15.3024C0.619659 17.3715 1.64322 19.2722 3.14623 20.764C4.64923 22.2558 6.56418 23.2718 8.64891 23.6834C10.7336 24.095 12.8945 23.8837 14.8583 23.0764C16.8221 22.269 18.5005 20.9018 19.6814 19.1476C20.8623 17.3934 21.4926 15.3311 21.4926 13.2213C21.4926 10.3923 20.3604 7.67907 18.3449 5.67861C16.3294 3.67816 13.5959 2.55432 10.7456 2.55432V2.55432ZM16.1966 10.421C15.9428 11.4772 15.207 13.5095 14.602 14.8885C13.3768 17.6978 12.1182 19.7347 10.8763 20.9855C9.81222 22.022 8.39245 22.8699 6.79483 22.2211C4.97071 21.4818 4.89167 19.8961 4.93423 18.4205C4.99807 16.6915 5.65324 14.3665 6.88451 11.5587C8.11579 8.75084 9.37899 6.68835 10.6103 5.46172C11.6743 4.42369 13.0211 3.50937 14.6674 4.17776C16.8624 5.06793 16.9673 7.35523 16.1966 10.421Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_94_1002">
        <rect width="102" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { Logo };
