import { useMutation } from "@apollo/react-hooks";
import { ApolloError } from "@apollo/client";
import gql from "graphql-tag";
import { useCallback } from "react";
import { Mutation } from "src/types/graphql.d";

interface UpdateAccountData {
  updateAccount: Mutation["updateAccount"];
}
interface UpdateAccountVars {
  language: string;
}

interface UpdateAccountHook {
  data?: UpdateAccountData;
  loading: boolean;
  error?: ApolloError;
  updateAccount: (language: string) => void;
}

const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($language: String!) {
    updateAccount(input: { language: $language }) {
      email
      language
      shop_language
    }
  }
`;

const useUpdateAccount = ({
  refetchQueries = [],
  onCompleted,
}: {
  refetchQueries?: string[];
  onCompleted?: (updateAccountData: UpdateAccountData) => void;
} = {}): UpdateAccountHook => {
  const [updateAccountMutation, { loading, error }] = useMutation<
    UpdateAccountData,
    UpdateAccountVars
  >(UPDATE_ACCOUNT, {
    refetchQueries,
    onCompleted,
  });

  const updateAccount = useCallback(
    (language: string) => {
      return updateAccountMutation({
        variables: { language },
      });
    },
    [updateAccountMutation]
  );

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  return { updateAccount, loading, error };
};

export { useUpdateAccount };
