import { FC, SVGAttributes } from "react";

interface Props extends SVGAttributes<SVGSVGElement> {
  svgPrefixId: string;
}

const FlagDkRound: FC<Props> = ({ svgPrefixId, ...rest }) => {
  const id = `${svgPrefixId}-flag-dk-round`;
  const circleId = `circle-${id}`;
  const maskId = `mask-${id}`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...rest}
    >
      <defs>
        <circle id={circleId} cx="10" cy="10" r="10" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-438 -1502) translate(302 1502) translate(136)">
          <mask id={maskId} fill="#fff">
            <use xlinkHref={`#${circleId}`} />
          </mask>
          <use fill="#FFF" xlinkHref={`#${circleId}`} />
          <path
            fill="#DA022E"
            d="M0 0H5V8.333H0zM0 11.667H5V20H0zM8.333 0H20V8.333H8.333zM8.333 11.667H20V20H8.333z"
            mask={`url(#${maskId})`}
          />
          <circle
            cx="10"
            cy="10"
            r="9.75"
            stroke="#000"
            strokeOpacity=".2"
            strokeWidth=".5"
            mask={`url(#${maskId})`}
          />
        </g>
      </g>
    </svg>
  );
};

export { FlagDkRound };
