import styled from "@emotion/styled";
import { Box } from "@otrium/core";
import { WrapperProps } from "src/atoms/LanguageMenu/LanguageMenuNew";

const Wrapper = styled.ul<WrapperProps>`
  min-width: 180px;

  ${({ limitHeight }) =>
    limitHeight &&
    `
      max-height: 260px;
      overflow-y: scroll;
    `}
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Item = styled.li<{ isActive?: boolean }>`
  font-size: 16px;
  font-weight: normal;
  > a {
    color: ${({ theme }) => theme.colors.tone.black};
  }
  &:hover {
    background: ${({ theme }) => theme.colors.tone.sand};
  }

  ${({ isActive, theme }) =>
    isActive &&
    `
      background: ${theme.colors.tone.sand};
    `}
`;

const ItemLink = styled(Box)`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 43px;
  padding-left: ${({ theme }) => theme.space[5]}px;
  padding-right: ${({ theme }) => theme.space[5]}px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0.4px;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding-left: ${({ theme }) => theme.space[3]}px;
    padding-right: ${({ theme }) => theme.space[3]}px;
  }

  svg {
    margin-right: ${({ theme }) => theme.space[2]}px;
  }
`;

const Currency = styled.small<{ newDesign?: boolean }>`
  color: #999;
  font-size: 16px;
  margin-left: auto;
`;

export { Wrapper, Item, ItemLink, Currency };
