import { FC, SVGAttributes } from "react";

interface Props extends SVGAttributes<SVGSVGElement> {
  svgPrefixId: string;
}

const FlagUsRound: FC<Props> = ({ svgPrefixId, ...rest }) => {
  const id = `${svgPrefixId}-flag-dk-round`;
  const circleId = `circle-${id}`;
  const pathId = `path-${id}`;
  const maskId = `mask-${id}`;
  const mask2Id = `mask-2-${id}`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...rest}
    >
      <defs>
        <circle id={circleId} cx="10" cy="10" r="10" />
        <path id={pathId} d="M0 0H15.2V10.85H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-1118 -1502) translate(302 1502) translate(816)">
          <g>
            <mask id={maskId} fill="#fff">
              <use xlinkHref={`#${circleId}`} />
            </mask>
            <use fill="#FFF" xlinkHref={`#${circleId}`} />
            <g mask={`url(#${maskId})`}>
              <g>
                <path
                  fill="#C60B1E"
                  fillRule="nonzero"
                  d="M0.833 0H38.833V20H0.833z"
                  transform="translate(-5.833)"
                />
                <path
                  fill="#000"
                  fillRule="nonzero"
                  stroke="#FFF"
                  strokeWidth="1.846"
                  d="M5.833 2.388h20m0 3.077h-20m0 3.077h20m0 3.077h-20m0 3.077h20m0 3.077h-20"
                  transform="translate(-5.833)"
                />
                <g transform="translate(-5.833) translate(.625)">
                  <mask id={mask2Id} fill="#fff">
                    <use xlinkHref={`#${pathId}`} />
                  </mask>
                  <use
                    fill="#039"
                    fillRule="nonzero"
                    xlinkHref={`#${pathId}`}
                  />
                  <g mask={`url(#${mask2Id})`}>
                    <g fill="#FFF" fillRule="nonzero">
                      <g>
                        <g>
                          <path
                            d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                            transform="translate(.677 .462) translate(2.533) translate(1.267 1.077)"
                          />
                          <g>
                            <path
                              d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                              transform="translate(.677 .462) translate(2.533) translate(1.267 1.077) translate(0 2.154)"
                            />
                          </g>
                          <g>
                            <path
                              d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                              transform="translate(.677 .462) translate(2.533) translate(1.267 1.077) translate(0 4.308)"
                            />
                          </g>
                          <g>
                            <path
                              d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                              transform="translate(.677 .462) translate(2.533) translate(1.267 1.077) translate(0 6.462)"
                            />
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <path
                                d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                                transform="translate(.677 .462) translate(5.067)"
                              />
                              <g>
                                <path
                                  d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                                  transform="translate(.677 .462) translate(5.067) translate(0 2.154)"
                                />
                              </g>
                              <g>
                                <path
                                  d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                                  transform="translate(.677 .462) translate(5.067) translate(0 4.308)"
                                />
                              </g>
                              <g>
                                <path
                                  d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                                  transform="translate(.677 .462) translate(5.067) translate(0 6.462)"
                                />
                              </g>
                            </g>
                            <g>
                              <path
                                d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                                transform="translate(.677 .462) translate(5.067) translate(0 8.615)"
                              />
                            </g>
                          </g>
                          <g>
                            <path
                              d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                              transform="translate(.677 .462) translate(5.067) translate(1.267 1.077)"
                            />
                            <g>
                              <path
                                d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                                transform="translate(.677 .462) translate(5.067) translate(1.267 1.077) translate(0 2.154)"
                              />
                            </g>
                            <g>
                              <path
                                d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                                transform="translate(.677 .462) translate(5.067) translate(1.267 1.077) translate(0 4.308)"
                              />
                            </g>
                            <g>
                              <path
                                d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                                transform="translate(.677 .462) translate(5.067) translate(1.267 1.077) translate(0 6.462)"
                              />
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <path
                                d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                                transform="translate(.677 .462) translate(5.067) translate(2.533)"
                              />
                              <g>
                                <path
                                  d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                                  transform="translate(.677 .462) translate(5.067) translate(2.533) translate(0 2.154)"
                                />
                              </g>
                              <g>
                                <path
                                  d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                                  transform="translate(.677 .462) translate(5.067) translate(2.533) translate(0 4.308)"
                                />
                              </g>
                              <g>
                                <path
                                  d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                                  transform="translate(.677 .462) translate(5.067) translate(2.533) translate(0 6.462)"
                                />
                              </g>
                            </g>
                            <g>
                              <path
                                d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                                transform="translate(.677 .462) translate(5.067) translate(2.533) translate(0 8.615)"
                              />
                            </g>
                          </g>
                          <g>
                            <path
                              d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                              transform="translate(.677 .462) translate(5.067) translate(2.533) translate(1.267 1.077)"
                            />
                            <g>
                              <path
                                d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                                transform="translate(.677 .462) translate(5.067) translate(2.533) translate(1.267 1.077) translate(0 2.154)"
                              />
                            </g>
                            <g>
                              <path
                                d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                                transform="translate(.677 .462) translate(5.067) translate(2.533) translate(1.267 1.077) translate(0 4.308)"
                              />
                            </g>
                            <g>
                              <path
                                d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                                transform="translate(.677 .462) translate(5.067) translate(2.533) translate(1.267 1.077) translate(0 6.462)"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <path
                              d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                              transform="translate(.677 .462) translate(10.133)"
                            />
                            <g>
                              <path
                                d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                                transform="translate(.677 .462) translate(10.133) translate(0 2.154)"
                              />
                            </g>
                            <g>
                              <path
                                d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                                transform="translate(.677 .462) translate(10.133) translate(0 4.308)"
                              />
                            </g>
                            <g>
                              <path
                                d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                                transform="translate(.677 .462) translate(10.133) translate(0 6.462)"
                              />
                            </g>
                          </g>
                          <g>
                            <path
                              d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                              transform="translate(.677 .462) translate(10.133) translate(0 8.615)"
                            />
                          </g>
                        </g>
                        <g>
                          <path
                            d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                            transform="translate(.677 .462) translate(10.133) translate(1.267 1.077)"
                          />
                          <g>
                            <path
                              d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                              transform="translate(.677 .462) translate(10.133) translate(1.267 1.077) translate(0 2.154)"
                            />
                          </g>
                          <g>
                            <path
                              d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                              transform="translate(.677 .462) translate(10.133) translate(1.267 1.077) translate(0 4.308)"
                            />
                          </g>
                          <g>
                            <path
                              d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                              transform="translate(.677 .462) translate(10.133) translate(1.267 1.077) translate(0 6.462)"
                            />
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                            transform="translate(.677 .462) translate(12.667)"
                          />
                          <g>
                            <path
                              d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                              transform="translate(.677 .462) translate(12.667) translate(0 2.154)"
                            />
                          </g>
                          <g>
                            <path
                              d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                              transform="translate(.677 .462) translate(12.667) translate(0 4.308)"
                            />
                          </g>
                          <g>
                            <path
                              d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                              transform="translate(.677 .462) translate(12.667) translate(0 6.462)"
                            />
                          </g>
                        </g>
                        <g>
                          <path
                            d="M0.59 0L0.951 1.113 0.004 0.425 1.175 0.425 0.228 1.113z"
                            transform="translate(.677 .462) translate(12.667) translate(0 8.615)"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <circle
            cx="10"
            cy="10"
            r="9.75"
            stroke="#000"
            strokeOpacity=".2"
            strokeWidth=".5"
          />
        </g>
      </g>
    </svg>
  );
};

export { FlagUsRound };
