import styled from "@emotion/styled";
import { Flex } from "@otrium/core";
import { HyperLink } from "src/atoms/HyperLink";
import { CloseButton } from "src/atoms/CloseButton";

const ModalWrapper = styled(Flex)`
  width: 100%;
  padding: ${({ theme }) =>
    `${theme.space.space64} ${theme.space.space16} ${theme.space.space32}`};
  align-items: center;
  flex-direction: column;
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding: ${({ theme }) =>
      `${theme.space.space64} ${theme.space.space32} ${theme.space.space32}`};
    width: 456px;
    max-width: 100%;
  }
`;

const InlineHyperLink = styled(HyperLink)`
  display: inline;
  text-decoration: underline;
`;

const StyledCloseButton = styled(CloseButton)`
  right: ${({ theme }) => theme.space[3]}px;
  top: ${({ theme }) => theme.space[4]}px;
  position: absolute;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    right: ${({ theme }) => theme.space[4]}px;
    top: ${({ theme }) => theme.space[5]}px;
  }
`;

export { ModalWrapper, InlineHyperLink, StyledCloseButton };
