import { FC, SVGAttributes } from "react";

export const CustomerService: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.375 4.5C17.1382 4.5 21 7.5225 21 11.25C21 14.9775 17.1382 18 12.375 18C11.895 18 11.4225 17.9692 10.9643 17.91L6.23925 19.719L6.87075 16.4475C4.965 15.2093 3.75 13.3402 3.75 11.25C3.75 7.5225 7.61175 4.5 12.375 4.5Z"
      stroke="#333333"
      strokeWidth="2"
    />
    <path
      d="M9.25 11.25C9.25 11.3881 9.13807 11.5 9 11.5C8.86193 11.5 8.75 11.3881 8.75 11.25C8.75 11.1119 8.86193 11 9 11C9.13807 11 9.25 11.1119 9.25 11.25Z"
      fill="black"
      stroke="#333333"
    />
    <path
      d="M12.375 12C12.7892 12 13.125 11.6642 13.125 11.25C13.125 10.8358 12.7892 10.5 12.375 10.5C11.9608 10.5 11.625 10.8358 11.625 11.25C11.625 11.6642 11.9608 12 12.375 12Z"
      fill="black"
    />
    <path
      d="M15.75 12C16.1642 12 16.5 11.6642 16.5 11.25C16.5 10.8358 16.1642 10.5 15.75 10.5C15.3358 10.5 15 10.8358 15 11.25C15 11.6642 15.3358 12 15.75 12Z"
      fill="black"
    />
  </svg>
);
