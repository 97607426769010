import styled from "@emotion/styled";

const StyledLabel = styled.div<{
  color: string;
  background: string;
  showBackground: boolean;
}>`
  display: inline-block;
  padding: 2px 8px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.4px;
  cursor: default;
  text-transform: lowercase;
  &:first-letter {
    text-transform: capitalize;
  }

  color: ${({ color }) => color};
  background: ${({ background }) => background};

  ${({ showBackground }) =>
    !showBackground &&
    `
        background: none;
        line-height: 16px;
        padding: 0;
      `};
`;

export { StyledLabel };
