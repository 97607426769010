import { isClient } from "src/utils/isClient";

// NOTE: `sign_in_platform` and `sign_up_platform` values from
// https://otrium.atlassian.net/wiki/spaces/SS/pages/2399404159/Signed+Up+Signed+In+Signed+Out
//
// TODO: Remove this enum and manual setup of 'user.platform' inside [...nextauth].ts. Instead
// of thi use 'userPreferences.platform' field in 'useSegment' or ask API team to add 'platform' field
// in responses of new GraphQL sign methods. So 'platform' will be available in 'session.user'

enum SignPlatform {
  APPLE = "apple",
  EMAIL = "email",
  FACEBOOK = "facebook",
  GOOGLE = "google",
  GUEST_LOGIN = "guest_login",
  SOFT_LOGIN = "soft_login",
  WOO_SHOP_TOKEN = "woo_shop_token",
}

enum SignEvent {
  APPLE_SIGN_IN_STARTED = "APPLE_SIGN_IN_STARTED",
  EMAIL_REGISTRATION_STARTED = "EMAIL_REGISTRATION_STARTED",
  FACEBOOK_SIGN_IN_STARTED = "FACEBOOK_SIGN_IN_STARTED",
  FACEBOOK_SIGN_UP_STARTED = "FACEBOOK_SIGN_UP_STARTED",
  FORGOT_PASSWORD_FLOW_STARTED = "FORGOT_PASSWORD_FLOW_STARTED",
  GOOGLE_SIGN_IN_STARTED = "GOOGLE_SIGN_IN_STARTED",
  GOOGLE_SIGN_UP_STARTED = "GOOGLE_SIGN_UP_STARTED",
  GUEST_LOGIN_STARTED = "GUEST_LOGIN_STARTED",
  SIGN_IN_FORM_SUBMITTED = "SIGN_IN_FORM_SUBMITTED",
  SIGN_UP_FORM_SUBMITTED = "SIGN_UP_FORM_SUBMITTED",
  SOFT_LOGIN_STARTED = "SOFT_LOGIN_STARTED",
  WOO_SHOP_TOKEN_SIGN_IN_STARTED = "WOO_SHOP_TOKEN_LOGIN_STARTED",
}

const defaultSegmentProperties = {
  request_source: "webReact",
};

enum EVENT_NAME {
  SIGN_IN = "Sign In Started",
  SIGN_UP = "Sign Up Started",
}

enum KEY {
  SIGN_IN = "sign_in_platform",
  SIGN_UP = "sign_up_platform",
}

export type AuthMetadata = {
  origin?: string;
};

const segment = {
  signHandler: (type: SignEvent, metadata?: AuthMetadata): void => {
    if (!isClient) return;

    const track = (
      eventName: EVENT_NAME,
      otherProperties?: { [key in KEY]?: SignPlatform }
    ) => {
      void window.htevents.track(eventName, {
        ...defaultSegmentProperties,
        ...otherProperties,
        metadata,
      });
      window.dataLayer.push({
        event: eventName,
        ...{
          ...defaultSegmentProperties,
          ...otherProperties,
          metadata,
        },
      });
      void window.analytics.track(eventName, {
        ...defaultSegmentProperties,
        ...otherProperties,
        metadata,
      });
    };

    switch (type) {
      case SignEvent.APPLE_SIGN_IN_STARTED:
        track(EVENT_NAME.SIGN_IN, { [KEY.SIGN_IN]: SignPlatform.APPLE });
        break;
      case SignEvent.FACEBOOK_SIGN_IN_STARTED:
        track(EVENT_NAME.SIGN_IN, { [KEY.SIGN_IN]: SignPlatform.FACEBOOK });
        break;
      case SignEvent.GOOGLE_SIGN_IN_STARTED:
        track(EVENT_NAME.SIGN_IN, { [KEY.SIGN_IN]: SignPlatform.GOOGLE });
        break;
      case SignEvent.FACEBOOK_SIGN_UP_STARTED:
        track(EVENT_NAME.SIGN_UP, { [KEY.SIGN_UP]: SignPlatform.FACEBOOK });
        break;
      case SignEvent.GOOGLE_SIGN_UP_STARTED:
        track(EVENT_NAME.SIGN_UP, { [KEY.SIGN_UP]: SignPlatform.GOOGLE });
        break;
      case SignEvent.SIGN_IN_FORM_SUBMITTED:
        track(EVENT_NAME.SIGN_IN, { [KEY.SIGN_IN]: SignPlatform.EMAIL });
        break;
      case SignEvent.SIGN_UP_FORM_SUBMITTED:
        track(EVENT_NAME.SIGN_UP, { [KEY.SIGN_UP]: SignPlatform.EMAIL });
        break;
      case SignEvent.SOFT_LOGIN_STARTED:
        track(EVENT_NAME.SIGN_IN, { [KEY.SIGN_IN]: SignPlatform.SOFT_LOGIN });
        break;
      case SignEvent.WOO_SHOP_TOKEN_SIGN_IN_STARTED:
        track(EVENT_NAME.SIGN_IN, {
          [KEY.SIGN_IN]: SignPlatform.WOO_SHOP_TOKEN,
        });
        break;
    }
  },
};

export { segment, SignPlatform, SignEvent, defaultSegmentProperties };
