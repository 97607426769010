/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useCallback } from "react";
import { WISHLISTS } from "src/hooks/useWishlistProducts";
import { USER_WISHLIST_META } from "src/hooks/useUserWishlistMeta";
import { UserWishlistMeta, WishlistItemType } from "src/types/graphql.d";

interface userWishlistMeta {
  userWishlistMetaData: UserWishlistMeta;
}

const DELETE_WISHLIST_PRODUCT = gql`
  mutation deleteWishlistItem(
    $item_type: WishlistItemType!
    $item_id: String!
  ) {
    deleteWishlistItem(input: { item_type: $item_type, item_id: $item_id }) {
      id
      item_type
      item_id
      created_at
    }
  }
`;

const useDeleteWishlistItem = ({
  refetchQueries,
}: {
  refetchQueries: string[];
}) => {
  const [deleteWishListItemMutation, { loading, data }] = useMutation(
    DELETE_WISHLIST_PRODUCT,
    {
      refetchQueries,
    }
  );

  const deleteWishListItem = useCallback(
    (item_type: WishlistItemType, item_id: string, queryVars: any) => {
      return deleteWishListItemMutation({
        variables: { item_type, item_id },
        optimisticResponse: true,
        update: (cache) => {
          if (item_type === WishlistItemType.Brand) {
            try {
              const wishlistMeta = cache.readQuery({
                query: USER_WISHLIST_META,
              }) as userWishlistMeta;
              const newBrandItems =
                wishlistMeta?.userWishlistMetaData?.wishlist_brand_ids?.filter(
                  (item: string) => item !== item_id
                );
              cache.writeQuery({
                query: USER_WISHLIST_META,
                data: {
                  userWishlistMetaData: {
                    ...wishlistMeta.userWishlistMetaData,
                    wishlist_brand_ids: newBrandItems,
                  },
                },
              });
            } catch (err) {
              // console.log("error deleting brand", err);
            }
          }
          // if we try to delete a product item
          if (item_type === WishlistItemType.Product) {
            try {
              const wishlistMeta = cache.readQuery({
                query: USER_WISHLIST_META,
              }) as userWishlistMeta;

              const newProductItems =
                wishlistMeta?.userWishlistMetaData?.wishlist_product_ids?.filter(
                  (productId: string) => productId !== item_id
                );
              cache.writeQuery({
                query: USER_WISHLIST_META,
                data: {
                  userWishlistMetaData: {
                    ...wishlistMeta.userWishlistMetaData,
                    wishlist_product_ids: newProductItems,
                    wishlist_product_count:
                      wishlistMeta.userWishlistMetaData.wishlist_product_count -
                      1,
                  },
                },
              });
            } catch (err) {
              // console.log("err...", err);
            }
          }
          // if we try to delete wishlist product from wishlist
          try {
            const prevData = cache.readQuery({
              query: WISHLISTS,
              variables: queryVars,
            });

            // @ts-ignore
            const newItems = prevData?.wishlistProducts.items.filter(
              (item: { id: string }) => item.id !== item_id
            );

            cache.writeQuery({
              query: WISHLISTS,
              variables: queryVars,
              data: {
                wishlistProducts: {
                  items: newItems,
                  // @ts-ignore
                  total: prevData.wishlistProducts.total,
                  __typename: "WishlistProductCollection",
                },
              },
            });
          } catch (err) {
            // EMPTY CACHE of non existing
          }
        },
      });
    },
    [deleteWishListItemMutation]
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  return { deleteWishListItem, loading, data };
};

export { useDeleteWishlistItem };
