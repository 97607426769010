import { getI18nRoute } from "src/utils/i18nRoutes";
import { ShopType } from "src/types/graphql.d";
import { Locale } from "lib/Intl";

const getProductCategoryRoute = (
  shopType: ShopType,
  locale: Locale,
  productCategorySlug: string
): string => {
  return `${getI18nRoute({
    route: `/${shopType}`,
    locale,
  })}/${productCategorySlug}`;
};
export default getProductCategoryRoute;
