import { FC, SVGAttributes } from "react";

const FlagEur: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1">
      <path fill="#039" d="M0 2H16V14H0z"></path>
      <path
        fill="#FC0"
        d="M8 11.69l.16.5h.524l-.424.31.163.5-.424-.31-.422.31.162-.5-.423-.31h.522l.162-.5zm-2.158-.582l.161.5h.523l-.423.31.162.5-.423-.31-.423.31.16-.5-.421-.31h.523l.161-.5zm4.316 0l.16.5h.524l-.423.31.162.5-.424-.31-.422.31.161-.5-.422-.31h.522l.162-.5zm-5.896-1.59l.161.5h.523l-.423.309.162.5-.423-.31-.422.31.16-.5-.422-.31H4.1l.162-.5zm7.476 0l.16.5h.524l-.424.309.162.5-.423-.31-.422.31.16-.5-.421-.31h.522l.162-.5zM3.684 7.344l.16.5h.524l-.423.308.162.502-.424-.31-.422.31.161-.502L3 7.845h.522l.162-.5zm8.632 0l.161.5H13l-.423.308.162.502-.423-.31-.423.31.16-.502-.421-.308h.522l.162-.5zM4.262 5.172l.161.5h.523l-.423.31.162.5-.423-.309-.422.31.16-.5-.422-.31H4.1l.162-.5zm7.476 0l.16.5h.524l-.424.31.162.5-.423-.309-.422.31.16-.502-.421-.308h.522l.162-.5zm-5.896-1.59l.161.5h.523l-.423.31.162.5-.423-.31-.423.31.16-.5-.421-.31h.523l.161-.5zm4.316 0l.16.5h.524l-.423.31.162.5-.424-.31-.422.31.161-.5-.422-.31h.522l.162-.5zM8 3l.16.5h.524l-.424.31.163.5L7.999 4l-.422.31.162-.5-.423-.31h.522L8 3z"
      ></path>
    </g>
  </svg>
);

export { FlagEur };
