import { FC } from "react";
import { Box, Flex } from "@otrium/core";
import { Trans } from "@lingui/macro";
import { Term, StyledText, Title } from "./NoSearchResults.styled";
import { CheckIcon } from "src/icons/CheckIcon";
import { Container } from "src/atoms/Container";

interface Props {
  term: string;
}

const NoSearchResults: FC<Props> = ({ term }) => {
  return (
    <Box py={[4, 4, 4, 5]} data-testid="search-no-results" bg="tone.sand">
      <Container>
        <Flex
          flexDirection={["column", "column", "column", "row"]}
          flexWrap="wrap"
        >
          <Title as="h4">
            <Trans>No results for</Trans>
          </Title>
          <Term as="h4" ml={[0, 0, 0, 2]} mt={[2, 2, 2, 0]}>
            ‘{term}’
          </Term>
        </Flex>
        <StyledText fontWeight={600} my={3}>
          <Trans>Try again with a different search term.</Trans>
        </StyledText>
        <Flex alignItems="center">
          <CheckIcon />
          <StyledText ml={2}>
            <Trans>Check the spelling of your search term</Trans>
          </StyledText>
        </Flex>
        <Flex mt={2} alignItems="center">
          <CheckIcon />
          <StyledText ml={2}>
            <Trans>Make your search term less specific</Trans>
          </StyledText>
        </Flex>
      </Container>
    </Box>
  );
};

export default NoSearchResults;
