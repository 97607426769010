import styled from "@emotion/styled";

const StyledProductLabels = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -8px;
  margin-left: -8px;
`;

const StyledLabelWrapper = styled.div`
  margin-top: 8px;
  margin-left: 8px;
`;

export { StyledProductLabels, StyledLabelWrapper };
