import { useEffect, useState } from "react";

const useLockBodyScroll = (
  locked: boolean,
  modalRef?: React.RefObject<HTMLElement>
): void => {
  const [actualLocked, setActualLocked] = useState(locked);

  useEffect(() => {
    requestAnimationFrame(() => setActualLocked(locked));
  }, [locked]);

  useEffect(() => {
    if (actualLocked) {
      // Added a timeout here, so the cleanup of the previous useLockBodyScroll hook
      // can be called before this executes
      document.body.classList.add("no-scroll");
      const hiddenTimer = setTimeout(() => {
        document.body.style.overflowY = "hidden";
      }, 500);

      const preventTouchMove = (event: TouchEvent) => {
        if (
          modalRef &&
          modalRef.current &&
          !modalRef.current.contains(event.target as Node)
        ) {
          event.preventDefault();
        }
      };
      if (modalRef) {
        document.addEventListener("touchmove", preventTouchMove, {
          passive: false,
        });
      }

      return () => {
        document.body.classList.remove("no-scroll");
        document.body.style.overflowY = "visible";
        document.removeEventListener("touchmove", preventTouchMove);
        clearTimeout(hiddenTimer);
      };
    }

    return;
  }, [actualLocked, modalRef]);
};

export { useLockBodyScroll };
