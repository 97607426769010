// TODO: Move this to translations file when available

// Time translations
const MILLISECONDS_PER_SECOND = 1000;
const SECONDS_PER_MINUTE = 60;
const MINUTES_PER_HOUR = 60;
const HOURS_PER_DAY = 24;

interface RemainingTime {
  days: number;
  hours: number;
  minutes: number;
}

const calculateTimeUnit = (
  difference: number,
  unitValue: number
): [number, number] => {
  const unit = Math.floor(difference / unitValue);
  difference -= unit * unitValue;
  return [unit, difference];
};

export const calculateRemainingTime = (difference: number): RemainingTime => {
  let days;
  let hours;
  let minutes;

  [days, difference] = calculateTimeUnit(
    difference,
    MILLISECONDS_PER_SECOND *
      SECONDS_PER_MINUTE *
      MINUTES_PER_HOUR *
      HOURS_PER_DAY
  );
  [hours, difference] = calculateTimeUnit(
    difference,
    MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE * MINUTES_PER_HOUR
  );
  [minutes, difference] = calculateTimeUnit(
    difference,
    MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE
  );

  return { days, hours, minutes };
};
