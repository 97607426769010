import { FC, PropsWithChildren } from "react";
import { Box, BoxProps } from "@otrium/core";

const Container: FC<PropsWithChildren<BoxProps>> = ({
  maxWidth = 1264,
  sx,
  ...rest
}) => (
  // @ts-expect-error Fix typing issue inside the DS
  <Box
    sx={{
      maxWidth,
      mx: "auto",
      px: 3,
      ...sx,
    }}
    {...rest}
  />
);

export default Container;
