import { slugWomen, slugMen, slugKids } from "const/genderSlugs";

// product.gender is returned from gql in the language of the product
// it should be returned in english
interface Props {
  gender: string | undefined;
}

export function localeFix({ gender }: Props): string {
  const defaultGender = "women";
  const genderToLower = (gender && gender.toLowerCase()) || defaultGender;
  if (slugWomen.includes(genderToLower)) return "women";
  if (slugMen.includes(genderToLower)) return "men";
  if (slugKids.includes(genderToLower)) return "kids";
  return defaultGender;
}

export function isGender(gender: string): boolean {
  return [...slugWomen, ...slugMen, ...slugKids].includes(gender);
}

export function localeFixToNl({ gender }: { gender: string }): string {
  if (slugWomen.includes(gender)) return "dames";
  if (slugMen.includes(gender)) return "heren";
  if (slugKids.includes(gender)) return "kids";
  return "dames";
}
