import styled from "@emotion/styled";

const StyledGenderMenu = styled.nav<{ isInHeader: boolean }>`
  background: ${({ theme }) => theme.colors.tone.white};
  padding: ${({ isInHeader, theme }) => (isInHeader ? 0 : theme.space[3])}px;
`;

const StyledGenderList = styled.ul<{ isInHeader: boolean }>`
  height: 40px;
  display: flex;
  border-radius: 3px;
  background: ${({ theme, isInHeader }) =>
    isInHeader ? "initial" : theme.colors.tone.marble};
  max-width: 288px;
  margin: 0 auto;
`;

const StyledGenderListItem = styled.li<{ isInHeader: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  font-size: ${({ theme }) => theme.fontSizes[0]}px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.tone.anthraciteLight};
  text-transform: capitalize;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: calc(100% - 16px);
    width: 1px;
    margin: auto;
    background: ${({ theme, isInHeader }) =>
      isInHeader ? "initial" : theme.colors.primary.platinumLight};
  }

  &:first-of-type:after {
    display: none;
  }
`;

const StyledGenderLink = styled.a<{ isInHeader: boolean; active: boolean }>`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${({ isInHeader, theme }) =>
    isInHeader
      ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${theme.space.space0} ${theme.space.space32}`
      : // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${theme.space.space0} ${theme.space.space16}`};
  font-size: ${({ theme }) => theme.fontSizes[1]}px;
  font-weight: 600;
  color: ${({ active, theme }) =>
    active
      ? theme.colors.tone.anthraciteDark
      : theme.colors.tone.anthraciteLight};

  :hover {
    color: ${({ theme }) => theme.colors.tone.anthraciteDark};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    &:first-of-type {
      padding-left: 0;
    }
  }

  span {
    border-bottom: ${({ theme, active, isInHeader }) =>
      isInHeader && active
        ? `2px solid ${theme.colors.tone.anthraciteDark}`
        : "none"};
  }
`;

export {
  StyledGenderMenu,
  StyledGenderList,
  StyledGenderListItem,
  StyledGenderLink,
};
