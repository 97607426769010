import { FC } from "react";
import { sanitize } from "isomorphic-dompurify";
import { Locale } from "lib/Intl";

const PINGDOM_IDS = {
  en: "5b9b5e047e84eb00160000b4",
  nl: "5731cb34abe53d9f7c199891",
  "nl-be": "61c1b9999034fb001100050f",
  de: "5c7507ce1872b50016000733",
  "de-at": "5c7507ce1872b50016000733",
  fr: "5e15e192229e9300080001e0",
  "en-gb": "5ec4b5aa8e83fa0015000134",
  "en-us": "614c8e300d7ce50011000008",
  "en-dk": "5b9b5e047e84eb00160000b4",
  "en-se": "5b9b5e047e84eb00160000b4",
  "pl-pl": "6225bb9d0d7ce50011000927",
  "es-es": "6225bc939034fb001100090a",
  "it-it": "623c7d65405b1d0011000a57",
};

interface Props {
  locale: Locale | undefined;
  nonce: string;
}

const PingdomScript: FC<Props> = ({ locale, nonce }) => {
  const pingdomId = locale && PINGDOM_IDS[locale];

  if (!pingdomId) {
    return null;
  }

  return (
    <script
      nonce={nonce}
      dangerouslySetInnerHTML={{
        __html: sanitize(
          `
          var _prum = [['id', '${pingdomId}'],
          ['mark', 'firstbyte', (new Date()).getTime()]];
          (function() {
              var s = document.getElementsByTagName('script')[0]
              , p = document.createElement('script');
              p.async = 'async';
              p.src = '//rum-static.pingdom.net/prum.min.js';
              s.parentNode.insertBefore(p, s);
          })();
        `,
          {
            KEEP_CONTENT: true,
          }
        ),
      }}
    />
  );
};

export { PingdomScript };
