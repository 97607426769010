/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  defaultDataIdFromObject,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import fetch from "cross-fetch";
import errorLink from "lib/errorLink";
import Cookies from "js-cookie";
import { CreateApolloClientOptions } from "src/types/apolloClient";
import { REQUEST_SOURCE, REQUEST_SOURCE_TYPE } from "./config";
import introspectionQueryResultData from "src/types/ctgraphql.d";
import { isNativeApp } from "src/utils/isNativeApp";
import { sha256 } from "crypto-hash";

const getAccessToken = () => Cookies.get("ot_auth_token");

/**
 * Creates and configures the ApolloClient
 * @param  {Object} [initialState={}]
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createApolloClient = ({
  initialState = {},
  featureFlags,
  locale,
  storeKey,
  environmentId,
}: CreateApolloClientOptions) => {
  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  const httpLink = new HttpLink({
    uri: process.env.GRAPHQL_URI, // Server URL (must be absolute)
    fetch,
  });

  const httpCTLink = new HttpLink({
    uri: process.env.GRAPHQL_CT_URI, // Server URL (must be absolute)
    fetch,
  });

  // Sets a token from session as the default value
  let accessToken: string | undefined;

  const authLink = setContext((_, { headers }) => {
    const wishlists = featureFlags?.wishlists || false;

    // set accessToken if feature flag is enabled
    if (wishlists && typeof window !== "undefined") {
      accessToken = getAccessToken();
    }

    const filteredHeaders =
      process.env.RELEASE_STAGE === "production"
        ? {
            ...headers,
            authorization: accessToken ? `Bearer ${accessToken}` : "",
            "Accept-language": locale,
            "Accept-StoreKey": storeKey,
            "Request-Source": REQUEST_SOURCE,
            ...(isNativeApp && { "Request-Source-Type": REQUEST_SOURCE_TYPE }),
          }
        : {
            ...headers,
            authorization: accessToken ? `Bearer ${accessToken}` : "",
            "Accept-language": locale,
            "Accept-StoreKey": storeKey,
            "Request-Source": REQUEST_SOURCE,
            "CMS-Environment": environmentId,
            ...(isNativeApp && { "Request-Source-Type": REQUEST_SOURCE_TYPE }),
          };

    return {
      headers: filteredHeaders,
    };
  });

  const authCTLink = setContext((_, { headers }) => {
    accessToken = getAccessToken();
    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : "",
        "Accept-language": locale,
        "Accept-StoreKey": storeKey,
      },
    };
  });

  const inMemoryCacheConfig = {
    dataIdFromObject: (object: {
      __typename?: string;
      id?: string;
      title?: string;
      subtitle?: string;
      url?: string;
      slug?: string;
    }) => {
      switch (
        object.__typename // eslint-disable-line no-underscore-dangle
      ) {
        case "HomepageCategorySectionData":
          return `${object.id || ""}_${object.title || ""}_${
            object.subtitle || ""
          }_${object.url || ""}`;
        case "HomepagePopularCategoryData":
          return `${object.slug || ""}_${object.url || ""}`;
        default:
          return defaultDataIdFromObject(object);
      }
    },
  };

  const possibleTypes = introspectionQueryResultData.__schema.types.reduce(
    (possibleTypes, supertype) => {
      if (supertype.possibleTypes) {
        possibleTypes[supertype.name] = supertype.possibleTypes.map(
          (subtype) => subtype.name
        );
      }
      return possibleTypes;
    },
    {} as Record<string, string[]>
  );

  const cache = new InMemoryCache({
    ...inMemoryCacheConfig,
    possibleTypes,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  }).restore(initialState);
  const persistedQueryLink = createPersistedQueryLink({
    sha256,
    useGETForHashedQueries: true,
  });

  return new ApolloClient({
    ssrMode: typeof window === "undefined", // Disables forceFetch on the server (so queries are only run once)
    link: ApolloLink.split(
      (operation) => operation.getContext().useCT === true,
      from([authCTLink, httpCTLink]),
      ApolloLink.split(
        (operation) => operation.getContext().usePOST === true,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        from([
          errorLink,
          authLink,
          createPersistedQueryLink({ sha256 }).concat(httpLink),
        ]) as any,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        from([errorLink, authLink, persistedQueryLink.concat(httpLink)]) as any
      )
    ),
    cache,
    resolvers: {},
    connectToDevTools: process.env.NODE_ENV === "development",
    name: REQUEST_SOURCE,
    version: "3.8.10",
  });
};

export type OtriumApolloClient = ReturnType<typeof createApolloClient>;

export { createApolloClient };
