import styled from "@emotion/styled";

const Badge = styled.span`
  padding: 0 5px;
  text-align: center;

  border-radius: 8px;
  background: ${({ theme }) => theme.colors.semantic.red};

  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.tone.white};
  font-weight: 700;
`;

export { Badge };
