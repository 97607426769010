import { FC, useCallback, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import styled from "@emotion/styled";
import { Drawer } from "src/atoms/Drawer";
import { Sticky } from "src/atoms/Sticky";
import {
  closeNavigationMenu,
  getIsOpenNavigationMenu,
  getIsOpenSearch,
} from "./duck";
import { getGenderId } from "src/modules/page";
import { Navigation } from "./Navigation";
import { NavMenu } from "./NavMenu";
import { SubMenu } from "./SubMenu";
import { ServiceBanner } from "./ServiceBanner";
import { AppCtx } from "src/contexts/app.context";
import { Ribbon } from "src/molecules/Ribbon";
import { useRibbon } from "src/hooks/useRibbon";
import { AddedToCart } from "src/molecules/AddedtoCart";
import { Heading } from "@otrium/atoms";
import { AlertMessage } from "src/molecules/AlertMessage";

const StyledHeader = styled.header<{
  isOpenSearch: boolean;
  simplifiedHeader: boolean;
  duration?: number;
}>`
  border-bottom-style: solid;
  border-bottom-color: ${({ isOpenSearch, theme }) =>
    isOpenSearch ? "transparent" : theme.colors.primary.platinumLight};
  display: block !important;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    border-bottom-width: 1px;
    transition: border-bottom-color ${({ duration }) => duration || 0}ms
      ease-out;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    border-bottom-width: ${({ simplifiedHeader }) =>
      simplifiedHeader ? "1px" : "0"};
  }

  .Header__searchModal {
    position: absolute;
    top: 84px;
    width: 100%;

    @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
      position: static;
    }
  }

  .Header_Navigation {
    position: relative;
    z-index: 1102;
  }

  .Drawer__closeContainer.Drawer__closeMenuBtn {
    top: 4px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

interface Props {
  isLoginFlow: boolean;
}

const SEARCH_ANIMATION_TIME = 700;

const Header: FC<Props> = ({ isLoginFlow }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const genderId = useSelector(getGenderId) || "women";
  const gendersRef = useRef<HTMLDivElement>(null);

  const isOpenNavigationMenu = useSelector(getIsOpenNavigationMenu);
  const { locale, isPrivateSale } = useContext(AppCtx);

  const handleCloseNavigationMenu = useCallback(
    () => dispatch(closeNavigationMenu()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const { showRibbon, toggleShowRibbon, discountMessage, combinedAlertBars } =
    useRibbon();
  const isRibbonVisible = showRibbon && !isLoginFlow && !isPrivateSale;
  const simplifiedHeader =
    router.route === "/checkout" &&
    (router.query.step === "1" || router.query.step === "2");

  const isOpenSearch = useSelector(getIsOpenSearch);

  const isCartPage = router.route === "/cart";
  const isCartOrCheckoutPage = isCartPage || simplifiedHeader;

  return (
    <StyledHeader
      isOpenSearch={isOpenSearch}
      duration={SEARCH_ANIMATION_TIME}
      simplifiedHeader={simplifiedHeader}
    >
      <Heading className="sr-only">Otrium</Heading>
      {(!isCartOrCheckoutPage || !discountMessage) && (
        <>
          {!simplifiedHeader && <ServiceBanner aria-hidden={isRibbonVisible} />}
          {isRibbonVisible && combinedAlertBars.length > 0 && (
            <Ribbon
              data-testid="ribbon"
              alertBars={combinedAlertBars}
              onClick={toggleShowRibbon}
            />
          )}
        </>
      )}
      {isLoginFlow ? (
        <Navigation
          className="Header_Navigation"
          isLoginFlow={isLoginFlow}
          gendersRef={gendersRef}
          simplifiedHeader={simplifiedHeader}
        />
      ) : (
        <Sticky isHeader>
          {isCartOrCheckoutPage && discountMessage && (
            <AlertMessage isVisible message={discountMessage} />
          )}
          <Navigation
            className="Header_Navigation"
            isLoginFlow={isLoginFlow}
            gendersRef={gendersRef}
            simplifiedHeader={simplifiedHeader}
          />
          <AddedToCart />
        </Sticky>
      )}
      {!["/not-found", "/my-profile", "/my-orders"].includes(router.route) &&
        !isLoginFlow &&
        !isPrivateSale &&
        !simplifiedHeader && (
          <SubMenu
            genderId={genderId}
            locale={locale}
            gendersRef={gendersRef}
            searchAnimationTime={SEARCH_ANIMATION_TIME}
          />
        )}
      <Drawer isOpen={isOpenNavigationMenu} onClose={handleCloseNavigationMenu}>
        {isOpenNavigationMenu && (
          <NavMenu
            locale={locale}
            onClose={handleCloseNavigationMenu}
            isLoginFlow={isLoginFlow}
          />
        )}
      </Drawer>
    </StyledHeader>
  );
};

export { Header };
