import { msg } from "@lingui/macro";

/**
 * Generic error messages for forms
 */

const FORM_ERROR_MESSAGES = {
  required: msg`Required`,
  invalidEmail: msg`Invalid email address`,
  nameRequired: msg`Name is required`,
  firstNameRequired: msg`First name is required`,
  firstNameMax: msg`First name is too long`,
  lastNameRequired: msg`Last name is required`,
  lastNameMax: msg`Last name is too long`,
  emailRequired: msg`Email is required`,
  emailMax: msg`Email is too long`,
  passwordRequired: msg`Password is required`,
  equalPasswords: msg`Passwords must match`,
  email: msg`Enter a valid email address`,
  phone: msg`Enter a valid phone number. E.g.: +44 7911 123456`,
  phoneMax: msg`Phone number is too long`,
  passwordLength: msg`Password should be at least 6 characters long`,
  passwordSpace: msg`Password shouldn'msg contain space characters`,
  passwordMatch: msg`Confirmation Password must match new password`,
  passwordCantMatch: msg`Password can not match old password`,
  streetName: msg`Enter a valid street name. E.g.: Danzigerkade`,
  streetNameRequired: msg`Street is required`,
  streetNameMax: msg`Street is too long`,
  streetNumber: msg`Enter a valid house number. E.g.: 10 C`,
  streetNumberRequired: msg`House number is required`,
  streetNumberMax: msg`House number is too long`,
  additionalStreetInfoMax: msg`Additional street information is too long`,
  postalCode: msg`Enter a valid postal code. E.g.: AB19 1XY`,
  postalCodeRequired: msg`Postal code is required`,
  postalCodeMax: msg`Postal code is too long`,
  city: msg`Enter a valid city name`,
  cityMax: msg`City is too long`,
  countryRequired: msg`Country is required`,
  selectGender: msg`Select a gender`,
  stateRequired: msg`State is required`,
};

export default FORM_ERROR_MESSAGES;
