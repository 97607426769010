import { FC } from "react";
import styled from "@emotion/styled";

const StyledSkeleton = styled.div<{
  invert?: boolean;
}>`
  max-width: 100%;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background: ${({ theme, invert }) =>
    invert ? theme.colors.tone.white : theme.colors.tone.marble};
  border-radius: 2px;
  animation: progress 2s ease infinite;

  @keyframes progress {
    0% {
      opacity: 0.8;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 0.8;
    }
  }
`;

interface Props {
  className?: string;
  width?: number | string;
  height?: number | string;
  invert?: boolean;
}

const Skeleton: FC<Props> = ({ className, width, height, invert }) => {
  return (
    <StyledSkeleton
      className={className}
      style={{ width, height }}
      invert={invert}
    />
  );
};

export { Skeleton };
