import { FC, SVGAttributes } from "react";
import { useTheme, Theme } from "@emotion/react";
import { IconProps } from "src/types/Icon";

const CheckCircleIcon: FC<SVGAttributes<SVGSVGElement> & IconProps> = ({
  title = "Check Circle",
  width = 24,
  height = 24,
  color,
  testId = "check-circle-icon",
  ...rest
}) => {
  const theme: Theme = useTheme();
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 24 24"
      fill="none"
      data-testid={testId}
      {...rest}
    >
      <title>{title}</title>
      <path
        d="M23 11.0857C23 10.5334 22.5523 10.0857 22 10.0857C21.4477 10.0857 21 10.5334 21 11.0857H23ZM22 12.0057L23 12.0063V12.0057H22ZM15.663 3.77915C16.1675 4.00393 16.7587 3.7772 16.9834 3.27272C17.2082 2.76825 16.9815 2.17708 16.477 1.9523L15.663 3.77915ZM22.7075 4.70675C23.0978 4.31603 23.0975 3.68287 22.7068 3.29254C22.316 2.90221 21.6829 2.90253 21.2925 3.29325L22.7075 4.70675ZM12 14.01L11.2929 14.7171C11.4805 14.9047 11.7349 15.0101 12.0002 15.01C12.2656 15.0099 12.52 14.9044 12.7075 14.7168L12 14.01ZM9.70711 10.3029C9.31658 9.91237 8.68342 9.91237 8.29289 10.3029C7.90237 10.6934 7.90237 11.3266 8.29289 11.7171L9.70711 10.3029ZM21 11.0857V12.0057H23V11.0857H21ZM21 12.0052C20.9989 13.9459 20.3705 15.8344 19.2084 17.3888L20.8103 18.5863C22.2306 16.6864 22.9986 14.3784 23 12.0063L21 12.0052ZM19.2084 17.3888C18.0464 18.9432 16.413 20.0804 14.5518 20.6307L15.1189 22.5486C17.3936 21.876 19.39 20.4862 20.8103 18.5863L19.2084 17.3888ZM14.5518 20.6307C12.6907 21.1809 10.7015 21.1149 8.88102 20.4423L8.18792 22.3183C10.413 23.1404 12.8442 23.2211 15.1189 22.5486L14.5518 20.6307ZM8.88102 20.4423C7.06051 19.7697 5.50619 18.5266 4.44987 16.8985L2.77206 17.9871C4.06312 19.977 5.96285 21.4963 8.18792 22.3183L8.88102 20.4423ZM4.44987 16.8985C3.39354 15.2704 2.89181 13.3444 3.01951 11.4078L1.02384 11.2762C0.86777 13.6432 1.48099 15.9971 2.77206 17.9871L4.44987 16.8985ZM3.01951 11.4078C3.14721 9.47126 3.89749 7.62785 5.15845 6.15252L3.63811 4.85308C2.09693 6.65625 1.17992 8.90932 1.02384 11.2762L3.01951 11.4078ZM5.15845 6.15252C6.41942 4.67719 8.12351 3.649 10.0166 3.22128L9.57581 1.27046C7.26206 1.79322 5.17929 3.0499 3.63811 4.85308L5.15845 6.15252ZM10.0166 3.22128C11.9096 2.79357 13.8902 2.98926 15.663 3.77915L16.477 1.9523C14.3103 0.986866 11.8896 0.747696 9.57581 1.27046L10.0166 3.22128ZM21.2925 3.29325L11.2925 13.3032L12.7075 14.7168L22.7075 4.70675L21.2925 3.29325ZM12.7071 13.3029L9.70711 10.3029L8.29289 11.7171L11.2929 14.7171L12.7071 13.3029Z"
        fill={color || theme.colors.tone.black}
      />
    </svg>
  );
};

export { CheckCircleIcon };
