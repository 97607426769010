import { useState, useEffect, useCallback } from "react";

type UseIsScrollAtPositionProps = {
  scrollDirection: "horizontal" | "vertical";
  validator: (scrollPos: number) => boolean;
};

function useIsScrollAtPosition({
  scrollDirection,
  validator,
}: UseIsScrollAtPositionProps): boolean {
  const [isScrollAtTarget, setScrollAtTarget] = useState(false);

  const handleScroll = useCallback(() => {
    const isAtPosition = validator(
      scrollDirection === "vertical" ? window.scrollY : window.scrollX
    );

    if (isAtPosition !== isScrollAtTarget) {
      setScrollAtTarget(isAtPosition);
    }
  }, [setScrollAtTarget, isScrollAtTarget, validator, scrollDirection]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return isScrollAtTarget;
}

export { useIsScrollAtPosition };
