import { useContext, useEffect, useMemo } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { ApolloError } from "@apollo/client";
import { useSession } from "next-auth/client";
import { Cart, LineItem } from "src/types/ctgraphql.d";
import { AppCtx } from "src/contexts/app.context";

export interface CartVars {
  locale: string;
  storeKey: string;
}

interface CartHook {
  data: { cart: Cart | null };
  loading: boolean;
  error?: ApolloError;
}
export interface CartData {
  inStore: { me: { activeCart: Cart | null } };
}

export const moneyFragment = gql`
  fragment money on Money {
    type
    currencyCode
    centAmount
    fractionDigits
  }
`;

export const addressFragment = gql`
  fragment address on Address {
    firstName
    lastName
    phone
    email
    streetName
    streetNumber
    additionalStreetInfo
    postalCode
    city
    state
    country
    additionalAddressInfo
  }
`;

export const cartFragment = gql`
  fragment cart on Cart {
    id
    version
    createdAt
    customerEmail
    inventoryMode
    discountCodes {
      discountCodeRef {
        typeId
        id
      }
      state
      discountCode {
        id
        code
        isActive
        name(locale: $locale)
        description(locale: $locale)
        cartDiscounts {
          id
          key
          target {
            type
          }
          value {
            ... on AbsoluteDiscountValue {
              money {
                ...money
              }
            }
            ... on FixedPriceDiscountValue {
              money {
                ...money
              }
            }
            ... on RelativeDiscountValue {
              permyriad
            }
            ... on GiftLineItemValue {
              variantId
            }
          }
        }
      }
    }
    totalPrice {
      ...money
    }
    taxedPrice {
      totalNet {
        ...money
      }
      totalGross {
        ...money
      }
      totalTax {
        ...money
      }
    }
    lineItems {
      id
      productId
      name(locale: $locale)
      productSlug(locale: "en")
      quantity
      price {
        value {
          ...money
        }
      }
      discountedPricePerQuantity {
        quantity
        discountedPrice {
          value {
            ...money
          }
          includedDiscounts {
            discountedAmount {
              ...money
            }
            discount {
              id
              key
            }
          }
        }
      }
      totalPrice {
        ...money
      }
      variant {
        sku
        prices {
          id
          country
          value {
            ...money
          }
          custom {
            customFieldsRaw {
              name
              value
            }
          }
        }
        images {
          url
          dimensions {
            width
            height
          }
          label
        }
        availability {
          channels {
            results {
              availability {
                id
                isOnStock
                availableQuantity
              }
              channel {
                id
              }
            }
          }
        }
        attributesRaw {
          name
          value
        }
      }
      supplyChannel {
        id
      }
    }
    customLineItems {
      totalPrice {
        ...money
      }
      slug
    }
    shippingAddress {
      ...address
    }
    billingAddress {
      ...address
    }
    shippingInfo {
      shippingMethod {
        id
        key
        name
      }
      shippingRate {
        price {
          ...money
        }
        freeAbove {
          ...money
        }
      }
      price {
        ...money
      }
      discountedPrice {
        value {
          ...money
        }
      }
    }
    country
    origin
    custom {
      customFieldsRaw {
        name
        value
      }
    }
  }

  ${moneyFragment}
  ${addressFragment}
`;

const CART = gql`
  query cart($locale: Locale!, $storeKey: KeyReferenceInput!) {
    inStore(key: $storeKey) {
      me {
        activeCart {
          ...cart
        }
      }
    }
  }

  ${cartFragment}
`;

const useCart = ({
  onCompleted,
}: {
  onCompleted?: (cartData: CartData) => void;
} = {}): CartHook => {
  const [session] = useSession();
  const { locale, storeKey, setCartInfo, setCartMinLineItemsTotalPriceDiff } =
    useContext(AppCtx);
  const { loading, error, data, refetch } = useQuery<CartData, CartVars>(CART, {
    variables: {
      locale,
      storeKey,
    },
    context: { useCT: true },
    ssr: true,
    fetchPolicy: "network-only",
    skip: !session,
    onCompleted: (cartData) => {
      setCartMinLineItemsTotalPriceDiff(
        cartData?.inStore.me.activeCart?.lineItems
      );

      if (onCompleted) {
        onCompleted(cartData);
      }
    },
  });

  useEffect(() => {
    const refetchCart = () => {
      if (!document.hidden && refetch) {
        void refetch();
      }
    };

    window.addEventListener("visibilitychange", refetchCart, false);

    return () => {
      window.removeEventListener("visibilitychange", refetchCart);
    };
  }, [refetch]);

  const formattedData = useMemo(
    () => ({
      cart: data?.inStore.me.activeCart && {
        ...data.inStore.me.activeCart,
        lineItems: formatLineItems(data, data.inStore.me.activeCart.lineItems),
      },
    }),
    [data]
  );

  useEffect(() => {
    if (
      data?.inStore?.me?.activeCart?.id &&
      data?.inStore?.me?.activeCart?.version
    ) {
      setCartInfo(
        data?.inStore?.me?.activeCart?.id,
        data?.inStore?.me?.activeCart?.version as number
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.inStore?.me?.activeCart?.lineItems,
    data?.inStore?.me?.activeCart?.id,
    data?.inStore?.me?.activeCart?.version,
  ]);

  // @ts-ignore
  return { loading, error, data: formattedData };
};

const formatLineItems = (data: CartData, lineItems?: LineItem[]) => {
  const newItems = lineItems?.map((item) => ({
    ...item,
    // taxedPrice?
    currency: formatCurrency(data),
  }));
  return newItems || [];
};
const formatCurrency = (data: CartData) => ({
  code: data?.inStore?.me?.activeCart?.totalPrice.currencyCode,
  symbol: "",
  position: "",
});

export { useCart };
