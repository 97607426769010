import { FC, SVGAttributes } from "react";

const FlagPl: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 10" {...props}>
    <path fill="#fff" d="M0 0h16v10H0z" />
    <path fill="#dc143c" d="M0 5h16v5H0z" />
  </svg>
);

export { FlagPl };
