import { Skeleton } from "src/atoms/Skeleton";
import styled from "@emotion/styled";
import { Flex } from "@otrium/core";

const SkeletonWrapper = styled(Flex)`
  align-items: center;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex-direction: column;
  }
`;

const SkeletonItem = styled(Skeleton)`
  height: 20px;
  width: 120px;
  margin-right: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    height: 50px;
    width: 100%;
    margin-right: 0;
    margin-bottom: 1px;
  }
`;

const NavMenuSkeleton = (): JSX.Element => {
  return (
    <SkeletonWrapper>
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
    </SkeletonWrapper>
  );
};

export { NavMenuSkeleton };
