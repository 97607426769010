import {
  Children,
  cloneElement,
  FC,
  isValidElement,
  useState,
  ReactNode,
  useCallback,
} from "react";
import cn from "classnames";
import { identity, pickBy } from "lodash-es";
import styled from "@emotion/styled";
import { ChevronRight } from "src/icons/ChevronRight";
import { Expandable } from "src/atoms/Expandable";
import BaseButton from "src/atoms/Button/BaseButton";

const StyledAccordion = styled.div`
  .Accordion__title {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-top: 16px;
    padding-bottom: 16px;

    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.35px;
    color: ${({ theme }) => theme.colors.tone.black};

    cursor: pointer;

    width: 100%;
    background-color: transparent;
    outline: none;
    border: none;
  }

  .Accordion__titleIcon {
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.colors.tone.silver};
    transition: all 0.3s;
    pointer-events: none;
  }

  .Accordion_contentContainer {
    padding-bottom: 16px;

    font-size: 14px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.tone.black};
  }

  .Accordion__title_active > .Accordion__titleIcon {
    transform: rotate(-90deg);
  }
`;

type Icon = FC<{
  className?: string;
  isActive: boolean;
}>;

interface Props {
  className?: string;
  expanded?: boolean;
  icon?: Icon;
  children: ReactNode;
}

const Accordion: FC<Props> & {
  Panel: typeof AccordionPanel;
  Title: typeof AccordionTitle;
  Content: typeof AccordionContent;
  Control: typeof Control;
} = ({ className, expanded = false, icon, children }) => {
  return (
    <StyledAccordion className={className}>
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(
            child,
            pickBy(
              {
                expanded,
                icon,
              },
              identity
            )
          );
        }

        return child;
      })}
    </StyledAccordion>
  );
};

interface ControlProps {
  onToggle: () => void;
  expanded?: boolean;
  isActive?: boolean;
  children: ({ onToggle }: { onToggle: () => void }) => ReactNode;
}
const Control: FC<ControlProps> = ({ onToggle, isActive, children }) => {
  if (!isActive) return null;
  return <div style={{ padding: "0 20px" }}>{children({ onToggle })}</div>;
};

interface AccordionPanelProps {
  className?: string;
  expanded?: boolean;
  icon?: Icon;
  children: ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
  onToggle?: (expanded: boolean) => void;
}

const AccordionPanel: FC<AccordionPanelProps> = ({
  className,
  expanded,
  icon,
  children,
  onOpen,
  onClose,
  onToggle,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(expanded);

  const handleTitleClick = useCallback(() => {
    setIsActive((currentValue) => {
      const newValue = !currentValue;

      if (newValue) {
        onOpen?.();
      } else {
        onClose?.();
      }

      onToggle?.(newValue);

      return newValue;
    });
  }, [onOpen, onClose, onToggle]);

  return (
    <div className={className}>
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement<any>(child, {
            isActive,
            onTitleClick: handleTitleClick,
            expanded,
            icon,
            ...rest,
          });
        }

        return child;
      })}
    </div>
  );
};

interface AccordionTitleProps {
  className?: string;
  isActive?: boolean;
  icon?: Icon;
  onTitleClick?: () => void;
  children: ReactNode;
}

const AccordionTitle: FC<AccordionTitleProps> = ({
  className,
  isActive,
  onTitleClick,
  icon: TitleIcon,
  children,
}) => {
  return (
    <BaseButton
      data-testid="accordion-title"
      className={cn(className, "Accordion__title", {
        Accordion__title_active: isActive,
      })}
      onPress={onTitleClick}
    >
      {children}
      {TitleIcon ? (
        <TitleIcon isActive={!!isActive} className="Accordion__titleIcon" />
      ) : (
        <ChevronRight className="Accordion__titleIcon" />
      )}
    </BaseButton>
  );
};

interface AccordionContentProps {
  className?: string;
  isActive?: boolean;
  children: ReactNode;
}

const AccordionContent: FC<AccordionContentProps> = ({
  className,
  isActive,
  children,
}) => {
  return (
    <Expandable className={className} isExpanded={isActive}>
      <div className="Accordion_contentContainer">{children}</div>
    </Expandable>
  );
};

Accordion.Panel = AccordionPanel;
Accordion.Title = AccordionTitle;
Accordion.Content = AccordionContent;
Accordion.Control = Control;

export { Accordion };
