import { NavigationItems } from "src/types/graphql.d";
import { createContext } from "react";

export interface NavigationState {
  navigationItems: NavigationItems | undefined;
  loading: boolean;
}

const initialContext: NavigationState = {
  navigationItems: undefined,
  loading: false,
};

const NavigationContext = createContext<NavigationState>(initialContext);

NavigationContext.displayName = "NavigationContext";

export default NavigationContext;
