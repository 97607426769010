import useAlgoliaCreateClient from "src/hooks/algolia/useAlgoliaCreateClient";
import { AlgoliaContext, AlgoliaState } from "src/contexts/algolia.context";
import { ReactNode } from "react";

const AlgoliaContextProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const algoliaClient = useAlgoliaCreateClient();
  const algoliaState: AlgoliaState = {
    client: algoliaClient,
  };

  return (
    <AlgoliaContext.Provider value={algoliaState}>
      {children}
    </AlgoliaContext.Provider>
  );
};

export default AlgoliaContextProvider;
