import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  SearchBrand,
  SearchCategory,
  SearchProduct,
} from "src/types/graphql.d";

interface SearchData {
  search: {
    brands: [SearchBrand];
    categories: [SearchCategory];
    products: [SearchProduct];
  };
}

interface SearchVars {
  query: string;
  genderId: string | undefined;
}

const SEARCH = gql`
  query Search($query: String!, $genderId: String!) {
    search(filter: { q: $query, gender: $genderId, max_results: 40 }) {
      brands {
        id
        slug
      }
    }
  }
`;

const useSearch = ({ query, genderId }: SearchVars) => {
  const { data, loading, error } = useQuery<SearchData, SearchVars>(SEARCH, {
    variables: { query, genderId },
    skip: !genderId,
    ssr: true,
  });

  const { brands, categories, products } = data
    ? data.search
    : { brands: undefined, categories: undefined, products: undefined };

  return { brands, categories, products, loading, error };
};

export { useSearch };
