import { useEffect } from "react";

export function useKeypress(
  code: string,
  enableListener: boolean,
  callback: () => void
): void {
  useEffect(() => {
    function onKeyup(e: KeyboardEvent) {
      if (e.code === code) callback();
    }

    if (enableListener) {
      window.addEventListener("keyup", onKeyup);
    }
    return () => window.removeEventListener("keyup", onKeyup);
  }, [enableListener]);
}
