import { useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { AppCtx } from "src/contexts/app.context";
import { getGenderId } from "src/modules/page";
import { localeDomainPostfixes, localeDomainMap } from "src/utils/domains";
import { getI18nRoute } from "src/utils/i18nRoutes";
import { Locale } from "lib/Intl";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import { useUpdateAccount } from "src/hooks/useUpdateAccount";
import { useSegment } from "src/hooks/useSegment";
import { MenuOptionId } from "./LanguageMenuOptions";

function useHandleRedirect(): {
  locale: Locale;
  handleRedirect: (
    lang: MenuOptionId,
    queryParams?: Record<string, string>
  ) => void;
} {
  const { locale } = useContext(AppCtx);
  const isLoggedIn = useIsLoggedIn();
  const { updateAccount } = useUpdateAccount();
  const { segmentDomainChanged } = useSegment();
  const genderId = useSelector(getGenderId);

  const handleRedirect = useCallback(
    (
      destinationLocale: MenuOptionId,
      queryParams: Record<string, string> = {}
    ) => {
      if (isLoggedIn) updateAccount(destinationLocale);
      if (destinationLocale === locale) return;

      const urlIso = localeDomainMap[destinationLocale];

      const newUrl = getI18nRoute({
        route: `/${genderId as string}`,
        locale: urlIso as Locale,
      });

      let baseHostName = window.location.host.replace(
        // prettier-ignore
        new RegExp(`(${localeDomainPostfixes.map(localeDomain => `\\.${localeDomain}`).join("|")})`), // /(\.com|\.nl| ... )/
        `.${urlIso}`
      );

      // In case hostname contains us- or us. we need to remove it. Legacy from old domain structure
      if (baseHostName?.includes("us-")) {
        baseHostName = baseHostName.replace("us-", "");
      }
      if (baseHostName?.includes("us.")) {
        baseHostName = baseHostName.replace("us.", "");
      }

      const query = {
        utm_medium: "otrium websites",
        utm_source: window.location.hostname,
        utm_campaign: "language currency selector",
        ...queryParams,
      };

      const urlQuery = Object.keys(query).reduce((value, current) => {
        return `${value}${value && "&"}${current}=${encodeURIComponent(
          query[current as keyof typeof query]
        )}`;
      }, "");

      const redirectURL = `//${baseHostName}${newUrl}?${urlQuery}`; // prevent redirect to https on localhost

      void segmentDomainChanged(baseHostName, () => {
        window.location.replace(redirectURL);
      });

      // setting a timeout as a fallback if any ad/privacy blockers should block segment requests
      setTimeout(() => {
        window.location.replace(redirectURL);
      }, 3000);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale, genderId, segmentDomainChanged]
  );
  return { locale, handleRedirect };
}

export { useHandleRedirect };
