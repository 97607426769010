function ArrowNext({
  color = "#000",
  role = "none",
}: {
  color?: string;
  role?: string;
}): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" role={role}>
      <path
        d="M2 10.556l4-4-4-4"
        stroke={color}
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
}

export { ArrowNext };
