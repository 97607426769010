import styled from "@emotion/styled";
import { Button } from "src/atoms/Button";

const FooterButton = styled(Button)`
  width: 100%;
  background-color: ${(props) => props.theme.socialButton};
  margin: 0;
  padding: 8px;
  cursor: pointer;
  height: 48px;

  &:hover {
    background-color: ${(props) => props.theme.colors.tone.anthraciteDark};
  }
  &:focus-visible {
    border: 2px solid ${(props) => props.theme.colors.semantic.blue};
  }

  &:first-of-type {
    margin-right: 16px;
  }
`;

export const Styled = {
  FooterButton,
};
