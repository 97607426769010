import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useCallback } from "react";

interface GetGenderData {
  genderId?: string;
}

const GET_GENDER = gql`
  query GetGender {
    genderId @client
  }
`;

const getGenderId = (gender: string) => {
  switch (gender.toLowerCase()) {
    case "women":
    case "dames":
    case "damen":
      return "women";
    case "men":
    case "heren":
    case "herren":
      return "men";
    case "kids":
    case "kinder":
      return "kids";
    default:
      return "women";
  }
};

const useGenderId = (): [string | undefined, (gender: string) => void] => {
  const { data, client } = useQuery<GetGenderData>(GET_GENDER);

  const genderId = data && data.genderId;

  const setGenderId = useCallback(
    (gender: string) => {
      client.writeQuery({
        query: GET_GENDER,
        data: { genderId: getGenderId(gender) },
      });
    },
    [client]
  );

  return [genderId, setGenderId];
};

export { useGenderId };
