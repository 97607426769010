import { FC, PropsWithChildren } from "react";
import { Text } from "@otrium/atoms";
import { Box, Flex } from "@otrium/core";
import { Trans } from "@lingui/macro";
import styled from "@emotion/styled";
import { getI18nRoute } from "src/utils/i18nRoutes";
import { Locale } from "lib/Intl";
import { LanguageSwitcher } from "src/molecules/LanguageSwitcher";
import { LogoFooter } from "src/icons/LogoFooter";

interface CopywriteTextProps extends PropsWithChildren {
  pl?: number;
}

const CopywriteText: FC<CopywriteTextProps> = ({ children, ...rest }) => (
  <Text
    fontSize={[0]}
    lineHeight={1.43}
    color="tone.anthraciteLight"
    textAlign="center"
    {...rest}
  >
    {children}
  </Text>
);

const Dot = styled(Box)`
  margin-top: 3px;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.tone.anthraciteLight};
`;

interface Props {
  locale: Locale;
  isGhostDomain?: boolean;
}

export const FooterBottom: FC<Props> = ({ locale, isGhostDomain }) => {
  return (
    <Flex
      variant="centered"
      mt={[4, 4, 4, 5]}
      justifyContent="space-between"
      flexDirection={[
        "column-reverse",
        "column-reverse",
        "column-reverse",
        "row",
      ]}
    >
      <Flex alignItems="center">
        <LogoFooter />
        <CopywriteText pl={2}>
          © 2016-{new Date().getFullYear()} Otrium,{" "}
          <Trans>except certain content provided by third parties</Trans>
        </CopywriteText>
      </Flex>
      <Flex alignItems="center" flexWrap="wrap" justifyContent="center" px={3}>
        {["en-us"].includes(locale) && (
          <Flex alignItems="center">
            <Box
              as="a"
              href="https://lookbook.otrium.com/files/Otrium_California_Resident_Privacy_Notice.pdf"
            >
              <CopywriteText>
                CCPA (Do not sell my personal Information)
              </CopywriteText>
            </Box>
          </Flex>
        )}
        {["en-us"].includes(locale) && (
          <Flex alignItems="center">
            <Dot mx={[1, 2]} />
            <Box as="a" href="/terms-of-use">
              <CopywriteText>Terms of sale</CopywriteText>
            </Box>
          </Flex>
        )}
        <Flex alignItems="center">
          {["en-us"].includes(locale) && <Dot mx={[1, 2]} />}
          <Box
            as="a"
            href={getI18nRoute({ route: "/accessibility-statement", locale })}
          >
            <CopywriteText>
              <Trans>Accessibility Statement</Trans>
            </CopywriteText>
          </Box>
        </Flex>
        <Flex alignItems="center">
          <Dot mx={[1, 2]} />
          <Box
            as="a"
            href={getI18nRoute({ route: "/privacy-statement", locale })}
          >
            <CopywriteText>Privacy and cookie statement</CopywriteText>
          </Box>
        </Flex>
        <Flex alignItems="center">
          <Dot mx={[1, 2]} />
          <Box
            as="a"
            target="_blank"
            href={getI18nRoute({ route: "/terms-and-conditions", locale })}
          >
            <CopywriteText>
              <Trans>Terms & Conditions</Trans>
            </CopywriteText>
          </Box>
        </Flex>
        {["de"].includes(locale) && (
          <Flex alignItems="center">
            <Dot mx={[1, 2]} />
            <Box as="a" href="/impressum">
              <CopywriteText>Impressum</CopywriteText>
            </Box>
          </Flex>
        )}
        {["fr"].includes(locale) && (
          <Flex alignItems="center">
            <Dot mx={[1, 2]} />
            <Box as="a" href="/mentions-legales">
              <CopywriteText>Mentions Légales</CopywriteText>
            </Box>
          </Flex>
        )}
      </Flex>
      {!isGhostDomain && (
        <LanguageSwitcher
          svgPrefixId="footer-language-switcher"
          locale={locale}
          isFooter
        />
      )}
    </Flex>
  );
};
