import styled from "@emotion/styled";
import { ReactNode } from "react";
import BaseButton from "src/atoms/Button/BaseButton";
import { IColor } from "@otrium/core";

const Button = styled(BaseButton)<{
  icon?: ReactNode;
  hasUnderline?: boolean;
  fullWidth?: boolean;
  color?: string | IColor;
}>`
  border: none;
  display: flex;
  background-color: transparent;
  color: ${({ theme, color }) => color || theme.colors.tone.black};
  cursor: pointer;

  ${({ hasUnderline }) =>
    hasUnderline &&
    `
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-position: under;
  `}

  ${({ fullWidth }) => fullWidth && "width: 100%;"};

  svg {
    margin-right: ${({ theme }) => theme.space[2]}px;
    fill: ${({ theme }) => theme.colors.tone.black};
  }

  ${({ hasUnderline, icon }) =>
    hasUnderline &&
    !icon &&
    `
    :hover,:active {
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-position: under;
    }
  `}

  :disabled {
    color: ${({ theme }) => theme.colors.tone.anthraciteLight};
    cursor: default;
    text-decoration: none;
    svg {
      fill: ${({ theme }) => theme.colors.tone.anthraciteLight};
    }
  }
`;

const Link = Button.withComponent("a");

export { Button, Link };
