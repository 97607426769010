import styled from "@emotion/styled";
import { Box } from "@otrium/core";

const Wrapper = styled(Box)<{ isTightColumn?: boolean }>`
  position: relative;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: ${({ isTightColumn }) => (isTightColumn ? "calc(100% / 3)" : "50%")};
  }
`;

const ChevronBottom = styled.div`
  border-left: 3px solid ${({ theme }) => theme.colors.tone.anthraciteLight};
  border-top: 3px solid ${({ theme }) => theme.colors.tone.anthraciteLight};
  transform: rotate(-135deg);
`;

const ListWrapper = styled(Box)`
  li {
    margin: 8px 0 0 0;
  }

  a {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.4px;
    color: ${(props) => props.theme.colors.tone.anthraciteLight};
  }
`;

const ChevronIcon = styled(ChevronBottom)`
  position: absolute;
  right: 0;
  top: 13px;
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: block;
  }
`;

const DesktopWrapper = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: block;
  }
`;

const MobileWrapper = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: block;
  }

  .Accordion__title_footer {
    align-items: center;
    height: 50px;
  }

  .Accordion__title_footer > .Accordion__titleIcon {
    transition: all 200ms ease;
    width: 10px;
    height: 10px;
  }

  .Accordion__title_footer.Accordion__title_active > .Accordion__titleIcon {
    border-left: 3px solid ${({ theme }) => theme.colors.tone.white};
    border-top: 3px solid ${({ theme }) => theme.colors.tone.white};
    transform: rotate(45deg);
  }
`;

export { Wrapper, ListWrapper, ChevronIcon, DesktopWrapper, MobileWrapper };
