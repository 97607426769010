import { PLPType } from "src/types/PLPType.d";
import {
  BRAND_DESIGNER_ONLY_FACET,
  BRAND_FACET,
  BRAND_IS_PRIVATE_SALE,
  CATEGORIES_SLUG_FACET,
  COLLECTIONS_FACET,
  GENDERS_FACET,
  IS_CLEARANCE_PRODUCT_FACET,
  IS_DELETED,
  LABELS_TYPE_FACET,
  PROMOTION_ID_FACET,
  SLUG_FACET,
} from "src/utils/algoliaFacets";

export interface AlgoliaFilters {
  gender: string;
  plpType?: PLPType;
  brandName?: string;
  brandId?: string;
  slug?: string;
  collectionId?: string;
  categorySlug?: string;
  missingCategorySlug?: boolean;
}

const getFiltersByPLP = (
  plpType: PLPType,
  collectionId?: string,
  categorySlug?: string,
  missingCategorySlug?: boolean
): string[] => {
  const filters = [];
  if (plpType === PLPType.ICONS_PLP) {
    filters.push(`${LABELS_TYPE_FACET}:icons`);
  }
  if (plpType === PLPType.NEW_IN) {
    filters.push(`${LABELS_TYPE_FACET}:new`);
  }
  if (plpType === PLPType.HOT_DEALS) {
    filters.push(`${PROMOTION_ID_FACET}:HOT_DEALS`);
  }
  if (plpType === PLPType.CLEARANCE_PLP) {
    filters.push(`${IS_CLEARANCE_PRODUCT_FACET}:true`);
  }
  if (plpType === PLPType.COLLECTIONS_PLP && collectionId) {
    filters.push(`${COLLECTIONS_FACET}:${collectionId}`);
  }
  if (
    [
      PLPType.NEW_IN,
      PLPType.HOT_DEALS,
      PLPType.CLEARANCE_PLP,
      PLPType.ICONS_PLP,
      PLPType.DESIGNER_PLP,
    ].includes(plpType)
  ) {
    filters.push(`${BRAND_IS_PRIVATE_SALE}:false`);
  }
  if (
    [
      PLPType.CATEGORY_PLP,
      PLPType.NEW_IN,
      PLPType.HOT_DEALS,
      PLPType.CLEARANCE_PLP,
    ].includes(plpType)
  ) {
    filters.push(`${BRAND_DESIGNER_ONLY_FACET}:false`);
  }
  if (plpType === PLPType.CATEGORY_PLP && categorySlug && missingCategorySlug) {
    filters.push(`${CATEGORIES_SLUG_FACET}:${categorySlug}`);
  }
  return filters;
};

const getFilters = ({
  gender,
  plpType,
  brandId,
  brandName,
  slug,
  collectionId,
  missingCategorySlug,
  categorySlug,
}: AlgoliaFilters): string => {
  const filters = [`${GENDERS_FACET}:${gender}`, `${IS_DELETED}:false`];
  if (plpType) {
    filters.push(
      ...getFiltersByPLP(
        plpType,
        collectionId,
        categorySlug,
        missingCategorySlug
      )
    );
  }
  if (slug) {
    filters.push(`${SLUG_FACET}:${slug}`);
  }
  if (brandName && brandId) {
    filters.push(`${BRAND_FACET}:"${brandId}||${brandName}"`);
  }

  return filters.join(" AND ");
};

export default getFilters;
