import { FC } from "react";

export const TiktokIcon: FC = () => (
  <svg height="32" width="32" viewBox="0 0 24 24" fill="none">
    <title>Tiktok</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0319 0.898479V0.916216C16.0329 1.23746 16.127 5.8814 21.0005 6.17107C21.0005 10.4914 21.0027 6.17107 21.0027 9.75924C20.6365 9.78095 17.7948 9.57562 16.0264 7.99835L16.021 14.9836C16.0647 18.1467 14.3039 21.2485 11.0075 21.8736C10.084 22.0485 9.25238 22.0682 7.85641 21.7632C-0.185472 19.3577 2.48679 7.43014 10.5616 8.70659C10.5616 12.5573 10.5638 8.70551 10.5638 12.5573C7.22806 12.0665 6.11215 14.8415 6.99854 16.8285C7.80528 18.6374 11.1267 19.0297 12.2852 16.4777C12.4163 15.9782 12.482 15.4088 12.482 14.7694V0.898479H16.0319Z"
      fill="#FFFFFE"
    />
  </svg>
);
