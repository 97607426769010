import {
  FC,
  useState,
  useEffect,
  useCallback,
  useRef,
  useLayoutEffect,
} from "react";
import Cookies from "js-cookie";
import { useSession } from "next-auth/client";
import { useDebouncedCallback } from "use-debounce";
import { Modal } from "src/atoms/Modal";
import { Container } from "src/atoms/Container";
import { Text } from "@otrium/atoms";
import { Box, Flex } from "@otrium/core";
import { Button } from "src/atoms/Button";
import { Trans } from "@lingui/macro";
import { LoadingSpinner } from "src/atoms/LoadingSpinner";
import {
  StyledModalWrapper,
  StyledA,
  StyledH2,
  LoadingWrapper,
  Terms,
  ImageWrapper,
  Image,
  StyledButtonText,
} from "./OptIn.styled";
import { useSegment } from "src/hooks/useSegment";
import { useUpdateUser } from "src/hooks/useUpdateUser";
import { OPTED_IN_COOKIE_NAME } from "src/molecules/SubscribeOverlay/SubscribeOverlay";
import { useWelcomePopup } from "src/hooks/useWelcomePopup";
import { Origin, WelcomePopup } from "src/types/graphql.d";
import { useOptIn } from "src/hooks/useOptIn";
import { sanitize } from "isomorphic-dompurify";
import { ID } from "@ht-sdks/events-sdk-js-browser";

interface Props {
  onAccept: () => void;
  onReject: () => void;
  isLoading: boolean;
  values: Omit<WelcomePopup, "__typename">;
}
const UserOptIn: FC<Props> = ({
  onAccept,
  onReject,
  isLoading,
  values: {
    title,
    subTitle,
    cta,
    termsAndConditions,
    desktopImage,
    mobileImage,
  },
}) => {
  const termsRef = useRef<HTMLDivElement>();

  useLayoutEffect(() => {
    if (!termsRef.current) return;
    const anchor = termsRef.current.querySelector("a");
    if (anchor) {
      anchor.target = "_blank";
    }
  }, [termsAndConditions]);

  return (
    <div data-testid="optin-wrapper">
      <ImageWrapper>
        <Image className="desktop" src={desktopImage} />
        <Image className="mobile" src={mobileImage} />
      </ImageWrapper>

      <Container minWidth={["initial", "initial", 400]} maxWidth={450} pb={5}>
        <Flex
          sx={{ flexFlow: "column wrap" }}
          justifyContent="center"
          alignItems="stretch"
          px={[8, 8, 0]}
        >
          <StyledH2 data-testid="modal-titte">{title}</StyledH2>
          <Box py={3} data-testid="modal-paragraph">
            <Text as="p" textAlign="center" color="tone.anthracite">
              {subTitle}
            </Text>
          </Box>
          <Box>
            <Button
              data-testid="accept-btn"
              fullWidth
              variant="primary"
              onClick={onAccept}
              disabled={isLoading}
            >
              {isLoading ? (
                <LoadingSpinner data-testid="loading-spinner" stroke="white" />
              ) : (
                <StyledButtonText>{cta}</StyledButtonText>
              )}
            </Button>
            <Terms
              ref={termsRef}
              mt={3}
              fontSize={0}
              dangerouslySetInnerHTML={{
                __html: sanitize(termsAndConditions),
              }}
            />
          </Box>
          <StyledA data-testid="reject-cta" onClick={onReject}>
            <Trans>No Thanks</Trans>
          </StyledA>
        </Flex>
      </Container>
    </div>
  );
};

const OptIn = (): JSX.Element | null => {
  const { segmentOptIn, user } = useSegment();
  const [session] = useSession();
  const [isLoading, setIsLoading] = useState(false);
  const [shouldOpen, setShouldOpen] = useState(true);
  const [anonymousId, setAnonymousId] = useState<ID>("");
  const { data, loading } = useWelcomePopup();
  const { optIn } = useOptIn();

  const { updateUser } = useUpdateUser({
    onCompleted: ({ updateUser: updateUserData }) => {
      if (updateUserData?.opt_in) {
        setIsLoading(false);
        setShouldOpen(false);
      }
      Cookies.remove("client_opt_in");
    },
  });

  const debouncedSegmentOptIn = useDebouncedCallback(segmentOptIn, 500);

  const handleAccept = useCallback(() => {
    setIsLoading(true);
    void debouncedSegmentOptIn(true, session?.user.email, "optin-popup");
    void updateUser({ opt_in: true });
    void optIn({
      email: session?.user.email,
      segment_anonymous_id: anonymousId,
      metadata: {
        origin: Origin.OptinPopup,
      },
    });
  }, [
    updateUser,
    debouncedSegmentOptIn,
    session?.user.email,
    optIn,
    anonymousId,
  ]);

  const handleReject = useCallback(() => {
    setShouldOpen(false);
    void debouncedSegmentOptIn(false, session?.user.email, "optin-popup");
    void updateUser({ opt_in: false });
  }, [updateUser, debouncedSegmentOptIn, session?.user.email]);

  useEffect(() => {
    // TODO: Refactor usage client_opt_in from cookies once https://otrium.atlassian.net/browse/CN-1266 is done
    setShouldOpen(
      Boolean(Cookies.get("client_opt_in")) &&
        // Hidden for users who opted in as guest and registered recently
        !Boolean(Cookies.get(OPTED_IN_COOKIE_NAME))
    );
  }, []);

  useEffect(() => {
    void (async () => {
      const htUser = await user;
      setAnonymousId(htUser?.anonymousId?.() || "");
    })();
  }, [user]);

  if (!session || !data) return null;

  return (
    <StyledModalWrapper>
      <Modal
        isOpen={shouldOpen}
        onClose={handleReject}
        fullHeight={false}
        data-testid="optin-modal-container"
        centerOnScreen
        maxWidth={["calc(100% - 32px)"]}
        rounded
      >
        {loading && (
          <LoadingWrapper>
            <LoadingSpinner />
          </LoadingWrapper>
        )}
        {!loading && (
          <UserOptIn
            values={data.miscellaneous.welcomePopup}
            onAccept={handleAccept}
            onReject={handleReject}
            isLoading={isLoading}
          />
        )}
      </Modal>
    </StyledModalWrapper>
  );
};

export { UserOptIn };
export default OptIn;
