import { Hit } from "@algolia/client-search";
import { AlgoliaSearchSuggestion } from "src/types/Algolia";

const checkForDuplicates = (
  hits: Hit<AlgoliaSearchSuggestion>[]
): Hit<AlgoliaSearchSuggestion>[] => {
  if (hits.length === 0) {
    return [];
  }
  const duplicates = hits.reduce((obj, next) => {
    if (next.type === "category") {
      if (!obj[next.query]) {
        obj[next.query] = 0;
      }
      obj[next.query]++;
    }
    return obj;
  }, {} as { [key: string]: number });

  return hits.map((hit) => {
    hit.hasDuplicate = (duplicates[hit.query] || 0) > 1;
    return hit;
  });
};

export { checkForDuplicates };
