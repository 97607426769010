import { getI18nRoute } from "src/utils/i18nRoutes";
import { Locale } from "lib/Intl";
import { ProductGender, ShopType } from "src/types/graphql.d";

interface GetCollectionsRoute {
  shopType: ShopType | ProductGender;
  collectionsSlug: string;
  locale: Locale;
}

const getCollectionsRoute = ({
  shopType,
  collectionsSlug,
  locale,
}: GetCollectionsRoute): string => {
  return `${getI18nRoute({
    route: `/collections/${shopType}`,
    locale,
  })}/${collectionsSlug}`;
};

export default getCollectionsRoute;
