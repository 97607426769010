import { FC, useContext } from "react";
import { useRouter } from "next/router";
import { Trans } from "@lingui/macro";
import { UAParser } from "ua-parser-js";
import { AppCtx } from "src/contexts/app.context";
import { LogoSquare } from "src/icons/LogoSquare";
import { useSmartBanner } from "src/hooks/useSmartBanner";
import { localeDomainMap } from "src/utils/domains";
import { useBreakpoints } from "@otrium/core";
import {
  StyledSmartBanner,
  StyledSmartBannerClose,
  StyledSmartBannerContent,
  StyledSmartBannerLogoWrapper,
  StyledSmartBannerTextContainer,
  StyledSmartBannerTitle,
  StyledSmartBannerDescription,
  StyledSmartBannerLink,
} from "./SmartBanner.styled";
import { getUrlParams } from "src/modules/page/utils";
import { useTheme, Theme } from "@emotion/react";

const parser = new UAParser();

const SmartBanner: FC = () => {
  const { locale } = useContext(AppCtx);
  const router = useRouter();
  const [showSmartBanner, handleHideSmartBanner] = useSmartBanner();
  const theme: Theme = useTheme();

  const { isMobile } = useBreakpoints();

  if (!showSmartBanner || !isMobile) {
    return null;
  }

  const isProd = process.env.RELEASE_STAGE === "production";

  const {
    domainWithoutLocale,
    ios: { isi, ibi },
    android: { apn },
  } = isProd ? getUrlParams(locale).production : getUrlParams(locale).staging;

  const localeDomain = localeDomainMap[locale] || localeDomainMap.en;

  const encodedPath = encodeURIComponent(router.asPath);

  // NOTE: Google recomends to not skip Preview Page to have more stable Dynamic Links.
  // We figured out that we can skip it for iOS Safari, but have to show it for iOS Chrome
  const showPreviewPage =
    parser.getOS().name === "iOS" &&
    parser.getBrowser().name === "Mobile Chrome";
  const efr = showPreviewPage ? "0" : "1";

  return (
    <StyledSmartBanner>
      <StyledSmartBannerContent>
        <StyledSmartBannerClose
          onClick={handleHideSmartBanner}
          fill={theme.colors.tone.anthraciteLight}
        />

        <StyledSmartBannerLogoWrapper>
          <LogoSquare />
        </StyledSmartBannerLogoWrapper>

        <StyledSmartBannerTextContainer>
          <StyledSmartBannerTitle>
            <Trans>The Otrium App</Trans>
          </StyledSmartBannerTitle>

          <StyledSmartBannerDescription>
            <Trans>Free in the app store</Trans>
          </StyledSmartBannerDescription>
        </StyledSmartBannerTextContainer>
      </StyledSmartBannerContent>

      <StyledSmartBannerLink
        href={`https://otrium.page.link/?link=${domainWithoutLocale}${localeDomain}${encodedPath}&apn=${apn}&isi=${isi}&ibi=${ibi}&efr=${efr}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Trans>Open</Trans>
      </StyledSmartBannerLink>
    </StyledSmartBanner>
  );
};

export default SmartBanner;
