import { FC } from "react";
import {
  Item,
  ItemLink,
  Title,
  Currency,
  List,
  Menu,
} from "./LanguageMenu.styled";
import { menuOptions } from "./LanguageMenuOptions";
import { Trans } from "@lingui/macro";
import { useHandleRedirect } from "./useHandleRedirect";
import { translatedLanguageMap } from "src/atoms/LanguageMenu/getTranslatedMenuOptions";
import { useLingui } from "@lingui/react";

interface Props {
  onBack: () => void;
  onClose: () => void;
}

const LanguageMenu: FC<Props> = ({ onBack, onClose }) => {
  const { locale, handleRedirect } = useHandleRedirect();
  const { _ } = useLingui();

  return (
    <Menu title={<Trans>Select country</Trans>} goBack={onBack}>
      <List role="button" aria-pressed="false" onClick={onClose}>
        {menuOptions.map(({ id, language, currency, FlagRound }) => (
          <Item key={id} isActive={locale === id}>
            <ItemLink onClick={() => handleRedirect(id)}>
              <FlagRound svgPrefixId={id} />
              <Title>{_(translatedLanguageMap[id])}</Title>
              <Currency>{currency}</Currency>
            </ItemLink>
          </Item>
        ))}
      </List>
    </Menu>
  );
};

export { LanguageMenu };
