import { UserProfile } from "../types/graphql.d";
import { ApolloError } from "@apollo/client";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { useIsLoggedIn } from "./useIsLoggedIn";
import { useSession } from "next-auth/client";

export const GET_USER_PROFILE = gql`
  query userProfile($email: String!) {
    userProfile(email: $email) {
      token
    }
  }
`;

const useUserProfile = (): {
  loading: boolean;
  error: ApolloError | undefined;
  data: { userProfile: UserProfile } | undefined;
} => {
  const isLoggedIn = useIsLoggedIn();
  const [session] = useSession();
  const { loading, error, data } = useQuery<{ userProfile: UserProfile }>(
    GET_USER_PROFILE,
    {
      skip: !isLoggedIn,
      variables: {
        email: session?.user?.email,
      },
    }
  );
  return { loading, error, data };
};

export { useUserProfile };
