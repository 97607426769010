/* eslint-disable no-underscore-dangle */
import { NextComponentType } from "next";
import { AppContext } from "next/app";
import { Component } from "react";
import { Provider } from "react-redux";
import { createStore, OtriumStore, State } from "src/redux";

const isServer = typeof window === "undefined";
const __NEXT_REDUX_STORE__ = "__NEXT_REDUX_STORE__";

function getOrCreateStore(initialState?: State): OtriumStore {
  // Always make a new store if server, otherwise state is shared between requests
  if (isServer) {
    return createStore(initialState);
  }

  let store = window[__NEXT_REDUX_STORE__];

  // Create store if unavailable on the client and set it on the window object
  if (!store) {
    store = window[__NEXT_REDUX_STORE__] = createStore(initialState);
  }

  return store;
}

function withRedux<P>(App: NextComponentType<AppContext, P>) {
  type ComponentProps = P & { initialReduxState: State };

  return class AppWithRedux extends Component<ComponentProps> {
    reduxStore: OtriumStore;

    static async getInitialProps(appContext: AppContext) {
      // Get or Create the store with `undefined` as initialState
      // This allows you to set a custom default initialState
      const reduxStore = getOrCreateStore();

      // Provide the store to getInitialProps of pages
      appContext.ctx.store = reduxStore;

      let appProps = {};
      if (typeof App.getInitialProps === "function") {
        appProps = (await App.getInitialProps(appContext)) || {};
      }

      return {
        ...appProps,
        initialReduxState: reduxStore.getState(),
      };
    }

    constructor(props: ComponentProps) {
      super(props);
      this.reduxStore = getOrCreateStore(props.initialReduxState);
    }

    render() {
      return (
        <Provider store={this.reduxStore}>
          {/* @ts-expect-error Upgrade NextJS to support this */}
          <App {...this.props} />
        </Provider>
      );
    }
  };
}

export { withRedux };
