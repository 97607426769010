import { FC, PropsWithChildren, ReactNode } from "react";
import { Accordion } from "src/atoms/Accordion";
import {
  Wrapper,
  ListWrapper,
  ChevronIcon,
  DesktopWrapper,
  MobileWrapper,
} from "./FooterLinksBox.styled";

type SxType = number[] | number | string;

interface Props extends PropsWithChildren {
  title: ReactNode;
  wrapperProps?: { mt?: SxType; ml?: SxType };
  open?: boolean;
  isTightColumn?: boolean;
}

export const FooterLinksBox: FC<Props> = ({
  title,
  children,
  wrapperProps,
  open = false,
  isTightColumn,
  ...rest
}) => {
  const content = (
    <ListWrapper as="ul" {...rest}>
      {children}
    </ListWrapper>
  );
  return (
    <Wrapper {...wrapperProps}>
      <DesktopWrapper data-testid="footer-desktop-links">
        {title}
        {content}
      </DesktopWrapper>
      <MobileWrapper data-testid="footer-mobile-links">
        <Accordion icon={ChevronIcon}>
          <Accordion.Panel expanded={open}>
            <Accordion.Title className="Accordion__title_footer">
              {title}
            </Accordion.Title>
            <Accordion.Content>{content}</Accordion.Content>
          </Accordion.Panel>
        </Accordion>
      </MobileWrapper>
    </Wrapper>
  );
};
