export enum PLPType {
  BRAND_PLP,
  ICONS_PLP,
  CATEGORY_PLP,
  NEW_IN,
  HOT_DEALS,
  CLEARANCE_PLP,
  DESIGNER_PLP,
  COLLECTIONS_PLP,
  SEARCH_PLP,
  SHOP_TYPE,
}
