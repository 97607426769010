import { FC, SVGAttributes } from "react";

const GreyDotIcon: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    width="4"
    height="4"
    viewBox="0 0 4 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle id="Oval" cx="2" cy="2" r="2" fill="#999999" />
  </svg>
);

export { GreyDotIcon };
