import { FC, SVGAttributes } from "react";

const HamburgerMenu: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="22"
    viewBox="5 13 19 5"
    {...props}
  >
    <g fillRule="evenodd">
      <rect width="22" height="2" x="5" y="23" rx="1" />
      <rect width="22" height="2" x="5" y="15" rx="1" />
      <rect width="22" height="2" x="5" y="7" rx="1" />
    </g>
  </svg>
);

export { HamburgerMenu };
