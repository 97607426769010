import styled from "@emotion/styled";
import { Content as AtomContent } from "src/atoms/Content";

interface PropsStyled {
  isOpen: boolean;
}

const MainWrapperDesktop = styled(AtomContent)<PropsStyled>`
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  position: relative;
  transition: opacity 0.225s ease-in-out;

  > div {
    position: absolute;
    right: 0;
    top: -1px;
    width: 375px;
    z-index: 1100;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    display: none;
  }
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.tone.white};
  border: solid 1px ${({ theme }) => theme.colors.tone.silver};
  box-shadow: 0 0 16px 0 ${({ theme }) => theme.colors.primary.platinumLight};
  background: ${({ theme }) => theme.colors.tone.white};
  margin-right: 20px;
  padding: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 100%;
    position: static;
  }
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 16px;
`;

const Image = styled.img`
  height: 120px;
  object-fit: contain;
  width: 80px;
`;

const DescriptionContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-left: 16px;
`;

const BrandName = styled.span`
  color: ${({ theme }) => theme.colors.tone.anthraciteDark};
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 1.5;
`;

const ProductName = styled.span`
  color: ${({ theme }) => theme.colors.tone.anthraciteLight};
  letter-spacing: 0.4px;
  line-height: 1.5;
`;

const SizeContent = styled.div`
  color: ${({ theme }) => theme.colors.tone.anthraciteDark};
  font-size: 14px;
  letter-spacing: normal;
  line-height: 1.43;
  margin-top: 8px;

  span:first-of-type {
    color: ${({ theme }) => theme.colors.tone.anthraciteLight};
    margin-right: 8px;
  }
`;

const PriceContent = styled.div`
  display: inline-flex;
  letter-spacing: 0.4px;
  line-height: 1.5;
  margin-top: 8px;
  gap: ${({ theme }) => theme.space.space8};

  span:first-of-type {
    color: ${({ theme }) => theme.colors.semantic.red};
    font-weight: 600;
  }

  span:not(:first-of-type):not(:last-of-type) {
    color: ${({ theme }) => theme.colors.tone.anthraciteLight};
    text-decoration: line-through;
  }

  .promo-code {
    display: inline-flex;
    place-items: center;
    color: ${({ theme }) => theme.colors.semantic.red};
    font-size: ${({ theme }) => theme.fontSizes.fontSize14};
    font-weight: ${({ theme }) => theme.fontWeights.body};

    svg {
      width: ${({ theme }) => theme.fontSizes.fontSize14};
      height: ${({ theme }) => theme.fontSizes.fontSize14};
    }
  }
`;

export {
  MainWrapperDesktop,
  Wrapper,
  Content,
  Image,
  DescriptionContent,
  BrandName,
  ProductName,
  SizeContent,
  PriceContent,
};
