/*
  returns a hash based on a string
*/

function hashCode(s: string): string | number {
  if (typeof s == "undefined") {
    return "";
  }
  return s.split("").reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0); // eslint-disable-line no-bitwise
    return a & a; // eslint-disable-line no-bitwise
  }, 0);
}

export default hashCode;
