import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { ApolloError } from "@apollo/client";
import {
  FilterProductLabelInputGroup,
  FilterProductLabelResult,
  PageContentType,
  ProductLabel,
  WishlistProduct,
} from "src/types/graphql.d";
import {
  DeprecatedAlgoliaSearchProduct,
  AlgoliaSearchProduct,
} from "src/types/Algolia.d";
import { Hit } from "@algolia/client-search";

interface FilteredProductLabels {
  filterProductLabels: FilterProductLabelResult;
}

interface ProductLabelVars {
  items: FilterProductLabelInputGroup[];
  page_content_type: PageContentType;
}

export interface ProductFilteredLabels {
  [key: string]: ProductLabel[];
}

interface FilteredProductLabelsResponse {
  data: ProductFilteredLabels;
  loading: boolean;
  error?: ApolloError;
}

type Product =
  | DeprecatedAlgoliaSearchProduct
  | Hit<AlgoliaSearchProduct>
  | WishlistProduct;

export const FILTER_PRODUCT_LABELS = gql`
  query filterProductLabels(
    $items: [FilterProductLabelInputGroup]
    $page_content_type: PageContentType
  ) {
    filterProductLabels(items: $items, page_content_type: $page_content_type) {
      items {
        slug
        labels {
          type
          color
          background_color
          text_color
          priority
          value
          expires_at
          starts_at
        }
      }
    }
  }
`;

const useFilterProductLabels = ({
  products,
  pageContentType,
}: {
  products: (Product | null)[];
  pageContentType: PageContentType;
}): FilteredProductLabelsResponse => {
  const nonNullableItems = products.filter(
    (product) => product !== null
  ) as Product[]; // Need to trick TS here, there is a definitely no way to get null[] as output

  const items: FilterProductLabelInputGroup[] = nonNullableItems.map(
    ({ slug, labels }) => ({
      slug,
      labels: labels?.map(({ __typename, ...label }) => label) || [],
    })
  );

  const { data, loading, error } = useQuery<
    FilteredProductLabels,
    ProductLabelVars
  >(FILTER_PRODUCT_LABELS, {
    variables: {
      items,
      page_content_type: pageContentType,
    },
    ssr: true,
    fetchPolicy: "cache-first",
    context: {
      usePOST: true,
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const filters: ProductFilteredLabels =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    data?.filterProductLabels?.items?.reduce(
      (filteredLabels: ProductFilteredLabels, product) => {
        if (product) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          filteredLabels[product.slug] = (product.labels ||
            []) as ProductLabel[];
        }
        return filteredLabels;
      },
      {}
    ) || {};

  return {
    data: filters,
    loading,
    error,
  };
};
export default useFilterProductLabels;
