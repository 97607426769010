import { FC, SVGAttributes } from "react";

const HeartPageIcon: FC<SVGAttributes<SVGSVGElement>> = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Wishlist</title>
    <path
      id="Combined Shape"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5043 3C17.9767 3 19.314 3.58098 20.3014 4.52708C21.2936 5.47782 21.9322 6.79738 22 8.26656C22.0063 12.3952 19.1099 16.4759 12.9498 20.6529C12.7754 20.7712 12.602 20.8871 12.4296 21C12.2502 20.8987 12.0696 20.7951 11.8877 20.689C5.00733 16.675 2 12.475 2 8.5259C2.01833 6.93924 2.64314 5.57921 3.63506 4.59486C4.6287 3.6088 5.99438 3 7.50164 3C8.97403 3 10.3113 3.58098 11.2987 4.52708C11.5591 4.7766 11.7952 5.05153 12.003 5.34771C12.2109 5.05139 12.4469 4.77652 12.7073 4.52708C13.6947 3.58098 15.0319 3 16.5043 3Z"
      fillOpacity="0"
      stroke="black"
      strokeWidth="2"
    />
  </svg>
);

export { HeartPageIcon };
