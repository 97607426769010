import { FC, SVGAttributes } from "react";

const User: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="22"
    viewBox="6 4 20 22"
    {...props}
  >
    <title>My account</title>
    <g fill="none" fillRule="evenodd">
      <circle cx="16" cy="10" r="5" stroke="#000" strokeWidth="2" />
      <path
        fill="#000"
        d="M26 27h-2c0-4.418-3.582-8-8-8s-8 3.582-8 8H6c0-5.523 4.477-10 10-10s10 4.477 10 10z"
      />
    </g>
  </svg>
);

export { User };
