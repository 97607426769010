import styled from "@emotion/styled";

interface Props {
  bgColor?: string | undefined;
  textColor?: string | undefined;
  showBgColor?: boolean;
}
const ContentBox = styled.p<Props>`
  background-color: ${(props) => (props.showBgColor ? props.bgColor : "none")};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.textColor};
  height: 47px;
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizes.fontSize14};
  letter-spacing: 0.04px;
  line-height: 20px;
  text-align: center;
  padding: 0 16px;
  width: 100%;
`;

export { ContentBox };
