import styled from "@emotion/styled";

const Container = styled.div<{ show: boolean }>`
  position: fixed;
  width: 320px;
  right: 0px;
  bottom: -16px;
  z-index: 1224;

  .roll-appear {
    opacity: 0;
    div {
      transform: translateY(150px);
    }
  }
  .roll-enter {
    opacity: 0;
    div {
      transform: translateY(150px);
    }
  }
  .roll-enter-done {
    opacity: 1;
    transition: opacity 1s;
    div {
      transform: translateY(0px);
      transition: transform 1s;
    }
  }
  .roll-exit {
    opacity: 1;
    div {
      transform: translateY(150px);
    }
  }
  .roll-exit-active {
    opacity: 0;
    transition: opacity 1s;
    div {
      transform: translateY(150px);
      transition: transform 1s;
    }
  }

  .action-arrow-right {
    position: absolute;
  }

  #ot-notifications-popup {
    display: ${(props) => (props.show ? "block" : "none")};
    position: fixed;
    width: 320px;
    right: 16px;
    bottom: 16px;
    z-index: 999999;
  }

  #ot-notifications-popup .ot-notification {
    padding: 0;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  }

  #ot-notifications-popup .ot-notification__title {
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 700;
    color: #101010;
  }

  #ot-notifications-popup .ot-notification__title:before {
    content: "";
    display: inline-block;
    width: 32px;
    height: 32px;
    background-image: url(/static/images/notifications-black.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    vertical-align: middle;
    margin-top: -4px;
    margin-left: -4px;
  }

  #ot-notifications-popup .ot-notification__content {
    max-width: 90%;
    margin-bottom: 7px;
    color: #101010;
  }

  #ot-notifications-popup .ot-notification__overlay-link {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  #ot-notifications-popup .ot-notification__button {
    color: #101010;
    font-size: 14px;
    position: relative;
    z-index: 20;
  }

  #ot-notifications-popup .ot-notification__button:hover {
    color: #fff;
  }

  #ot-notifications-popup .ot-notification__link {
    border-bottom: 2px solid #000000;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.4px;
    line-height: 1.2;
    cursor: pointer;
    white-space: nowrap;
    align-self: flex-end;
  }

  #ot-notifications-popup
    .ot-notification__link
    .action-arrow-right.action-black:after {
    text-decoration: none;
    margin-left: 10px;
    position: relative;
    top: 0;
  }

  #ot-notifications-popup .ot-notification__promo-code {
    font-size: 14px;
    color: #101010;
    font-weight: 700;
    text-transform: uppercase;
    z-index: 50;
    position: relative;
    display: block;
  }

  #ot-notifications-popup .ot-notification-top {
    padding: 14px;
    position: relative;
    background: white;
  }

  #ot-notifications-popup .ot-notification__view-more {
    display: inline-block;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    padding: 20px;
    color: #101010;
    text-align: center;
  }

  #ot-notifications-popup
    .ot-notification__view-more
    .ot-notification__view-more-inner {
    display: inline-block;
    position: relative;
  }

  #ot-notifications-popup
    .ot-notification__view-more
    .ot-notification__view-more-inner:before {
    content: "";
    display: inline-block;
    width: 32px;
    height: 32px;
    background-image: url(/static/images/notifications-black.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    vertical-align: middle;
    margin-right: 13px;
    margin-top: -4px;
    margin-left: -4px;
    margin-bottom: -4px;
  }

  #ot-notifications-popup .ot-notification__view-more:active,
  #ot-notifications-popup .ot-notification__view-more:focus,
  #ot-notifications-popup .ot-notification__view-more:hover {
    text-decoration: none;
  }

  #ot-notifications-popup .ot-notification__view-more .notification-count {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: -1.6px;
    color: #fff;
    background: #000;
    border-radius: 8px;
    padding: 1px 8px 2px 7px;
    position: absolute;
    left: 15px;
    top: -6px;
  }

  #ot-notifications-popup .ot-notification__close {
    position: absolute;
    right: 10px;
    top: 12px;
    z-index: 30;
    background-position: 50%;
    background-repeat: no-repeat;
    background-image: url(/static/images/close-icon-black.svg);
    width: 26px;
    height: 26px;
    background-size: 12px 12px;
    border-radius: 50%;
  }

  #ot-notifications-popup .ot-notification__close,
  #ot-notifications-popup .ot-notification__close:hover {
    transition: all 0.2s ease-in-out;
  }

  #ot-notifications-popup .ot-notification__close:hover {
    opacity: 0.5;
  }

  #ot-notificationsList {
    max-width: 930px;
    margin: 0 auto 100px;
  }

  #ot-notificationsList .ot-notification {
    display: table;
    width: 100%;
    background-color: #fff;
    border: solid #f0f0f0;
    border-width: 1px 1px 0;
    position: relative;
  }

  #ot-notificationsList .ot-notification:last-of-type {
    border-bottom-width: 1px;
  }

  #ot-notificationsList .ot-notification.alert-type,
  #ot-notificationsList .ot-notification.unread {
    background-color: #f7f7f7;
    border: solid #f0f0f0;
    border-width: 1px 1px 0;
  }

  #ot-notificationsList .ot-notification.alert-type:last-of-type,
  #ot-notificationsList .ot-notification.unread:last-of-type {
    border-bottom-width: 1px;
  }

  #ot-notificationsList
    .ot-notification.alert-type
    + .ot-notification:not(.read),
  #ot-notificationsList .ot-notification.unread + .ot-notification:not(.read) {
    border-top-color: #f0f0f0;
  }

  #ot-notificationsList .ot-notification > div {
    display: table-cell;
    padding: 20px;
    vertical-align: top;
  }

  #ot-notificationsList .ot-notification .ot-notification__brand {
    width: 15%;
    text-align: center;
    padding: 10px 20px 0;
  }

  #ot-notificationsList
    .ot-notification
    .ot-notification__brand
    .ot-notification__brand-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #f0f0f0;
    padding: 8px;
    width: 116px;
    height: 54px;
    text-align: center;
  }

  #ot-notificationsList .ot-notification .ot-notification__brand img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
  }

  #ot-notificationsList .ot-notification .ot-notification__brand h2 {
    position: absolute;
    display: block;
    font-size: 16px;
    font-weight: 600;
  }

  @media screen and (max-width: 480px) {
    #ot-notificationsList .ot-notification .ot-notification__brand h2 {
      font-size: 14px;
    }
  }

  #ot-notificationsList .ot-notification.alert-type .ot-notification__brand {
    padding: 10px 20px 0;
  }

  #ot-notificationsList .ot-notification .ot-notification__title {
    width: 18%;
  }

  #ot-notificationsList
    .ot-notification
    .ot-notification__title
    .ot-notification__title-brand {
    font-size: 18px;
    font-weight: 700;
    color: #101010;
  }

  #ot-notificationsList
    .ot-notification
    .ot-notification__title
    .ot-notification__title-time {
    font-size: 14px;
    font-weight: 400;
    color: #8c8c8c;
  }

  #ot-notificationsList .ot-notification .ot-notification__content {
    width: 43%;
    padding: 20px 10px;
  }

  #ot-notificationsList .ot-notification .ot-notification__actions {
    width: 24%;
  }

  #ot-notificationsList
    .ot-notification
    .ot-notification__actions
    .ot-notification__button {
    width: 100%;
    max-width: 250px;
    min-width: 160px;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
  }

  #ot-notificationsList .ot-notifications__load-more {
    margin: 60px auto 0;
    display: block;
    text-align: center;
    color: #fff;
    background: #000;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.9px;
    max-width: 170px;
    position: relative;
  }

  #ot-notificationsList .ot-notifications__load-more.loading {
    text-indent: -9999px;
    transition: none;
  }

  #ot-notificationsList .ot-notifications__load-more .path {
    stroke: #fff;
  }
`;

export { Container };
