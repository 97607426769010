const isIOSApp =
  typeof webkit !== "undefined" &&
  typeof webkit.messageHandlers?.track !== "undefined";

const isAndroidApp = typeof segment_webview !== "undefined";

const isNativeApp = isAndroidApp || isIOSApp;

const nativeAppSchema = (): string => {
  return process.env.RELEASE_STAGE === "production"
    ? "otrium://"
    : "otrium-staging://";
};

export { isAndroidApp, isIOSApp, isNativeApp, nativeAppSchema };
