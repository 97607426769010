import styled from "@emotion/styled";

const Wrapper = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding-left: ${({ theme }) => theme.space[2]}px;
    padding-right: ${({ theme }) => theme.space[2]}px;
  }
  &:hover {
    opacity: 0.7;
  }
`;

export { Wrapper };
