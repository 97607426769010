/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// translated i18nRoutes
// extended are the translated routes different in code only nl-be and de-at

const base = {
  "/women": {
    en: null,
    fr: "/femme",
    de: "/damen",
    nl: "/dames",
    pl: "/kobiety",
    es: "/mujer",
    it: "/donna",
    dk: "/dame",
    se: "/kvinna",
  },
  "/about-us": {
    en: "https://about.otrium.com/",
    fr: "/a-propos",
    de: "https://about.otrium.com/de",
    nl: "https://about.otrium.com/nl",
    es: "/sobre-nosotros",
    it: "/chi-siamo",
    pl: "/o-nas",
    dk: null,
    se: null,
  },
  "/brands": {
    en: "/brands",
    fr: "/marques",
    de: "/marken",
    nl: "/merken",
    pl: "/marki",
    es: "/marcas",
    it: "/marche",
    dk: null,
    se: null,
    "nl-be": "/merken",
  },
  "/brand": {
    en: "/brand",
    fr: "/marques",
    de: "/marken",
    nl: "/merken",
    pl: "/marki",
    es: "/marcas",
    it: "/marche",
    dk: null,
    se: null,
    at: "/marken",
    "nl-be": "/merken",
  },
  "/conscious": {
    en: "https://conscious.otrium.com/",
    fr: "https://conscious.otrium.com/",
    de: "https://conscious.otrium.com/",
    nl: "https://conscious.otrium.com/",
    pl: "https://conscious.otrium.com/",
    es: "https://conscious.otrium.com/",
    it: "https://conscious.otrium.com/",
    dk: "https://conscious.otrium.com/",
    se: "https://conscious.otrium.com/",
  },
  "/shipping-delivery": {
    en: "https://help.otrium.nl/en/categories/8-shipping-delivery",
    "en-gb": "https://help.otrium.nl/en-gb/categories/8-shipping-delivery",
    "en-us": "https://help.otrium.nl/en-us/categories/8-shipping-delivery",
    de: "https://help.otrium.nl/de/categories/8-versand-lieferung",
    fr: "https://help.otrium.nl/fr/categories/8-livraison",
    se: "https://help.otrium.nl/en/categories/8-shipping-delivery",
    dk: "https://help.otrium.nl/en/categories/8-shipping-delivery",
    nl: "https://help.otrium.nl/nl/categories/8-verzending-bezorging",
    es: "https://help.otrium.nl/es/categories/8-env-o-entrega",
    it: "https://help.otrium.nl/it/categories/8-spedizione-consegna",
    pl: "https://help.otrium.nl/pl/categories/8-wysy-ka-i-dostawa",
  },
  "/returns-refunds": {
    en: "https://help.otrium.nl/en/categories/9-returns-refunds",
    "en-gb": "https://help.otrium.nl/en-gb/categories/9-returns-refunds",
    "en-us": "https://help.otrium.nl/en-us/categories/9-returns-refunds",
    de: "https://help.otrium.nl/de/categories/9-r-ckgabe-r-ckerstattung",
    fr: "https://help.otrium.nl/fr/categories/9-retours-remboursements",
    se: "https://help.otrium.nl/en/categories/9-returns-refunds",
    dk: "https://help.otrium.nl/en/categories/9-returns-refunds",
    nl: "https://help.otrium.nl/nl/categories/9-retourneren-terugbetalingen",
    es: "https://help.otrium.nl/es/categories/9-devoluciones-reembolsos",
    it: "https://help.otrium.nl/it/categories/9-resi-rimborsi",
    pl: "https://help.otrium.nl/pl/categories/9-zwroty-i-reklamacje",
  },
  "/payment-promotions": {
    en: "https://help.otrium.nl/en/categories/10-payment-discounts-promotions",
    "en-gb":
      "https://help.otrium.nl/en-gb/categories/10-payment-discounts-promotions",
    "en-us":
      "https://help.otrium.nl/en-us/categories/10-payment-discounts-promotions",
    de: "https://help.otrium.nl/de/categories/10-zahlung-rabatte-aktionen",
    fr: "https://help.otrium.nl/fr/categories/10-r-ductions-promotions",
    se: "https://help.otrium.nl/en/categories/10-payment-discounts-promotions",
    dk: "https://help.otrium.nl/en/categories/10-payment-discounts-promotions",
    nl: "https://help.otrium.nl/nl/categories/10-betaling-korting-promoties",
    es: "https://help.otrium.nl/es/categories/10-descuentos-promociones",
    it: "https://help.otrium.nl/it/categories/10-sconti-promozioni",
    pl: "https://help.otrium.nl/pl/categories/10-rabaty-i-promocje",
  },
  "/delete-account": {
    en: "https://help.otrium.nl/en/articles/387",
    "en-gb": "https://help.otrium.nl/en-gb/articles/387",
    "en-us": "https://help.otrium.nl/en-us/articles/387",
    de: "https://help.otrium.nl/de/articles/387",
    fr: "https://help.otrium.nl/fr/articles/387",
    se: "https://help.otrium.nl/en/articles/387",
    dk: "https://help.otrium.nl/en/articles/387",
    nl: "https://help.otrium.nl/nl/articles/387",
    es: "https://help.otrium.nl/es/articles/387",
    it: "https://help.otrium.nl/it/articles/387",
    pl: "https://help.otrium.nl/pl/articles/387",
    "de-at": "https://help.otrium.nl/de/articles/387",
    "nl-be": "https://help.otrium.nl/nl/articles/387",
  },
  "/faq": {
    en: "https://help.otrium.nl/en",
    "en-gb": "https://help.otrium.nl/en-gb",
    "en-us": "https://help.otrium.nl/en-us",
    fr: "https://help.otrium.nl/fr",
    de: "https://help.otrium.nl/de",
    nl: "https://help.otrium.nl/nl",
    "nl-be": "https://help.otrium.nl/nl-be",
    pl: "https://help.otrium.nl/pl",
    es: "https://help.otrium.nl/es",
    it: "https://help.otrium.nl/it",
    dk: "https://help.otrium.nl/en",
    se: "https://help.otrium.nl/en",
  },
  "/kids": {
    en: null,
    fr: "/enfant",
    de: "/kinder",
    nl: "/kids",
    es: "/ninos",
    it: "/bambini",
    dk: "/born",
    se: "/barn",
  },
  "/kids/latest-updates": {
    en: null,
    fr: "/enfant/dernieres-mises-a-jour",
    de: "/kinder/letzte-aktualisierungen",
    nl: "/kids/laatste-updates",
    pl: "/kids/laatste-updates",
    es: "/kids/laatste-updates",
    it: "/kids/laatste-updates",
    dk: "/kids/laatste-updates",
    se: "/kids/laatste-updates",
  },
  "/kids/brands": {
    en: "/kids/brands",
    fr: "/enfant/marques",
    de: "/kinder/marken",
    nl: "/kids/merken",
    pl: "/dzieci/marki",
    es: "/ninos/marcas",
    it: "/bambini/marche",
    dk: null,
    se: null,
  },
  "/kids": {
    en: null,
    fr: "/enfant",
    de: "/kinder",
    nl: "/kids",
    pl: "/dzieci",
    es: "/ninos",
    it: "/bambini",
    dk: "/born",
    se: "/barn",
  },
  "/men": {
    en: null,
    fr: "/homme",
    de: null,
    nl: "/heren",
    es: "/hombre",
    it: "/uomo",
    dk: "/men",
    se: "/man",
  },
  "/men/latest-updates": {
    en: null,
    fr: "/homme/dernieres-mises-a-jour",
    de: "/harren/letzte-aktualisierungen",
    nl: "/heren/laatste-updates",
    pl: "/heren/laatste-updates",
    es: "/heren/laatste-updates",
    it: "/heren/laatste-updates",
    dk: "/heren/laatste-updates",
    se: "/heren/laatste-updates",
  },
  "/men/brands": {
    en: "/men/brands",
    fr: "/homme/marques",
    de: "/herren/marken",
    nl: "/heren/merken",
    pl: "/mezczyzni/marki",
    es: "/hombre/marcas",
    it: "/uomo/marche",
    dk: null,
    se: null,
  },
  "/men": {
    en: null,
    fr: "/homme",
    de: "/herren",
    nl: "/heren",
    pl: "/mezczyzni",
    es: "/hombre",
    it: "/uomo",
    dk: "/men",
    se: "/man",
  },
  "/my-preferences": {
    en: null,
    fr: "/mes-preferences",
    de: "/meine-einstellungen",
    nl: "/mijn-voorkeuren",
    pl: null,
    es: null,
    it: null,
    dk: null,
    se: null,
  },
  "/return-requests": {
    en: null,
    fr: "/retours",
    de: null,
    nl: "/retour-aanvragen",
    pl: null,
    es: null,
    it: null,
    dk: null,
    se: null,
  },
  "/paiement-information": {
    en: null,
    fr: "/paiement",
    de: null,
    nl: null,
    pl: null,
    es: "https://help.otrium.nl/es/sections/360000154638-Pedidos",
    it: null,
    dk: null,
    se: null,
  },
  "/partnerships": {
    en: null,
    "en-us": "https://partnerships.otrium.com",
    fr: "/partenaires",
    de: "/partnerschaften",
    nl: null,
    pl: "/partnerzy",
    es: "/colaboradores",
    it: "/collaborazioni",
    dk: null,
    se: null,
  },
  "/privacy-statement": {
    en: null,
    "en-us": "/privacy-notice",
    fr: "/confidentialite",
    de: "/datenschutzerklarung",
    nl: "/privacyverklaring",
    pl: "/polityka-prywatnosci",
    es: "/privacidad",
    it: "/dichiarazioni-sulla-privacy",
    dk: null,
    se: null,
  },
  "/accessibility-statement": {
    en: null,
    de: "/erklarung-zur-barrierefreiheit",
    fr: "/declaration-daccessibilite",
    se: null,
    dk: null,
    nl: "/toegankelijkheidsverklaring",
    es: "/declaracion-de-accesibilidad",
    it: "/dichiarazione-di-accessibilita",
    pl: "/oswiadczenie-o-dostepnosci",
  },
  "/boys": {
    en: null,
    fr: "/enfant",
    de: "/jungen",
    nl: "/boys",
    pl: "/boys",
    es: "/boys",
    it: "/boys",
    dk: null,
    se: null,
  },
  "/boys-en": {
    en: "/kids-en/boys-en",
    fr: "/enfant/garcon",
    de: "/kinder/jungen",
    nl: "/kids/boys",
    pl: "/dzieci/boys",
    es: "/ninos/boys",
    it: "/kids/boys",
    dk: null,
    se: null,
  },
  "/girls": {
    en: null,
    fr: "/fille",
    de: "/madchen",
    nl: "/girls",
    pl: "/girls",
    es: "/girls",
    it: "/girls",
    dk: null,
    se: null,
  },
  "/girls-en": {
    en: "/kids-en/girls-en",
    fr: "/enfant/fille",
    de: "/kinger/madchen",
    nl: "/kids/girls",
    pl: "/dzieci/girls",
    es: "/ninos/girls",
    it: "/kids/girls",
    dk: null,
    se: null,
  },
  "/kids": {
    en: null,
    fr: "/enfant",
    de: "/kinder",
    nl: "/kids",
    pl: "/dzieci",
    es: "/ninos",
    it: "/bambini",
    dk: null,
    se: null,
  },
  "/kids-en/?cnpf=1&cnep=1&orderby=new": {
    en: null,
    fr: "/enfant/?cnpf=1&cnep=0&orderby=new",
    de: "/kinder/?cnpf=1&cnep=1&orderby=new",
    nl: "/kids/?cnpf=1&cnep=1&orderby=new",
    pl: "/dzieci/?cnpf=1&cnep=1&orderby=new",
    es: "/ninos/?cnpf=1&cnep=1&orderby=new",
    it: "/bambini/?cnpf=1&cnep=1&orderby=new",
    dk: null,
    se: null,
  },
  "/kids/?cnpf=1&cnep=1&orderby=new": {
    en: null,
    fr: "/enfant/?cnpf=1&cnep=0&orderby=new",
    de: "/kinder/?cnpf=1&cnep=1&orderby=new",
    nl: "/kids/?cnpf=1&cnep=1&orderby=new",
    pl: "/dzieci/?cnpf=1&cnep=1&orderby=new",
    es: "/ninos/?cnpf=1&cnep=1&orderby=new",
    it: "/bambini/?cnpf=1&cnep=1&orderby=new",
    dk: null,
    se: null,
  },
  "/kids/boys": {
    en: null,
    fr: "/garcon",
    de: "/jungen",
    nl: "/kids/boys",
    pl: "/dzieci/boys",
    es: "/ninos/boys",
    it: "/bambini/boys",
    dk: null,
    se: null,
  },
  "/kids/girls": {
    en: null,
    fr: "/enfant/fille",
    de: "/kinder/madchen",
    nl: "/kids/girls",
    pl: "/dzieci/girls",
    es: "/ninos/girls",
    it: "/bambini/girls",
    dk: null,
    se: null,
  },
  "/products/boys": {
    en: null,
    fr: "/products/enfant",
    de: "/products/jungen",
    nl: null,
    pl: null,
    es: null,
    it: null,
    dk: null,
    se: null,
  },
  "/products/boys-en": {
    en: null,
    fr: "/products/enfant/garcon",
    de: "/products/kinder/jungen",
    nl: null,
    pl: null,
    es: null,
    it: null,
    dk: null,
    se: null,
  },
  "/products/girls": {
    en: null,
    fr: "/products/fille",
    de: "/products/madchen",
    nl: null,
    pl: null,
    es: null,
    it: null,
    dk: null,
    se: null,
  },
  "/products/girls-en": {
    en: null,
    fr: "/products/enfant/fille",
    de: "/products/kinger/madchen",
    nl: null,
    pl: null,
    es: null,
    it: null,
    dk: null,
    se: null,
  },
  "/products/kids": {
    en: null,
    fr: "/products/enfant",
    de: "/products/kinder",
    nl: null,
    pl: null,
    es: null,
    it: null,
    dk: null,
    se: null,
  },
  "/men": {
    en: null,
    fr: "/homme",
    de: "/herren",
    nl: "/heren",
    pl: "/mezczyzni",
    es: "/hombre",
    it: "/uomo",
    dk: null,
    se: null,
  },
  "/men/clothing": {
    en: null,
    fr: "/homme/vetements",
    de: "/herren/bekleidung-herren",
    nl: "/heren/kleding-man",
    pl: "/mezczyzni/kleding-man",
    es: "/hombre/kleding-man",
    it: "/uomo/kleding-man",
    dk: null,
    se: null,
  },
  "/men/?cnpf=1&cnep=1&orderby=new": {
    en: null,
    fr: "/homme/?cnpf=1&cnep=1&orderby=new",
    de: "/herren/?cnpf=1&cnep=1&orderby=new",
    nl: "/heren/?cnpf=1&cnep=1&orderby=new",
    pl: "/mezczyzni/?cnpf=1&cnep=1&orderby=new",
    es: "/hombre/?cnpf=1&cnep=1&orderby=new",
    it: "/uomo/?cnpf=1&cnep=1&orderby=new",
    dk: null,
    se: null,
  },

  "/men/shoes": {
    en: null,
    fr: "/homme/chaussures-homme",
    de: "/herren/schuhe-bekleidung-herren",
    nl: "/heren/schoenen-man",
    pl: "/mezczyzni/schoenen-man",
    es: "/hombre/schoenen-man",
    it: "/uomo/schoenen-man",
    dk: null,
    se: null,
  },

  "/men/sports-clothing": {
    en: null,
    fr: "/homme/sport-homme",
    de: "/herren/sport-herren",
    nl: "/heren/sportkleding",
    pl: "/mezczyzni/sportkleding",
    es: "/hombre/sportkleding",
    it: "/uomo/sportkleding",
    dk: null,
    se: null,
  },
  "/men/designer": {
    en: null,
    fr: "/homme/designer",
    de: "/herren/designer",
    nl: "/heren/designer",
    pl: "/mezczyzni/designer",
    es: "/hombre/designer",
    it: "/uomo/designer",
    dk: null,
    se: null,
  },
  "/products/men": {
    en: null,
    fr: "/products/homme",
    de: "/products/herren",
    nl: "/products/heren",
    pl: "/products/heren",
    es: "/products/heren",
    it: "/products/heren",
    dk: null,
    se: null,
  },
  "/women": {
    en: null,
    fr: "/femme",
    de: "/damen",
    nl: "/dames",
    pl: "/kobiety",
    es: "/mujer",
    it: "/donna",
    dk: null,
    se: null,
  },
  "/women/?cnpf=1&cnep=1&orderby=new": {
    en: null,
    fr: "/femme/?cnpf=1&cnep=1&orderby=new",
    de: "/damen/?cnpf=1&cnep=1&orderby=new",
    nl: "/dames/?cnpf=1&cnep=1&orderby=new",
    pl: "/kobiety/?cnpf=1&cnep=1&orderby=new",
    es: "/mujer/?cnpf=1&cnep=1&orderby=new",
    it: "/donna/?cnpf=1&cnep=1&orderby=new",
    dk: null,
    se: null,
  },
  "/women/designer": {
    en: null,
    fr: "/femme/designer",
    de: "/damen/designer",
    nl: "/dames/designer",
    pl: "/kobiety/designer",
    es: "/mujer/designer",
    it: "/donna/designer",
    dk: null,
    se: null,
  },
  "/women/accessories": {
    en: null,
    fr: "/femme/accessoires",
    de: "/damen/accessoires",
    nl: "/dames/accessoires",
    pl: "/kobiety/accessoires",
    es: "/mujer/accessoires",
    it: "/donna/accessoires",
    dk: null,
    se: null,
  },
  "/men/accessories": {
    en: null,
    fr: "/homme/accessoires",
    de: "/accessoires-herren",
    nl: "/heren/accessoires",
    pl: "/mezczyzni/accessoires",
    es: "/hombre/accessoires",
    it: "/uomo/accessoires",
    dk: null,
    se: null,
  },
  "/women/accessories/bags": {
    en: null,
    fr: "/femme/accessoires-femme/sacs",
    de: "/damen/accessoires/taschen",
    nl: "/dames/accessoires/tassen",
    pl: "/kobiety/accessoires/tassen",
    es: "/mujer/accessoires/tassen",
    it: "/donna/accessoires/tassen",
    dk: null,
    se: null,
  },
  "/men/accessories/bags": {
    en: "/men/bags-accessories-men",
    "en-gb": "/men/bags-accessories-men",
    "en-us": "/men/bags-accessories-men",
    fr: "/homme/sacs-accessoires",
    de: "/herren/taschen-accessoires-herren",
    nl: "/heren/tassen-accessoires-man",
    pl: "/mezczyzni/tassen-accessoires-man",
    es: "/heren/tassen-accessoires-man",
    it: "/uomo/tassen-accessoires-man",
    dk: null,
    se: null,
  },
  "/women/clothing": {
    en: null,
    fr: "/femme/vetements-femme",
    de: "/damen/bekleidung",
    nl: "/dames/kleding",
    pl: "/kobiety/kleding",
    es: "/mujer/kleding",
    it: "/donna/kleding",
    dk: null,
    se: null,
  },
  "/women/shoes": {
    en: null,
    fr: "/femme/chaussures-femme",
    de: "/damen/schuhe",
    nl: "/dames/schoenen",
    pl: "/kobiety/buty",
    es: "/mujer/zapatos",
    it: "/donna/scarpe",
    dk: null,
    se: null,
  },
  "/women/sports-clothing": {
    en: null,
    fr: "/femme/sport-femme",
    de: "/damen/sport",
    nl: "/dames/sportkleding-kleding",
    pl: "/kobiety/sportkleding-kleding",
    es: "/mujer/sportkleding-kleding",
    it: "/donna/sportkleding-kleding",
    dk: null,
    se: null,
  },
  "/products/women": {
    en: null,
    fr: "/products/femme",
    de: "/products/damen",
    nl: "/products/dames",
    pl: "/products/dames",
    es: "/products/dames",
    it: "/products/dames",
    dk: null,
    se: null,
  },
  "/team": {
    en: null,
    fr: "/notre-equipe",
    de: null,
    nl: null,
    es: null,
    it: null,
    dk: null,
    se: null,
  },
  "/terms-and-conditions": {
    en: null,
    fr: "/conditions-generales",
    de: "/geschaftsbedingungen",
    nl: "/algemene-voorwaarden",
    pl: "/regulamin",
    es: "/terminos-y-condiciones",
    it: "/termini-e-condizioni",
    dk: null,
    se: null,
  },
  "/privacy-and-cookie-statement": {
    "en-us": "/privacy-and-cookie-statement",
  },
  "/terms-of-use": {
    "en-us": "/terms-of-use",
  },
  "/terms-of-sale": {
    "en-us": "/terms-of-sale",
  },
  "/women": {
    en: null,
    fr: "/femme",
    de: "/damen",
    nl: "/dames",
    pl: "/kobiety",
    es: "/mujer",
    it: "/donna",
    dk: "/dame",
    se: "/kvinna",
  },
  "/women/latest-updates": {
    en: null,
    fr: "/femme/dernieres-mises-a-jour",
    de: "/damen/letzte-aktualisierungen",
    nl: "/dames/laatste-updates",
    pl: "/dames/laatste-updates",
    es: "/dames/laatste-updates",
    it: "/dames/laatste-updates",
    dk: "/dames/laatste-updates",
    se: "/dames/laatste-updates",
  },
  "/women/brands": {
    en: "/women/brands",
    fr: "/femme/marques",
    de: "/damen/marken",
    nl: "/dames/merken",
    pl: "/kobiety/marki",
    es: "/mujer/marcas",
    it: "/donna/marche",
    dk: null,
    se: null,
  },
  "/promotion-conditions": {
    en: null,
    de: "/werbebedingungen",
    fr: "/conditions-dadhesion",
    nl: "/promotievoorwaarden",
    pl: null,
    es: null,
    it: null,
    dk: null,
    se: null,
  },
};

// extend the languages with similar keys
// nl-be similar to nl
// de-at similar to de

// eslint-disable-next-line guard-for-in
for (const property in base) {
  base[property] = {
    ...base[property],
    "en-dk": base[property].dk,
    "en-se": base[property].se,
    "pl-pl": base[property].pl,
    "it-it": base[property].it,
    "es-es": base[property].es,
    "de-at": base[property].de,
    "nl-be": base[property]["nl-be"] || base[property].nl,
    "en-gb": base[property]["en-gb"] || base[property].en,
    "en-us": base[property]["en-us"] || base[property].en,
  };
}

module.exports = { ...base };
