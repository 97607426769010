import { SearchResponse } from "@algolia/client-search";
import { SearchResults } from "src/types/Algolia.d";

// TODO: define it better when implementing components
const getSearchResults = <T>(
  searchResponse: SearchResponse<T>
): SearchResults<T> => {
  return {
    /* The hits are returned by the search.
    Hits are ordered according to the ranking or sorting of the index being queried. */
    results: searchResponse.hits,
    meta: {
      // The number of hits matched by the query.
      nbHits: searchResponse.nbHits,
      // Index of the current page (zero-based).
      page: searchResponse.page + 1,
      // The number of returned pages.
      nbPages: searchResponse.nbPages,
      queryID: searchResponse.queryID,
    },
    facets: {
      facets: searchResponse.facets,
      facetStats: searchResponse.facets_stats,
    },
  };
};

export default getSearchResults;
