import { FC, SVGAttributes } from "react";

interface Props extends SVGAttributes<SVGSVGElement> {
  svgPrefixId: string;
}

const FlagFrRound: FC<Props> = ({ svgPrefixId, ...rest }) => {
  const id = `${svgPrefixId}-flag-dk-round`;
  const circleId = `circle-${id}`;
  const maskId = `mask-${id}`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...rest}
    >
      <defs>
        <circle id={circleId} cx="10" cy="10" r="10" />
      </defs>
      <g
        id="Stylesheet"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Icons" transform="translate(-710.000000, -1502.000000)">
          <g id="Group-5" transform="translate(302.000000, 1502.000000)">
            <g
              id="Icons/Navigation/Flags/France"
              transform="translate(408.000000, 0.000000)"
            >
              <g id="Group">
                <mask id={maskId} fill="white">
                  <use xlinkHref={`#${circleId}`} />
                </mask>
                <use id="Oval" fill="#FFFFFF" xlinkHref={`#${circleId}`} />
                <rect
                  id="Rectangle"
                  fill="#343997"
                  mask={`url(#${maskId})`}
                  x="0"
                  y="0"
                  width="6.66666667"
                  height="20"
                />
                <rect
                  id="Rectangle"
                  fill="#DA022E"
                  mask={`url(#${maskId})`}
                  x="13.3333333"
                  y="0"
                  width="6.66666667"
                  height="20"
                />
              </g>
              <circle
                id="Oval-overlay"
                strokeOpacity="0.2"
                stroke="#000000"
                strokeWidth="0.5"
                cx="10"
                cy="10"
                r="9.75"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export { FlagFrRound };
