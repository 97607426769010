import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useCallback, useState } from "react";
import { ApolloError, ApolloQueryResult } from "@apollo/client";
import { WishlistProductCollection } from "src/types/graphql.d";

export const WISHLISTS = gql`
  query wishlistProducts($limit: Int!, $offset: Int!) {
    wishlistProducts(limit: $limit, offset: $offset, sort: CREATED_DATE_DESC) {
      total
      items {
        id
        name
        slug
        price
        regular_price
        thumbnail
        labels {
          type
          value
          color
          background_color
          text_color
          priority
        }
        availability
        available_sizes_label
        user_wishlist_item_id
        created_at
        brand {
          id
          name
        }
        translated_category_ids
        translated_category_names
        product_discount
        product_kids_gender
        product_shop_type
        is_personalised
      }
    }
  }
`;

interface wishlistProductsData {
  wishlistProducts: WishlistProductCollection;
}
interface wishlistProductVars {
  limit: number;
  offset: number;
}

interface WishlistProductsHook {
  loading: boolean;
  error: ApolloError | undefined;
  data: wishlistProductsData | undefined;
  hasMore: boolean;
  loadMore: () => Promise<ApolloQueryResult<wishlistProductsData>>;
  page: number;
}

const useWishlistProducts = (
  limit: number,
  offset: number
): WishlistProductsHook => {
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const { loading, error, data, fetchMore } = useQuery<
    wishlistProductsData,
    wishlistProductVars
  >(WISHLISTS, {
    variables: { limit, offset },
    ssr: false,
    fetchPolicy: "cache-and-network",
  });
  const loadMore = useCallback(() => {
    return fetchMore({
      variables: {
        limit,
        offset: (page + 1) * limit,
      },
      // @ts-ignore
      updateQuery: (
        prev,
        { fetchMoreResult }: { fetchMoreResult: wishlistProductsData }
      ) => {
        if (!fetchMoreResult) return prev;
        const { wishlistProducts } = fetchMoreResult;
        setPage(page + 1);
        const newItems = [
          ...(prev.wishlistProducts?.items || []),
          ...(wishlistProducts?.items.filter(
            (n) => !prev?.wishlistProducts?.items?.some((p) => p?.id === n?.id)
          ) || []),
        ];

        if (wishlistProducts.items.length === 0) {
          setHasMore(false);
        }

        return {
          wishlistProducts: {
            total: wishlistProducts?.total,
            items: newItems,
            __typename: prev?.wishlistProducts?.__typename, // eslint-disable-line no-underscore-dangle
          },
        };
      },
    });
  }, [fetchMore, limit, page, setHasMore, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  return { loading, error, data, loadMore, hasMore, page };
};

export { useWishlistProducts };
