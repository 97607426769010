import { SVGAttributes } from "react";

function Flash(props: SVGAttributes<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M5.25313 11.7035L12.6858 2.42402C12.8323 2.24143 13.0317 2.10869 13.2567 2.04405C13.4817 1.97941 13.7212 1.98605 13.9422 2.06305C14.1633 2.14005 14.3551 2.28363 14.4912 2.47406C14.6274 2.66449 14.7012 2.89241 14.7025 3.1265V9.73203C14.7025 10.0325 14.8219 10.3207 15.0344 10.5332C15.2469 10.7457 15.5351 10.8651 15.8356 10.8651H17.977C18.1717 10.8799 18.3592 10.9449 18.5214 11.0536C18.6836 11.1623 18.815 11.311 18.9028 11.4854C18.9905 11.6598 19.0318 11.854 19.0224 12.049C19.0131 12.2441 18.9535 12.4334 18.8494 12.5986L12.6178 21.5155C12.4799 21.713 12.2827 21.8614 12.0547 21.9392C11.8268 22.0171 11.5799 22.0202 11.3501 21.9482C11.1202 21.8762 10.9193 21.7328 10.7764 21.5389C10.6336 21.345 10.5562 21.1105 10.5557 20.8697V14.6833C10.5557 14.3828 10.4363 14.0947 10.2238 13.8822C10.0113 13.6697 9.72315 13.5503 9.42266 13.5503H6.13689C5.92248 13.551 5.71227 13.4909 5.53067 13.3769C5.34907 13.263 5.20354 13.0998 5.11099 12.9064C5.01844 12.713 4.98267 12.4973 5.00783 12.2843C5.03299 12.0714 5.11805 11.87 5.25313 11.7035Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export { Flash };
