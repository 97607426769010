type LocaleDomainType = {
  [key: string]: string;
};

const localeDomainMap: LocaleDomainType = {
  fr: "fr",
  de: "de",
  "de-at": "at",
  nl: "nl",
  en: "com",
  "en-gb": "co.uk",
  "en-us": "com",
  "en-dk": "dk",
  "en-se": "se",
  "pl-pl": "pl",
  "es-es": "es",
  "it-it": "it",
  "nl-be": "be",
};

const localeDomainPostfixes = Object.values(localeDomainMap);

export { localeDomainPostfixes, localeDomainMap };
