import { FC, SVGAttributes } from "react";

const FlagFr: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 9 6"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="none"
    {...props}
  >
    <rect width="9" height="6" fill="#ED2939" />
    <rect width="6" height="6" fill="#fff" />
    <rect width="3" height="6" fill="#002395" />
  </svg>
);

export { FlagFr };
