import { FC, ReactNode, useContext } from "react";
import { Trans } from "@lingui/macro";
import { Content } from "src/atoms/Content";
import { Done } from "src/icons/Done";
import { InfoItalic } from "src/icons/InfoItalic";
import {
  ServiceBannerOffer,
  ServiceBannerLink,
  ServiceBannerContainer,
} from "./ServiceBanner.styled";
import { AppCtx } from "src/contexts/app.context";
import { getI18nRoute } from "src/utils/i18nRoutes";
import { Text } from "@otrium/atoms";
import { Box, Flex } from "@otrium/core";
import { TrustpilotCustom } from "src/molecules/TrustpilotBox";
import { LanguageSwitcher } from "src/molecules/LanguageSwitcher";
import { AccessibilityProps } from "src/types/AccessibilityProps";
import { useMiscellaneous } from "src/hooks/useMiscellaneous";
import { useTheme, Theme } from "@emotion/react";
import NoSSR from "src/atoms/NoSSR";

interface Props extends AccessibilityProps {
  children?: ReactNode;
}

const ServiceBanner: FC<Props> = (props) => {
  const { ...a11yProps } = props;
  const { locale, isGhostDomain, isPrivateSale } = useContext(AppCtx);
  const helpCenterHref = isPrivateSale
    ? "https://otrium-balenciaga.elevio.help/"
    : getI18nRoute({ route: "/faq", locale });
  const { data: miscellaneousData } = useMiscellaneous();
  const theme: Theme = useTheme();
  return (
    <Box backgroundColor={theme.colors.tone.black} {...a11yProps}>
      <Content>
        <NoSSR>
          <ServiceBannerContainer>
            {!isPrivateSale && (
              <Flex
                mr="auto"
                display={["none", "none", "flex"]}
                as="ul"
                aria-label="Our advantages"
              >
                {miscellaneousData?.miscellaneous?.header?.map(
                  (header, index) => (
                    <ServiceBannerOffer
                      display={["none", "none", "none", "flex"]}
                      as="li"
                      mr={4}
                      key={index}
                    >
                      <Done width="14" height="14" role="presentation" />
                      <Text ml={2}>{header}</Text>
                    </ServiceBannerOffer>
                  )
                )}
              </Flex>
            )}
            <Box mr={[4, 4, 4, 0]}>
              <TrustpilotCustom locale={locale} />
            </Box>
            <Box display={["none", "none", "none", "flex"]} ml={3} mr={3}>
              <ServiceBannerLink href={helpCenterHref}>
                <InfoItalic width={24} height={24} aria-hidden />
                <Text ml={2} mr={2} sx={{ textDecoration: "underline" }}>
                  <Trans>Help center</Trans>
                </Text>
              </ServiceBannerLink>
            </Box>
            {!isGhostDomain && !isPrivateSale && (
              <LanguageSwitcher
                svgPrefixId="top-header-language-switcher"
                locale={locale}
              />
            )}
          </ServiceBannerContainer>
        </NoSSR>
      </Content>
    </Box>
  );
};

export { ServiceBanner };
