import {
  Children,
  cloneElement,
  createRef,
  FC,
  isValidElement,
  ReactNode,
  RefObject,
} from "react";
import { useDisableScrollBody } from "src/hooks/useDisableScrollBody";
import { Wrapper } from "./Overlay.styled";
import { useOnClickOutside } from "src/hooks/useOnClickOutside";
import { useKeypress } from "src/hooks/useKeypress";
import { Portal } from "src/atoms/Portal";

interface Props {
  isOpen: boolean;
  isActive: boolean;
  onClose: () => void;
  excludeFromOutside?: RefObject<HTMLElement>[];
  mobileTop?: number;
  tabletTop?: number;
  desktopTop?: number;
  delay?: number;
  children?: ReactNode;
}

const Overlay: FC<Props> = ({
  isOpen,
  onClose,
  excludeFromOutside,
  children,
  ...rest
}) => {
  const container = createRef<HTMLDivElement>();
  useOnClickOutside(
    excludeFromOutside ? [container, ...excludeFromOutside] : [container],
    onClose,
    isOpen
  );
  useKeypress("Escape", isOpen, onClose);
  void useDisableScrollBody(isOpen);
  if (!isOpen) {
    return null;
  }
  return (
    <Portal selector="#overlay-portal">
      <Wrapper {...rest}>
        {Children.map(children, (child) =>
          isValidElement(child)
            ? cloneElement<any>(child, { ref: container })
            : null
        )}
      </Wrapper>
    </Portal>
  );
};

export default Overlay;
