import { ButtonHTMLAttributes, forwardRef, ReactNode } from "react";
import { AriaButtonProps, useButton } from "react-aria";

interface BaseButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
}

type Props = BaseButtonProps & AriaButtonProps;

const BaseButton = forwardRef<HTMLButtonElement, Props>(
  (props, ref): JSX.Element => {
    const { buttonProps } = useButton(
      { ...props, isDisabled: props.disabled },
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ref as any
    );
    const { children, className, name } = props;

    return (
      <button ref={ref} name={name} className={className} {...buttonProps}>
        {children}
      </button>
    );
  }
);

BaseButton.displayName = "BaseButton";

export default BaseButton;
