import { forwardRef, useContext } from "react";
import Link from "next/link";
import { useSelector } from "react-redux";
import { getLocaleGenderId, getLocaleGenderIds } from "src/modules/page";
import { getI18nRoute } from "src/utils/i18nRoutes";
import { Locale } from "lib/Intl";
import { AppCtx } from "src/contexts/app.context";
import { EMPLOYEE_SALE_BRAND_SLUG } from "const/employeeSaleBrands";
import {
  StyledGenderLink,
  StyledGenderList,
  StyledGenderListItem,
  StyledGenderMenu,
} from "./GenderSelect.styled";
import { useLingui } from "@lingui/react";
import { shopTypeTranslation } from "src/translations/shopTypeTranslation";

const GenderSelect = forwardRef<
  HTMLDivElement,
  { locale: Locale; isInHeader?: boolean }
>(({ locale, isInHeader = false }, ref) => {
  const localeGenderId = useSelector(getLocaleGenderId);
  const { women, men, kids } = useSelector(getLocaleGenderIds);
  const { isPrivateSale } = useContext(AppCtx);
  const { _ } = useLingui();
  return (
    <StyledGenderMenu
      ref={ref}
      isInHeader={isInHeader}
      data-testid="navmenu-links"
      aria-labelledby="gender-select-label"
    >
      <p id="gender-select-label" className="sr-only">
        Gender
      </p>
      <StyledGenderList isInHeader={isInHeader}>
        {[women, men, kids].map((genderIdValue) => (
          <StyledGenderListItem key={genderIdValue} isInHeader={isInHeader}>
            <Link
              href={
                isPrivateSale
                  ? `${getI18nRoute({
                      route: `/brands`,
                      locale,
                    })}/${EMPLOYEE_SALE_BRAND_SLUG}${getI18nRoute({
                      route: `/${genderIdValue}`,
                      locale,
                    })}`
                  : getI18nRoute({
                      route: `/${genderIdValue}`,
                      locale,
                    })
              }
              passHref
              legacyBehavior
            >
              <StyledGenderLink
                data-testid={`${
                  isInHeader ? "main-header" : "nav-menu"
                }-${genderIdValue}`}
                isInHeader={isInHeader}
                active={
                  genderIdValue === localeGenderId ||
                  (genderIdValue === "women" && !localeGenderId)
                }
              >
                <span>{_(shopTypeTranslation[genderIdValue])}</span>
              </StyledGenderLink>
            </Link>
          </StyledGenderListItem>
        ))}
      </StyledGenderList>
    </StyledGenderMenu>
  );
});

GenderSelect.displayName = "GenderSelect";

export { GenderSelect };
