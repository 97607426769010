import { createRef, FC, PropsWithChildren, ReactNode } from "react";
import { useLockBodyScroll } from "src/hooks/useLockBodyScroll";
import { Overlay } from "src/atoms/Overlay";
import { useOnClickOutside } from "src/hooks/useOnClickOutside";
import { Portal } from "src/atoms/Portal";
import { CloseIconBig } from "src/icons/CloseIconBig";
import {
  StyledDrawer,
  DrawerContainer,
  DrawerCloseButton,
  Slider,
} from "./Drawer.styled";
import { useTheme, Theme } from "@emotion/react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const DrawerSlider: FC<PropsWithChildren<unknown>> = ({
  children,
  ...rest
}) => <Slider {...rest}>{children}</Slider>;

const Drawer: FC<PropsWithChildren<Props>> & {
  Slider: typeof DrawerSlider;
} = ({ isOpen, onClose, children }) => {
  const theme: Theme = useTheme();
  const drawerContainer = createRef<HTMLDivElement>();
  useOnClickOutside(drawerContainer, onClose);
  useLockBodyScroll(isOpen);

  return (
    <Portal selector="#portal">
      <StyledDrawer isOpen={isOpen}>
        <DrawerContainer ref={drawerContainer}>
          {children}
          <DrawerCloseButton
            isOpen={isOpen}
            onClick={onClose}
            fill={theme.colors.tone.anthraciteDark}
            icon={<CloseIconBig />}
          />
        </DrawerContainer>
      </StyledDrawer>
      <Overlay isOpen={isOpen} />
    </Portal>
  );
};

Drawer.Slider = DrawerSlider;

export default Drawer;
