interface Breakpoints {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
}
const BREAKPOINTS: Breakpoints = {
  xs: "0px",
  sm: "600px",
  md: "905px",
  lg: "1240px",
  xl: "1440px",
};

export { BREAKPOINTS };
