import { FC, SVGAttributes } from "react";
import styled from "@emotion/styled";

const StyledSpinner = styled.svg`
  animation: rotate 1.5s linear infinite;
  transform-origin: center center;

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1s ease-in-out infinite;
    stroke-linecap: round;
    stroke-width: 4px;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }

    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
`;

const Spinner: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <StyledSpinner
    viewBox="25 25 50 50"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      className="path"
      cx="50"
      cy="50"
      r="20"
      fill="none"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </StyledSpinner>
);

export { Spinner };
