import { useRef } from "react";
import { omit } from "lodash-es";
import { SearchState } from "react-instantsearch-core";
import {
  SegmentProduct,
  SEGMENT_LIST_FORMAT,
  SEGMENT_LIST_ID,
  SEGMENT_LIST_TYPE,
  Filter,
  restructureRefinements,
  restructureRange,
  restructureMenu,
} from "src/segment";
import {
  CatalogProduct,
  HomepageSingleCategoryProduct,
} from "src/types/graphql.d";
import { useDebouncedCallback } from "use-debounce";
import { useSegment, getFilterDiff, FilterAction } from "./useSegment";
import useProductListViewed from "./useProductListViewed";
import { ContextKey } from "src/utils/algolia/contextKeys";
import { RankingInfo } from "src/contexts/app.context";

export enum LimitType {
  DURATION = 1,
  LENGTH,
}

interface SegmentProductListProps {
  algoliaIndex?: string;
  defaultSortBy?: string;
  isPersonalised?: boolean;
  listFormat: SEGMENT_LIST_FORMAT;
  listId: SEGMENT_LIST_ID | string;
  listType: SEGMENT_LIST_TYPE | ContextKey;
  numberOfItems: number;
  queryID?: string;
  shopType?: string;
  rankingInfo?: RankingInfo;
  responseSourceType?: string | null;
}

export interface SegmentProductList {
  segmentProductOnClick: (
    product: (CatalogProduct | HomepageSingleCategoryProduct) & {
      objectID?: string;
    },
    position: number
  ) => void;
  segmentViewAllOnClick: () => void;
  setVisibleProducts: (
    product: CatalogProduct | HomepageSingleCategoryProduct,
    position: number,
    limitType?: LimitType
  ) => void;
  algoliaListFiltered: (searchState: SearchState) => void;
  algoliaListReset: () => void;
}

interface Ref {
  products: SegmentProduct[];
}

const useSegmentProductList = ({
  algoliaIndex,
  defaultSortBy,
  isPersonalised,
  listFormat,
  listId,
  listType,
  numberOfItems,
  queryID,
  shopType,
  rankingInfo,
  responseSourceType,
}: SegmentProductListProps): SegmentProductList => {
  const ref = useRef<Ref>({
    products: [],
  });

  const prevListingData = useRef<{
    page: string | number | null | undefined;
    sort: string | null | undefined;
    filters: Filter[];
  }>();

  const {
    productListFiltered,
    segmentProductClicked,
    segmentProductListClicked,
    productListFilterReset,
    segmentProductListViewed,
  } = useSegment();

  const { addProductToQueue } = useProductListViewed({
    algoliaIndex,
    defaultSortBy,
    isPersonalised,
    listFormat,
    listId,
    listType,
    numberOfItems,
    queryID,
    shopType,
    rankingInfo,
  });

  const debounced = useDebouncedCallback((products: SegmentProduct[]) => {
    if (products?.length) {
      void segmentProductListViewed({
        algoliaIndex,
        defaultSortBy,
        isPersonalised,
        productListMeta: {
          listFormat,
          listId,
          listType,
          shopType,
        },
        numberOfItems,
        products,
        queryID,
        rankingInfo,
        responseSourceType,
      });
      ref.current.products = [];
    }
  }, 500);

  const setVisibleProducts = (
    product: CatalogProduct | HomepageSingleCategoryProduct,
    position: number,
    limitType: LimitType = LimitType.DURATION
  ) => {
    if (limitType === LimitType.DURATION) {
      ref.current.products = [
        ...ref.current.products,
        { ...product, position: position + 1 },
      ];

      debounced(ref.current.products);
    } else {
      addProductToQueue({
        ...product,
        position,
      });
    }
  };

  const segmentViewAllOnClick = () => {
    void segmentProductListClicked({
      listFormat,
      listId,
      listType,
      shopType,
      rankingInfo,
    });
  };

  const segmentProductOnClick = (
    product: (CatalogProduct | HomepageSingleCategoryProduct) & {
      objectID?: string;
    },
    position: number
  ) =>
    void segmentProductClicked({
      algoliaIndex,
      isPersonalised,
      position,
      product,
      productListMeta: {
        listFormat,
        listId,
        listType,
        shopType,
      },
      rankingInfo,
      queryID,
    });

  const algoliaListFiltered = (searchState: SearchState) => {
    const { refinementList, sortBy, page, range, menu } = searchState;
    const ignoredFilters = Object.keys(
      (searchState.keepSelectedIgnoreInActive as object) ?? {}
    );
    const relevantFilters = restructureRefinements(
      omit(refinementList, ignoredFilters)
    );

    const relevantRange = restructureRange(range?.price);

    const relevantMenu = restructureMenu(omit(menu, ignoredFilters));

    const allFilters = [...relevantFilters, ...relevantRange, ...relevantMenu];

    const filterDiff = getFilterDiff(
      allFilters,
      prevListingData.current?.filters
    ).filter((f) => !!f.value);

    const data: {
      filterAction?: FilterAction;
      filtersApplied?: Filter[];
      sortApplied?: string;
      numPage?: number;
    } = {};

    if (
      page &&
      prevListingData.current?.page !== page &&
      page > (prevListingData.current?.page ?? 1)
    ) {
      data.filterAction = "next_page";
      data.numPage = page;
    } else if (prevListingData.current?.sort !== sortBy) {
      data.filterAction = "sort";
      data.sortApplied = sortBy;
    } else if (filterDiff.length > 0) {
      data.filterAction = "filter";
      data.filtersApplied = filterDiff;
    }

    if (data.filterAction) {
      void productListFiltered({
        algoliaIndex,
        meta: {
          listFormat,
          listId,
          listType,
          shopType,
        },
        queryID,
        searchState,
        rankingInfo,
        ...data,
      });
    }

    prevListingData.current = {
      page,
      sort: sortBy,
      filters: allFilters,
    };
  };

  const algoliaListReset = () =>
    void productListFilterReset({
      algoliaIndex,
      meta: {
        listFormat,
        listId,
        listType,
        shopType,
      },
      queryID,
      rankingInfo,
    });

  return {
    segmentProductOnClick,
    segmentViewAllOnClick,
    setVisibleProducts,
    algoliaListFiltered,
    algoliaListReset,
  };
};

export { useSegmentProductList };
