import { useContext, FC, useEffect, useRef } from "react";
import { AppCtx } from "src/contexts/app.context";
import { Divider } from "src/atoms/Divider";
import { Wrapper, MiddleWrapper, Container } from "./Footer.styled";
import { FooterHighlight } from "./FooterHighlight";
import { FooterLinks } from "./FooterLinks";
import { FooterButtons } from "./FooterButtons";
import { FooterBottom } from "./FooterBottom";
import { FooterFeatures } from "./FooterFeatures";
import FooterOptIn from "./FooterOptIn";
import { useSegment } from "src/hooks/useSegment";
import NoSSR from "src/atoms/NoSSR";

interface Props {
  className?: string;
}

const Footer: FC<Props> = ({ className }) => {
  const { locale, isGhostDomain, shouldScrollToFooter, homePageMounted } =
    useContext(AppCtx);
  const footerRef = useRef<HTMLElement | null>(null);
  const { segmentOptInDeeplinkViewed } = useSegment();

  useEffect(() => {
    if (homePageMounted && shouldScrollToFooter && footerRef.current) {
      setTimeout(() => {
        const footerPosition =
          (footerRef.current?.getBoundingClientRect()?.top ?? 0) +
          window.scrollY;
        window.scrollTo({ top: footerPosition - 50, behavior: "auto" });
      }, 1000);
      void segmentOptInDeeplinkViewed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homePageMounted]);

  return (
    <Wrapper ref={footerRef} className={className}>
      <NoSSR>
        <FooterOptIn />
      </NoSSR>
      <FooterHighlight locale={locale} />
      <FooterFeatures locale={locale} />
      <Container>
        <MiddleWrapper justifyContent="space-between" mb={[0, 0, 0, "48px"]}>
          <FooterLinks />
          <FooterButtons />
        </MiddleWrapper>
        <Divider />
        <FooterBottom locale={locale} isGhostDomain={isGhostDomain} />
      </Container>
    </Wrapper>
  );
};

export default Footer;
