import { HtEventsBrowser } from "@ht-sdks/events-sdk-js-browser";
import type { AnalyticsSnippet } from "@segment/analytics-next";
import { isClient } from "src/utils/isClient";

type AnalyticsProps = {
  analytics: AnalyticsSnippet;
  htevents: HtEventsBrowser;
};

export const getAnalytics = (): {
  analytics: AnalyticsSnippet;
  htevents: HtEventsBrowser;
} => {
  if (!isClient) {
    // analytics is only available in the client
    return {} as AnalyticsProps;
  }
  return { analytics: window.analytics, htevents: window.htevents };
};
