const localeGenderMap = {
  en: {
    women: "women",
    men: "men",
    kids: "kids",
  },
  "en-gb": {
    women: "women",
    men: "men",
    kids: "kids",
  },
  "en-us": {
    women: "women",
    men: "men",
    kids: "kids",
  },
  "en-dk": {
    women: "women",
    men: "men",
    kids: "kids",
  },
  "en-se": {
    women: "women",
    men: "men",
    kids: "kids",
  },
  "pl-pl": {
    women: "women",
    men: "men",
    kids: "kids",
  },
  "de-at": {
    women: "damen",
    men: "herren",
    kids: "kinder",
  },
  nl: {
    women: "dames",
    men: "heren",
    kids: "kids",
  },
  "nl-be": {
    women: "dames",
    men: "heren",
    kids: "kids",
  },
  de: {
    women: "damen",
    men: "herren",
    kids: "kinder",
  },
  fr: {
    women: "femme",
    men: "homme",
    kids: "enfant",
  },
  "es-es": {
    women: "mujer",
    men: "hombre",
    kids: "ninos",
  },
  "it-it": {
    women: "donna",
    men: "uomo",
    kids: "bambini",
  },
};

module.exports = {
  localeGenderMap,
};
