import { FC, SVGAttributes } from "react";

const ReferAFriend: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(9 16)" fill="none" fillRule="evenodd">
      <circle stroke="#000" strokeWidth="2" cx="15" cy="4" r="3" />
      <path
        d="M23 0a4 4 0 11-2.749 6.906A5.977 5.977 0 0021 4a5.973 5.973 0 00-.749-2.905A3.985 3.985 0 0123 0zm0 2a2 2 0 100 4 2 2 0 000-4zM15 9a7 7 0 017 7h-2a5 5 0 00-10 0H8a7 7 0 017-7zM23 9a7 7 0 017 7h-2a5 5 0 00-5.499-4.975A9.066 9.066 0 0021 9.29 6.952 6.952 0 0123 9z"
        fill="#000"
        fillRule="nonzero"
      />
      <path
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="round"
        d="M0 8h8M4 4v8"
      />
    </g>
  </svg>
);

export { ReferAFriend };
