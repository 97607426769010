import { FC, JSX, ForwardedRef, forwardRef } from "react";
import { Flex, SxStyleProp } from "@otrium/core";
import { useTheme, Theme } from "@emotion/react";
import BaseButton from "src/atoms/Button/BaseButton";

export type IconButtonProps = {
  onClick: () => void;
  sx?: SxStyleProp;
  children?: JSX.Element;
  "data-testid"?: string;
  "aria-label"?: string;
  className?: string;
  ref?: ForwardedRef<any>;
};

const IconButton: FC<IconButtonProps> = forwardRef(
  ({ onClick, children, sx, ...rest }, ref) => {
    const theme: Theme = useTheme();
    return (
      <Flex
        ref={ref}
        as={BaseButton}
        sx={{
          cursor: "pointer",
          border: "none",
          width: 32,
          height: 32,
          padding: theme?.space[2],
          ...sx,
        }}
        alignItems="center"
        bg="transparent"
        onClick={onClick}
        {...rest}
        data-testid={rest["data-testid"] || "icon-button"}
        aria-label={rest["aria-label"] || "Icon Button"}
      >
        {children}
      </Flex>
    );
  }
);

IconButton.displayName = "IconButton";

export default IconButton;
