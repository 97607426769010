/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/**
 * Creates a tree of nested categories
 */
const makeTreeNode = (
  cat: any,
  parentId: number | string,
  maxLevels?: number
) => {
  const filtered = (cat || []).filter(
    ({ parent }: { parent: string | number }) => parent === parentId
  );
  const hasChildTree = filtered.length > 0;
  if (!hasChildTree) {
    return null;
  }

  if (maxLevels !== undefined && maxLevels === 0) {
    return filtered;
  }

  return filtered.map((i: any) => {
    const children = makeTreeNode(cat, i.id as string, (maxLevels || 0) - 1);

    return {
      ...i,
      children: children
        ? children.sort((a: any, b: any) => a.name.localeCompare(b.name))
        : children,
    };
  });
};

const createTreeNode = (
  data: any,
  rootCategoryId: string | number,
  maxLevels?: number
) => makeTreeNode(data, rootCategoryId, maxLevels);

type genderEnum = "men" | "women" | "kids";

const getTreeByGenderId = (dataTree: any, genderId: genderEnum) =>
  dataTree.filter((i: any) => i.name.toLowerCase() === genderId);

export { createTreeNode, getTreeByGenderId };
