import { useQuery } from "@apollo/react-hooks";
import { ApolloError, ApolloQueryResult } from "@apollo/client";
import gql from "graphql-tag";
import { StoreSettings } from "src/types/graphql.d";

export interface StoreSettingsData {
  storeSettings: StoreSettings;
}

interface StoreSettingsHook {
  data: StoreSettingsData | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => Promise<ApolloQueryResult<StoreSettingsData>>;
}

export const GET_STORE_SETTINGS = gql`
  query storeSettings {
    storeSettings {
      min_cart_subtotal_price_cent_amount
    }
  }
`;

const useStoreSettings = (): StoreSettingsHook => {
  const { loading, error, data, refetch } = useQuery<StoreSettingsData>(
    GET_STORE_SETTINGS,
    {
      ssr: true,
    }
  );
  return { loading, error, data, refetch };
};

export { useStoreSettings };
