import { Text, FormInput } from "@otrium/atoms";
import { Box } from "@otrium/core";
import styled from "@emotion/styled";

const Wrapper = styled(Box)<{
  hasValue: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
}>`
  position: relative;
  ${({ hasValue, disabled, theme }) =>
    (!hasValue || disabled) &&
    `
  g {
    stroke: ${theme.colors.tone.silver};
    }
  `}
  ${({ fullWidth }) => fullWidth && "width: 100%;"}
`;

const Error = styled(Text)`
  color: ${({ theme }) => theme.colors.semantic.red};
  font-size: ${({ theme }) => theme.fontSizes[0]}px;
  line-height: 20px;
  letter-spacing: 0.004em;
  padding-top: ${({ theme }) => theme.space.space8};
`;

const StyledInput = styled(FormInput)<{ error?: boolean; rightIcon?: boolean }>`
  border-radius: 4px;
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colors.semantic.red : theme.colors.tone.silver};
  font-size: ${({ theme }) => theme.fontSizes[1]}px;
  line-height: 1.5;
  letter-spacing: 0.4px;
  padding-left: ${({ theme }) => theme.space.space16};
  padding-right: ${({ theme, rightIcon }) =>
    rightIcon ? theme.space.space48 : theme.space.space16};
  color: ${({ theme }) => theme.colors.tone.black};
  background-color: ${({ theme }) => theme.colors.tone.white};
  height: 48px;
  width: 100%;
  &::placeholder {
    color: ${({ theme }) => theme.colors.tone.anthraciteLight};
  }
  &:focus-visible {
    border: 2px solid ${({ theme }) => theme.colors.semantic.blue};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.tone.sand};
    color: ${({ theme }) => theme.colors.tone.anthraciteLight};
  }
`;

const Label = styled(Text)`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.tone.white};
  color: ${({ theme }) => theme.colors.tone.black};
  top: -10px;
  left: 8px;
  font-size: ${({ theme }) => theme.fontSizes[0]}px;
  line-height: 20px;
  letter-spacing: 0.004em;
  font-weight: 600;
  z-index: 1;
`;

const IconBox = styled(Box)`
  position: absolute;
  right: 12px;
  top: 12px;
  width: 24px;
  height: 24px;
  svg {
    width: 24px;
    height: 24px;
  }
`;

export { Wrapper, Error, StyledInput, Label, IconBox };
