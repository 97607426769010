import { Iso2LetterLocale } from "lib/Intl";
import {
  BaseMoney,
  LineItem,
  Money,
  ProductPrice,
  RawCustomField,
} from "src/types/ctgraphql.d";
import { StoreConfig } from "src/utils/storeConfig";
import { getCTCustomField } from "src/utils/ct/attributeHelper";

export function getRegularPrice(
  lineItem: LineItem,
  storeConfig: StoreConfig
): BaseMoney | Money {
  return getCTCustomField(
    "list_price",
    getLineItemPrice(lineItem.variant?.prices, storeConfig)?.custom
      ?.customFieldsRaw as RawCustomField[]
  ) as BaseMoney | Money;
}

export function getLineItemPrice(
  prices: ProductPrice[] | undefined | null,
  storeConfigOrCountryCode: StoreConfig | Iso2LetterLocale | null
): ProductPrice | undefined {
  const key = (() => {
    if (typeof storeConfigOrCountryCode === "string") {
      return storeConfigOrCountryCode;
    }

    if (
      typeof storeConfigOrCountryCode === "object" &&
      storeConfigOrCountryCode?.currencyCode
    ) {
      return storeConfigOrCountryCode.currencyCode;
    }

    return storeConfigOrCountryCode;
  })();

  const fallbackPrice = prices?.find(
    (price) => !price?.country && price?.value?.currencyCode === key
  );

  const currencyPrice = key
    ? prices?.find(
        (price) => price?.value?.currencyCode === key && price.country === key
      )
    : null;

  return currencyPrice || fallbackPrice;
}

export function getLineItemDiscountPrice(lineItem: LineItem): BaseMoney | null {
  if (lineItem.discountedPricePerQuantity.length === 0) {
    return null;
  }

  const lineItemDiscountValue = lineItem.discountedPricePerQuantity
    .map((item) => item.discountedPrice.value)
    .reduce((totalDiscount, currentValue) => {
      return {
        ...totalDiscount,
        ...currentValue,
        centAmount:
          ((totalDiscount.centAmount as number) || 0) +
          ((currentValue.centAmount as number) || 0),
      };
    }, {} as BaseMoney);

  return lineItemDiscountValue;
}
