import { FC, SVGAttributes } from "react";

const SearchBlackIcon: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="10" cy="10" r="7" stroke="black" strokeWidth="2" />
    <path
      d="M15 15L21 21"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export { SearchBlackIcon };
