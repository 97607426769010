import { FC, SVGAttributes } from "react";

const PaypalIconWhite: FC<SVGAttributes<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="96"
      height="40"
      viewBox="0 0 96 40"
      {...props}
    >
      <title>Paypal</title>
      <defs>
        <path
          id="6vwlb6qzla"
          d="M28.904 4.925c.318 0 .598.209.69.513L31.05 10.3l3.434-5.06c.135-.196.358-.314.596-.315h2.49c.16 0 .308.09.382.232.075.142.064.314-.028.445l-8.282 11.956c-.135.194-.355.309-.591.309h-2.487c-.162 0-.31-.09-.384-.234-.074-.143-.062-.316.032-.447l2.578-3.64-2.742-8.05c-.045-.132-.024-.278.057-.39.082-.114.213-.181.352-.18h2.447zm-10.646-.233c1.194 0 2.39.26 2.927 1.04l.173.25.11-.693c.033-.21.214-.364.426-.364h2.478c.126 0 .246.055.327.151.082.096.118.223.098.347l-1.34 8.482c-.054.35-.356.608-.71.607h-2.232c-.126 0-.246-.055-.328-.151-.082-.096-.117-.223-.098-.348l.11-.689s-1.223 1.42-3.43 1.42c-1.285 0-2.365-.372-3.12-1.261-.824-.968-1.16-2.356-.924-3.807.456-2.91 2.795-4.984 5.533-4.984zM8.903.138c1.733 0 3.037.458 3.773 1.322.67.787.89 1.91.66 3.34-.514 3.27-2.482 4.919-5.89 4.919H5.806c-.354 0-.655.258-.71.607l-.564 3.58c-.055.35-.356.607-.71.607H1.351c-.126 0-.246-.055-.328-.15-.082-.096-.118-.223-.099-.348L3.018.745c.055-.35.357-.607.71-.607h5.175zm9.943 7.182c-1.411 0-2.554.982-2.777 2.385-.116.689.023 1.307.39 1.741.368.438.943.668 1.663.668 1.433 0 2.557-.95 2.796-2.366.108-.685-.042-1.307-.42-1.753-.376-.441-.948-.675-1.652-.675zM7.21 3.01h-.59c-.212 0-.393.154-.426.364l-.548 3.468h.953c1.316-.003 2.63-.077 2.902-1.857.103-.652.02-1.124-.252-1.442-.455-.533-1.337-.533-2.271-.533h.232z"
        />
        <path
          id="002ak8eljb"
          d="M17.38 4.692c1.193 0 2.389.26 2.926 1.04l.174.25.11-.693c.031-.21.212-.364.425-.364h2.476c.126 0 .246.055.328.15.083.097.119.223.1.348l-1.34 8.482c-.055.35-.356.607-.71.607h-2.232c-.126 0-.246-.055-.328-.15-.083-.097-.118-.224-.098-.35l.11-.688s-1.223 1.42-3.43 1.42c-1.286 0-2.365-.372-3.121-1.261-.823-.968-1.159-2.356-.923-3.807.456-2.91 2.794-4.984 5.532-4.984zM8.022.138c1.732 0 3.036.458 3.77 1.322.67.787.894 1.91.661 3.34-.514 3.27-2.48 4.919-5.89 4.919H4.926c-.354 0-.655.258-.71.607l-.594 3.762c-.038.244-.249.425-.496.425H.47c-.126 0-.246-.055-.327-.15-.082-.097-.118-.223-.098-.348L2.138.745c.055-.349.356-.606.71-.607h5.175zm21.206 0c.126 0 .246.055.327.151.082.096.118.223.098.347l-2.094 13.27c-.055.35-.356.607-.71.607h-2.136c-.126 0-.246-.055-.327-.15-.082-.097-.118-.223-.098-.348L26.413.502c.033-.209.213-.364.425-.364h2.391zM17.965 7.32c-1.411 0-2.552.982-2.777 2.385-.115.689.023 1.307.39 1.741.368.438.945.668 1.663.668 1.433 0 2.558-.95 2.796-2.366.11-.685-.04-1.307-.42-1.753-.376-.441-.948-.675-1.652-.675zM6.33 3.01h-.59c-.212 0-.393.154-.425.364l-.549 3.468h.952c1.317-.003 2.632-.077 2.902-1.857.104-.652.021-1.124-.25-1.442-.456-.533-1.338-.533-2.272-.533h.232z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-1056 -768) translate(1056 768) translate(14 12)">
          <use fill="#FFF" xlinkHref="#6vwlb6qzla" />
        </g>
        <g transform="translate(-1056 -768) translate(1056 768) translate(14 12) translate(37.792)">
          <use fill="#FFF" xlinkHref="#002ak8eljb" />
        </g>
      </g>
    </svg>
  );
};

export { PaypalIconWhite };
