import { FC, SVGAttributes } from "react";

interface Props extends SVGAttributes<SVGSVGElement> {
  svgPrefixId: string;
}

const FlagGbRound: FC<Props> = ({ svgPrefixId, ...rest }) => {
  const id = `${svgPrefixId}-flag-dk-round`;
  const rectId = `rect-${id}`;
  const maskId = `mask-${id}`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...rest}
    >
      <defs>
        <rect id={rectId} width="20" height="20" x="0" y="0" rx="10" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-574 -1502) translate(302 1502) translate(272)">
          <g>
            <mask id={maskId} fill="#fff">
              <use xlinkHref={`#${rectId}`} />
            </mask>
            <use fill="#051D7F" xlinkHref={`#${rectId}`} />
            <path
              fill="#FFF"
              d="M0 5.833L10 8.333 20 5.833 20 14.167 10 11.667 0 14.167z"
              mask={`url(#${maskId})`}
            />
            <path
              stroke="#FFF"
              strokeWidth="3.333"
              d="M20 15.833L0 4.167M0 15.833L20 4.167"
              mask={`url(#${maskId})`}
            />
            <path
              stroke="#D01628"
              strokeWidth="1.25"
              d="M20 15.833L0 4.167M0 15.833L20 4.167"
              mask={`url(#${maskId})`}
            />
            <path
              fill="#FFF"
              d="M0 6.667H20V13.334H0z"
              mask={`url(#${maskId})`}
            />
            <path
              fill="#FFF"
              d="M6.667 0L13.333 0 13.333 20 6.667 20z"
              mask={`url(#${maskId})`}
            />
            <path
              fill="#D01628"
              d="M0 8.333H20V11.666H0z"
              mask={`url(#${maskId})`}
            />
            <path
              fill="#D01628"
              d="M8.333 0H11.666V20H8.333z"
              mask={`url(#${maskId})`}
            />
          </g>
          <circle
            cx="10"
            cy="10"
            r="9.75"
            stroke="#000"
            strokeOpacity=".2"
            strokeWidth=".5"
          />
        </g>
      </g>
    </svg>
  );
};

export { FlagGbRound };
