import { FC, InputHTMLAttributes, useContext } from "react";
import { AppCtx } from "src/contexts/app.context";
import { getCurrencySymbol } from "src/utils/getCurrencySymbol";
import { Currency, Input, Wrapper } from "./CurrencyInput.styled";

const CurrencyInput: FC<InputHTMLAttributes<HTMLInputElement>> = (props) => {
  const { locale } = useContext(AppCtx);
  const currency = getCurrencySymbol(locale);
  return (
    <Wrapper>
      <Input type="number" {...props} currencyLength={currency.length} />
      <Currency>{currency}</Currency>
    </Wrapper>
  );
};

export default CurrencyInput;
