import { FC, SVGAttributes } from "react";

interface Props {
  isFooter?: boolean;
}

const ChevronRight: FC<SVGAttributes<SVGSVGElement> & Props> = ({
  isFooter,
  ...rest
}) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    stroke="none"
    {...rest}
  >
    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
  </svg>
);

export { ChevronRight };
