import { FC, SVGAttributes } from "react";
export const LogoFooter: FC<SVGAttributes<SVGSVGElement>> = ({ color }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6744_16435)">
      <path
        d="M7.99976 0C6.41751 0 4.87079 0.469192 3.5552 1.34824C2.2396 2.22729 1.21422 3.47672 0.608723 4.93853C0.00322211 6.40034 -0.155205 8.00887 0.153477 9.56072C0.462159 11.1126 1.22408 12.538 2.3429 13.6569C3.46172 14.7757 4.88719 15.5376 6.43904 15.8463C7.99088 16.155 9.59942 15.9965 11.0612 15.391C12.523 14.7855 13.7725 13.7602 14.6515 12.4446C15.5306 11.129 15.9998 9.58225 15.9998 8C15.9998 5.87827 15.1569 3.84344 13.6566 2.34315C12.1563 0.842854 10.1215 0 7.99976 0V0ZM12.0575 5.89986C11.8685 6.69194 11.3208 8.21612 10.8705 9.25035C9.95846 11.3573 9.02154 12.8849 8.09707 13.8229C7.30499 14.6003 6.24813 15.2362 5.05888 14.7496C3.70103 14.1952 3.64219 13.0059 3.67387 11.8993C3.7214 10.6025 4.20909 8.85884 5.12564 6.75304C6.04219 4.64724 6.9825 3.10042 7.89905 2.18048C8.69113 1.40198 9.69368 0.716266 10.9191 1.21754C12.5531 1.88515 12.6312 3.60056 12.0575 5.89986Z"
        fill={color ? color : "white"}
      />
    </g>
    <defs>
      <clipPath id="clip0_6744_16435">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
