import styled from "@emotion/styled";
import { HeartCardIcon } from "src/icons/HeartCardIcon";
import { HeartPageIcon } from "src/icons/HeartPageIcon";

const StyledHeartCardIcon = styled(HeartCardIcon)`
  path {
    transition: fill-opacity 0.3s;
  }
`;

const StyledHeartPageIcon = styled(HeartPageIcon)`
  path {
    transition: fill 0.3s, fill-opacity 0.3s;
  }
`;

export {
  StyledHeartCardIcon as HeartCardIcon,
  StyledHeartPageIcon as HeartPageIcon,
};
