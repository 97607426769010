import { combineReducers } from "redux";
import { reducer as header } from "src/organisms/Header";
import { reducer as page } from "src/modules/page";

const rootReducer = combineReducers({
  header,
  page,
});

export { rootReducer };
