function ArrowBack({
  color = "#000",
  role,
}: {
  color?: string;
  role?: string;
}): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" role={role}>
      <path
        d="M6 2L2 6l4 4"
        stroke={color}
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
}

export { ArrowBack };
