import { FC, useCallback, useState, useContext, useMemo } from "react";
import { Trans } from "@lingui/macro";
import { Locale } from "lib/Intl";
import { useSelector } from "react-redux";
import Link from "next/link";
import { Box, Flex } from "@otrium/core";
import { LanguageMenu } from "src/atoms/LanguageMenu";
import { Button } from "src/atoms/Button";
import { signOut } from "src/utils/nextAuth";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import { getI18nRoute } from "src/utils/i18nRoutes";
import { getGenderId } from "src/modules/page";
import {
  CustomerServiceIcon,
  DrawerSlider,
  NavIcon,
  NavItem,
  NavItemSecondary,
  ReferFriendsIcon,
  StyledNavMenu,
  UserIcon,
  Submenu,
  NavMenuContentWrapper,
  NavMenuNavigation,
  NavMenuActions,
  SubcategoriesMenu,
  StyledNavButton,
} from "./NavMenu.styled";
import { GenderSelect } from "./GenderSelect";
import { Item, loggedOutConfig } from "./config";
import { LanguageSelector } from "src/molecules/LanguageSelector";
import { AppCtx } from "src/contexts/app.context";
import { ArrowRightIcon } from "src/icons/ArrowRightIcon";
import { useCategories } from "src/hooks/useCategories";
import { LoadingSpinner } from "src/atoms/LoadingSpinner";
import { CatalogCategoryWithEnglishName } from "src/types/graphql.d";
import { createTreeNode } from "src/utils/treeNode";
import { formatName } from "src/utils/formatName";
import { NavMenuSkeleton } from "src/organisms/Header/NavMenuSkeleton";
import NavigationContext from "src/contexts/navigation.context";
import useNavigationItemsBySection, {
  NavigationLink,
} from "src/organisms/Header/useNavigationItemsBySection";
import { Divider } from "src/atoms/Divider";
import { useTheme, Theme } from "@emotion/react";
import { useSegment } from "src/hooks/useSegment";
import { useLingui } from "@lingui/react";

export enum SubMenu {
  categories = "categories",
  myAccount = "myAccount",
  languages = "languages",
}

const NavigationItem = ({
  item,
  onOpenSubMenu,
  onClose,
}: {
  item: NavigationLink;
  onOpenSubMenu: (
    subMenu: SubMenu,
    category?: Item | CatalogCategoryWithEnglishName
  ) => () => void;
  onClose: () => void;
}) => {
  const { segmentMenuNavClicked } = useSegment();

  const handleNavItemItemClick = useCallback(
    ({
      menuClickType,
      menuClickName,
    }: {
      menuClickType: string;
      menuClickName: string;
    }) => {
      const menuType = "mobile";
      void segmentMenuNavClicked(menuType, menuClickType, menuClickName);
    },
    [segmentMenuNavClicked]
  );

  return "title" in item ? (
    <NavItem
      onClick={() => {
        handleNavItemItemClick({
          menuClickType: "new page",
          menuClickName: item.title_en,
        });
      }}
    >
      <Link href={item.link} passHref legacyBehavior>
        <Box as="a" color={item.colour} onClick={onClose}>
          {item.title}
        </Box>
      </Link>
    </NavItem>
  ) : (
    <NavItem>
      <Flex
        as="button"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        sx={{ border: "none", background: "none" }}
        color={item.colour}
        onClick={onOpenSubMenu(
          SubMenu.categories,
          item as CatalogCategoryWithEnglishName
        )}
      >
        {item.name}
        <ArrowRightIcon />
      </Flex>
    </NavItem>
  );
};

const NavMenu: FC<{
  locale: Locale;
  onClose: () => void;
  isLoginFlow: boolean;
}> = ({ locale, onClose, isLoginFlow }) => {
  const [subMenuItems, setSubMenuItems] = useState<SubMenu | undefined>(
    undefined
  );
  const [isOpenSubMenu, setIsOpenSubMenu] = useState(false);
  const [category, setCategory] = useState<
    Item | CatalogCategoryWithEnglishName | undefined
  >(undefined);

  const handleOpenSubMenu = useCallback(
    (subMenu: SubMenu, subCategory?: Item | CatalogCategoryWithEnglishName) =>
      () => {
        setIsOpenSubMenu(true);
        setCategory(subCategory);
        setSubMenuItems(subMenu);
      },
    []
  );
  const handleCloseSubMenu = useCallback(() => {
    setIsOpenSubMenu(false);
    setTimeout(() => setSubMenuItems(undefined), 300);
  }, []);

  return (
    <StyledNavMenu>
      <DrawerSlider isOpen={isOpenSubMenu}>
        <MainMenu
          onOpenSubMenu={handleOpenSubMenu}
          locale={locale}
          onClose={onClose}
          isLoginFlow={isLoginFlow}
        />
        {subMenuItems === SubMenu.categories && (
          <CategoriesMenu
            onBack={handleCloseSubMenu}
            onClose={onClose}
            locale={locale}
            category={category}
          />
        )}
        {subMenuItems === SubMenu.languages && (
          <LanguageMenu onBack={handleCloseSubMenu} onClose={onClose} />
        )}
      </DrawerSlider>
    </StyledNavMenu>
  );
};

interface MainMenuProps {
  onOpenSubMenu: (
    subMenu: SubMenu,
    category?: Item | CatalogCategoryWithEnglishName
  ) => () => void;
  onClose: () => void;
  locale: Locale;
  isLoginFlow: boolean;
}

const MainMenu: FC<MainMenuProps> = ({
  onOpenSubMenu,
  locale,
  onClose,
  isLoginFlow,
}) => {
  const theme: Theme = useTheme();
  const { navigationItems, loading: navigationItemsLoading } =
    useContext(NavigationContext);
  const { i18n } = useLingui();

  const isLoggedIn = useIsLoggedIn();
  const [showSignOutButtonUntilRedirect, setShowSignOutButtonUntilRedirect] =
    useState<boolean | undefined>(undefined);

  const navigationItemsBySection = useNavigationItemsBySection(navigationItems);
  const loggedOutItems = () => loggedOutConfig(i18n)[locale];
  const { isGhostDomain, featureFlags, isPrivateSale } = useContext(AppCtx);

  const blockForLoginFlow = (
    <NavMenuContentWrapper>
      <NavMenuNavigation>
        {loggedOutItems()?.map((item) => {
          return (
            <NavItem key={item.name}>
              <Link href={item.url} passHref legacyBehavior>
                <Box as="a" color="tone.black" onClick={onClose}>
                  {item.name}
                </Box>
              </Link>
            </NavItem>
          );
        })}
        {!isGhostDomain && (
          <NavItemSecondary>
            <LanguageSelector onClick={onOpenSubMenu(SubMenu.languages)} />
          </NavItemSecondary>
        )}
      </NavMenuNavigation>
      <Flex
        flexDirection={["column", "column", "row"]}
        px={3}
        justifyContent={["center", "center", "space-around"]}
      >
        <Link href="/register/" passHref legacyBehavior>
          <StyledNavButton variant="primary" as="a" onClick={onClose}>
            <Trans>Sign up</Trans>
          </StyledNavButton>
        </Link>
        <Link href="/sign-in/" passHref legacyBehavior>
          <StyledNavButton as="a" onClick={onClose} variant="secondary">
            <Trans>Login</Trans>
          </StyledNavButton>
        </Link>
      </Flex>
    </NavMenuContentWrapper>
  );

  const blockForNotLoginFlow = (
    <>
      <GenderSelect locale={locale} />

      <NavMenuContentWrapper>
        <NavMenuNavigation>
          {navigationItemsLoading ? (
            <NavMenuSkeleton />
          ) : (
            <>
              {navigationItemsBySection.firstSection.map((item, index) => (
                <NavigationItem
                  key={`navigation_first_section_${index}`}
                  item={item}
                  onOpenSubMenu={onOpenSubMenu}
                  onClose={onClose}
                />
              ))}
              {navigationItemsBySection.firstSection.length > 0 && (
                <Divider as="li" color={theme.colors.tone.marble} my={3} />
              )}
              {navigationItemsBySection.restSections.map((item, index) => (
                <NavigationItem
                  key={`navigation_rest_sections_${index}`}
                  item={item}
                  onOpenSubMenu={onOpenSubMenu}
                  onClose={onClose}
                />
              ))}
            </>
          )}
        </NavMenuNavigation>
        <NavMenuNavigation>
          <NavItemSecondary>
            <Link href="/my-profile" passHref legacyBehavior>
              <Flex
                as="a"
                rel="noopener noreferrer"
                onClick={onClose}
                alignItems="center"
                color="tone.black"
              >
                <NavIcon>
                  <UserIcon />
                </NavIcon>
                <Trans>My account</Trans>
              </Flex>
            </Link>
          </NavItemSecondary>
          {featureFlags.referAFriend && (
            <NavItemSecondary>
              <Link href="/refer-a-friend" passHref legacyBehavior>
                <Flex
                  as="a"
                  onClick={onClose}
                  alignItems="center"
                  color="tone.black"
                >
                  <NavIcon>
                    <ReferFriendsIcon />
                  </NavIcon>
                  <Trans>Free Shopping</Trans>
                </Flex>
              </Link>
            </NavItemSecondary>
          )}
          <NavItemSecondary>
            <Link
              href={getI18nRoute({
                route: "/faq",
                locale,
              })}
              passHref
              legacyBehavior
            >
              <Flex
                as="a"
                onClick={onClose}
                alignItems="center"
                color="tone.black"
              >
                <NavIcon>
                  <CustomerServiceIcon />
                </NavIcon>
                <Trans>Customer service</Trans>
              </Flex>
            </Link>
          </NavItemSecondary>
          {!isGhostDomain && (
            <NavItemSecondary>
              <LanguageSelector onClick={onOpenSubMenu(SubMenu.languages)} />
            </NavItemSecondary>
          )}
        </NavMenuNavigation>
        <NavMenuActions>
          <Box maxWidth={["288px", "288px", "204px"]}>
            {isLoggedIn || showSignOutButtonUntilRedirect ? (
              <Button
                fullWidth
                as="a"
                variant="secondary"
                loading={showSignOutButtonUntilRedirect}
                onClick={() => {
                  setShowSignOutButtonUntilRedirect(true);
                  void signOut();
                }}
              >
                <Trans>Logout</Trans>
              </Button>
            ) : (
              <Link href="/sign-in/" passHref legacyBehavior>
                <Button as="a" variant="secondary" fullWidth>
                  <Trans>Login</Trans>
                </Button>
              </Link>
            )}
          </Box>
        </NavMenuActions>
      </NavMenuContentWrapper>
    </>
  );

  return (
    <Submenu title={<Trans>Menu</Trans>}>
      {isPrivateSale ? (
        <GenderSelect locale={locale} />
      ) : isLoginFlow ? (
        blockForLoginFlow
      ) : (
        blockForNotLoginFlow
      )}
    </Submenu>
  );
};

interface CategoriesMenuProps {
  onBack: () => void;
  onClose: () => void;
  locale: Locale;
  category?: Item | CatalogCategoryWithEnglishName;
}

const CategoriesMenu: FC<CategoriesMenuProps> = ({
  locale,
  onBack,
  onClose,
  category,
}) => {
  const { segmentMenuNavClicked } = useSegment();
  const genderId = useSelector(getGenderId) as string;
  const { data, loading } = useCategories(
    genderId,
    (category as CatalogCategoryWithEnglishName)?.id
  );

  const categorySubcategories = useMemo(() => {
    if (!data || !category || !data.categories.length) {
      return null;
    }
    const nodes = createTreeNode(
      data.categories,
      (category as CatalogCategoryWithEnglishName).id,
      1
    ) as (CatalogCategoryWithEnglishName & {
      children: CatalogCategoryWithEnglishName[];
    })[];
    return nodes.map((item: CatalogCategoryWithEnglishName) => ({
      ...item,
      href: `${getI18nRoute({
        route: `/${genderId}`,
        locale,
      })}/${item.slug}`,
    }));
  }, [data, category, genderId, locale]);

  const handleNavItemItemClick = useCallback(
    ({
      menuClickType,
      menuSectionName,
      menuClickName,
    }: {
      menuClickType: string;
      menuSectionName: string;
      menuClickName: string;
    }) => {
      const menuType = "mobile";
      void segmentMenuNavClicked(
        menuType,
        menuClickType,
        menuClickName,
        menuSectionName
      );
    },
    [segmentMenuNavClicked]
  );

  const title = category?.name
    ? category.name.charAt(0).toUpperCase() + category.name.slice(1)
    : "";

  return (
    <Submenu title={title} goBack={onBack}>
      {loading && <LoadingSpinner />}

      <NavMenuContentWrapper>
        <SubcategoriesMenu>
          {categorySubcategories?.map((subcategory) => (
            <NavItem
              key={`subcategory_${subcategory.id}`}
              onClick={() => {
                handleNavItemItemClick({
                  menuClickType: "new menu",
                  menuSectionName:
                    (category as CatalogCategoryWithEnglishName)?.name_en || "",
                  menuClickName: subcategory.name,
                });
              }}
            >
              <Link href={subcategory.href} passHref legacyBehavior>
                <Box as="a" color="tone.black" onClick={onClose}>
                  {formatName(subcategory.name)}
                </Box>
              </Link>
            </NavItem>
          ))}
        </SubcategoriesMenu>
      </NavMenuContentWrapper>
    </Submenu>
  );
};

export { NavMenu };
