import { FC } from "react";
import Link from "next/link";
import { Text } from "@otrium/atoms";
import { formatName } from "src/utils/formatName";
import { Wrapper } from "./SearchCategoryCard.styled";
import { Flex } from "@otrium/core";

interface Props {
  name?: string;
  src: string;
  link: string;
  onClick: () => void;
}

const SearchCategoryCard: FC<Props> = ({ name, src, link, onClick }) => {
  return (
    <Link href={link} passHref legacyBehavior>
      <Wrapper onClick={onClick} data-testid="search-category-card">
        <Flex flex={1} alignItems="center">
          <img src={src} alt={name} />
        </Flex>
        {name && (
          <Text
            fontSize={1}
            letterSpacing={"0.4%"}
            fontWeight={600}
            lineHeight={"24px"}
            py={1}
            textAlign="center"
            mt={2}
            color="tone.black"
            as="span"
          >
            {formatName(name)}
          </Text>
        )}
      </Wrapper>
    </Link>
  );
};

export default SearchCategoryCard;
