/* eslint-disable no-underscore-dangle */
import styled from "@emotion/styled";
import { Theme } from "@emotion/react";
import { theme } from "src/theme";
import { Spinner } from "src/icons/Spinner";
import { ButtonColorScheme, ButtonProps, ButtonVariants } from "./types";
import { buttonVariants, colorVariants } from "./constants";

export const getButtonStylesByVariant = (
  { colors, effects }: Theme,
  variant: ButtonVariants,
  colorScheme: ButtonColorScheme,
  fullWidth?: boolean
): any => {
  const defaultButtonStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 48,
    maxWidth: fullWidth ? "100%" : 360,
    fontSize: theme.fontSizes.fontSize16,
    fontWeight: 600,
    width: fullWidth ? "100%" : "auto",
    borderRadius: effects.rounding._4,
    transition: "all 300ms ease, border 0ms ease",
    letterSpacing: 0.4,
    cursor: "pointer",
    textAlign: "center",
    border: "none",
    paddingTop: theme.space._12,
    paddingBottom: theme.space._12,
    paddingLeft: theme.space._16,
    paddingRight: theme.space._16,
  };

  const primaryDark = {
    ...defaultButtonStyle,
    color: colors.tone.white,
    backgroundColor: colors.tone.black,
    ":hover": {
      backgroundColor: colors.tone.anthraciteDark,
      boxShadow: `0 4px 8px 0 ${effects.overlay.black_10}`,
    },
    ":focus-visible": {
      border: "solid 2px",
      borderColor: colors.semantic.blue,
    },
    ":disabled": {
      backgroundColor: colors.tone.silver,
      cursor: "unset",
      boxShadow: "none",
      color: colors.tone.anthraciteLight,
    },
  };

  const primaryLight = {
    ...defaultButtonStyle,
    backgroundColor: colors.tone.white,
    color: colors.tone.black,
    ":hover": {
      boxShadow: `0 4px 8px 0 ${effects.overlay.black_10}`,
      backgroundColor: colors.tone.marble,
    },
    ":focus-visible": {
      border: "solid 2px",
      borderColor: colors.semantic.blue,
    },
    ":disabled": {
      backgroundColor: colors.tone.silver,
      cursor: "unset",
      boxShadow: "none",
      color: colors.tone.anthraciteLight,
      background: effects.overlay.white_50,
    },
  };

  const secondaryDark = {
    ...defaultButtonStyle,
    border: "2px solid",
    borderColor: colors.tone.black,
    backgroundColor: "transparent",
    color: colors.tone.black,
    ":hover": {
      backgroundColor: effects.overlay.black_10,
    },
    ":focus-visible": {
      border: "solid 2px",
      borderColor: colors.semantic.blue,
    },
    ":active": {
      backgroundColor: "rgba(0, 0, 0, 0.16)",
    },
    ":disabled": {
      backgroundColor: "transparent",
      cursor: "unset",
      boxShadow: "none",
      color: colors.tone.anthraciteLight,
      border: "2px solid",
      borderColor: colors.tone.silver,
    },
  };

  const secondaryLight = {
    ...defaultButtonStyle,
    border: "2px solid",
    borderColor: colors.tone.white,
    color: colors.tone.white,
    backgroundColor: "transparent",
    ":hover": {
      backgroundColor: "rgba(255, 255, 255, 0.16)",
    },
    ":focus-visible": {
      border: "solid 2px",
      borderColor: colors.semantic.blue,
    },
    ":active": {
      backgroundColor: "rgba(255, 255, 255, 0.32)",
    },
    ":disabled": {
      backgroundColor: "transparent",
      cursor: "unset",
      boxShadow: "none",
      color: colors.tone.anthraciteLight,
      border: "2px solid",
      borderColor: colors.tone.silver,
    },
  };

  const tertiaryDark = {
    ...defaultButtonStyle,
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    color: "Black",
    ":hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    ":focus-visible": {
      border: "solid 2px",
      borderColor: colors.semantic.blue,
    },
    ":active": {
      backgroundColor: "rgba(0, 0, 0, 0.16)",
    },
    ":disabled": {
      backgroundColor: effects.overlay.white_50,
      cursor: "unset",
      boxShadow: "none",
      color: colors.tone.anthraciteLight,
    },
  };

  const tertiaryLight = {
    ...defaultButtonStyle,
    color: "White",
    backgroundColor: "rgba(255, 255, 255, 0.25)",
    ":hover": {
      backgroundColor: effects.overlay.white_50,
    },
    ":focus-visible": {
      border: "solid 2px",
      borderColor: colors.semantic.blue,
    },
    ":active": {
      backgroundColor: effects.overlay.white_50,
    },
    ":disabled": {
      backgroundColor: effects.overlay.white_25,
      color: colors.tone.anthraciteLight,
      cursor: "unset",
      boxShadow: "none",
    },
  };

  const buttonsStyles = {
    [buttonVariants.primary]: {
      [colorVariants.dark]: primaryDark,
      [colorVariants.light]: primaryLight,
    },
    [buttonVariants.secondary]: {
      [colorVariants.dark]: secondaryDark,
      [colorVariants.light]: secondaryLight,
    },
    [buttonVariants.tertiary]: {
      [colorVariants.dark]: tertiaryDark,
      [colorVariants.light]: tertiaryLight,
    },
  };

  return buttonsStyles[variant][colorScheme];
};

const StyledButton = styled.button<ButtonProps>`
  ${({ theme, variant = "primary", colorScheme = "dark", fullWidth }) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    getButtonStylesByVariant(theme, variant, colorScheme, fullWidth)}
`;

const StyledButtonContent = styled.span<{ fullHeight?: boolean }>`
  text-transform: lowercase;
  display: inline-block;

  ${({ fullHeight }) => (fullHeight ? "height: 100%;" : "")}

  :first-letter {
    text-transform: capitalize;
  }
`;

const StyledSpinner = styled(Spinner)`
  height: 20px;
  stroke: ${({ theme }) => theme.colors.tone.black};
  width: 20px;
`;

const LeftIcon = styled.span`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 8px;
`;

const RightIcon = styled.span`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 8px;

  > svg {
    fill: ${({ theme }) => theme.colors.tone.black};
    stroke: ${({ theme }) => theme.colors.tone.black};
  }
`;

export {
  LeftIcon,
  RightIcon,
  StyledSpinner,
  StyledButton,
  StyledButtonContent,
};
