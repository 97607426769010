import { FC, SVGAttributes } from "react";

const Notification: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(6.000000, 5.000000)"
        stroke="#000000"
        strokeWidth="2.5"
      >
        <path d="M10,0 C11.1045695,0 12,0.8954305 12,2 C12,2.10568195 11.9918031,2.20944942 11.9760105,2.31070134 C14.6554302,3.17480634 16.6,5.75343237 16.6,8.8 L16.6,8.8 L16.6,9.59211458 C16.6,11.3688878 17.1914916,13.0951029 18.2811815,14.4984913 L18.2811815,14.4984913 L19.7473223,16.3867029 C19.8835335,16.5621265 19.9574699,16.7779033 19.9574699,17 C19.9574699,17.5522847 19.5097547,18 18.9574699,18 L18.9574699,18 L1.04253006,18 C0.820433404,18 0.604656518,17.9260635 0.429232964,17.7898523 C-0.00699042333,17.4511377 -0.0860368892,16.8229263 0.252677741,16.3867029 L0.252677741,16.3867029 L1.71881851,14.4984913 C2.80850836,13.0951029 3.4,11.3688878 3.4,9.59211458 L3.4,9.59211458 L3.4,8.8 C3.4,5.75343237 5.34456983,3.17480634 8.02467374,2.30979511 C8.00819686,2.20944942 8,2.10568195 8,2 C8,0.8954305 8.8954305,0 10,0 Z"></path>
        <path
          d="M8.5,23.5 C10.1568542,23.5 11.5,22.1568542 11.5,20.5 C11.5,18.8431458 10.1568542,17.5 8.5,17.5"
          id="Oval"
          transform="translate(10.000000, 20.500000) rotate(-270.000000) translate(-10.000000, -20.500000) "
        ></path>
      </g>
    </g>
  </svg>
);

export { Notification };
