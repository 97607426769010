import { FC, useContext } from "react";
import { Modal } from "src/atoms/Modal";
import {
  InlineHyperLink,
  ModalWrapper,
  StyledCloseButton,
} from "./SubscribeModal.styled";
import { Button } from "src/atoms/Button";
import { Trans } from "@lingui/macro";
import { Text, Heading } from "@otrium/atoms";
import { Box } from "@otrium/core";
import { getI18nRoute } from "src/utils/i18nRoutes";
import { AppCtx } from "src/contexts/app.context";
import { SubscribeForm } from "src/molecules/SubscribeForm";

interface Props {
  open: boolean;
  onClose: (closeModal?: boolean) => void;
  onFormSubmit: () => void;
  submitted: boolean;
}

const SubscribeModal: FC<Props> = ({
  open,
  submitted,
  onClose,
  onFormSubmit,
}) => {
  const { locale } = useContext(AppCtx);
  return (
    <Modal isOpen={open} fullWidth onClose={() => onClose(submitted)}>
      <ModalWrapper>
        <StyledCloseButton
          onClick={() => onClose(submitted)}
          sx={{ height: 44, width: 44 }}
          iconSize={44}
        />
        <Heading
          my={2}
          as="h2"
          variant="h2"
          fontFamily="CharisSILW"
          letterSpacing="normal"
          pb={0}
          mb={2}
          mt={0}
          sx={{
            textTransform: "none",
          }}
          textAlign="center"
        >
          {!submitted ? (
            <Trans>Get 15% off</Trans>
          ) : (
            <Trans>guest-optin.thank-you</Trans>
          )}
        </Heading>
        <Text
          variant="body.source.regular16"
          color="tone.anthracite"
          as="p"
          textAlign="center"
        >
          {!submitted ? (
            <Trans>
              Get 15% off your first order and receive updates on our latest
              arrivals, promos and trends.
            </Trans>
          ) : (
            <Trans>guest-optin.thank-you-description</Trans>
          )}
        </Text>
        {!submitted && (
          <>
            <Box mt={4} mb={3} width="100%">
              <SubscribeForm onSubmit={onFormSubmit} />
            </Box>
            <Text
              as="p"
              mt={2}
              fontSize={0}
              color="tone.anthraciteLight"
              letterSpacing="normal"
              lineHeight="1.43"
              textAlign="center"
            >
              *{" "}
              <Trans>
                I agree with the{" "}
                <InlineHyperLink
                  target="blank"
                  asLink
                  variant="body.small"
                  color="inherit"
                  hasUnderline={false}
                  href={getI18nRoute({
                    route: "/terms-and-conditions",
                    locale,
                  })}
                >
                  Promotional Terms
                </InlineHyperLink>{" "}
                and give Otrium permission to send me email updates.
              </Trans>
            </Text>
          </>
        )}

        {submitted && (
          <Box mt={36}>
            <Button
              data-testid="subscribe-form-button"
              variant="primary"
              type="button"
              fullWidth
              onClick={() => onClose(true)}
            >
              <Trans>guest-optin.back-button</Trans>
            </Button>
          </Box>
        )}
      </ModalWrapper>
    </Modal>
  );
};

export default SubscribeModal;
