import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { ApolloError } from "@apollo/client";
import { SeoQueryInput, SeoQueryResponse } from "src/types/graphql.d";
import { useFeatureFlags } from "./useFeatureFlags";

interface SeoContentResponse {
  getSeoContent: SeoQueryResponse | undefined;
}

interface SeoContentVars {
  page_url_slug: string;
}
interface SeoContentHook {
  data: SeoContentResponse | undefined;
  loading: boolean;
  error: ApolloError | undefined;
}

const GET_SEO_CONTENT = gql`
  query getSeoContent($page_url_slug: String!) {
    getSeoContent(filter: { page_url_slug: $page_url_slug }) {
      meta_title
      meta_description
      meta_keywords
      canonical_tags
      robots_tag_value
      page_type
      brand
    }
  }
`;

const useSeoContent = ({ page_url_slug }: SeoContentVars): SeoContentHook => {
  const { enableSeoContent } = useFeatureFlags();

  const { data, loading, error } = useQuery<SeoContentResponse, SeoQueryInput>(
    GET_SEO_CONTENT,
    {
      variables: {
        page_url_slug,
      },
      skip: !enableSeoContent,
      ssr: true,
      fetchPolicy: "cache-first",
    }
  );

  return {
    data,
    loading,
    error,
  };
};

export default useSeoContent;
