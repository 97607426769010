import styled from "@emotion/styled";
import { CloseButton } from "src/atoms/CloseButton";
import { Button } from "src/atoms/Button";

const Wrapper = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? "block" : "none")};
  position: fixed;
  bottom: 0;
  width: 100%;
  background: ${({ theme }) => theme.colors.tone.white};
  padding: ${({ theme }) => theme.space.space16} 0;
  text-align: center;
  z-index: ${({ theme }) => theme.zIndex.drawer};
  border-radius: ${({ theme }) => theme.space[2]}px
    ${({ theme }) => theme.space[2]}px 0 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding: ${({ theme }) => theme.space.space32} 0;
  }
`;

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  right: ${({ theme }) => theme.space[3]}px;
  top: ${({ theme }) => theme.space[1]}px;
  width: 16px;
  height: 16px;
  padding: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    top: ${({ theme }) => theme.space[2]}px;
  }
`;

const StyledButton = styled(Button)`
  padding-left: ${({ theme }) => theme.space[5]}px;
  padding-right: ${({ theme }) => theme.space[5]}px;
  max-width: unset;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: auto;
    margin: auto;
  }
`;

export { Wrapper, StyledCloseButton, StyledButton };
