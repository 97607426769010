// app config translations
import { Locale } from "lib/Intl";

interface TrustPilotCredentials {
  link: string;
  businessUnitId: string;
}

const TRUSTPILOT_CREDENTIALS_BY_LOCALE: {
  [key in Locale]: TrustPilotCredentials;
} = {
  en: {
    link: "https://trustpilot.com/review/otrium.com",
    businessUnitId: "5f4e4d97e6d8ce00019a7ef0",
  },
  de: {
    link: "https://de.trustpilot.com/review/otrium.de",
    businessUnitId: "5f4f180810e7a00001359ad3",
  },
  nl: {
    link: "https://nl.trustpilot.com/review/otrium.nl",
    businessUnitId: "5c62b90b58ba3f0001df921e",
  },
  fr: {
    link: "https://fr.trustpilot.com/review/otrium.fr",
    businessUnitId: "5f90419debb6630001322b9f",
  },
  "en-gb": {
    link: "https://uk.trustpilot.com/review/www.otrium.co.uk",
    businessUnitId: "5f4e8990e33e6300012f4e5f",
  },
  "en-us": {
    link: "https://trustpilot.com/review/us.otrium.com",
    businessUnitId: "5f9c41dbce6f0b000158bed9",
  },
  "de-at": {
    link: "https://uk.trustpilot.com/review/otrium.nl",
    businessUnitId: "5c62b90b58ba3f0001df921e",
  },
  "nl-be": {
    link: "https://uk.trustpilot.com/review/otrium.nl",
    businessUnitId: "5c62b90b58ba3f0001df921e",
  },
  "en-dk": {
    link: "https://uk.trustpilot.com/review/otrium.nl",
    businessUnitId: "5c62b90b58ba3f0001df921e",
  },
  "en-se": {
    link: "https://uk.trustpilot.com/review/otrium.nl",
    businessUnitId: "5c62b90b58ba3f0001df921e",
  },
  "pl-pl": {
    link: "https://uk.trustpilot.com/review/otrium.nl",
    businessUnitId: "5c62b90b58ba3f0001df921e",
  },
  "es-es": {
    link: "https://trustpilot.com/review/otrium.com",
    businessUnitId: "5f4e4d97e6d8ce00019a7ef0",
  },
  "it-it": {
    link: "https://uk.trustpilot.com/review/otrium.nl",
    businessUnitId: "5c62b90b58ba3f0001df921e",
  },
};

const MAX_THUMBNAILS_PER_VIEW = 5;

const REQUEST_SOURCE = "WEB_SHOP_APP";
const REQUEST_SOURCE_TYPE = "WEB_VIEW";

export {
  MAX_THUMBNAILS_PER_VIEW,
  REQUEST_SOURCE,
  REQUEST_SOURCE_TYPE,
  TRUSTPILOT_CREDENTIALS_BY_LOCALE,
};
