import { FC, SVGAttributes } from "react";

const Close: FC<SVGAttributes<SVGSVGElement>> = ({
  width = "16px",
  height = "16px",
  ...restProps
}) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={width}
    {...restProps}
  >
    <path
      d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
      stroke="none"
    />
  </svg>
);

export { Close };
