import { I18n } from "@lingui/core";
import { msg, t } from "@lingui/macro";
import { languages } from "src/utils/currencySymbols";
import { FlagBe, FlagEs } from "src/icons";
import { FlagBeRound } from "src/icons/FlagBeRound";
import { FlagNl } from "src/icons/FlagNl";
import { FlagNlRound } from "src/icons/FlagNlround";
import { FlagEur } from "src/icons/flag-eur";
import { FlagEuRound } from "src/icons/FlagEuRound";
import { FlagFr } from "src/icons/FlagFr";
import { FlagFrRound } from "src/icons/FlagFrRound";
import { FlagDe } from "src/icons/FlagDe";
import { FlagDeRound } from "src/icons/FlagDeRound";
import { FlagIt } from "src/icons/flag-it";
import { FlagItRound } from "src/icons/FlagItRound";
import { FlagPl } from "src/icons/flag-pl";
import { FlagPlRound } from "src/icons/FlagPlRound";
import getCurrency from "src/atoms/LanguageMenu/getCurrency";
import { FlagEsRound } from "src/icons/FlagEsRound";

export const translatedLanguageMap = {
  "nl-be": msg`Belgian`,
  nl: msg`Dutch`,
  en: msg`English`,
  "en-gb": msg`English`,
  "en-us": msg`English`,
  fr: msg`French`,
  de: msg`German`,
  "it-it": msg`Italian`,
  "pl-pl": msg`Polish`,
  "es-es": msg`Spanish`,
};

const getTranslatedMenuOptions = (i18n: I18n) =>
  [
    {
      id: "nl-be",
      language: "Belgian",
      region: i18n._(`Belgium`),
      regionCode: "BE",
      currency: getCurrency(languages.nl_BE),
      flag: <FlagBe className="flag" />,
      FlagRound: FlagBeRound,
    },
    {
      id: "nl",
      language: "Dutch",
      region: i18n._(t`Netherlands`),
      regionCode: "NL",
      currency: getCurrency(languages.nl_NL),
      flag: <FlagNl className="flag" />,
      FlagRound: FlagNlRound,
    },
    {
      id: "en",
      language: "English",
      region: i18n._(t`Europe`),
      regionCode: null,
      currency: getCurrency(languages.en_EU),
      flag: <FlagEur className="flag" />,
      FlagRound: FlagEuRound,
    },
    {
      id: "fr",
      language: "French",
      region: i18n._(t`France`),
      regionCode: "FR",
      currency: getCurrency(languages.fr_FR),
      flag: <FlagFr className="flag" />,
      FlagRound: FlagFrRound,
    },
    {
      id: "de",
      language: "German",
      region: i18n._(t`Germany`),
      regionCode: "DE",
      currency: getCurrency(languages.de_DE),
      flag: <FlagDe className="flag" />,
      FlagRound: FlagDeRound,
    },
    {
      id: "it-it",
      language: "Italian",
      region: i18n._(t`Italy`),
      regionCode: "IT",
      currency: getCurrency(languages.it_IT),
      flag: <FlagIt className="flag" />,
      FlagRound: FlagItRound,
    },
    {
      id: "pl-pl",
      language: "Polish",
      region: i18n._(t`Poland`),
      regionCode: "PL",
      currency: getCurrency(languages.pl_PL),
      flag: <FlagPl className="flag" />,
      FlagRound: FlagPlRound,
    },
    {
      id: "es-es",
      language: "Spanish",
      region: i18n._(t`Spain`),
      regionCode: "ES",
      currency: getCurrency(languages.es_ES),
      flag: <FlagEs className="flag" />,
      FlagRound: FlagEsRound,
    },
  ] as const;

export default getTranslatedMenuOptions;
