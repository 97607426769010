import { FC, SVGAttributes } from "react";

export const ApplePayIconWhite: FC<SVGAttributes<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="96"
      height="40"
      viewBox="0 0 96 40"
      {...props}
    >
      <title>ApplePay</title>
      <g fill="none" fillRule="evenodd">
        <g fill="#FFF" fillRule="nonzero">
          <g>
            <g>
              <path
                d="M8.872 2.569c-.569.673-1.479 1.204-2.389 1.128-.113-.91.332-1.877.853-2.474C7.906.53 8.9.038 9.706 0c.095.948-.275 1.877-.834 2.569m.825 1.308c-1.318-.076-2.446.749-3.071.749-.635 0-1.593-.711-2.635-.692-1.356.019-2.617.786-3.309 2.01C-.739 8.388.312 12.008 1.687 14c.673.986 1.479 2.066 2.54 2.028 1.005-.037 1.403-.654 2.617-.654 1.222 0 1.573.654 2.635.635 1.1-.018 1.791-.985 2.464-1.971.768-1.119 1.08-2.209 1.1-2.265-.02-.02-2.124-.825-2.143-3.252-.018-2.028 1.66-2.995 1.735-3.052-.948-1.403-2.426-1.554-2.938-1.592m7.611-2.749v14.777h2.294v-5.052h3.175c2.9 0 4.939-1.99 4.939-4.872 0-2.881-2-4.853-4.863-4.853h-5.545zm2.294 1.934h2.644c1.991 0 3.128 1.061 3.128 2.929 0 1.867-1.137 2.938-3.137 2.938h-2.635V3.062zm12.303 12.957c1.441 0 2.777-.73 3.384-1.886h.047v1.772h2.124V8.55c0-2.133-1.706-3.507-4.332-3.507-2.436 0-4.237 1.393-4.303 3.308h2.066c.17-.91 1.014-1.507 2.17-1.507 1.403 0 2.19.654 2.19 1.857v.816l-2.862.17c-2.664.161-4.105 1.251-4.105 3.147 0 1.915 1.489 3.185 3.621 3.185zm.616-1.754c-1.222 0-2-.587-2-1.488 0-.929.75-1.469 2.18-1.554l2.55-.161v.834c0 1.384-1.175 2.37-2.73 2.37zm7.773 5.66c2.237 0 3.289-.854 4.208-3.442l4.029-11.298h-2.332l-2.701 8.73h-.048l-2.701-8.73H38.35l3.886 10.758-.209.654c-.35 1.11-.919 1.536-1.933 1.536-.18 0-.531-.02-.673-.038v1.772c.133.038.701.057.872.057z"
                transform="translate(-832 -816) translate(832 816) translate(24 11)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
