import { FC, SVGAttributes } from "react";

const ChevronDown: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="8"
    viewBox="0 0 13 8"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g stroke="#999" strokeWidth="2">
        <path
          d="M3 2L7 6 11 2"
          transform="translate(-385 -339) translate(16 330) translate(288) translate(81 9)"
        />
      </g>
    </g>
  </svg>
);

export { ChevronDown };
