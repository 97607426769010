import { FC, SVGAttributes } from "react";

const FlagIt: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 3 2"
    fill="none"
    stroke="none"
    {...props}
  >
    <path fill="#009246" d="M0 0h1v2H0z" />
    <path fill="#fff" d="M1 0h1v2H1z" />
    <path fill="#ce2b37" d="M2 0h1v2H2z" />
  </svg>
);

export { FlagIt };
