import styled from "@emotion/styled";
import { DrawerContent } from "../../molecules/DrawerContent";

const Item = styled.li<{ isActive?: boolean }>`
  padding: 0 ${({ theme }) => theme.space[3]}px;
  position: relative;
  text-transform: capitalize;
  border-bottom: 1px solid ${({ theme }) => theme.colors.tone.marble};

  height: 50px;

  font-size: ${({ theme }) => theme.fontSizes[1]}px;
  color: ${({ theme }) => theme.colors.tone.black};

  cursor: pointer;

  ${({ isActive, theme }) =>
    isActive &&
    `
      background: ${theme.colors.tone.sand};
    `}

  &:hover {
    background: ${({ theme }) => theme.colors.tone.sand};
  }
`;

const ItemLink = styled.a`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 43px;
  margin: 0 -16px;
  padding: 0 16px;

  &:hover {
    background: ${({ theme }) => theme.colors.tone.sand};
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

const Title = styled.span`
  margin-left: ${({ theme }) => theme.space[2]}px;
`;

const Currency = styled.small`
  color: ${({ theme }) => theme.colors.tone.anthraciteLight};
  font-size: 14px;
  margin-left: auto;
  text-transform: uppercase;
`;

const Menu = styled(DrawerContent)`
  flex-direction: column;
  flex-shrink: 0;
  background: ${({ theme }) => theme.colors.tone.white};
  width: 320px;
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    width: 250px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 556px;
  }
`;

const List = styled.ul`
  list-style: none;
  background-color: ${({ theme }) => theme.colors.tone.white};
  margin-bottom: ${({ theme }) => theme.space[4]}px;
  overflow-y: auto;
`;

export { Item, ItemLink, Title, Currency, Menu, List };
