import { FC } from "react";

interface Props {
  stroke?: string;
  strokeWidth?: string;
  width?: string;
  height?: string;
}

const Heart: FC<Props> = ({
  stroke = "#FFF",
  strokeWidth = "1",
  width = "24",
  height = "22",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 -1 24 22"
      {...props}
    >
      <title>Wishlist</title>
      <path
        fill="none"
        fillRule="evenodd"
        clipRule="evenodd"
        alignmentBaseline="middle"
        stroke={stroke}
        strokeWidth={strokeWidth}
        d="M17.238 1C20.42 1 23 3.6 23 6.806C23 6.898 22.998 6.99 22.994 7.082C22.998 7.162 23 7.246 23 7.333C23 10.852 19.333 15.074 12 20C4.667 15.074 1 10.852 1 7.333C1 7.246 1.002 7.162 1.006 7.08C1.002 6.99 1 6.898 1 6.806C1 3.599 3.58 1 6.762 1C9.087 1 11.09 2.387 12 4.385C12.91 2.387 14.913 1 17.238 1Z"
      />
    </svg>
  );
};

export { Heart };
