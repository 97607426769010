import { FlagDe } from "src/icons/FlagDe";
import { FlagFr } from "src/icons/FlagFr";
import { FlagNl } from "src/icons/FlagNl";
import { FlagEur } from "src/icons/flag-eur";
import { FlagEs } from "src/icons/flag-es";
import { FlagIt } from "src/icons/flag-it";
import { FlagPl } from "src/icons/flag-pl";
import { FlagBe } from "src/icons/flag-be";
import { FlagBeRound } from "src/icons/FlagBeRound";
import { FlagEuRound } from "src/icons/FlagEuRound";
import { FlagFrRound } from "src/icons/FlagFrRound";
import { FlagDeRound } from "src/icons/FlagDeRound";
import { FlagItRound } from "src/icons/FlagItRound";
import { FlagPlRound } from "src/icons/FlagPlRound";
import { FlagEsRound } from "src/icons/FlagEsRound";
import { FlagNlRound } from "src/icons/FlagNlround";
import { languages } from "src/utils/currencySymbols";
import getCurrency from "src/atoms/LanguageMenu/getCurrency";

export const menuOptions = [
  {
    id: "nl-be",
    language: "Belgian",
    region: "Belgium",
    regionCode: "BE",
    currency: getCurrency(languages.nl_BE),
    flag: <FlagBe className="flag" />,
    FlagRound: FlagBeRound,
  },
  {
    id: "nl",
    language: "Dutch",
    region: "Netherlands",
    regionCode: "NL",
    currency: getCurrency(languages.nl_NL),
    flag: <FlagNl className="flag" />,
    FlagRound: FlagNlRound,
  },
  {
    id: "en",
    language: "English",
    region: "Europe",
    regionCode: null,
    currency: getCurrency(languages.en_EU),
    flag: <FlagEur className="flag" />,
    FlagRound: FlagEuRound,
  },
  {
    id: "fr",
    language: "French",
    region: "France",
    regionCode: "FR",
    currency: getCurrency(languages.fr_FR),
    flag: <FlagFr className="flag" />,
    FlagRound: FlagFrRound,
  },
  {
    id: "de",
    language: "German",
    region: "Germany",
    regionCode: "DE",
    currency: getCurrency(languages.de_DE),
    flag: <FlagDe className="flag" />,
    FlagRound: FlagDeRound,
  },
  {
    id: "it-it",
    language: "Italian",
    region: "Italy",
    regionCode: "IT",
    currency: getCurrency(languages.it_IT),
    flag: <FlagIt className="flag" />,
    FlagRound: FlagItRound,
  },
  {
    id: "pl-pl",
    language: "Polish",
    region: "Poland",
    regionCode: "PL",
    currency: getCurrency(languages.pl_PL),
    flag: <FlagPl className="flag" />,
    FlagRound: FlagPlRound,
  },
  {
    id: "es-es",
    language: "Spanish",
    region: "Spain",
    regionCode: "ES",
    currency: getCurrency(languages.es_ES),
    flag: <FlagEs className="flag" />,
    FlagRound: FlagEsRound,
  },
] as const;

export type MenuOption = (typeof menuOptions)[number];
export type MenuOptionId = MenuOption["id"];
