import { ApolloError } from "@apollo/client";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { ExecutionResult } from "@apollo/react-common";
import { useCallback } from "react";
import { MutationUpdateUserArgs, UpdateUser } from "src/types/graphql.d";

interface UpdateUserData {
  updateUser: UpdateUser | undefined;
}

interface UpdateUserHook {
  updateUser: (
    userData: MutationUpdateUserArgs
  ) => Promise<ExecutionResult<UpdateUserData>>;
  data: UpdateUserData | null | undefined;
  loading: boolean;
  error: ApolloError | undefined;
}

const UPDATE_USER = gql`
  mutation updateUser($opt_in: Boolean) {
    updateUser(opt_in: $opt_in) {
      user_id
      email
      opt_in
      gender
    }
  }
`;

const useUpdateUser = ({
  onCompleted,
}: {
  onCompleted?: (data: UpdateUserData) => void;
  onError?: (error: ApolloError) => void;
} = {}): UpdateUserHook => {
  const [updateUserMutation, { loading, data, error }] = useMutation<
    UpdateUserData,
    MutationUpdateUserArgs
  >(UPDATE_USER, { onCompleted });

  const updateUser = useCallback(
    (variables: MutationUpdateUserArgs) => {
      return updateUserMutation({ variables });
    },
    [updateUserMutation]
  );

  return { updateUser, loading, data, error } as UpdateUserHook;
};

export { useUpdateUser };
