import { FC, PropsWithChildren, useContext } from "react";
import { t } from "@lingui/macro";
import { Box, Flex, BoxProps } from "@otrium/core";
import { AndroidIcon } from "src/icons/AndroidIcon";
import { AppleIcon } from "src/icons/AppleIcon";
import { InstaIcon } from "src/icons/InstaIcon";
import { AppCtx } from "src/contexts/app.context";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import { EmotionBaseProps as BaseProps } from "src/types/global";
import { Title } from "./Footer.styled";
import { TiktokIcon } from "src/icons/TiktokIcon";
import { ButtonProps } from "src/atoms/Button";
import { Styled } from "src/molecules/Footer/FooterButtons.styled";
import { useLingui } from "@lingui/react";

const CustomButton: FC<PropsWithChildren<ButtonProps>> = ({
  children,
  ...props
}) => (
  <Styled.FooterButton
    as="a"
    target="_blank"
    rel="noopener noreferrer"
    {...props}
  >
    {children}
  </Styled.FooterButton>
);

type ButtonBoxType = BoxProps &
  BaseProps & {
    label: string;
  };

const ButtonBox: FC<PropsWithChildren<ButtonBoxType>> = ({
  children,
  label,
  ...rest
}) => (
  <Box {...rest}>
    <Title as="h4" mb={3} fontSize={[2, 2, 2, 2, 3]}>
      {label}
    </Title>
    <Flex variant="centered">{children}</Flex>
  </Box>
);

export const FooterButtons: FC<PropsWithChildren<unknown>> = () => {
  const { locale } = useContext(AppCtx);
  const isLoggedIn = useIsLoggedIn();
  const locales = ["fr", "de", "nl"];
  const appStoreLocale = locales.includes(locale) ? locale : "us";
  const googlePlayLocale = locales.includes(locale) ? locale : "en";
  const { i18n } = useLingui();
  return (
    <Box
      width={["100%", "100%", "100%", isLoggedIn ? "25%" : "calc(100% / 3)"]}
      mt={[5, 5, 5, "48px"]}
    >
      <ButtonBox label={t(i18n)`Download our app`}>
        <CustomButton
          data-testid="app-store-badge"
          href={`https://apps.apple.com/${appStoreLocale}/app/otrium/id1465868643?mt=8`}
        >
          <AppleIcon />
        </CustomButton>
        <CustomButton
          data-testid="google-play-badge"
          href={`https://play.google.com/store/apps/details?id=com.otrium.shop&hl=${googlePlayLocale}&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1`}
        >
          <AndroidIcon />
        </CustomButton>
      </ButtonBox>
      <ButtonBox label={t(i18n)`Get social`} mt={[5, 5, 5, 3]}>
        <CustomButton href="https://www.tiktok.com/@otrium" aria-label="Tiktok">
          <TiktokIcon />
        </CustomButton>
        <CustomButton
          href="https://www.instagram.com/otriumofficial/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstaIcon />
        </CustomButton>
      </ButtonBox>
      <Box mt={"36px"}>
        <iframe
          role="none"
          title="Facebook plugin"
          src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Fotriumofficial%2F&width=141&layout=button_count&action=like&size=small&share=true&height=46&appId"
          scrolling="no"
          frameBorder="0"
          allow="encrypted-media"
          style={{
            height: "20" + "px",
            width: 100 + "%", // eslint-disable-line @typescript-eslint/restrict-plus-operands
            border: "none",
            overflow: "hidden",
          }}
        />
      </Box>
    </Box>
  );
};
