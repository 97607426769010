import { useSelector } from "react-redux";
import gql from "graphql-tag";
import { ApolloError } from "@apollo/client";
import { useQuery } from "@apollo/react-hooks";
import { AuthProvider } from "pages/api/auth/getUserTokenFromApi";
import { UserPreferences, UserPreferencesByEmail } from "src/types/graphql.d";
import { useSession } from "next-auth/client";
import { getGenderId } from "src/modules/page";

interface UserPreferencesData {
  userPreferences?: UserPreferences;
  userPreferencesByEmail?: UserPreferencesByEmail;
}
interface UserPreferencesHook {
  data: UserPreferencesData | null | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => void;
}

const GET_USER_PREFERENCES = gql`
  query userPreferences($shop_type: String) {
    userPreferences(shop_type: $shop_type) {
      country
      gender
      member_id
      notification
      preferred_shop_type
      user_id
      private_sale_consent_status
    }
  }
`;

const GET_USER_PREFERENCES_BY_EMAIL = gql`
  query userPreferencesByEmail($user_email: String, $shop_type: String) {
    userPreferencesByEmail(user_email: $user_email, shop_type: $shop_type) {
      country
      gender
      member_id
      notification
      user_id
    }
  }
`;

const useUserPreferences = (): UserPreferencesHook => {
  const [session] = useSession();
  const genderId = useSelector(getGenderId);
  const shouldUsePreferencesByEmail =
    session?.user.signInPlatform === AuthProvider.CREDENTIALS_SOFT_LOGIN;

  const { data, loading, error, refetch } = useQuery<UserPreferencesData>(
    shouldUsePreferencesByEmail
      ? GET_USER_PREFERENCES_BY_EMAIL
      : GET_USER_PREFERENCES,
    {
      ssr: false,
      fetchPolicy: "cache-and-network",
      skip: !session,
      variables: {
        shop_type: genderId,
        ...(shouldUsePreferencesByEmail && session
          ? { user_email: session.user.email }
          : {}),
      },
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  return { data, loading, error, refetch };
};

export { useUserPreferences };
