interface GenderAndId {
  gender: string;
  id: string;
}
export default function getDataAttrsFromSelector(
  selector: string
): GenderAndId {
  const el = document.querySelectorAll<HTMLElement>(selector);
  return {
    // @ts-ignore
    gender: el[0]?.attributes["data-gender"]?.value,
    // @ts-ignore
    id: el[0]?.attributes["data-id"]?.value,
  };
}
