import { FC, useEffect } from "react";
import { useTheme, Theme } from "@emotion/react";
import { ContentBox } from "./AlertMessage.styled";
import { Box } from "@otrium/core";
import decodeHTMLEntities from "src/utils/decodeHTMLEntities";
import { AlertBar } from "src/types/graphql.d";
import { Trans } from "@lingui/macro";
import useDiscountTimerBar from "src/hooks/useDiscountTimerBar";
import { useSelector } from "react-redux";
import { getGenderId } from "src/modules/page";

interface IState {
  bgColor: string | undefined;
  textColor: string | undefined;
}

interface Props {
  isVisible?: boolean;
  message: AlertBar;
  onAnchorClick?: () => void;
  onContentClick?: () => void;
  handleSettings?: ({ bgColor, textColor }: IState) => void;
}

const BackgroundWrapper: FC<
  IState & {
    callback?: ({ bgColor, textColor }: IState) => void;
  }
> = ({ bgColor, textColor, callback }) => {
  useEffect(() => {
    if (bgColor && textColor && callback) {
      callback({ bgColor, textColor });
    }
  }, [bgColor, textColor]);

  return <></>;
};

const AlertMessage: FC<Props> = ({
  isVisible,
  message,
  handleSettings,
  onAnchorClick,
  onContentClick,
}) => {
  const theme: Theme = useTheme();
  const genderId = useSelector(getGenderId) || "women";
  const { hours, days, minutes } = useDiscountTimerBar({ shop_type: genderId });

  const bgColor =
    isVisible && message?.bg_color
      ? message?.bg_color
      : theme.colors.tone.black;
  const textColor =
    isVisible && message?.text_color
      ? message?.text_color
      : theme.colors.tone.white;

  return (
    <Box
      className="alert-message"
      width={1}
      aria-hidden={!isVisible}
      aria-current={isVisible}
    >
      {isVisible && (
        <BackgroundWrapper
          bgColor={bgColor}
          textColor={textColor}
          callback={handleSettings}
        />
      )}

      {message?.link ? (
        <a
          onClick={onAnchorClick}
          rel="noopener noreferrer"
          href={decodeHTMLEntities(message?.link)}
        >
          <ContentBox
            bgColor={bgColor}
            textColor={textColor}
            onClick={onContentClick}
          >
            <span>{message?.content}</span>
          </ContentBox>
        </a>
      ) : (
        <>
          <ContentBox
            data-testid="alert-message"
            showBgColor={!!message?.valid_till}
            bgColor={bgColor}
            textColor={textColor}
          >
            <span>
              {message?.content}
              {message?.valid_till && (
                <>
                  {" - "}
                  <Trans>timer_coupon.end_text</Trans>{" "}
                  {days > 1 ? (
                    <Trans>{days} days</Trans>
                  ) : (
                    days === 1 && <Trans>time_day_one</Trans>
                  )}{" "}
                  {hours > 0 && (
                    <>
                      <Trans>{hours}h</Trans> <Trans>and</Trans>{" "}
                    </>
                  )}
                  <Trans>{minutes}m</Trans>
                </>
              )}
            </span>
          </ContentBox>
        </>
      )}
    </Box>
  );
};

export default AlertMessage;
