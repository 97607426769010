import { useTheme, Theme } from "@emotion/react";

interface LabelProps {
  styleBackgroundColor: string;
  styleTextColor: string;
}

const getLabelProps = ({
  type,
  color,
  textColor,
  backgroundColor,
}: {
  type: string;
  textColor: string | null | undefined;
  color: string;
  backgroundColor?: string | null;
}): LabelProps => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const theme: Theme = useTheme();

  let styleBackgroundColor: string = theme.colors.tone.sand;
  let styleTextColor: string = theme.colors.tone.black;

  if (type === "new") {
    styleTextColor = theme.colors.primary.gold;
  } else if (textColor && backgroundColor) {
    styleBackgroundColor = backgroundColor;
    styleTextColor = textColor;
  } else {
    if (textColor) {
      if (type === "themed_label") {
        styleBackgroundColor = color;
        styleTextColor = textColor;
      } else {
        styleTextColor = color;
      }
    } else {
      styleTextColor = color;
    }
  }

  return { styleBackgroundColor, styleTextColor };
};

export { getLabelProps };
