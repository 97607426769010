import { FC, SVGAttributes } from "react";

const Done: FC<SVGAttributes<SVGSVGElement>> = ({
  height = "12px",
  width = "12px",
  color = "#FFF",
  ...restProps
}) => (
  <svg
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={width}
    {...restProps}
  >
    <path
      fill="none"
      fillRule="evenodd"
      stroke={color}
      strokeWidth="2"
      d="M1 7.077L5.949 12 15 3"
    />
  </svg>
);

export { Done };
