import { configureStore } from "@reduxjs/toolkit";
import { OtriumStore, State } from ".";
import { rootReducer } from "./rootReducer";

function createStore(initialState?: State): OtriumStore {
  return configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
  });
}

export { createStore };
