import useAlgoliaProductsSearch from "./useAlgoliaProductsSearch";
import getFullIndexName from "src/utils/algolia/getFullIndexName";
import { useCallback, useContext, useMemo } from "react";
import { AppCtx } from "src/contexts/app.context";
import { CatalogBrand, PageContentType, ShopType } from "src/types/graphql.d";
import NoSearchOverlayResults from "src/organisms/Search/NoSearchOverlayResults";
import { SearchWidget } from "src/molecules/SearchWidget";
import { SuggestionItem } from "src/molecules/SuggestionItem";
import { Hit } from "@algolia/client-search";
import {
  AlgoliaSearchProduct,
  AlgoliaSearchSuggestion,
  SuggestionType,
} from "src/types/Algolia";
import getBrandPageRoute from "src/utils/getBrandPageRoute";
import getCollectionsRoute from "src/utils/getCollectionsRoute";
import getProductCategoryRoute from "src/utils/getProductCategoryRoute";
import { useSegment } from "src/hooks/useSegment";
import { Trans } from "@lingui/macro";
import { ProductCardWishlist } from "src/organisms/ProductCardWishlist";
import useFilterProductLabels from "src/hooks/useFilterProductLabels";
import { useSegmentProductList } from "src/hooks/useSegmentProductList";
import {
  SEGMENT_LIST_FORMAT,
  SEGMENT_LIST_ID,
  SEGMENT_LIST_TYPE,
} from "src/segment";
import { checkForDuplicates } from "src/organisms/Search/utils";
import { useFeatureFlags } from "src/hooks/useFeatureFlags";

interface AlgoliaSearchResults {
  searchTerm: string;
  shopType: ShopType;
}

const AlgoliaSearchResults = ({
  searchTerm,
  shopType,
}: AlgoliaSearchResults): JSX.Element | null => {
  const { locale, isGhostDomain } = useContext(AppCtx);
  const { enableAugmentedSearch } = useFeatureFlags();
  const indexName =
    enableAugmentedSearch === "mapped"
      ? "products_augmented_search"
      : enableAugmentedSearch === "raw"
      ? "products_augmented_raw_search"
      : "products";
  const productsIndexName = useMemo(
    (): string =>
      getFullIndexName({
        indexName,
        prefix: isGhostDomain ? "zz" : locale,
      }),
    [indexName, isGhostDomain, locale]
  );

  const suggestionsIndexName = useMemo(
    (): string =>
      getFullIndexName({
        indexName: "suggestions",
        prefix: isGhostDomain ? "zz" : locale,
      }),
    [isGhostDomain, locale]
  );

  const { data, loading } = useAlgoliaProductsSearch({
    query: searchTerm,
    shopType,
    productsIndexName,
    suggestionsIndexName,
  });

  const suggestions = useMemo(
    () =>
      checkForDuplicates(
        (data?.[suggestionsIndexName].results ||
          []) as Hit<AlgoliaSearchSuggestion>[]
      ),
    [data, suggestionsIndexName]
  );
  const hasSuggestions = suggestions.length > 0;

  const products = (data?.[productsIndexName].results ||
    []) as Hit<AlgoliaSearchProduct>[];
  const queryID = data?.[productsIndexName].meta.queryID;
  const hasProducts = products.length > 0;
  const allProductsCount = data?.[productsIndexName].meta.nbHits;

  const hasResults = hasSuggestions || hasProducts;

  const { data: labels } = useFilterProductLabels({
    products,
    pageContentType: PageContentType.Category,
  });

  const getRecommendProductsLink = (
    suggestion: AlgoliaSearchSuggestion
  ): string => {
    if (suggestion.slug) {
      switch (suggestion.type) {
        case "brand":
          return getBrandPageRoute(shopType, suggestion.slug, locale);
        case "category":
          return getProductCategoryRoute(shopType, locale, suggestion.slug);
        case "collection":
          return getCollectionsRoute({
            shopType,
            collectionsSlug: suggestion.slug,
            locale,
          });
        default:
          return suggestion.url || "";
      }
    }
    return "";
  };

  const viewSearchResultsLink = useMemo(
    () =>
      hasProducts && products.length === 6
        ? `/${shopType}/search?q=${searchTerm}&q-type=page`
        : undefined,
    [hasProducts, products.length, shopType, searchTerm]
  );

  const { segmentSearchLinkClicked, segmentViewSearchResultPage } =
    useSegment();
  const handleSuggestionClick = useCallback(
    (data: { name: string; type: SuggestionType }) => {
      const { name, type } = data;
      void segmentSearchLinkClicked(type, name);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const listFormat = SEGMENT_LIST_FORMAT.search;
  const listType = SEGMENT_LIST_TYPE.products;
  const listId = SEGMENT_LIST_ID.search;
  const { segmentProductOnClick, setVisibleProducts } = useSegmentProductList({
    algoliaIndex: productsIndexName,
    listFormat,
    listId,
    listType,
    numberOfItems: products?.length || 0,
    queryID,
    shopType,
  });

  return (
    <>
      {hasResults && !loading && (
        <>
          {hasSuggestions && (
            <SearchWidget
              data-testid="search-suggestions"
              isList={false}
              pt={[0, 0, 0, 0, 5]}
            >
              {suggestions.map((suggestion, i) => {
                const link = getRecommendProductsLink(suggestion);
                return (
                  <SuggestionItem
                    key={`${suggestion.type}-${suggestion.entityID}`}
                    link={link}
                    hit={suggestion}
                    onClick={() =>
                      handleSuggestionClick({
                        name: suggestion.query,
                        type: suggestion.type,
                      })
                    }
                  />
                );
              })}
            </SearchWidget>
          )}
          {hasProducts && (
            <SearchWidget
              data-testid="search-products"
              header={<Trans>Products</Trans>}
              viewAllText={<Trans>Show {allProductsCount} results</Trans>}
              viewAllOnClick={() => {
                void segmentViewSearchResultPage();
              }}
              viewAllLink={viewSearchResultsLink}
              scrolled={false}
              pt={[3, 3, 3, 5]}
              pb={[3, 3, 3, "space48"]}
              withBottomButton
            >
              {products.map((product, index) => (
                <ProductCardWishlist
                  {...product}
                  key={product.objectID}
                  product={{
                    ...product,
                    id: product.objectID,
                    name: product.name,
                    price: product.price,
                    regular_price: product.regular_price,
                    brand: {
                      name: product.brand_name || "",
                    } as CatalogBrand,
                    thumbnail: product.image || "",
                    labels: labels[product.slug],
                    available_sizes_label: "",
                  }}
                  position={index}
                  segmentProductOnClick={segmentProductOnClick}
                  setVisibleProducts={setVisibleProducts}
                  queryID={queryID}
                  algoliaIndex={productsIndexName}
                  noGateLevel
                />
              ))}
            </SearchWidget>
          )}
        </>
      )}
      {!hasResults && !loading && (
        <NoSearchOverlayResults searchTerm={searchTerm} shopType={shopType} />
      )}
    </>
  );
};

export default AlgoliaSearchResults;
