type UpdateSessionData = { token: string };

// When the user data is updated, we have to update the session data
// so that the new user data becomes available on changing screens / refreshing.
// While it might seem hacky, it seems to be one of the few possible ways to achieve this.
// Reference: https://github.com/nextauthjs/next-auth/issues/371#issuecomment-963254428
export const updateSession = async (data: UpdateSessionData): Promise<void> => {
  // todo remove the unnecessary data from the UserProfile object
  // so it contains only the data which is updated in the session callback
  // Hint: make use of UserPersonalInfo type
  const encodedData = encodeURIComponent(JSON.stringify(data));
  await fetch(`/api/auth/session?update_session=${encodedData}`, {
    method: "GET",
    credentials: "include",
  });
  const event = new Event("visibilitychange");
  document.dispatchEvent(event);
};
