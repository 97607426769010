import { FC, forwardRef } from "react";
import { Close } from "src/icons/Close";
import { IconButton, IconButtonProps } from "src/atoms/IconButton";
import { theme } from "src/theme";

interface CloseButtonProps extends IconButtonProps {
  fill?: string;
  icon?: JSX.Element;
  iconSize?: number | string;
}

const CloseButton: FC<CloseButtonProps> = forwardRef(
  ({ fill, icon, iconSize, ...rest }, ref) => {
    return (
      <IconButton
        data-testid="close-button"
        aria-label="Close"
        ref={ref}
        {...rest}
      >
        {icon || (
          <Close
            fill={fill || theme?.colors?.tone?.black}
            data-testid="close-button-icon"
            width={iconSize}
            height={iconSize}
          />
        )}
      </IconButton>
    );
  }
);

CloseButton.displayName = "CloseButton";

export default CloseButton;
