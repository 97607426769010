import { RefObject, useEffect, useState } from "react";

type ClientRect = Omit<DOMRect, "x" | "y" | "toJSON">;

export function useClientRect(
  elementRef: RefObject<HTMLDivElement>
): ClientRect {
  const [clientRect, setClientRect] = useState<ClientRect>({
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
  });

  useEffect(() => {
    const handleResize = () => {
      if (!elementRef.current) return;
      setClientRect(elementRef.current.getBoundingClientRect());
    };

    handleResize();
    window.addEventListener("resize", handleResize, { passive: true });
    return () => window.removeEventListener("resize", handleResize);
  }, [elementRef]);

  return clientRect;
}
