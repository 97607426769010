import { Locale, IsoLocale, Iso2LetterLocale, LokaliseLocales } from "lib/Intl";

type IsoLocaleMap = {
  [key in Locale]: IsoLocale;
};

const isoLocaleMap: IsoLocaleMap = {
  en: "en_EU",
  de: "de_DE",
  nl: "nl_NL",
  fr: "fr_FR",
  "en-gb": "en_GB",
  "en-us": "en_US",
  "de-at": "de_AT",
  "nl-be": "nl_BE",
  "en-dk": "en_DK",
  "en-se": "en_SE",
  "pl-pl": "pl_PL",
  "es-es": "es_ES",
  "it-it": "it_IT",
};

const getIsoLocale = (locale: Locale): IsoLocale => {
  return isoLocaleMap[locale] || isoLocaleMap.en;
};

type Iso2LetterLocaleMap = {
  [key in Locale]: Iso2LetterLocale;
};

const iso2LetterLocaleMap: Iso2LetterLocaleMap = {
  en: "EU",
  de: "DE",
  nl: "NL",
  fr: "FR",
  "en-gb": "GB",
  "en-us": "US",
  "de-at": "AT",
  "nl-be": "BE",
  "en-dk": "DK",
  "en-se": "SE",
  "pl-pl": "PL",
  "es-es": "ES",
  "it-it": "IT",
};

const getIso2LetterLocale = (locale: Locale): Iso2LetterLocale => {
  return iso2LetterLocaleMap[locale] || iso2LetterLocaleMap.en;
};

const lokaliseLocaleMap: Record<Locale, LokaliseLocales> = {
  de: "de",
  "de-at": "de_AT",
  en: "en",
  "en-dk": "en_DK",
  "en-gb": "en_GB",
  "en-se": "en_SE",
  "en-us": "en_US",
  "es-es": "es_ES",
  fr: "fr",
  "it-it": "it_IT",
  nl: "nl",
  "nl-be": "nl",
  "pl-pl": "pl_PL",
};

const getLokaliseLocale = (locale: Locale): LokaliseLocales => {
  return lokaliseLocaleMap[locale] || lokaliseLocaleMap.en;
};

export { getIsoLocale, getIso2LetterLocale, getLokaliseLocale };
