import { FC } from "react";

export const AndroidIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="32"
    viewBox="0 0 28 32"
  >
    <title>Android</title>
    <path
      fill="#FFF"
      fillRule="nonzero"
      d="M22.848 10.76l-18.032.002v.039l.003 12.723c0 .34.083.66.229.943a2.159 2.159 0 001.923 1.155h1.466l.001 4.357c0 .034.001.068.003.101.043.837.626 1.538 1.414 1.777l.004.002.091.025a2.09 2.09 0 00.811.039c.969-.144 1.71-.961 1.71-1.945v-4.356h2.722l.001 4.356c0 1.084.905 1.968 2.016 1.966 1.115 0 2.018-.882 2.018-1.968l-.001-4.355 1.47-.002c.465 0 .896-.144 1.248-.389a2.08 2.08 0 00.905-1.71l-.002-12.722v-.039zM9.694 5.384c.42 0 .761.335.762.743 0 .41-.341.742-.763.742a.751.751 0 01-.76-.742c0-.408.34-.743.76-.743m8.272-.003c.418.001.76.337.76.745.001.41-.34.742-.76.742s-.762-.332-.762-.743a.758.758 0 01.762-.744m.316-2.385L19.689.49a.27.27 0 00-.101-.365l-.01-.007c-.01-.006-.022-.01-.034-.014a.287.287 0 00-.345.123L17.777 2.76a9.956 9.956 0 00-3.952-.809c-.316 0-.629.015-.937.043a9.807 9.807 0 00-3.006.762L8.46.23a.28.28 0 00-.3-.136.274.274 0 00-.224.241.256.256 0 00.034.156l1.408 2.504c-2.248 1.13-3.904 3.092-4.445 5.42a7.334 7.334 0 00-.19 1.625v.038l18.168-.002-.001-.04c-.016-3.024-1.877-5.651-4.628-7.04m9.378 9.358c-.001-1.052-.848-1.91-1.914-1.963a1.78 1.78 0 00-.104-.003c-1.113 0-2.016.881-2.016 1.967l.002 8.235c0 1.087.902 1.966 2.016 1.966 1.114 0 2.016-.879 2.017-1.967l-.002-8.235zM2.017 10.39C.903 10.39 0 11.273 0 12.358l.001 8.235c0 .646.32 1.219.811 1.577.337.245.754.39 1.207.39a2.006 2.006 0 001.993-1.667 1.98 1.98 0 00.023-.3v-8.236c0-1.085-.904-1.966-2.018-1.965"
    />
  </svg>
);
