import { FC, JSX } from "react";
import styled from "@emotion/styled";

const StyledLoader = styled.div<{
  stroke?: string;
  height?: string;
  width?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  height: 100%;
  width: 100%;
  .container {
    position: absolute;
    height: ${(props) => props.height || "28px"};
    width: ${(props) => props.width || "28px"};
  }
  .circular {
    animation: rotate 1.5s linear infinite;
    transform-origin: center center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: ${(props) => props.height || "28px"};
    width: ${(props) => props.width || "28px"};
  }
  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1s ease-in-out infinite;
    stroke-linecap: round;
    stroke: ${(props) => props.stroke || "#000"};
    stroke-width: 4px;
  }
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
`;

interface Props {
  stroke?: string;
  width?: string;
  height?: string;
}
const LoadingSpinner: FC<Props> = (props): JSX.Element => {
  return (
    <StyledLoader {...props}>
      <div className="container">
        <svg className="circular" viewBox="25 25 50 50">
          <circle className="path" cx="50" cy="50" r="20" fill="none" />
        </svg>
      </div>
    </StyledLoader>
  );
};

export { LoadingSpinner };
