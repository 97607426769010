import { createContext } from "react";
import { featureFlagsConfig } from "src/featureFlagsConfig/config";
import {
  EppoFeatureFlags,
  FeatureFlagName,
  FeatureFlagOption,
} from "src/types/featureFlags";

export const EPPO_FEATURE_FLAG_CONTEXT_DEFAULT_VALUE: EppoFeatureFlags =
  Object.entries(featureFlagsConfig).reduce<EppoFeatureFlags>(
    (acc, [key, value]) => {
      const { defaultValue } = value as FeatureFlagOption;

      acc[key as FeatureFlagName] = defaultValue;

      return acc;
    },
    {} as EppoFeatureFlags
  );

export const EppoFeatureFlagContext = createContext<EppoFeatureFlags>(
  EPPO_FEATURE_FLAG_CONTEXT_DEFAULT_VALUE
);

EppoFeatureFlagContext.displayName = "EppoFeatureFlagContext";
