import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { UserWishlistMeta } from "src/types/graphql.d";
import { ApolloError } from "@apollo/client";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";

export const USER_WISHLIST_META = gql`
  query userWishlistMetaData {
    userWishlistMetaData {
      wishlist_product_count
      wishlist_product_ids
      wishlist_brand_ids
    }
  }
`;

interface UserWishlistMetaData {
  userWishlistMetaData: UserWishlistMeta | undefined;
}

interface UseUserWishlistMeta {
  data: undefined | UserWishlistMetaData;
  loading: boolean;
  error: ApolloError | undefined;
}

/**
 * this query requires jwt token in the headers
 * therefore can't be cached in redis
 * should fetch only on the client side
 * the fetch policy should be cache first
 * to prevent multiple fetches
 * Adding / Removing to wishlist is handled by
 * changing the apollo cache keys on mutation
 * delete from wishlist
 */
const useUserWishlistMeta = (): UseUserWishlistMeta => {
  const isLoggedIn = useIsLoggedIn();
  const { loading, error, data } = useQuery<UserWishlistMetaData>(
    USER_WISHLIST_META,
    {
      ssr: false,
      fetchPolicy: "network-only",
      skip: !isLoggedIn,
    }
  );
  return { loading, error, data };
};

export { useUserWishlistMeta };
