import { FC, SVGAttributes } from "react";

const Discount: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Discount">
      <g id="Group 11">
        <circle id="Oval" cx="7" cy="10" r="1.5" stroke="black" />
        <circle id="Oval_2" cx="11" cy="14" r="1.5" stroke="black" />
        <g id="Combined Shape">
          <mask id="path-3-inside-1" fill="white">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M23 4H1V20H23V14.8293C21.8348 14.4175 21 13.3062 21 12C21 10.6938 21.8348 9.58254 23 9.17071V4Z"
            />
          </mask>
          <path
            d="M1 4V2H-1V4H1ZM23 4H25V2H23V4ZM1 20H-1V22H1V20ZM23 20V22H25V20H23ZM23 14.8293H25V13.4149L23.6665 12.9436L23 14.8293ZM23 9.17071L23.6665 11.0564L25 10.5851V9.17071H23ZM1 6H23V2H1V6ZM3 20V4H-1V20H3ZM23 18H1V22H23V18ZM21 14.8293V20H25V14.8293H21ZM23.6665 12.9436C23.2754 12.8054 23 12.4323 23 12H19C19 14.1802 20.3942 16.0295 22.3335 16.715L23.6665 12.9436ZM23 12C23 11.5677 23.2754 11.1946 23.6665 11.0564L22.3335 7.28503C20.3942 7.97047 19 9.81984 19 12H23ZM21 4V9.17071H25V4H21Z"
            fill="black"
            mask="url(#path-3-inside-1)"
          />
        </g>
        <path id="Path 2" d="M6 15L12 9" stroke="black" />
        <rect
          id="Rectangle"
          x="16"
          y="12"
          width="1"
          height="1"
          rx="0.5"
          fill="black"
        />
        <rect
          id="Rectangle_2"
          x="16"
          y="10"
          width="1"
          height="1"
          rx="0.5"
          fill="black"
        />
        <rect
          id="Rectangle_3"
          x="16"
          y="8"
          width="1"
          height="1"
          rx="0.5"
          fill="black"
        />
        <rect
          id="Rectangle_4"
          x="16"
          y="6"
          width="1"
          height="1"
          rx="0.5"
          fill="black"
        />
        <rect
          id="Rectangle_5"
          x="16"
          y="14"
          width="1"
          height="1"
          rx="0.5"
          fill="black"
        />
        <rect
          id="Rectangle_6"
          x="16"
          y="16"
          width="1"
          height="1"
          rx="0.5"
          fill="black"
        />
      </g>
    </g>
  </svg>
);

export { Discount };
