import styled from "@emotion/styled";

const StyledUserMenu = styled.div`
  padding-top: 15px;
  display: flex;
  flex-direction: column;

  .UserMenu__link {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 12px 30px;
    color: ${({ theme }) => theme.colors.tone.anthraciteDark};
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.7px;
    line-height: 24px;

    svg {
      width: 18px;
      fill: ${({ theme }) => theme.colors.tone.anthraciteLight};
    }
  }

  .UserMenu__link:hover {
    background: ${({ theme }) => theme.colors.tone.sand};
  }

  .UserMenu__actions {
    display: flex;
    flex-direction: column;
    padding: 15px 30px;
    background: ${({ theme }) => theme.colors.tone.sand};
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    color: ${({ theme }) => theme.colors.tone.black};
  }

  .UserMenu__actions > *:not(:first-of-type) {
    margin-top: 15px;
  }
`;

export { StyledUserMenu };
