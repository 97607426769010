import { useEffect, useRef } from "react";
import { isClient } from "../utils/isClient";

export const useDisableScrollBody = (disable: boolean): void => {
  const valueBeforeDisabling = useRef<string>(
    isClient ? document.body.style.overflow : ""
  );
  useEffect(() => {
    if (disable) {
      valueBeforeDisabling.current = document.body.style.overflow;
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = valueBeforeDisabling.current;
    }
  }, [disable, valueBeforeDisabling]);

  useEffect(
    () => () => {
      document.body.style.overflow =
        valueBeforeDisabling.current === "hidden"
          ? "visible"
          : valueBeforeDisabling.current;
    },
    []
  );
};
