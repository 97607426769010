import { FC, useEffect, useState } from "react";
import { Text } from "@otrium/atoms";
import { SxStyleProp, Flex, Box } from "@otrium/core";
import { Locale } from "lib/Intl";
import { TrustpilotIcon } from "src/icons/TrustpilotIcon";
import { TrustpilotIconSmall } from "src/icons/TrustpilotIconSmall";
import { TRUSTPILOT_CREDENTIALS_BY_LOCALE } from "src/config";
import { getTrustpilotData, TrustpilotInterface } from "lib/getTrustpilotData";
import Link from "next/link";

interface Props {
  locale: Locale;
  small?: boolean;
  color?: string;
  sx?: SxStyleProp;
}
const TrustpilotCustom: FC<Props> = ({
  locale,
  small = false,
  color = "white",
  sx = {},
}) => {
  const [score, setScore] = useState<number>(4);
  const { link, businessUnitId } = TRUSTPILOT_CREDENTIALS_BY_LOCALE[locale];

  useEffect(() => {
    let isMounted = true;

    async function fetchData() {
      try {
        const data: TrustpilotInterface = await getTrustpilotData(
          businessUnitId
        );
        if (isMounted) {
          setScore(data.score.trustScore);
        }
      } catch (err) {
        if (process.env.NODE_ENV === "development") {
          console.error("Error while running `getTrustpilotData`", err);
        }
      }
    }
    void fetchData();

    return () => {
      isMounted = false; // Set the flag to false when the component unmounts
    };
  }, [setScore, businessUnitId]);

  if (locale === "en-us") {
    return null;
  }

  return (
    <Flex
      variant="centered"
      height="100%"
      sx={sx}
      aria-labelledby="trustpilot-label"
    >
      <Text className="sr-only" as="p" id="trustpilot-label">
        Trustpilot score
      </Text>
      <Flex variant="centered" fontSize={0} height="100%">
        <Text fontSize={1} fontWeight={600} letterSpacing={0.4} color={color}>
          {score}
        </Text>
        <Text ml={small ? "2px" : "0px"} color="tone.anthraciteLight">
          /
        </Text>
        <Text
          sx={{
            position: "relative",
            top: "1px",
          }}
          color="tone.anthraciteLight"
          ml={small ? "2px" : "0px"}
        >
          5
        </Text>
      </Flex>
      {small ? (
        <Box as="a" ml="5px" mb="-4px">
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            legacyBehavior
          >
            <TrustpilotIconSmall color={color} />
          </Link>
        </Box>
      ) : (
        <Box as="a" ml={2} mr={2} height="100%">
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            legacyBehavior
          >
            <>
              <Text className="sr-only">Trustpilot website</Text>
              <TrustpilotIcon color={color} />
            </>
          </Link>
        </Box>
      )}
    </Flex>
  );
};
export default TrustpilotCustom;
