import { Flex } from "@otrium/core";
import styled from "@emotion/styled";
import { CloseButton } from "src/atoms/CloseButton";

const StyledDrawer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: ${({ theme }) => theme.zIndex.drawer};
  transform: translateX(-100%);
  transition: transform 0.225s ease-in-out;
  ${({ isOpen }) => isOpen && `transform: translateX(0);`}
`;

const DrawerContainer = styled.div`
  position: relative;
  height: 100%;
  background: ${({ theme }) => theme.colors.tone.sand};
`;

const DrawerCloseButton = styled(CloseButton)<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  right: -44px;
  width: 44px;
  height: 44px;
  justify-content: center;
  background: ${({ theme }) => theme.colors.tone.white};
  ${({ isOpen }) =>
    isOpen &&
    `
    right: 0;
  `}
`;

const Slider = styled(Flex)`
  transition: transform 0.3s ease;
`;

export { StyledDrawer, DrawerContainer, DrawerCloseButton, Slider };
