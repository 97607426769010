export interface TrustpilotInterface {
  score: { trustScore: number };
}

export const getTrustpilotData = async (
  businessUnitId: string
): Promise<TrustpilotInterface> => {
  // [TODO:move to kube-env after this ticket is done]
  // https://otrium.atlassian.net/browse/DEV-147
  const key = "ikwK2lTTCyYRAsABiP6X1ERcN9CCv3EU";
  const url = `https://api.trustpilot.com/v1/business-units/${businessUnitId}?apikey=${key}`;
  const response = await fetch(url);
  return response.json() as Promise<TrustpilotInterface>;
};
