import { FC, SVGAttributes } from "react";

export const Przelewy24IconWhite: FC<SVGAttributes<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="96"
    height="40"
    viewBox="0 0 96 40"
    {...props}
  >
    <title>Przelewy24</title>
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF">
        <path
          d="M14.91 13.834L20.775 13.826 20.561 15.161 15.656 19.845 19.774 19.837 19.534 21.277 13.47 21.281 13.725 19.793 18.427 15.289 14.674 15.289zM8.494 11.55c-.128-.135-.312-.24-.514-.323-.214-.082-.45-.142-.683-.183-.292-.053-.577-.075-.78-.083-.187-.007-.307-.007-.307-.007H1.736L.026 21.28h1.598l.63-3.825 3.097.015s1.208.049 2.063-.405c.855-.453 1.084-1.485 1.084-1.485s.093-.382.172-.847c.086-.514.172-1.129.217-1.455.02-.143.03-.233.03-.233s.023-.105.023-.27c.004-.229-.03-.573-.21-.903-.064-.117-.139-.222-.236-.323zm-1.23 1.822c0 .02-.143.848-.33 1.83-.072.376-.36.578-.69.65-.548.115-1.136.093-1.136.093l-2.588-.008.57-3.43 2.344.007s.157-.004.378 0c.252.004.585.015.86.052.236.03.423.079.483.154.079.098.109.225.12.337.015.165-.011.304-.011.316zM28.729 10.954L30.266 10.954 28.545 21.281 27.004 21.277zM36.934 13.834L38.407 13.83 38.929 19.073 41.197 13.822 43.016 13.826 43.553 19.091 45.818 13.83 47.347 13.83 44.1 21.274 42.285 21.274 41.76 16.061 39.469 21.274 37.684 21.281z"
          transform="translate(-1056 -912) translate(1056 912) translate(12 8)"
        />
        <g fillRule="nonzero">
          <path
            d="M5.753.169C5.32.03 4.575 0 3.94.007 3.33.016 3.05.046 2.82.095c0 0-1.087.157-1.706.922S.31 3.454.31 3.454-.056 5.303.052 5.92c.11.615.3 1.189.998 1.455.698.27 1.29.255 1.29.255s1.245.098 2.183-.123c.937-.222 1.432-.882 1.432-.882s.221-.285.379-.622c.157-.338.206-.574.214-.604l.097-.401-1.594.004s-.086 1.053-.949 1.15c-.858.098-1.32.06-1.488.053-.165-.007-1.088.034-1.013-.739 0-.01 0-.026.004-.048.041-.878.139-1.106.139-1.106l5.006-.015.214-1.238C7.21 1.657 7.035.589 5.752.169zm-.334 2.715L1.988 2.88l.135-.544s.12-.427.356-.607c.24-.18.543-.214.829-.24.284-.027 1.046-.083 1.665.045.206.041.4.157.453.33.12.405-.007 1.02-.007 1.02z"
            transform="translate(-1056 -912) translate(1056 912) translate(12 8) translate(20.137 13.725)"
          />
          <path
            d="M1.648 5.434c0 .02-.01.042-.01.063-.01.112.01-.07.01-.063z"
            transform="translate(-1056 -912) translate(1056 912) translate(12 8) translate(20.137 13.725)"
          />
        </g>
        <g fillRule="nonzero">
          <path
            d="M1.64 5.438c0 .02-.01.042-.01.063 0 .112.01-.07.01-.063z"
            transform="translate(-1056 -912) translate(1056 912) translate(12 8) translate(29.587 13.725)"
          />
          <path
            d="M5.749.172C5.318.034 4.57.004 3.938.012c-.612.007-.893.037-1.122.086 0 0-1.087.157-1.706.922S.307 3.458.307 3.458-.06 5.306.05 5.925c.108.615.3 1.189.997 1.455.698.27 1.29.255 1.29.255s1.245.098 2.183-.124C5.456 7.29 5.95 6.63 5.95 6.63s.222-.285.379-.622c.157-.338.206-.574.214-.604l.097-.401-1.593.003s-.087 1.054-.95 1.151c-.858.098-1.32.06-1.488.057-.165-.008-1.087.03-1.012-.739 0-.011 0-.026.003-.049.042-.877.139-1.106.139-1.106l5.006-.015.214-1.237C7.207 1.66 7.031.587 5.749.171zM5.4 2.885L1.969 2.88l.135-.544s.12-.427.356-.607.544-.214.829-.24c.285-.027 1.046-.083 1.665.045.206.041.401.157.454.33.12.401-.008 1.02-.008 1.02z"
            transform="translate(-1056 -912) translate(1056 912) translate(12 8) translate(29.587 13.725)"
          />
        </g>
        <path
          d="M49.117 13.834l.987 5.445 2.786-5.449 1.568.015-4.017 7.781s-.727 1.41-1.177 1.766c-.45.357-.728.518-1.095.556-.368.037-.518.063-.87 0l-.375-.068.232-1.391s.623.116.99-.03c.371-.146.668-.776.668-.776l.187-.316-1.451-7.537 1.568.004zM55.028 14.572l1.616.004.097-.626s.173-1.132.559-1.35c.124-.071.323-.135.551-.176.424-.075.96-.083 1.399-.068.671.023.926.03 1.609.109.682.079.51.739.51.739l-.135.986s-.06.443-.218.716c-.139.244-.521.409-.742.48-.533.173-2.355.638-2.355.638l-1.433.412s-.881.255-1.372.799c-.495.547-.69 1.166-.758 1.489-.067.322-.446 2.553-.446 2.553l7.733.004.258-1.545-6.116.008.109-.63s.071-.649.334-.863c.082-.067.123-.157.61-.33.293-.105 1.29-.371 1.29-.371l2.307-.63s1.26-.323 1.755-1.013c.495-.686.686-2.002.686-2.002s.135-1.279.03-1.68c-.101-.401-.48-.881-.941-1.088-.461-.206-.941-.326-2.333-.307-1.39.019-2.08.082-2.786.345-.705.259-1.113.731-1.372 1.399-.281.633-.446 1.998-.446 1.998zM69.585 17.445l1.084-6.495h-1.92l-5.978 6.42-.27 1.627h5.228l-.383 2.28 1.605.004.379-2.284 1.481.004.266-1.556h-1.492zm-1.597.004l-3.38-.004 4.114-4.384-.734 4.388zM11.884 11.805h4.924s1.102-.893 1.886-1.466c.784-.574 2.209-1.478 2.209-1.478L18.12 7.56s-2.351 1.455-3.349 2.141c-.967.634-2.887 2.104-2.887 2.104zM22.466 7.942l-2.284-1.537s2.067-1.174 4.816-2.269c2.744-1.095 4.21-1.552 4.21-1.552l.466 2.179s-2.644.885-4.159 1.6c-1.567.668-3.049 1.58-3.049 1.58zM31.361 4.286l-.397-2.231s2.82-.75 5.4-1.23C38.948.345 42.37.116 42.37.116L41.24 3.562s-3.011-.412-5.839-.026c-2.201.263-4.039.75-4.039.75zM43.031 3.855L44.936.026s4.174-.082 7.774.476c3.6.556 6.892 1.41 6.821 1.448l-9.127 4.774s-2.134-1.35-4.782-2.179c-1.5-.442-2.59-.69-2.59-.69zM52.039 7.763l2.006 1.522h16.489s-.034-.533-.473-1.29c-.274-.473-.772-.975-1.294-1.496-.19-.188-.948-.78-1.518-1.151-1.455-.946-2.269-1.31-3.78-1.995l-11.43 4.41z"
          transform="translate(-1056 -912) translate(1056 912) translate(12 8)"
        />
        <path
          fillRule="nonzero"
          d="M12.953 13.826c-.62 0-1.204.244-1.703.518l.086-.518H9.697L8.386 21.24h1.643l.727-4.106c.15-.833.772-1.864 1.987-1.864l.848-.004.255-1.44h-.893z"
          transform="translate(-1056 -912) translate(1056 912) translate(12 8)"
        />
      </g>
    </g>
  </svg>
);
