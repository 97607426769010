import { FC, SVGAttributes } from "react";
import { useTheme, Theme } from "@emotion/react";
import { IconProps } from "src/types/Icon";

const InfoCircleIcon: FC<SVGAttributes<SVGSVGElement> & IconProps> = ({
  title = "Info",
  width = 24,
  height = 24,
  color,
  testId = "info-circle-icon",
  ...rest
}) => {
  const theme: Theme = useTheme();
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 24 24"
      fill="none"
      data-testid={testId}
      {...rest}
    >
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.831 8.79539C13.2447 8.79539 13.5984 8.65968 13.8922 8.38825C14.186 8.11682 14.3333 7.78746 14.3333 7.40101C14.3333 7.01621 14.186 6.68519 13.8922 6.41128C13.5993 6.13654 13.2447 6 12.831 6C12.4182 6 12.062 6.13654 11.7657 6.41128C11.4719 6.68519 11.3221 7.01621 11.3221 7.40101C11.3221 7.78664 11.4711 8.11682 11.7657 8.38825C12.062 8.65968 12.4182 8.79539 12.831 8.79539ZM12.7928 17.8436C13.0949 17.7393 13.4977 17.5887 14.0037 17.3901L14.1727 16.7057C14.0845 16.7463 13.9447 16.7935 13.7516 16.8464C13.5568 16.8994 13.3845 16.9267 13.2305 16.9267C12.9043 16.9267 12.6737 16.8729 12.5422 16.7662C12.4091 16.6594 12.3433 16.4583 12.3433 16.1637C12.3433 16.047 12.3633 15.8733 12.4041 15.6457C12.4457 15.4181 12.4915 15.2145 12.5431 15.0366L13.1731 12.8188C13.2347 12.6144 13.2772 12.3918 13.2996 12.1477C13.3221 11.9044 13.3337 11.7339 13.3337 11.6371C13.3337 11.1704 13.1681 10.7906 12.8394 10.4984C12.5089 10.2072 12.0395 10.0599 11.4311 10.0599C11.094 10.0599 10.7362 10.1194 10.3575 10.2403C9.97878 10.3594 9.58177 10.5034 9.16729 10.6714L9 11.3566C9.12151 11.3103 9.26966 11.2631 9.44028 11.211C9.61257 11.1605 9.78069 11.1365 9.94382 11.1365C10.2776 11.1365 10.5015 11.1911 10.6205 11.3028C10.7378 11.4145 10.7969 11.6131 10.7969 11.8961C10.7969 12.055 10.7786 12.2296 10.7403 12.42C10.702 12.6086 10.6538 12.8114 10.598 13.0249L9.96463 15.2493C9.90886 15.4835 9.86808 15.692 9.84228 15.8774C9.81731 16.0628 9.80483 16.2448 9.80483 16.4227C9.80483 16.8804 9.97378 17.2569 10.3134 17.554C10.6529 17.851 11.1298 18 11.7424 18C12.1411 18 12.4915 17.9479 12.7928 17.8436Z"
        fill={color || theme.colors.tone.black}
      />
      <path
        d="M21 12C21 16.9706 16.9706 21 12 21V23C18.0751 23 23 18.0751 23 12H21ZM12 21C7.02944 21 3 16.9706 3 12H1C1 18.0751 5.92487 23 12 23V21ZM3 12C3 7.02944 7.02944 3 12 3V1C5.92487 1 1 5.92487 1 12H3ZM12 3C16.9706 3 21 7.02944 21 12H23C23 5.92487 18.0751 1 12 1V3Z"
        fill={color || theme.colors.tone.black}
      />
    </svg>
  );
};

export { InfoCircleIcon };
