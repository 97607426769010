import { RefObject, useEffect } from "react";

interface UseScrollHandlerArgs {
  element: RefObject<HTMLDivElement>;
  bottomPlacement: boolean;
  isOpen: boolean;
}
export const useScrollHandler = ({
  element,
  bottomPlacement,
  isOpen,
}: UseScrollHandlerArgs) => {
  useEffect(() => {
    const scrollHandler = () => {
      if (element.current) {
        const maxScroll = element.current.scrollHeight - window.innerHeight;

        if (isOpen && window.scrollY > maxScroll) {
          document.body.style.overflow = "hidden";
          return bottomPlacement
            ? window.scroll(0, document.body.scrollHeight)
            : window.scroll(0, maxScroll);
        }
      }
    };
    window.addEventListener("scroll", scrollHandler, { passive: true });

    if (!isOpen) {
      window.removeEventListener("scroll", scrollHandler);

      document.body.style.overflow = "auto";
    }

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, [isOpen, element, bottomPlacement]);
};
