import { FC, SVGAttributes } from "react";

const ArrowRightIcon: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Arrow_Right">
      <path id="Drop-down" d="M6 12L10 8L6 4" stroke="black" strokeWidth="2" />
    </g>
  </svg>
);

export { ArrowRightIcon };
