import { Locale } from "lib/Intl";

const currencyMap = {
  "en-gb": "GBP",
  "en-dk": "DKK",
  "en-se": "SEK",
  "pl-pl": "PLN",
  "en-us": "USD",
  "es-es": "EUR",
} as { [locale in Locale]: string };

export default currencyMap;
