import { FC, SVGAttributes } from "react";

const IdealIconWhite: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="96"
    height="40"
    viewBox="0 0 96 40"
    {...props}
  >
    <title>Ideal</title>
    <defs>
      <path
        id="jah65l4eha"
        d="M0.824 0.887L6.338 0.887 6.338 10.042 0.824 10.042z"
      />
      <path
        id="iydunvjwuc"
        d="M3.633.189c1.724 0 3.121 1.397 3.121 3.12 0 1.724-1.397 3.122-3.12 3.122C1.91 6.431.511 5.033.511 3.31.512 1.586 1.91.189 3.633.189z"
      />
      <path
        id="9drs35er8d"
        d="M19.78.001c4.165.075 7.684 1.28 10.19 3.507 1.423 1.265 2.522 2.853 3.265 4.722.777 1.953 1.17 4.231 1.17 6.77 0 5.314-1.497 9.282-4.451 11.795-2.343 1.993-5.609 3.063-9.714 3.19l-.46.01v.002H0V0h19.78zm-.373 1.993H1.992v26.014h17.415c8.752 0 13.006-4.255 13.006-13.007 0-4.417-1.267-7.782-3.767-10.003-2.21-1.966-5.406-3.004-9.24-3.004z"
      />
      <path
        id="j776072pze"
        d="M7.58.42c5.578 0 11.5 2.105 12.005 9.763l-2.471.001v-4.28h-1.335v5.447h3.84l-.002.27c-.162 8.721-6.282 10.43-12.037 10.43H.87l-.002-10.7h.773l.194-.003c.313-.011.574-.05.778-.114.33-.106.598-.257.795-.449.256-.249.456-.577.593-.975.11-.324.167-.712.167-1.154 0-.501-.06-.93-.178-1.276-.12-.351-.298-.652-.528-.896-.235-.247-.52-.42-.848-.518-.242-.07-.58-.105-1.03-.105H.868L.87.418H7.58zM9.21 5.86H5.07v5.49h4.237v-1.167H6.405v-1.12h2.608V7.895H6.405v-.868H9.21V5.861zm3.85 0h-1.313l-2.133 5.49h1.422l.007-.017.424-1.164h1.882l.02.05.156.407.098.255.18.469h1.452l-2.196-5.49zM1.185 7.028c.52 0 .74.025.834.046.171.037.313.108.421.21.11.103.198.253.261.444.066.2.1.495.1.88s-.034.692-.1.91c-.064.206-.143.353-.236.437-.094.085-.217.147-.364.184-.119.03-.321.045-.602.045H.868V7.026zm11.207.6l.511 1.375h-1.012l.035-.098.187-.511.146-.402.133-.364z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-944 -816) translate(944 816) translate(32 5) translate(3 16)">
        <mask id="1w71knggib" fill="#fff">
          <use xlinkHref="#jah65l4eha" />
        </mask>
        <path
          fill="#FFF"
          d="M-4.176 -4.113L11.338 -4.113 11.338 15.042 -4.176 15.042z"
          mask="url(#1w71knggib)"
        />
      </g>
      <g>
        <g transform="translate(-944 -816) translate(944 816) translate(32 5) translate(3 9)">
          <use fill="#FFF" xlinkHref="#iydunvjwuc" />
        </g>
        <g transform="translate(-944 -816) translate(944 816) translate(32 5)">
          <use fill="#FFF" xlinkHref="#9drs35er8d" />
        </g>
      </g>
      <g transform="translate(-944 -816) translate(944 816) translate(32 5) translate(11 4)">
        <use fill="#FFF" xlinkHref="#j776072pze" />
      </g>
    </g>
  </svg>
);

export { IdealIconWhite };
