import { Text } from "@otrium/atoms";
import { Flex } from "@otrium/core";
import styled from "@emotion/styled";
import { GreyDotIcon } from "src/icons/GreyDotIcon";

const Wrapper = styled(Flex)`
  justify-content: space-between;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    justify-content: flex-start;
  }
`;

const SuggestionLink = styled.a`
  cursor: pointer;
  margin-bottom: ${({ theme }) => theme.space[3]}px;
  display: block;
`;

const Highlighted = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes[1]}px;
  letter-spacing: 0.4px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.tone.black};
`;

const SuggestionType = styled(Highlighted)`
  color: ${({ theme }) => theme.colors.tone.anthraciteLight};
  margin-left: ${({ theme }) => theme.space[1]}px;
`;

const DividerDot = styled(GreyDotIcon)`
  margin-left: ${({ theme }) => theme.space[2]}px;
  margin-right: ${({ theme }) => theme.space[2]}px;
`;

const Breadcrumb = styled(SuggestionType)`
  margin-left: ${({ theme }) => theme.space[0]}px;
`;

export {
  SuggestionLink,
  Wrapper,
  Highlighted,
  SuggestionType,
  DividerDot,
  Breadcrumb,
};
