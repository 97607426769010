import { FC, forwardRef, ReactNode, useEffect } from "react";
import { Box, Flex, BoxProps, useBreakpoints } from "@otrium/core";
import Link from "next/link";
import { EmotionBaseProps as BaseProps } from "src/types/global";
import { Container } from "src/atoms/Container";
import { HyperLink } from "src/atoms/HyperLink";
import { AnimatedBox, List, Title, ViewAllButton } from "./SearchWidget.styled";
import { useInView } from "react-intersection-observer";
import { SEGMENT_IN_VIEW_THRESHOLD } from "src/segment";
import { useSegment } from "src/hooks/useSegment";
import { CarouselType } from "src/types/Carousels.d";

interface Props {
  header?: ReactNode;
  viewAllText?: ReactNode;
  viewAllLink?: string | null;
  as?: string;
  prefetch?: boolean;
  scrolled?: boolean;
  isList?: boolean;
  widgetType?: CarouselType;
  position?: number;
  animationDuration?: number;
  viewAllOnClick?: () => void;
  withBottomButton?: boolean;
}

const AnimatedSearchWidget = forwardRef<
  HTMLDivElement,
  BoxProps & BaseProps & Props
>(({ animationDuration, children }, ref) => {
  return (
    <Box sx={{ position: "relative" }} ref={ref}>
      <AnimatedBox duration={animationDuration as number}>
        {children}
      </AnimatedBox>
      <Box sx={{ opacity: 0 }}>{children}</Box>
    </Box>
  );
});

AnimatedSearchWidget.displayName = "AnimatedSearchWidget";

const SearchWidget: FC<BoxProps & BaseProps & Props> = ({
  header,
  viewAllText,
  viewAllLink,
  as,
  prefetch,
  scrolled = true,
  isList = true,
  animationDuration,
  children,
  viewAllOnClick,
  widgetType,
  position,
  withBottomButton,
  ...rest
}) => {
  const [ref, inView] = useInView({
    threshold: SEGMENT_IN_VIEW_THRESHOLD,
    triggerOnce: true,
  });

  const { segmentSearchOverlayCarouselsViewed } = useSegment();

  useEffect(() => {
    if (inView && widgetType) {
      void segmentSearchOverlayCarouselsViewed(widgetType, position);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, position, widgetType]);

  const { isMobile } = useBreakpoints();

  const component = (
    <Box {...rest}>
      <Container>
        {header && (
          <Flex
            as="header"
            justifyContent="space-between"
            alignItems="flex-start"
            flexWrap="wrap"
          >
            <Title as="h4">{header}</Title>
            <Box pb={[3, 3, 3, 3, 0]} display="block" sx={{ zIndex: 2 }}>
              {viewAllText && viewAllLink && (
                <Link
                  href={viewAllLink}
                  {...(as ? { as } : {})}
                  {...(!prefetch ? { prefetch } : {})}
                  passHref
                  legacyBehavior
                >
                  <HyperLink
                    onClick={viewAllOnClick}
                    asLink
                    hasUnderline
                    data-testid="search-widget-header-link"
                  >
                    {viewAllText}
                  </HyperLink>
                </Link>
              )}
            </Box>
          </Flex>
        )}
        {isList ? <List scrolled={scrolled}>{children}</List> : children}
        {isMobile && withBottomButton && viewAllText && viewAllLink && (
          <Box mt={4}>
            <Link
              href={viewAllLink}
              {...(as ? { as } : {})}
              {...(!prefetch ? { prefetch } : {})}
              passHref
            >
              <ViewAllButton
                as="a"
                data-testid="search-widget-bottom-link"
                variant="secondary"
                colorScheme="dark"
                onClick={viewAllOnClick}
              >
                {viewAllText}
              </ViewAllButton>
            </Link>
          </Box>
        )}
      </Container>
    </Box>
  );
  if (animationDuration) {
    return (
      <AnimatedSearchWidget ref={ref} animationDuration={animationDuration}>
        {component}
      </AnimatedSearchWidget>
    );
  }
  return component;
};

export default SearchWidget;
