import styled from "@emotion/styled";
import { Text, Heading } from "@otrium/atoms";

const Term = styled(Heading)`
  line-height: 32px;
  color: ${({ theme }) => theme.colors.tone.black};
  font-size: ${({ theme }) => theme.fontSizes[3]}px;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    line-height: 40px;
    font-size: ${({ theme }) => theme.fontSizes[4]}px;
  }
`;

const StyledText = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes[1]}px;
  letter-spacing: 0.4px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.tone.black};
`;

const Title = styled(Text)`
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.tone.black};
  &:after {
    content: ":";
  }
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    line-height: 40px;
    font-size: ${({ theme }) => theme.fontSizes[4]}px;
    &:after {
      content: "";
    }
  }
`;

export { Title, Term, StyledText };
