import { Box, Flex } from "@otrium/core";
import styled from "@emotion/styled";
import { User } from "src/icons/User";
import { ReferAFriend } from "src/icons/ReferAFriend";
import { Notification } from "src/icons/Notification";
import { CustomerService } from "src/icons/CustomerService";
import { Drawer } from "src/atoms/Drawer";
import { DrawerContent } from "src/molecules/DrawerContent";
import { Button } from "src/atoms/Button";

const StyledNavMenu = styled.nav`
  width: 320px;
  height: 100%;
  overflow-x: hidden;
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 556px;
  }
`;

const NavItem = styled.li`
  padding: 0 ${({ theme }) => theme.space[3]}px;
  position: relative;
  text-transform: capitalize;

  display: flex;
  align-items: center;
  height: 40px;

  font-size: ${({ theme }) => theme.fontSizes[1]}px;
  color: ${({ theme }) => theme.colors.tone.black};

  cursor: pointer;
`;

const NavItemSecondary = styled(NavItem)`
  width: 100%;
  padding-top: ${({ theme }) => theme.space[2]}px;
  padding-bottom: ${({ theme }) => theme.space[2]}px;
  height: 56px;

  font-weight: 600;
  color: ${({ theme }) => theme.colors.tone.anthraciteDark};
  background: ${({ theme }) => theme.colors.tone.sand};
  fill: ${({ theme }) => theme.colors.tone.anthraciteDark};

  background: none;

  border-bottom: 1px solid ${({ theme }) => theme.colors.tone.marble};
  &:first-of-type {
    border-top: 1px solid ${({ theme }) => theme.colors.tone.marble};
  }
`;

const NavIcon = styled(Flex)`
  width: 32px;
`;

const UserIcon = styled(User)`
  width: 22px;
  height: 18px;
`;

const ReferFriendsIcon = styled(ReferAFriend)`
  height: 36px;
  width: 38px;
  margin-left: -8px;
`;

const NotificationsIcon = styled(Notification)`
  width: 24px;
  height: 24px;
`;

const CustomerServiceIcon = styled(CustomerService)`
  width: 24px;
  height: 24px;
`;

const DrawerSlider = styled(Drawer.Slider)<{ isOpen: boolean }>`
  min-width: 100%;
  background: ${({ theme }) => theme.colors.tone.white};

  ${({ theme, isOpen }) =>
    isOpen &&
    `
  transform: translateX(-320px);
  @media (min-width: ${theme.breakpoints[1]}) {
    transform: translateX(-556px);
  }
  `}
`;

const Submenu = styled(DrawerContent)`
  flex-direction: column;
  flex-shrink: 0;
  background: ${({ theme }) => theme.colors.tone.white};
  width: 320px;
`;

const NavMenuContentWrapper = styled.div`
  flex: 1;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.colors.tone.white};
`;

const NavMenuNavigation = styled.ul`
  list-style: none;
  background-color: ${({ theme }) => theme.colors.tone.white};
  margin-bottom: ${({ theme }) => theme.space[3]}px;
  overflow-y: auto;
`;

const SubcategoriesMenu = styled(NavMenuNavigation)`
  border-top: 1px solid ${({ theme }) => theme.colors.tone.marble};
  margin-top: ${({ theme }) => theme.space[3]}px;
`;

const NavMenuActions = styled(Box)`
  margin: 13px 0;
  padding: 0 15px;

  font-size: 13px;
  text-align: center;
  color: ${({ theme }) => theme.colors.tone.black};
`;

const StyledNavButton = styled(Button)`
  height: 40px;
  margin-bottom: ${({ theme }) => theme.space[3]}px;
  max-width: 288px;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    max-width: 247px;
  }
`;

export {
  StyledNavMenu,
  NavItem,
  NavItemSecondary,
  NavIcon,
  UserIcon,
  ReferFriendsIcon,
  NotificationsIcon,
  CustomerServiceIcon,
  DrawerSlider,
  Submenu,
  NavMenuContentWrapper,
  NavMenuNavigation,
  NavMenuActions,
  SubcategoriesMenu,
  StyledNavButton,
};
