import styled from "@emotion/styled";
import { Flex } from "@otrium/core";

interface Props {
  bgColor?: string | undefined;
  textColor?: string | undefined;
}

const StyledContainer = styled.div<Props>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 21;
  background-color: ${(props) => props.bgColor};
  transition: background-color ease 0.3s;
  width: 100%;
  text-align: center;

  a {
    color: ${(props) => props.textColor};
    width: 100%;
  }
  .swiper-container {
    width: 100%;
  }
  .swiper-wrapper,
  .alert-message {
    display: flex;
    justify-items: center;
    height: 48px;
  }
`;

const InnerWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
`;

export { StyledContainer, InnerWrapper };
