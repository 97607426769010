import styled from "@emotion/styled";
import { FormInput } from "@otrium/atoms";

const Wrapper = styled.div`
  position: relative;
  line-height: 1.5em;
  font-size: ${({ theme }) => theme.fontSizes[1]}px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.tone.black};
  border: 1px solid ${({ theme }) => theme.colors.tone.silver};
  border-radius: 4px;
  flex: 1;

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  :focus-within {
    border-color: ${({ theme }) => theme.colors.tone.anthraciteLight};
  }
`;

const Input = styled(FormInput)<{ currencyLength: number }>`
  border: none;
  padding: 8px 0 8px ${({ currencyLength }) => 22 + currencyLength * 6}px;
  height: 40px;
  width: 100%;
`;

const Currency = styled.div`
  position: absolute;
  top: 50%;
  left: ${({ theme }) => theme.space[3]}px;
  transform: translate(0, -50%);
`;

export { Wrapper, Currency, Input };
