import { RedirectableProvider } from "next-auth/client";
import { signIn } from "src/utils/nextAuth";
import { AuthProvider } from "pages/api/auth/getUserTokenFromApi";
import { NextRouter } from "next/router";

const employeeSaleSoftLogin = async (
  router: NextRouter,
  employeeSaleData: {
    email: string;
    shopType: string[];
  }
): Promise<void> => {
  const response = await signIn(
    AuthProvider.CREDENTIALS_SOFT_LOGIN as RedirectableProvider,
    {
      assumedAuthType: "sign_up",
      email: employeeSaleData?.email,
      shopPreferences: employeeSaleData?.shopType,
      redirect: false,
      isEmployeeSale: true,
    }
  );

  if (response?.url) {
    void router.push(response.url, undefined, { shallow: true });
  }
};

export { employeeSaleSoftLogin };
