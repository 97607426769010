import { Trans } from "@lingui/macro";
import { FC } from "react";
import Link from "next/link";
import {
  Wrapper,
  Content,
  DescriptionContent,
  BrandName,
  ProductName,
  SizeContent,
  PriceContent,
  Image,
} from "./AddedToCart.styled";
import { Button } from "src/atoms/Button";
import { Price } from "src/atoms/Price";
import { Money } from "src/types/ctgraphql.d";
import { AddedToCartProduct } from "src/types/addedToCardProduct.d";
import { Text, AlertBanner } from "@otrium/atoms";
import { Box } from "@otrium/core";
import { CTPrice } from "src/atoms/CTPrice";
import { PromotionDisplayType } from "src/types/graphql.d";
import { Flash } from "src/icons/Flash";

interface Props extends AddedToCartProduct {
  onClose?: () => void;
  cartMinLineItemsTotalPriceDiff: Money | null;
  hideActionButtons?: boolean;
}

const AddedToCartContent: FC<Props> = ({
  productName,
  brandName,
  price,
  regularPrice,
  image,
  selectedSize,
  cartMinLineItemsTotalPriceDiff,
  onClose,
  hideActionButtons = false,
  promoCode,
  promotionDisplayType,
}) => {
  return (
    <Wrapper>
      <Content data-testid="add-to-cart-popup">
        {image && <Image src={image} />}
        <DescriptionContent>
          <BrandName>{brandName}</BrandName>
          <ProductName>{productName}</ProductName>
          <SizeContent>
            <span>
              <Trans>Size</Trans>
            </span>
            <span>{selectedSize}</span>
          </SizeContent>
          <PriceContent>
            <Price price={price} />
            <Price price={regularPrice} />
            {promoCode &&
            promotionDisplayType === PromotionDisplayType.LightningDeal ? (
              <span className="promo-code">
                <Flash />
                {promoCode}
              </span>
            ) : null}
          </PriceContent>
        </DescriptionContent>
      </Content>

      {cartMinLineItemsTotalPriceDiff &&
        cartMinLineItemsTotalPriceDiff.centAmount > 0 && (
          <Box mb={3}>
            <AlertBanner variant="info">
              <Text variant="body.source.semibold14">
                <Trans id="added_to_cart_popup.min_cart_subtotal_warning">
                  Almost there! Add{" "}
                  <CTPrice price={cartMinLineItemsTotalPriceDiff} /> to your
                  order to unlock check-out.
                </Trans>
              </Text>
            </AlertBanner>
          </Box>
        )}

      {!hideActionButtons && (
        <Link href="/cart" passHref legacyBehavior>
          <Button
            as="a"
            variant="primary"
            data-testid="order-now-button"
            fullWidth
          >
            <Trans>To shopping cart</Trans>
          </Button>
        </Link>
      )}
      <Box mt={2}>
        <Button
          as="a"
          variant="tertiary"
          data-testid="continue-shopping-button"
          fullWidth
          onClick={onClose}
        >
          <Trans>Continue shopping</Trans>
        </Button>
      </Box>
    </Wrapper>
  );
};

export default AddedToCartContent;
