import { FC, ReactNode, SyntheticEvent, useRef, useState } from "react";
import { Box } from "@otrium/core";
import { Trans } from "@lingui/macro";
import { useScrollHandler } from "src/hooks/useScrollHandler";
import LanguageMenu from "src/atoms/LanguageMenu/LanguageMenuNew";
import { menuOptions } from "src/atoms/LanguageMenu/LanguageMenuOptions";
import { Menu as MenuAtom } from "src/atoms/Menu";
import { MenuListProps } from "src/atoms/Menu/Menu";
import { CloseSmall } from "src/icons/CloseSmall";
import { Locale } from "lib/Intl";
import {
  Button,
  Dropdown,
  Expandable,
  Flag,
  ListTitle,
  LocaleName,
  Menu,
  MenuListContent,
  MenuMobile,
  MenuWrapper,
  MobileListWrapper,
} from "./LanguageSwitcher.styled";
import { CloseButton } from "src/atoms/CloseButton";
import { useLingui } from "@lingui/react";
import { translatedLanguageMap } from "src/atoms/LanguageMenu/getTranslatedMenuOptions";

interface ToggleProps {
  isOpen?: boolean;
  onToggle?: () => void;
  isFooter: boolean;
  svgPrefixId: string;
  children?: ReactNode;
}
const Toggle: FC<ToggleProps> = ({
  isOpen,
  onToggle,
  isFooter,
  svgPrefixId,
  children,
  ...buttonAttrs
}) => (
  <Button isFooter={isFooter} onPress={onToggle} {...buttonAttrs}>
    <Box height="20px" mr={1}>
      <Flag svgPrefixId={svgPrefixId} />
    </Box>
    {children}
    <Dropdown open={isOpen} isFooter={isFooter} />
  </Button>
);

interface DesktopMenuListProps {
  isOpen?: boolean;
  isFooter: boolean;
  svgPrefixId: string;
}

const DesktopMenuList: FC<DesktopMenuListProps> = ({
  isOpen,
  isFooter,
  svgPrefixId,
}) => {
  const [isMask, setIsMask] = useState(true);
  const onScroll = (e: SyntheticEvent) => {
    const target = e.target as HTMLElement;
    const scrolledDown =
      target.scrollHeight - target.scrollTop < target.clientHeight + 10;
    setIsMask(!scrolledDown);
  };

  return (
    <Expandable isExpanded={isOpen} isMask={isMask} isUpward={isFooter}>
      <MenuListContent>
        <LanguageMenu
          svgPrefixId={`desktop-menu-list-${svgPrefixId}`}
          onScroll={onScroll}
          limitHeight
        />
      </MenuListContent>
    </Expandable>
  );
};

const MenuList: FC<MenuListProps> = ({
  isOpen,
  onToggle = () => null,
  children,
}) => {
  return (
    <MenuListContent>
      {children instanceof Function ? children({ onToggle, isOpen }) : children}
    </MenuListContent>
  );
};

interface MobileMenuListProps {
  isOpen: boolean;
  isFooter: boolean;
  children: ReactNode;
}

const MobileMenuList: FC<MobileMenuListProps> = ({
  isOpen,
  isFooter,
  children,
}) => {
  const menu = useRef<HTMLDivElement>(null);
  useScrollHandler({ element: menu, bottomPlacement: isFooter, isOpen });

  if (!isOpen) {
    return null;
  }

  return <MobileListWrapper ref={menu}>{children}</MobileListWrapper>;
};

interface Props {
  svgPrefixId: string;
  locale: Locale;
  isFooter?: boolean;
}

const LanguageSwitcher: FC<Props> = ({
  svgPrefixId,
  locale,
  isFooter = false,
}) => {
  const currentLocale = menuOptions.find((option) => option.id === locale);
  const language = currentLocale?.language;
  const currency = currentLocale?.currency;
  const { _ } = useLingui();
  const translatedLanguage = _(
    translatedLanguageMap[currentLocale?.id || "en"]
  );

  return (
    <MenuWrapper isFooter={isFooter}>
      <Menu>
        <p className="sr-only" id="domain-switcher-label">
          Country switcher
        </p>
        <p className="sr-only" id="domain-switcher-description">
          Selected shop is ${language}. Current currency is ${currency}. To open
          this listbox press Enter. To leave the opened listbox press Escape.
        </p>
        <MenuAtom.Button>
          {({ isOpen, onToggle }) => (
            <Toggle
              isOpen={isOpen}
              onToggle={onToggle}
              isFooter={isFooter}
              svgPrefixId="menu-toggle"
              aria-expanded={isOpen}
              aria-labelledby="domain-switcher-label"
              aria-describedby="domain-switcher-description"
            />
          )}
        </MenuAtom.Button>
        <DesktopMenuList isFooter={isFooter} svgPrefixId={svgPrefixId} />
      </Menu>
      <MenuMobile>
        <MenuAtom.Button>
          {({ isOpen, onToggle }) => (
            <Toggle
              isOpen={isOpen}
              onToggle={onToggle}
              isFooter={isFooter}
              svgPrefixId="menu-mobile-toggle"
            >
              <LocaleName isFooter={isFooter}>{translatedLanguage}</LocaleName>
            </Toggle>
          )}
        </MenuAtom.Button>
        <MenuList>
          {({ onToggle, isOpen }) => (
            <MobileMenuList isOpen={!!isOpen} isFooter={isFooter}>
              <CloseButton
                onClick={onToggle}
                icon={<CloseSmall />}
                sx={{
                  width: 48,
                  height: 48,
                  margin: "8px 8px 8px auto",
                  justifyContent: "center",
                }}
              />
              <ListTitle as="h4" mx={5}>
                <Trans>Choose your language</Trans>
              </ListTitle>
              <LanguageMenu svgPrefixId={svgPrefixId} />
            </MobileMenuList>
          )}
        </MenuList>
      </MenuMobile>
    </MenuWrapper>
  );
};

export default LanguageSwitcher;
