import {
  NavigationItems,
  NavigationLinksData,
  TopLevelCategoriesData,
} from "src/types/graphql.d";
import { useMemo } from "react";

export type NavigationLink = NavigationLinksData | TopLevelCategoriesData;

const useNavigationItemsBySection = (
  navigationItems?: NavigationItems
): { firstSection: NavigationLink[]; restSections: NavigationLink[] } => {
  const allItems = useMemo(
    () => [
      ...(navigationItems?.navigation_links || []),
      ...(navigationItems?.top_level_categories || []),
    ],
    [navigationItems?.navigation_links, navigationItems?.top_level_categories]
  );

  return useMemo(
    () => ({
      firstSection: allItems.filter((item) => item.section === 1),
      restSections: allItems
        .filter((item) => item.section !== 1)
        .sort((a, b) => a.section - b.section),
    }),
    [allItems]
  );
};

export default useNavigationItemsBySection;
