import { DependencyList, RefObject, useEffect, useState } from "react";

const useScrollHeight = (
  elementRef: RefObject<HTMLDivElement>,
  deps: DependencyList = []
) => {
  const [scrollHeight, setScrollHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (!elementRef.current) return;
      setScrollHeight(elementRef.current.scrollHeight);
    };

    handleResize();
    window.addEventListener("resize", handleResize, { passive: true });
    return () => window.removeEventListener("resize", handleResize);
  }, deps);

  return scrollHeight;
};

export { useScrollHeight };
