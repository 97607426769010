import { useSession } from "next-auth/client";
import { useEffect } from "react";
import { signOut } from "src/utils/nextAuth";
import { SessionError } from "pages/api/auth/getUserTokenFromApi";

export const useSessionError = (): void => {
  const [session] = useSession();

  useEffect(() => {
    if (session?.error === SessionError.REFRESH_ACCESS_TOKEN_ERROR) {
      void signOut();
    }
  }, [session]);
};
