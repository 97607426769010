import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { UrlObject } from "url";

export interface Item {
  name: string;
  url: string;
  href: string | UrlObject;
  hideOnDesktop?: boolean;
  hideOnMobile?: boolean;
  highlightMenu?: boolean;
  hasSubCategory?: boolean;
}

interface MenuItem {
  [key: string]: Item[];
}
export const loggedOutConfig = (i18n: I18n): MenuItem => ({
  en: [
    {
      name: t(i18n)`all brands`,
      url: "/brands",
      href: "/brands",
    },
    {
      name: t(i18n)`Customer service`,
      url: "https://help.otrium.nl/en",
      href: "https://help.otrium.nl/en",
    },
    {
      name: t(i18n)`About us`,
      url: "/about-us",
      href: "/[slug]",
    },
  ],
  de: [
    {
      name: t(i18n)`all brands`,
      url: "/marken",
      href: "/marken",
    },
    {
      name: t(i18n)`Customer service`,
      url: "https://help.otrium.nl/de",
      href: "https://help.otrium.nl/de",
    },
    {
      name: t(i18n)`About us`,
      url: "/uber-uns",
      href: "/[slug]",
    },
  ],
  "de-at": [
    {
      name: t(i18n)`all brands`,
      url: "/marken",
      href: "/marken",
    },
    {
      name: t(i18n)`Customer service`,
      url: "https://help.otrium.nl/de",
      href: "https://help.otrium.nl/de",
    },
    {
      name: t(i18n)`About us`,
      url: "/uber-uns",
      href: "/[slug]",
    },
  ],
  fr: [
    {
      name: t(i18n)`all brands`,
      url: "/marques",
      href: "/marques",
    },
    {
      name: t(i18n)`Customer service`,
      url: "https://help.otrium.nl/fr",
      href: "https://help.otrium.nl/fr",
    },
    {
      name: t(i18n)`About us`,
      url: "/a-propos",
      href: "/[slug]",
    },
  ],
  "en-gb": [
    {
      name: t(i18n)`all brands`,
      url: "/brands",
      href: "/brands",
    },
    {
      name: t(i18n)`Customer service`,
      url: "https://help.otrium.nl/en-gb",
      href: "https://help.otrium.nl/en-gb",
    },
    {
      name: t(i18n)`About us`,
      url: "/about-us",
      href: "/[slug]",
    },
  ],
  "en-us": [
    {
      name: t(i18n)`all brands`,
      url: "/brands",
      href: "/brands",
    },
    {
      name: t(i18n)`Customer service`,
      url: "https://help.otrium.nl/en-us",
      href: "https://help.otrium.nl/en-us",
    },
    {
      name: t(i18n)`About us`,
      url: "/about-us",
      href: "/[slug]",
    },
  ],
  "en-se": [
    {
      name: t(i18n)`all brands`,
      url: "/brands",
      href: "/brands",
    },
    {
      name: t(i18n)`Customer service`,
      url: "https://help.otrium.nl/en",
      href: "https://help.otrium.nl/en",
    },
    {
      name: t(i18n)`About us`,
      url: "/about-us",
      href: "/[slug]",
    },
  ],
  "en-dk": [
    {
      name: t(i18n)`all brands`,
      url: "/brands",
      href: "/brands",
    },
    {
      name: t(i18n)`Customer service`,
      url: "https://help.otrium.nl/en",
      href: "https://help.otrium.nl/en",
    },
    {
      name: t(i18n)`About us`,
      url: "/about-us",
      href: "/[slug]",
    },
  ],
  nl: [
    {
      name: t(i18n)`all brands`,
      url: "/merken",
      href: "/merken",
    },
    {
      name: t(i18n)`Customer service`,
      url: "https://help.otrium.nl/nl",
      href: "https://help.otrium.nl/nl",
    },
    {
      name: t(i18n)`About us`,
      url: "/overons",
      href: "/[slug]",
    },
  ],
  "nl-be": [
    {
      name: t(i18n)`all brands`,
      url: "/merken",
      href: "/merken",
    },
    {
      name: t(i18n)`Customer service`,
      url: "https://help.otrium.nl/nl",
      href: "https://help.otrium.nl/nl",
    },
    {
      name: t(i18n)`About us`,
      url: "/overons",
      href: "/[slug]",
    },
  ],
  es: [
    {
      name: t(i18n)`all brands`,
      url: "/marcas",
      href: "/marcas",
    },
    {
      name: t(i18n)`Customer service`,
      url: "https://help.otrium.nl/es",
      href: "https://help.otrium.nl/es",
    },
    {
      name: t(i18n)`About us`,
      url: "/sobre-nosotros",
      href: "/[slug]",
    },
  ],
  it: [
    {
      name: t(i18n)`all brands`,
      url: "/brands",
      href: "/brands",
    },
    {
      name: t(i18n)`Customer service`,
      url: "https://help.otrium.nl/it",
      href: "https://help.otrium.nl/it",
    },
    {
      name: t(i18n)`About us`,
      url: "/chi-siamo",
      href: "/[slug]",
    },
  ],
  "pl-pl": [
    {
      name: t(i18n)`all brands`,
      url: "/marki",
      href: "/marki",
    },
    {
      name: t(i18n)`Customer service`,
      url: "https://help.otrium.nl/pl",
      href: "https://help.otrium.nl/pl",
    },
    {
      name: t(i18n)`About us`,
      url: "/o-nas",
      href: "/[slug]",
    },
  ],
});
