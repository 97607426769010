import { useEffect, useRef, useState } from "react";
import { useSession } from "next-auth/client";
import qs from "query-string";
import { useRouter } from "next/router";
import { omit } from "lodash-es";
import Cookies from "js-cookie";
import {
  localeAdapter,
  productListClickedProps,
  TrackFnReturnType,
  SegmentProductClicked,
  useSegment,
  Promotion,
  requestSource,
} from "src/hooks/useSegment";
import { googleTagManager } from "src/tracking";
import { isClient } from "src/utils/isClient";
import { AuthProvider } from "pages/api/auth/getUserTokenFromApi";
import { guestLogin } from "src/utils/login/guestLogin";
import { employeeSaleSoftLogin } from "src/utils/login/employeeSaleSoftLogin";
import { PrivateSale } from "src/types/privateSale";
import { Locale } from "lib/Intl";
import { useMyOrders } from "./useMyOrders";
import {
  getVisitorTypeFromOrders,
  setLongCookie,
  setShortCookie,
} from "src/utils/prospect-vs-customer";
import { popPostLoginWishlistItem } from "src/utils/postLoginWishlist";
import { useAddToWishList } from "./useAddToWishlist";
import {
  GATED_POPUP_REDIRECT_DATA,
  GATED_POPUP_SOURCE,
} from "src/constants/gatedItem";
import { getStorageJSON } from "src/utils/helper/localStorage";
import {
  SEGMENT_EVENTS,
  SEGMENT_PROMOTION_TYPE,
  SegmentProductListMeta,
} from "src/segment";
import {
  CatalogProduct,
  HomepageSingleCategoryProduct,
} from "src/types/graphql.d";
import { RankingInfo } from "src/contexts/app.context";
import { getAnalytics } from "src/utils/getAnalytics";
import { ID } from "@ht-sdks/events-sdk-js-browser";

export interface SegmentPopupRedirect {
  gatedActionType?: string;
  algoliaIndex?: string;
  isPersonalised?: boolean;
  position?: number;
  product?: (CatalogProduct | HomepageSingleCategoryProduct) & {
    objectID?: string;
    entityID?: string;
  };
  productListMeta: SegmentProductListMeta;
  queryID?: string;
  rankingInfo?: RankingInfo;
  promo_id?: string;
  promo_name?: string;
  promo_creative?: string;
  promo_position?: string;
  promo_type?: SEGMENT_PROMOTION_TYPE;
  promo_path?: string;
  actionSource?: string;
  tab?: string;
}
const setAuthTokenCookie = (token: string) => {
  Cookies.set("ot_auth_token", token, {
    path: "/",
    sameSite: "strict",
  });
};

const setLocale = (locale: string) => {
  Cookies.set("locale", locale, {
    path: "/",
    sameSite: "strict",
  });
};

const redirect = async (
  segmentProductClicked: (args: SegmentProductClicked) => TrackFnReturnType,
  segmentProductListClicked: (
    data: productListClickedProps
  ) => TrackFnReturnType,
  segmentPromotionClickedOrViewed: (
    data: Promotion & {
      tab?: string;
      gatedActionType?: string;
      shopType?: string;
      redirectTo: string;
    }
  ) => TrackFnReturnType
) => {
  const query = qs.parse(window.location.search);
  const { htevents } = getAnalytics();
  // TODO: Remove once one version of SalesLanding Page is available
  // Add `new Date().getTime() + ""` to invalidate browser cache
  if (query.design) {
    query.time = `${new Date().getTime()}`;
  }
  const redirectUrl =
    (query.redirect_to as string) ||
    qs.stringifyUrl({
      url: window.location.pathname,
      query: omit(query, ["signin"]),
    });

  if (query.source === GATED_POPUP_SOURCE) {
    const redirectData = getStorageJSON(GATED_POPUP_REDIRECT_DATA);

    if (redirectData) {
      const {
        gatedActionType,
        isPersonalised,
        position,
        product,
        productListMeta: { listFormat, listId, listType, shopType },
        promo_id,
        promo_name,
        promo_creative,
        promo_position,
        promo_type,
        promo_path,
        actionSource,
      } = redirectData as SegmentPopupRedirect;

      if (promo_position) {
        await htevents.track(SEGMENT_EVENTS.gatedPopupRedirected, {
          ...requestSource,
          promo_id,
          promo_name,
          promo_creative,
          promo_position,
          promo_type,
          promo_path,
          shop_type: shopType,
          redirect_to: redirectUrl,
          gated_action_type: gatedActionType,
        });
      } else if (product && position) {
        void segmentProductClicked({
          isPersonalised,
          position,
          product,
          productListMeta: {
            listFormat,
            listId,
            listType,
            shopType,
          },
          gatedActionType,
          actionSource,
        });
      } else {
        void segmentProductListClicked({
          listFormat,
          listId,
          listType,
          shopType,
          gatedActionType,
          actionSource,
        });
      }
    }
  }

  window.location.assign(redirectUrl);
};

const useSessionChange = (
  isPrivateSale: boolean,
  employeeSaleData: PrivateSale,
  locale: Locale
): void => {
  const [isSignedIn, setIsSignedIn] = useState(false);

  const {
    segmentIdentify,
    segmentSignedIn,
    segmentSignedOut,
    segmentSignedUp,
    segmentProductClicked,
    segmentProductListClicked,
    segmentPromotionClickedOrViewed,
    user,
  } = useSegment();
  const router = useRouter();
  const [session] = useSession();
  const { refetch: fetchMyOrders } = useMyOrders(1);
  const { addToWishlist } = useAddToWishList({
    refetchQueries: ["userWishlistMetaData"],
  });

  const prevSessionRef: {
    current: typeof session | null | undefined;
  } = useRef();
  useEffect(() => {
    prevSessionRef.current = session;
  });
  const prevSession = prevSessionRef.current;

  const isSessionNotExists = prevSession === undefined && session === null;
  const isSessionEnded = prevSession && session === null;
  const [htUserId, setHtUserId] = useState<ID>("");

  if (locale) {
    setLocale(locale as string);
  }

  if (isClient && (isSessionNotExists || isSessionEnded)) {
    if (isPrivateSale) {
      void employeeSaleSoftLogin(router, employeeSaleData);
    } else if (!router.pathname.startsWith("/app-login")) {
      void guestLogin();
    }
  }

  // set the ot_auth_token cookie on the client side
  // to allow login with credentials to create it

  // prettier-ignore
  if (
    isClient &&
    prevSession?.accessToken !== session?.accessToken
  ) {
    setAuthTokenCookie(session?.accessToken ? session?.accessToken : "");
  }

  useEffect(() => {
    const getHtUserId = async () => {
      const htUser = await user;
      setHtUserId(htUser?.id?.() || "");
    };
    void getHtUserId();
  }, [user]);

  useEffect(() => {
    if (isClient && session && session.user.user_id && session.user.email) {
      googleTagManager.trackUser(session.user.user_id, session.user.email);

      if (window.NREUM) {
        window.NREUM.setCustomAttribute("enduser.id", session.user.user_id);
      }
    }
  }, [session]);

  // NOTE: Session change in progress (signin=true):
  // - changed from Guest Login to Login
  // - changed from Guest Login to Soft Login
  // - changed from Soft Login to Login
  if (isClient && !isSignedIn && session && router.query.signin === "true") {
    setIsSignedIn(true);

    void (async () => {
      // NOTE: Tracking: Segment, Google Analytics
      if (session.user?.new_signup === true) {
        await segmentIdentify({
          sign_up_platform: session.user?.signInPlatform,
          name: session.user?.name,
          locale: localeAdapter(locale),
        });
        await segmentSignedUp();
      } else if (session.user?.new_signup === false) {
        await segmentIdentify({
          sign_in_platform: session.user?.signInPlatform,
        });
        await segmentSignedIn();

        if (
          session.user.signInPlatform !== AuthProvider.CREDENTIALS_SOFT_LOGIN
        ) {
          try {
            const { data } = await fetchMyOrders();
            const visitorType = getVisitorTypeFromOrders(data);

            setLongCookie(visitorType);
            setShortCookie(visitorType);
          } catch (e) {
            console.error("Cannot get my orders", e);
          }
        }
      }

      if (session.user.signInPlatform !== AuthProvider.CREDENTIALS_SOFT_LOGIN) {
        const item = popPostLoginWishlistItem();
        if (item) {
          await addToWishlist(item.itemType, item.itemId).catch(() => {});
        }
      }

      // NOTE: Session Soft Login started
      if (
        session.user?.signInPlatform === AuthProvider.CREDENTIALS_SOFT_LOGIN &&
        session.user.email
      ) {
        const { email } = session.user;
        googleTagManager.trackUser(undefined, email);
      }

      if (!session.user.opt_in && !session.user.first_order) {
        Cookies.set("client_opt_in", "1");
      }

      void redirect(
        segmentProductClicked,
        segmentProductListClicked,
        segmentPromotionClickedOrViewed
      );
    })();
  }

  // NOTE: Session ended
  if (isClient && prevSession?.user && session === null) {
    if (htUserId) {
      void segmentSignedOut()?.finally(() => {
        window.location.assign("/");
      });
    } else {
      window.location.assign("/");
    }
  }
};

export { useSessionChange };
