import styled from "@emotion/styled";

const Wrapper = styled.label<{ light?: boolean }>`
  position: relative;
  display: inline-block;
  width: 28px;
  height: 16px;

  > input:checked + span {
    ${({ light, theme }) =>
      !light && `background-color: ${theme.colors.tone.black}`}
  }

  > input:checked + span:before {
    transform: translateX(12px);
    ${({ light, theme }) =>
      light && `background-color: ${theme.colors.tone.black}`}
  }

  > input:disabled + span {
    cursor: default;
  }
`;

const Input = styled.input`
  opacity: 0;
  width: 0;
  margin: 0;
`;

const Slider = styled.span<{ light?: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ light, theme }) =>
    light ? theme.colors.tone.sand : theme.colors.tone.silver};
  transition: 0.2s;
  border-radius: 8px;
  :before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 2px;
    background-color: ${({ light, theme }) =>
      light ? theme.colors.tone.silver : theme.colors.tone.white};
    border-radius: 50%;
    transition: 0.2s;
  }
`;

export { Wrapper, Input, Slider };
