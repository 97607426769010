import { Locale } from "lib/Intl";
import currencyMap from "./currencyMap";

export const getCurrencySymbol = (locale: Locale): string => {
  const currency = currencyMap[locale] || "EUR";
  return (0)
    .toLocaleString(locale, {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, "")
    .trim();
};
