import { ApolloError } from "@apollo/client";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { CatalogCategoryWithEnglishName } from "src/types/graphql.d";

interface CategoriesData {
  categories: CatalogCategoryWithEnglishName[];
}

interface CategoriesVars {
  shop_type: string;
  category_ids: string[];
}

export const CATEGORIES_QUERY = gql`
  query Categories($shop_type: String, $category_ids: [String]) {
    categories(filter: { shop_type: $shop_type, category_ids: $category_ids }) {
      id
      name
      slug
      parent
    }
  }
`;

const useCategories: (
  genderId: string,
  categoryId?: string
) => {
  data?: CategoriesData;
  loading: boolean;
  error?: ApolloError;
} = (genderId: string, categoryId?: string) => {
  const { loading, error, data } = useQuery<CategoriesData, CategoriesVars>(
    CATEGORIES_QUERY,
    {
      variables: {
        shop_type: genderId,
        category_ids: categoryId ? [categoryId] : [],
      },
      skip: !genderId || !categoryId,
      fetchPolicy: "cache-first",
    }
  );
  return { loading, error, data };
};

export { useCategories };
