import { FC, useContext, useEffect } from "react";
import { useUserPreferences } from "src/hooks/useUserPreferences";
import { AppCtx } from "src/contexts/app.context";

const UserPreferences: FC = () => {
  const { data } = useUserPreferences();
  const { setUserPreferences } = useContext(AppCtx);

  useEffect(() => {
    setUserPreferences(data?.userPreferences || data?.userPreferencesByEmail);
  }, [setUserPreferences, data]);

  return <></>;
};

export default UserPreferences;
