import { FC, useContext } from "react";
import { useSelector } from "react-redux";
import Link from "next/link";
import { getLocaleGenderId, getLocaleGenderIds } from "src/modules/page";
import {
  GenderButton,
  GenderButtons,
  Wrapper,
} from "./MobileToggleGender.styled";
import { getI18nRoute } from "src/utils/i18nRoutes";
import { AppCtx } from "src/contexts/app.context";
import { shopTypeTranslation } from "src/translations/shopTypeTranslation";
import { useLingui } from "@lingui/react";

interface Props {
  animationTime?: number;
  onClose?: boolean;
}

const MobileToggleGender: FC<Props> = ({ animationTime, onClose }) => {
  const { locale } = useContext(AppCtx);
  const localeGenderId = useSelector(getLocaleGenderId);
  const { _ } = useLingui();
  const { women, men, kids } = useSelector(getLocaleGenderIds);
  return (
    <Wrapper
      p={3}
      bg="White"
      display={["flex", "flex", "flex", "none"]}
      duration={animationTime}
      shouldBeHidden={onClose}
    >
      <GenderButtons>
        {[women, men, kids].map((genderIdValue) => (
          <Link
            key={`gender-button-${genderIdValue}`}
            href={getI18nRoute({
              route: `/${genderIdValue}`,
              locale,
            })}
            passHref
            legacyBehavior
          >
            <GenderButton active={genderIdValue === localeGenderId}>
              {_(shopTypeTranslation[genderIdValue])}
            </GenderButton>
          </Link>
        ))}
      </GenderButtons>
    </Wrapper>
  );
};

export { MobileToggleGender };
