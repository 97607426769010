import styled from "@emotion/styled";
import { Box, Flex } from "@otrium/core";
import { Text } from "@otrium/atoms";
import BaseButton from "src/atoms/Button/BaseButton";

const StyledA = styled(BaseButton)`
  background: none;
  border: 0;
  color: #8c8c8c;
  text-transform: none;
  font-size: 14px;
  font-weight: 600;
  margin-top: 35px;
  cursor: pointer;
`;

const StyledH2 = styled.h2`
  color: #101010;
  font-weight: bold;
  font-size: 24px;
  padding-top: 25px;
  letter-spacing: 2px;
  line-height: 1.38;
  margin-bottom: 0;
  text-align: center;
  text-transform: uppercase;
`;

const StyledModalWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    &:not(.Modal_withHeader) .Modal__container {
      top: 0px;
      position: absolute;
      width: 100%;
    }
  }
`;

const ImageWrapper = styled(Box)`
  width: 100%;

  .desktop,
  .mobile {
    object-fit: cover;
  }

  .desktop {
    display: none;
    height: 300px;
    min-width: 600px;
  }

  .mobile {
    display: block;
    aspect-ratio: 328/ 164;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }
  }
`;

const LoadingWrapper = styled(Flex)`
  position: relative;
  height: 100px;
  width: 100px;
  background-color: ${({ theme }) => theme.colors.tone.white};
`;

const Terms = styled(Box)`
  text-align: center;
  a {
    text-decoration: underline;
    color: inherit;
  }
`;

const Image = styled("img")`
  width: 100%;
  height: auto;
`;

const StyledButtonText = styled(Text)`
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
`;

export {
  StyledModalWrapper,
  StyledA,
  StyledH2,
  LoadingWrapper,
  Terms,
  ImageWrapper,
  Image,
  StyledButtonText,
};
