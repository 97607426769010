import { useQuery } from "@apollo/react-hooks";
import { ApolloError } from "@apollo/client";
import gql from "graphql-tag";
import { Miscellaneous } from "src/types/graphql.d";

const MISCELLANEOUS = gql`
  query miscellaneous {
    miscellaneous {
      header
      footer
      pdp {
        delivery
        returns
        shipping
        otriumOffers
        paymentAndShipping {
          title
          copy
        }
      }
    }
  }
`;

interface MiscellaneousData {
  miscellaneous: Miscellaneous;
}

interface useMiscellaneousProps {
  data: MiscellaneousData | undefined;
  loading: boolean;
  error: ApolloError | undefined;
}

const useMiscellaneous = (): useMiscellaneousProps => {
  const { data, loading, error } = useQuery<MiscellaneousData>(MISCELLANEOUS, {
    ssr: true,
    fetchPolicy: "cache-first",
  });

  return { data, loading, error };
};

export { useMiscellaneous };
