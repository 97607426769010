import { I18n, i18n, Messages } from "@lingui/core";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { LokaliseLocales } from "lib/Intl";

export async function loadCatalog(locale: LokaliseLocales): Promise<Messages> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { messages } = await import(`/locales/${locale}/messages.po`);
  return messages as Messages;
}

const useLinguiInit = (messages: Messages): I18n => {
  const router = useRouter();
  const locale = router?.locale || router?.defaultLocale || "en";
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (isClient && !i18n.locale) {
      // first client render
      i18n.load(locale, messages);
      i18n.activate(locale);
    }
  }, [isClient, locale, messages]);

  return i18n;
};

export { useLinguiInit };
