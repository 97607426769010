import { useEffect, useState } from "react";
import Cookies from "js-cookie";

function useSmartBanner(): [boolean, () => void] {
  const [showSmartBanner, setShowSmartBanner] = useState(false);

  useEffect(() => {
    const cookie = Cookies.get("otrium_cookie-hide-smart-banner");

    if (cookie && cookie.toLowerCase() === "true") {
      setShowSmartBanner(false);
    } else {
      setShowSmartBanner(true);
    }
  }, []);

  const handleHideSmartBanner = () => {
    Cookies.set("otrium_cookie-hide-smart-banner", "true");
    setShowSmartBanner(false);
  };

  return [showSmartBanner, handleHideSmartBanner];
}

export { useSmartBanner };
