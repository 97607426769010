import { FC, PropsWithChildren, ReactNode } from "react";
import { Flex } from "@otrium/core";
import { ArrowLeftIcon } from "src/icons/ArrowLeftIcon";
import { CloseIconBig } from "src/icons/CloseIconBig";
import {
  HeaderWrapper,
  IconButton,
  StyledCloseButton,
  Title,
} from "./DrawerContent.styled";

interface DrawerContentProps {
  title: string | ReactNode;
  goBack?: () => void;
  onClose?: () => void;
}

const DrawerContent: FC<PropsWithChildren<DrawerContentProps>> = ({
  title,
  goBack,
  onClose,
  children,
}) => {
  return (
    <Flex minWidth={"100%"} flexDirection="column">
      <HeaderWrapper>
        {goBack && (
          <IconButton onClick={goBack}>
            <ArrowLeftIcon />
          </IconButton>
        )}
        <Title as="h1">{title}</Title>
        {onClose && (
          <StyledCloseButton onClick={onClose} icon={<CloseIconBig />} />
        )}
      </HeaderWrapper>
      {children}
    </Flex>
  );
};

export default DrawerContent;
