import { Trans } from "@lingui/macro";
import { FC, useState } from "react";
import { Box } from "@otrium/core";
import Link from "next/link";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import { signOut } from "src/utils/nextAuth";
import { Button } from "src/atoms/Button";
import { ChevronRight } from "src/icons/ChevronRight";
import { StyledUserMenu } from "./UserMenu.styled";
import { Text } from "@otrium/atoms";

interface Props {
  onToggle?: () => void;
}

const UserMenu: FC<Props> = ({ onToggle }) => {
  const isLoggedIn = useIsLoggedIn();
  const [showSignOutButtonUntilRedirect, setShowSignOutButtonUntilRedirect] =
    useState<boolean | undefined>(undefined);

  return (
    <StyledUserMenu>
      <Link
        href="/my-orders"
        passHref
        className="UserMenu__link"
        legacyBehavior
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="UserMenu__link">
          {" "}
          <Trans>My orders</Trans> <ChevronRight />
        </a>
      </Link>
      <Link href="/my-profile" passHref legacyBehavior>
        <Box
          data-testid="user-menu-my-profile"
          onClick={() => (onToggle ? onToggle() : () => {})}
          as="a"
          className="UserMenu__link"
        >
          <Trans>My Profile</Trans> <ChevronRight />
        </Box>
      </Link>

      <div className="UserMenu__actions">
        {isLoggedIn || showSignOutButtonUntilRedirect ? (
          <Button
            data-testid="user-menu-logout-button"
            variant="secondary"
            fullWidth
            loading={showSignOutButtonUntilRedirect}
            onClick={() => {
              setShowSignOutButtonUntilRedirect(true);
              void signOut();
            }}
          >
            <Trans>Logout</Trans>
          </Button>
        ) : (
          <>
            <Text textTransform="uppercase">
              <Trans>Get Access</Trans>
            </Text>
            <Button as="a" href={"/sign-in/"} variant="secondary" fullWidth>
              <Trans>Login</Trans>
            </Button>
            <Button as="a" href={"/register/"} variant="secondary" fullWidth>
              <Trans>Create an account</Trans>
            </Button>
          </>
        )}
      </div>
    </StyledUserMenu>
  );
};

export { UserMenu };
