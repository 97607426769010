import useAlgoliaInitialiseIndex from "src/hooks/algolia/useAlgoliaInitialiseIndex";
import useAlgoliaSearchIndex from "src/hooks/algolia/useAlgoliaSearchIndex";
import {
  LOGGED_IN_USER,
  NON_LOGGED_IN_USER,
} from "src/utils/algolia/analyticsTags";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import { ShopType } from "src/types/graphql.d";
import {
  AlgoliaSearchProduct,
  ContextKeySearch,
  SearchResults,
} from "src/types/Algolia.d";
import { useMemo } from "react";
import { SearchOptions } from "@algolia/client-search";
import {
  DEFAULT_ANALYTICS_TAGS,
  DEFAULT_RULE_CONTEXTS,
} from "src/utils/algolia/defaultSettings";
import { useSession } from "next-auth/client";
import getFilters from "src/utils/algolia/getFilters";
import getSearchResults from "src/utils/algolia/getSearchResults";

interface GetAlgoliaRecommendedProducts {
  data: SearchResults<AlgoliaSearchProduct> | undefined;
  loading: boolean;
  error: unknown;
}

const useGetAlgoliaRecommendedProducts = (
  shopType: ShopType
): GetAlgoliaRecommendedProducts => {
  const isLoggedIn = useIsLoggedIn();
  const [session] = useSession();
  const userToken = useMemo(
    () =>
      session?.user.member_id ? { userToken: session.user.member_id } : {},
    [session?.user.member_id]
  );
  const filters = getFilters({
    gender: shopType,
  });
  const productsIndex = useAlgoliaInitialiseIndex({
    indexName: "products",
  });
  const searchParameters = useMemo(
    (): SearchOptions => ({
      query: "",
      hitsPerPage: 6,
      filters,
      ruleContexts: [
        ...DEFAULT_RULE_CONTEXTS,
        ContextKeySearch.recommendedProducts,
      ],
      analyticsTags: [
        ...DEFAULT_ANALYTICS_TAGS,
        shopType,
        isLoggedIn ? LOGGED_IN_USER : NON_LOGGED_IN_USER,
      ],
      clickAnalytics: true,
      ...userToken,
    }),
    [filters, isLoggedIn, shopType, userToken]
  );

  const {
    data: responseData,
    loading,
    error,
  } = useAlgoliaSearchIndex<AlgoliaSearchProduct>({
    index: productsIndex,
    searchParameters,
  });

  return {
    data: responseData ? getSearchResults(responseData) : undefined,
    loading,
    error,
  };
};

export default useGetAlgoliaRecommendedProducts;
