import { RawCustomField, RawProductAttribute } from "src/types/ctgraphql.d";

export const getCTAttribute = (
  name: string,
  attributesRaw?: RawProductAttribute[]
): unknown =>
  attributesRaw &&
  (attributesRaw.find((f) => f.name === name)?.value as unknown);

export const getCTCustomField = (
  name: string,
  customFieldsRaw: RawCustomField[] | undefined | null
): unknown =>
  customFieldsRaw &&
  (customFieldsRaw?.find((f) => f.name === name)?.value as unknown);
