import { MultipleQueriesResponse } from "@algolia/client-search";
import { SearchResults } from "src/types/Algolia.d";

export type MultipleQueriesSearchResults<T> = Record<string, SearchResults<T>>;

const getMultipleQueriesSearchResults = <T>(
  searchResponse: MultipleQueriesResponse<T>
): MultipleQueriesSearchResults<T> => {
  return searchResponse.results.reduce(
    (previousValue: MultipleQueriesSearchResults<T>, currentValue) => {
      const {
        index,
        hits,
        nbHits,
        page,
        nbPages,
        queryID,
        facets,
        facets_stats,
      } = currentValue;
      if (index) {
        previousValue[index] = {
          results: hits,
          meta: {
            nbHits,
            page: page + 1,
            nbPages,
            queryID,
          },
          facets: {
            facets,
            facetStats: facets_stats,
          },
        };
      }
      return previousValue;
    },
    {}
  );
};

export default getMultipleQueriesSearchResults;
