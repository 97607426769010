import { Text, Heading } from "@otrium/atoms";
import { Box } from "@otrium/core";
import { Trans } from "@lingui/macro";
import { Overlay } from "src/atoms/Overlay";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  StyledButton,
  StyledCloseButton,
  Wrapper,
} from "./SubscribeOverlay.styled";
import { Content } from "src/atoms/Content";
import SubscribeModal from "./SubscribeModal";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import { useSession } from "next-auth/client";
import Cookies from "js-cookie";
import { AppCtx } from "src/contexts/app.context";
import { AuthProvider } from "pages/api/auth/getUserTokenFromApi";
import { CONSENT_NOT_REQUIRED_LOCALES } from "src/molecules/ConsentMangerBanner/utils";
import { useSegment } from "src/hooks/useSegment";

const OPEN_TIME = 2000;
const COOKIE_NAME = "otrium_cookie-overlay_opt_in";
export const OPTED_IN_COOKIE_NAME = "otrium_cookie-guest-opted-in";

const SubscribeOverlay = (): JSX.Element => {
  const bannerViewed = useRef(false);
  const { displayConsentManager, locale } = useContext(AppCtx);
  const isLoggedIn = useIsLoggedIn();
  const [session] = useSession();
  const isUserOptIn = session?.user.opt_in;
  const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const cookiesOverlayOptIn = Cookies.get(COOKIE_NAME);
  const timerId = useRef<number>();
  const [submitted, setSubmitted] = useState(false);
  const storeRequiresConsent = !CONSENT_NOT_REQUIRED_LOCALES.includes(locale);
  const isConsentManagerShown = displayConsentManager && storeRequiresConsent;

  const {
    segmentGuestOptInBannerViewed,
    segmentGuestOptInBannerDismissed,
    segmentGuestOptInBannerCtaClick,
    segmentGuestOptInPopupDismissed,
  } = useSegment();

  useEffect(() => {
    if (cookiesOverlayOptIn === undefined) {
      Cookies.set(COOKIE_NAME, "1");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shouldShowOverlay = useMemo(
    () =>
      !isLoggedIn &&
      !(
        session?.user?.signInPlatform === AuthProvider.CREDENTIALS_SOFT_LOGIN
      ) &&
      !isModalOpen &&
      !isUserOptIn &&
      !isOverlayOpen &&
      cookiesOverlayOptIn === "1" &&
      !isConsentManagerShown &&
      !displayConsentManager,
    [
      cookiesOverlayOptIn,
      isLoggedIn,
      isModalOpen,
      isOverlayOpen,
      isUserOptIn,
      isConsentManagerShown,
      displayConsentManager,
      session?.user.signInPlatform,
    ]
  );

  useEffect(() => {
    if (shouldShowOverlay && !bannerViewed.current) {
      bannerViewed.current = true;
      void segmentGuestOptInBannerViewed();
    }
  }, [shouldShowOverlay]);

  const handleClose = useCallback((closeModal = false) => {
    setIsOverlayOpen(false);
    Cookies.set(COOKIE_NAME, "0");
    if (closeModal) {
      setIsModalOpen(false);
    }
  }, []);

  const handleFormSubmit = useCallback(() => {
    Cookies.set(COOKIE_NAME, "0");
    Cookies.set(OPTED_IN_COOKIE_NAME, "1");
    setSubmitted(true);
  }, []);

  useEffect(() => {
    clearTimeout(timerId.current);
    if (shouldShowOverlay) {
      timerId.current = window.setTimeout(() => {
        setIsOverlayOpen(true);
      }, OPEN_TIME);
    }
  }, [shouldShowOverlay]);

  return (
    <>
      <Overlay isOpen={isOverlayOpen} />
      <Wrapper open={isOverlayOpen}>
        <Content sx={{ position: "relative" }}>
          <StyledCloseButton
            onClick={() => {
              handleClose();
              void segmentGuestOptInBannerDismissed();
            }}
            iconSize={16}
          />
          <Box>
            <Heading
              as="h3"
              variant="h3"
              mb={2}
              fontSize="24px"
              fontFamily="CharisSILW"
              letterSpacing="normal"
              lineHeight="32px"
              pb={0}
              sx={{
                textTransform: "none",
                textAlign: ["left", "left", "center"],
              }}
            >
              <Trans>Get 15% off your first order</Trans>
            </Heading>
            <Text
              variant="body.source.regular16"
              as="p"
              mb={3}
              sx={{
                textAlign: ["left", "left", "center"],
              }}
            >
              <Trans>
                Over 400 brands, new daily fashion deals, and prices you’ll
                love!
              </Trans>
            </Text>
            <StyledButton
              variant="primary"
              onClick={() => {
                setIsModalOpen(true);
                setIsOverlayOpen(false);
                void segmentGuestOptInBannerCtaClick();
              }}
            >
              <Trans>Subscribe now</Trans>
            </StyledButton>
          </Box>
        </Content>
      </Wrapper>
      <SubscribeModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          void segmentGuestOptInPopupDismissed();
        }}
        onFormSubmit={handleFormSubmit}
        submitted={submitted}
      />
    </>
  );
};

export default SubscribeOverlay;
