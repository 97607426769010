import styled from "@emotion/styled";
import { Badge } from "src/atoms/Badge";
import { Button } from "src/atoms/Button";

const StyledNavigation = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.tone.white};

  .navigation__userMenu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 300px;
  }
`;

const StyledNotificationBadge = styled(Badge)`
  position: absolute;
  top: ${({ theme }) => theme.space.space16};
  right: 40px;
  padding: 2px;
`;

const StyledCartCount = styled(Badge)`
  position: absolute;
  top: -3px;
  left: 12px;
  height: 16px;
  width: fit-content;
  min-width: 16px;
`;

const StyledNavigationButton = styled(Button)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: none !important;
  border: none !important;
  outline: none !important;
  cursor: pointer;
  height: auto;
  padding: ${({ theme }) => theme.space.space0};
  &:hover {
    background: none;
    border: none;
  }
`;

const StyledLoginButton = styled(Button)`
  width: fit-content;
  height: 40px;
  font-size: ${({ theme }) => theme.fontSizes.fontSize16};
  color: ${({ theme }) => theme.colors.tone.anthraciteDark};
  font-weight: 600;
  padding-top: 6px;
  padding-bottom: 6px;

  :hover {
    box-shadow: none;
    background-color: ${({ theme }) => theme.effects.overlay.black_10};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: none;
  }
`;

const StyledLogo = styled.div`
  align-self: center;
`;

export {
  StyledNavigation,
  StyledNotificationBadge,
  StyledCartCount,
  StyledNavigationButton,
  StyledLoginButton,
  StyledLogo,
};
