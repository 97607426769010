import { Box, Flex } from "@otrium/core";
import styled from "@emotion/styled";
import { Button } from "src/atoms/Button";

const Wrapper = styled(Box)`
  width: 100%;
  background: #1d1e20;
  margin-left: -1px;
  padding: 32px 0 0;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding: 24px 32px 0;
  }
`;

const OptInRow = styled(Flex)`
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: baseline;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex-direction: row;
  }
`;

const OptInBox = styled(Box)`
  flex-basis: 100%;
  width: 100%;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex-basis: 50%;
    width: auto;
  }
`;

const InputWrapper = styled(Box)`
  margin-right: 0;
  flex-grow: 1;
  width: 100%;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin-right: ${({ theme }) => theme.space[3]}px;
    width: auto;
  }
`;

const OptInButton = styled(Button)`
  width: 100%;
  max-width: none;
  min-width: fit-content;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: auto;
  }
`;

export { Wrapper, OptInRow, OptInBox, InputWrapper, OptInButton };
