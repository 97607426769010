import { FC, useContext } from "react";
import { AppCtx } from "src/contexts/app.context";
import currencyMap from "src/utils/currencyMap";

interface Props {
  price: number;
}

const Price: FC<Props> = ({ price }) => {
  const { locale } = useContext(AppCtx);
  const currency = currencyMap[locale] || "EUR";

  const minimumFractionDigits = (price * 10) % 10 > 0 ? 2 : 0;
  const value = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    minimumFractionDigits,
  }).format(price);

  return <span>{value}</span>;
};

export default Price;
