import { EnhancedStore } from "@reduxjs/toolkit";
import { ActionCreator, AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { rootReducer } from "./rootReducer";

export * from "./store";

export type State = ReturnType<typeof rootReducer>;
export type OtriumStore = EnhancedStore<State>;
export type ThunkActionCreator<A extends AnyAction, R = any> = ActionCreator<
  ThunkAction<R, State, {}, A> // eslint-disable-line @typescript-eslint/ban-types
>;
