import { Trans } from "@lingui/macro";
import { useTheme, Theme } from "@emotion/react";
import { useSession } from "next-auth/client";
import { FC } from "react";
import { ShoppingCart } from "src/icons";
import { Button, ButtonProps } from "src/atoms/Button";
import { AriaButtonProps } from "react-aria";
import styled from "@emotion/styled";

type ProductOrderCartButtonProps = ButtonProps &
  AriaButtonProps & {
    isOutOfStock: boolean;
    showLeftIcon?: boolean;
    "data-testid"?: string;
  };

const StyledButton = styled(Button)`
  max-width: none !important;
  flex: 1 1 auto;
`;

const ProductOrderCartButton: FC<ProductOrderCartButtonProps> = (
  props: ProductOrderCartButtonProps
) => {
  const theme: Theme = useTheme();
  const [session] = useSession();
  const {
    isOutOfStock,
    variant,
    onClick,
    showLeftIcon = false,
    fullWidth,
    "data-testid": dataTestId = "product-order-button",
  } = props;

  return (
    <StyledButton
      variant={variant}
      data-testid={dataTestId}
      onClick={onClick}
      loading={!session?.user}
      disabled={!session?.user || isOutOfStock || props.isDisabled}
      fullWidth={fullWidth}
      leftIcon={
        showLeftIcon && (
          <ShoppingCart
            className="ProductOrder__cartIcon"
            width={26}
            height={26}
            color={theme.colors.tone.white}
          />
        )
      }
    >
      {!isOutOfStock ? <Trans>Add to cart</Trans> : <Trans>Out of stock</Trans>}
    </StyledButton>
  );
};

export default ProductOrderCartButton;
