import { FC, ReactNode } from "react";
import { Wrapper, Error, StyledInput, Label, IconBox } from "./Input.styled";
import { FormInputProps } from "@otrium/atoms";
import NoSSR from "../NoSSR";

interface Props {
  isError?: boolean;
  errorMessage?: string | ReactNode;
  rightIcon?: ReactNode;
  label?: string;
  fullWidth?: boolean;
  inputTestId?: string;
}

const Input: FC<Props & FormInputProps> = ({
  isError,
  errorMessage,
  label,
  fullWidth = false,
  rightIcon,
  inputTestId = "input",
  ...rest
}) => {
  return (
    <Wrapper
      hasValue={!!(rightIcon && rest.value?.toString().length)}
      disabled={rest.disabled}
      fullWidth={fullWidth}
      data-testid="input-wrapper"
    >
      {label && (
        <Label as="label" px={2} data-testid="input-label">
          {label}
        </Label>
      )}
      <NoSSR>
        {/* @ts-expect-error Fix types issue in the DS */}
        <StyledInput
          error={isError || !!errorMessage}
          rightIcon={!!rightIcon}
          {...rest}
          data-testid={inputTestId}
        />
      </NoSSR>
      {rightIcon && <IconBox data-testid="input-icon-box">{rightIcon}</IconBox>}
      {errorMessage && <Error data-testid="input-error">{errorMessage}</Error>}
    </Wrapper>
  );
};

export default Input;
