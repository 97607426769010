import { IsoLocale } from "lib/Intl";

export const languages: { [language in IsoLocale]: string } = {
  nl_NL: "nl",
  fr_FR: "fr",
  de_DE: "de",
  en_EU: "en",
  en_GB: "en-gb",
  es_ES: "es-es",
  it_IT: "it-it",
  en_DK: "en-dk",
  en_SE: "en-se",
  pl_PL: "pl-pl",
  de_AT: "de-at",
  fr_BE: "fr-be",
  nl_BE: "nl-be",
  en_US: "en-us",
};

type Code = "EUR" | "GBP" | "DKK" | "SEK" | "PLN" | "USD";
type CodeBody = {
  CODE: string;
  SYMBOL: string;
  SHORT_NAME: string;
};
type Currency = {
  [key in Code]: CodeBody;
};
const currencySymbols: Currency = {
  EUR: {
    CODE: "EUR",
    SYMBOL: "€",
    SHORT_NAME: "EUR &euro;",
  },
  GBP: {
    CODE: "GBP",
    SYMBOL: "£",
    SHORT_NAME: "GBP &#xa3;",
  },
  DKK: {
    CODE: "DKK",
    SYMBOL: "kr",
    SHORT_NAME: "DKK kr",
  },
  SEK: {
    CODE: "SEK",
    SYMBOL: "kr",
    SHORT_NAME: "SEK kr",
  },
  PLN: {
    CODE: "PLN",
    SYMBOL: "zł",
    SHORT_NAME: "PLN zł",
  },
  USD: {
    CODE: "USD",
    SYMBOL: "$",
    SHORT_NAME: "USD $",
  },
};
export const DEFAULT_BASE_CURRENCY = currencySymbols.EUR.CODE;
export const DEFAULT_BASE_SYMBOL = currencySymbols.EUR.SYMBOL;
export const POSITION_AFTER = "after";
export const POSITION_BEFORE = "before";
export const currencyMap = {
  [languages.nl_NL]: {
    code: DEFAULT_BASE_CURRENCY,
    symbol: DEFAULT_BASE_SYMBOL,
    position: POSITION_BEFORE,
  },
  [languages.en_EU]: {
    code: DEFAULT_BASE_CURRENCY,
    symbol: DEFAULT_BASE_SYMBOL,
    position: POSITION_BEFORE,
  },
  [languages.de_DE]: {
    code: DEFAULT_BASE_CURRENCY,
    symbol: DEFAULT_BASE_SYMBOL,
    position: POSITION_AFTER,
  },
  [languages.fr_FR]: {
    code: DEFAULT_BASE_CURRENCY,
    symbol: DEFAULT_BASE_SYMBOL,
    position: POSITION_AFTER,
  },
  [languages.en_GB]: {
    code: currencySymbols.GBP.CODE,
    symbol: currencySymbols.GBP.SYMBOL,
    position: POSITION_BEFORE,
  },
  [languages.es_ES]: {
    code: currencySymbols.EUR.CODE,
    symbol: currencySymbols.EUR.SYMBOL,
    position: POSITION_AFTER,
  },
  [languages.it_IT]: {
    code: currencySymbols.EUR.CODE,
    symbol: currencySymbols.EUR.SYMBOL,
    position: POSITION_AFTER,
  },
  [languages.en_DK]: {
    code: currencySymbols.DKK.CODE,
    symbol: currencySymbols.DKK.SYMBOL,
    position: POSITION_AFTER,
  },
  [languages.en_SE]: {
    code: currencySymbols.SEK.CODE,
    symbol: currencySymbols.SEK.SYMBOL,
    position: POSITION_AFTER,
  },
  [languages.pl_PL]: {
    code: currencySymbols.PLN.CODE,
    symbol: currencySymbols.PLN.SYMBOL,
    position: POSITION_AFTER,
  },
  [languages.en_US]: {
    code: currencySymbols.USD.CODE,
    symbol: currencySymbols.USD.SYMBOL,
    position: POSITION_BEFORE,
  },
  [languages.de_AT]: {
    code: DEFAULT_BASE_CURRENCY,
    symbol: DEFAULT_BASE_SYMBOL,
    position: POSITION_BEFORE,
  },
  [languages.fr_BE]: {
    code: DEFAULT_BASE_CURRENCY,
    symbol: DEFAULT_BASE_SYMBOL,
    position: POSITION_BEFORE,
  },
  [languages.nl_BE]: {
    code: DEFAULT_BASE_CURRENCY,
    symbol: DEFAULT_BASE_SYMBOL,
    position: POSITION_BEFORE,
  },
};

export default currencyMap;
