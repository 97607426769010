import styled from "@emotion/styled";
import { keyframes } from "@emotion/css";
import { Heading } from "@otrium/atoms";
import { Button } from "src/atoms/Button";

const animation = keyframes`
  0%, 30% {
    transform: translate(-50%, -100px);
    opacity: 0;
  }

  100% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
`;

const Title = styled(Heading)`
  line-height: 32px;
  color: ${({ theme }) => theme.colors.tone.black};
  font-size: ${({ theme }) => theme.fontSizes[3]}px;
  font-family: CharisSILW;
  overflow-wrap: break-word;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    line-height: 40px;
    font-size: ${({ theme }) => theme.fontSizes[4]}px;
  }
`;

const List = styled.div<{ scrolled: boolean }>`
  display: grid;
  overflow-x: auto;
  grid-template-columns: repeat(6, 1fr);
  margin-top: ${({ theme }) => theme.space[4]}px;
  grid-gap: 24px;
  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    grid-template-columns: repeat(6, 35%);
    grid-gap: 0;
    margin-right: -${({ theme }) => theme.space[3]}px;
    margin-left: -${({ theme }) => theme.space[3]}px;
  }

  ${({ scrolled, theme }) =>
    !scrolled &&
    `
    overflow-x: hidden;
  
    @media (max-width: ${theme.breakpoints[2]}) {
      grid-gap: 16px;
      grid-template-columns: repeat(3, 1fr);
      margin-left: ${theme.space[0]}px;
      margin-right: ${theme.space[0]}px;
    }
    @media (max-width: ${theme.breakpoints[1]}) {
      grid-template-columns: repeat(2, 1fr);
    }
  `}

  ::-webkit-scrollbar {
    display: none;
  }
`;

const AnimatedBox = styled.div<{ duration: number }>`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  animation: ${animation} ${({ duration }) => duration * 2}ms
    cubic-bezier(0, 0.32, 0, 1);
`;

const ViewAllButton = styled(Button)`
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
`;

export { Title, List, AnimatedBox, ViewAllButton };
