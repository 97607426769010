import styled from "@emotion/styled";
import { Heading, Text } from "@otrium/atoms";
import FlagRound from "src/atoms/FlagRound";
import { ChevronRight } from "src/icons/ChevronRight";
import { Expandable as ExpandableAtom } from "src/atoms/Expandable";
import { Menu as MenuAtom } from "src/atoms/Menu";
import BaseButton from "src/atoms/Button/BaseButton";

interface Props {
  isFooter?: boolean;
}

const MenuWrapper = styled.div<Props>`
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    ${({ isFooter, theme }) =>
      isFooter &&
      `
        width: 100%;
        padding-bottom: 23px;
        border-bottom: 1px solid ${theme.colors.tone.anthraciteDark};
        margin-bottom: 23px;
      `}
  }
`;

const Menu = styled(MenuAtom)`
  display: none;
  position: relative;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: block;
  }
`;

const MenuMobile = styled(MenuAtom)`
  display: block;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: none;
  }
`;

const Button = styled(BaseButton)<Props>`
  display: flex;
  align-items: center;
  border: none;
  background: none;
  color: ${({ theme }) => theme.colors.tone.white};
  margin-right: -5px;
  cursor: pointer;
  @media (max-width: 767px) {
    width: ${({ isFooter }) => (isFooter ? "100%" : "inherit")};
  }
`;

interface DropdownProps {
  isFooter: boolean;
  open?: boolean;
}

const Dropdown = styled(ChevronRight)<DropdownProps>`
  margin-left: auto;
  width: 16px;
  transform: ${({ isFooter }) => (isFooter ? "rotate(0deg)" : "rotate(90deg)")};
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    transform: rotate(90deg);
    ${({ open, theme }) =>
      open &&
      `
      transform: rotate(-90deg);
      fill: ${theme.colors.tone.white};
    `}
  }
  fill: ${({ theme }) => theme.colors.tone.anthraciteLight};
  transition: all 0.2s ease;
`;

const Flag = styled(FlagRound)`
  width: 20px;
  height: 20px;
`;

const ListTitle = styled(Heading)`
  font-size: 40px;
  line-height: 1.2;
  font-weight: bold;
  text-align: center;
  color: ${({ theme }) => theme.colors.tone.black};
  margin-bottom: 40px;
`;

const MenuListContent = styled.div`
  background: ${({ theme }) => theme.colors.tone.white};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
`;

const Expandable = styled(ExpandableAtom)<{ isMask: boolean }>`
  z-index: 1110;
  position: absolute;
  width: 320px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  margin-top: 23px;
  @media (max-width: 767px) {
    left: 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    right: -6px;
  }

  ${({ isMask }) =>
    isMask &&
    `
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 56px;
    border-radius: 8px;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      #ffffff
    );
  }
  `}
`;

const LocaleName = styled(Text)<Props>`
  display: ${({ isFooter }) => (isFooter ? "block" : "none")};
  font-size: ${({ theme }) => theme.fontSizes[0]}px;
  line-height: 1.43;
  font-weight: 600;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.colors.tone.anthraciteLight};
  margin-left: ${({ theme }) => theme.space[1]}px;
`;

const MobileListWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.overlay};
  background-color: ${({ theme }) => theme.colors.tone.white};
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export {
  MenuWrapper,
  Menu,
  Button,
  Flag,
  Dropdown,
  MenuMobile,
  ListTitle,
  MenuListContent,
  Expandable,
  LocaleName,
  MobileListWrapper,
};
