import { EppoFeatureFlagsConfig } from "src/types/featureFlags";

export const featureFlagsConfig: EppoFeatureFlagsConfig = {
  enableWishListCarousel: {
    flagKey: "wishlist-carousel",
    defaultValue: false,
    options: {
      is_wishlist_user: "",
    },
  },
  enableWishListCarouselOnCart: {
    flagKey: "wishlist-carousel-on-cart",
    defaultValue: false,
  },
  enableAugmentedSearch: {
    flagKey: "augmented-search-multivariate",
    defaultValue: "original",
    options: {
      locale: "",
    },
  },
  pdpCarouselRecommender: {
    flagKey: "pdp-carousel-you-may-also-like-recommender",
    defaultValue: "basic",
    options: {
      email: "",
    },
  },
  pdpTrendingCarouselRecommender: {
    flagKey: "web-pdp-carousel-trending-now-recommender",
    defaultValue: "basic",
    options: {
      email: "",
    },
  },
  homepageNewInCarouselRecommender: {
    flagKey: "web-carousel-homepage-new-in-recommender",
    defaultValue: "basic",
    options: {
      email: "",
    },
  },
  cartAlertBanner: {
    flagKey: "alert-banner-shopping-cart-web",
    defaultValue: { nl: "" },
    options: {
      email: "",
    },
  },
  cartYMALCarouselRecommender: {
    flagKey: "web-carousel-cart-ymal-recommender",
    defaultValue: "basic",
    options: {
      email: "",
    },
  },
  enableGatedHomepage: {
    flagKey: "gate-homepage-enabled",
    defaultValue: false,
    options: {
      locale: "",
    },
  },
  enableMolliePerStore: {
    flagKey: "web-psp-mollie-store-enabled",
    defaultValue: {},
  },
  enableSeoContent: {
    flagKey: "seo-content-enabled",
    defaultValue: false,
    options: {
      locale: "",
    },
  },
  enabledAccountDeletion: {
    flagKey: "web-enable-account-deletion",
    defaultValue: false,
    options: {
      locale: "",
    },
  },
  // Add more feature flags here with Eppo configurations
};

export const defaultFeatureFlags = {
  enableWishListCarousel: null,
  enableWishListCarouselOnCart: null,
  enableAugmentedSearch: null,
  pdpCarouselRecommender: "basic",
  pdpTrendingCarouselRecommender: "basic",
  homepageNewInCarouselRecommender: "basic",
  cartYMALCarouselRecommender: "basic",
  cartAlertBanner: { nl: "" },
  enableGatedHomepage: null,
  enableMolliePerStore: {},
  enableSeoContent: null,
  enabledAccountDeletion: false,
};
