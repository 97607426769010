import { Locale } from "lib/Intl";
import i18nRoutes from "./i18nRoutes";

interface i18RouteProps {
  route: string;
  locale: Locale;
}

interface Routes {
  [key: string]: {
    [key: string]: string | null;
  };
}

const getI18nRoute = ({ route, locale }: i18RouteProps): string => {
  if (process.env.NODE_ENV === "development") {
    if (typeof (i18nRoutes as Routes)[route] === "undefined") {
      // eslint-disable-next-line no-console
      console.warn(
        `i18nRoute() ${JSON.stringify(
          (i18nRoutes as Routes)[route]
        )} route missing : ${route}`
      );
    }
  }

  if ((i18nRoutes as Routes)?.[route]?.[locale])
    return (i18nRoutes as Routes)[route][locale] as string;
  return route;
};

export { getI18nRoute };
