import algoliasearch, { SearchClient } from "algoliasearch";
import useSearchConfiguration from "src/hooks/algolia/useSearchConfigurations";
import { useEffect, useState } from "react";
import { createInMemoryCache } from "@algolia/cache-in-memory";
import { createConsoleLogger } from "@algolia/logger-console";
import { LogLevelEnum } from "@algolia/logger-common";
import { createFetchRequester } from "@algolia/requester-fetch";
import { AlgoliaSearchOptions } from "algoliasearch/dist/algoliasearch";

const useAlgoliaCreateClient = (): SearchClient | undefined => {
  const [client, setClient] = useState<SearchClient | undefined>();
  const { data } = useSearchConfiguration();

  useEffect(() => {
    const options: AlgoliaSearchOptions = {
      // Caches responses from Algolia
      responsesCache: createInMemoryCache(),
      // Caches Promises with the same request payload
      requestsCache: createInMemoryCache({ serializable: false }),
      logger: createConsoleLogger(LogLevelEnum.Debug),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
      requester: createFetchRequester(),
    };

    const client = data?.searchConfigurationsWeb
      ? algoliasearch(
          data?.searchConfigurationsWeb.app_id,
          data?.searchConfigurationsWeb.search_api_key,
          options
        )
      : undefined;

    setClient(client);
  }, [data]);

  return client;
};

export default useAlgoliaCreateClient;
