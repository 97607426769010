import { ApolloError } from "@apollo/client";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { NavigationItems } from "src/types/graphql.d";

interface NavigationData {
  navigationItems: NavigationItems;
}

interface NavigationVars {
  shop_type: string;
}

export const NAVIGATION_ITEMS_QUERY = gql`
  query navigationItems($shop_type: String!) {
    navigationItems(shop_type: $shop_type) {
      navigation_links {
        link
        title
        title_en
        colour
        show_on_top
        section
      }
      top_level_categories {
        id
        name
        name_en
        parent
        colour
        slug
        section
      }
    }
  }
`;

const useNavigationItems: (genderId: string) => {
  data: NavigationData | undefined;
  loading: boolean;
  error: ApolloError | undefined;
} = (genderId: string) => {
  const { loading, error, data } = useQuery<NavigationData, NavigationVars>(
    NAVIGATION_ITEMS_QUERY,
    {
      variables: {
        shop_type: genderId,
      },
      fetchPolicy: "cache-first",
    }
  );
  return { loading, error, data };
};

export { useNavigationItems };
