import Link from "next/link";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { isFunction } from "lodash-es";
import { Text } from "@otrium/atoms";
import { Box } from "@otrium/core";
import { LinkProps } from "next/dist/client/link";
import {
  BrandGateLevelInCustomerPlatform,
  CatalogProduct,
  HomepageSingleCategoryProduct,
  ProductLabel,
  WishlistItemType,
} from "src/types/graphql.d";
import { ProductLabels } from "src/molecules/ProductLabels";
import { ProductPhoto } from "src/molecules/ProductPhoto";
import { formatName } from "src/utils/formatName";
import {
  StyledCardBrand,
  StyledCardPrices,
  StyledCardProduct,
  StyledCardRegularPrice,
  StyledCardSizes,
  StyledProductCard,
  Image,
  LoginButton,
} from "./ProductCard.styled";
import { Price } from "src/atoms/Price";
import { ToggleWishListButton } from "src/molecules/ToggleWishListButton";
import { useInView } from "react-intersection-observer";
import { SEGMENT_IN_VIEW_THRESHOLD } from "src/segment";
import { SegmentProductList } from "src/hooks/useSegmentProductList";
import { ProductOrderCartButton } from "src/molecules/ProductOrderCartButton";
import { useTheme, Theme } from "@emotion/react";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import { LockIcon } from "@otrium/icons";
import { useFeatureFlags } from "src/hooks/useFeatureFlags";

interface Props {
  centerText?: boolean;
  className?: string;
  getImageElementWrapper?: Dispatch<SetStateAction<HTMLDivElement | null>>;
  hideIconsLabel?: boolean;
  isAddedToWishList: boolean | undefined;
  onAddToWishList: (itemType: WishlistItemType, itemId: string) => void;
  onRemoveWishList: (itemType: WishlistItemType, itemId: string) => void;
  position: number;
  product: CatalogProduct | HomepageSingleCategoryProduct;
  labels?: ProductLabel[];
  segmentProductOnClick: SegmentProductList["segmentProductOnClick"];
  setVisibleProducts: SegmentProductList["setVisibleProducts"];
  showLabels?: boolean;
  showName?: boolean;
  showSizes?: boolean;
  timedToggleWishlist?: boolean;
  trackingDisabled?: boolean;
  wishlistProgress: boolean;
  zoomImageOnHover?: boolean;
  queryID?: string;
  algoliaIndex?: string;
  isOutOfStock?: boolean;
  allowAddToCart?: boolean;
  onAddToCart?: (productSlug: string) => void;
  onGateProductClick?: () => void;
  isLastTile?: boolean;
  noGateLevel?: boolean;
}

interface LinkWrapperProps extends PropsWithChildren<LinkProps> {
  outOfStock: boolean;
  isGated: boolean;
}

const LinkWrapper = ({
  outOfStock,
  children,
  isGated,
  ...linkProps
}: LinkWrapperProps) => {
  return outOfStock || isGated ? (
    <>{children}</>
  ) : (
    <Link {...linkProps} legacyBehavior>
      {children}
    </Link>
  );
};

const ProductCard: FC<Props> = ({
  centerText = false,
  className,
  getImageElementWrapper,
  hideIconsLabel,
  isAddedToWishList,
  onAddToWishList,
  onRemoveWishList,
  position,
  product,
  segmentProductOnClick,
  setVisibleProducts,
  showLabels = true,
  showName = true,
  showSizes = true,
  wishlistProgress,
  zoomImageOnHover,
  queryID,
  algoliaIndex,
  isOutOfStock = false,
  allowAddToCart = false,
  onAddToCart,
  onGateProductClick,
  isLastTile = false,
  noGateLevel,
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: SEGMENT_IN_VIEW_THRESHOLD,
  });
  const [isClient, setIsClient] = useState(false);
  const theme: Theme = useTheme();
  const { i18n } = useLingui();
  const isLoggedIn = useIsLoggedIn();
  const { enableGatedHomepage } = useFeatureFlags();

  const gatedLabel = {
    type: "gated",
    value: t(i18n)`Log in to access`,
    color: theme.colors.tone.white,
    priority: 1,
    text_color: theme.colors.tone.white,
    background_color: theme.colors.primary.goldDark,
  };

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (inView && isFunction(setVisibleProducts)) {
      setVisibleProducts(product, position);
    }
  }, [inView]); // eslint-disable-line react-hooks/exhaustive-deps

  const isPartiallyGated = () =>
    !!enableGatedHomepage &&
    !noGateLevel &&
    !isLoggedIn &&
    product.gate?.level === BrandGateLevelInCustomerPlatform.PartiallyGated;

  const productLabels = showLabels ? product.labels || [] : [];

  const allLabels = isPartiallyGated()
    ? [gatedLabel]
    : productLabels.slice(0, 2);

  const handleProductClick = () => {
    if (isPartiallyGated() && onGateProductClick) {
      onGateProductClick();
      return;
    }
    segmentProductOnClick(product, position + 1);
  };

  return (
    <StyledProductCard
      centerText={centerText}
      className={className}
      data-testid="product-card"
      onClick={handleProductClick}
      ref={ref} // For segment tracking
      isGated={isPartiallyGated()}
      isLastTile={isLastTile}
    >
      {isPartiallyGated() || isLastTile ? (
        <Box
          data-testid="product-card-lock-icon"
          sx={{
            position: "absolute",
            right: "8px",
            top: "8px",
            zIndex: 1,
          }}
        >
          <LockIcon
            color={
              isLastTile
                ? theme.colors.tone.white
                : theme.colors.primary.goldDark
            }
          ></LockIcon>
        </Box>
      ) : (
        <Box data-wishlist-hover={isAddedToWishList}>
          <ToggleWishListButton
            isAddedToWishList={isAddedToWishList}
            itemType={WishlistItemType.Product}
            itemId={product.id}
            loading={wishlistProgress}
            onAddToWishList={onAddToWishList}
            onRemoveWishList={onRemoveWishList}
          />
        </Box>
      )}
      <LinkWrapper
        outOfStock={isOutOfStock}
        href={{
          pathname: "/product/[productSlug]",
          ...(queryID && {
            query: { queryID, objectID: product.id, algoliaIndex },
          }),
        }}
        as={`/product/${product.slug}`}
        passHref
        isGated={isPartiallyGated() || isLastTile}
      >
        <Box
          as="a"
          display="block"
          sx={{
            textAlign: centerText ? "center" : "left",
          }}
        >
          <Box
            display="block"
            sx={{
              position: "relative",
              cursor: "pointer",
            }}
            ref={getImageElementWrapper}
          >
            <ProductPhoto
              className="ProductView__productPhotos"
              data-expand="-20"
              zoomImageOnHover={zoomImageOnHover}
            >
              <Image
                className={isClient ? "lazyload skeleton" : ""}
                src={product.thumbnail}
                data-src={product.thumbnail}
                alt={`${product.name} by ${product.brand.name}`}
                layout="fill"
                isGated={isPartiallyGated()}
              />
            </ProductPhoto>
            {isLastTile && (
              <LoginButton
                data-testid="last-card-login-button"
                className="ProductView__loginButton"
                variant="primary"
                colorScheme="light"
                onClick={onGateProductClick}
              >
                {product.name}
              </LoginButton>
            )}
            {allLabels.length > 0 && (
              <Box
                sx={{
                  position: "absolute",
                  left: "8px",
                  bottom: "8px",
                }}
              >
                <ProductLabels
                  labels={allLabels}
                  hideIconsLabel={hideIconsLabel}
                />
              </Box>
            )}
          </Box>
          <StyledCardBrand>{formatName(product?.brand?.name)}</StyledCardBrand>
          {showName && (
            <StyledCardProduct>{formatName(product.name)}</StyledCardProduct>
          )}
          {!isLastTile && (
            <>
              <StyledCardPrices>
                <Price
                  price={
                    // NOTE: 'price' is 'number' in CatalogProduct but 'string' in HomepageSingleCategoryProduct,
                    // so we have to use 'price_float' from there
                    "price_float" in product
                      ? product.price_float
                      : product.price
                  }
                />
                <StyledCardRegularPrice>
                  <Price
                    // NOTE: 'regular_price' is 'number' in CatalogProduct but 'string' in HomepageSingleCategoryProduct,
                    // so we have to use 'regular_price_float' from there
                    price={
                      "regular_price_float" in product
                        ? product.regular_price_float
                        : product.regular_price
                    }
                  />
                </StyledCardRegularPrice>
                {("regular_price_float" in product ||
                  product.regular_price) && (
                  <Text as="span" color="tone.anthraciteLight">
                    *
                  </Text>
                )}
              </StyledCardPrices>
              {showSizes &&
                "available_sizes_label" in product && ( // NOTE: HomepageSingleCategoryProduct has no 'available_sizes_label'
                  <StyledCardSizes>
                    {product?.available_sizes_label}
                  </StyledCardSizes>
                )}
            </>
          )}
        </Box>
      </LinkWrapper>
      {allowAddToCart && onAddToCart && (
        <Box py={3}>
          <ProductOrderCartButton
            variant="tertiary"
            isOutOfStock={isOutOfStock}
            onClick={() => onAddToCart(product.slug)}
            data-testid="product-add-to-cart-cta"
            fullWidth
          />
        </Box>
      )}
    </StyledProductCard>
  );
};

export default ProductCard;
