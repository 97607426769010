import { FC, SVGAttributes } from "react";

interface Props extends SVGAttributes<SVGSVGElement> {
  svgPrefixId: string;
}

const FlagEuRound: FC<Props> = ({ svgPrefixId, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    {...rest}
  >
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g transform="translate(-642 -1502) translate(302 1502) translate(340)">
          <rect width="20" height="20" fill="#039" rx="10" />
          <path
            fill="#FC0"
            d="M10 15.166l.225.7h.733l-.593.434.227.7-.593-.433-.59.433.225-.7-.592-.433.731-.001.227-.7zm-3.021-.815l.226.7h.732l-.593.434.227.7-.592-.433-.592.433.225-.7-.59-.433.73-.002.227-.699zm6.042 0l.225.7h.733l-.592.434.226.7-.593-.433-.59.433.225-.7-.592-.433.731-.002.227-.699zm-8.254-2.227l.225.7.733.001-.593.432.227.702-.593-.434-.59.434.225-.702-.591-.432h.73l.227-.7zm10.466 0l.225.7.732.001-.592.432.227.702-.593-.434-.591.434.224-.702-.59-.432h.731l.227-.7zM3.958 9.083l.224.7h.734l-.593.432.227.702-.593-.433-.591.433.225-.702L3 9.784l.73-.002.228-.699zm12.084 0l.226.7H17l-.593.432.227.702-.592-.433-.592.433.225-.702-.59-.431.731-.002.226-.699zM4.767 6.041l.225.7.733.001-.593.433.227.7-.593-.432-.59.433.225-.7-.591-.434h.73l.227-.7zm10.466 0l.225.7.732.001-.592.433.227.7-.593-.432-.591.433.224-.702-.59-.432h.731l.227-.7zM6.979 3.815l.226.7h.732l-.593.433.227.701-.592-.434-.592.434.225-.7-.59-.433h.73l.227-.701zm6.042 0l.225.7h.733l-.592.433.226.701-.593-.434-.59.434.225-.7-.592-.433h.731l.227-.701zM10 3l.225.7h.733l-.593.433.227.701-.593-.434-.59.434.225-.701-.592-.432h.731L10 3z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export { FlagEuRound };
