import { FC, SVGAttributes } from "react";

const FlagBe: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512pt"
    height="512pt"
    viewBox="0 -79 512 512"
    {...props}
  >
    <path
      fill="#2c2f38"
      d="M397.242 353.105h97.102c9.75 0 17.656-7.906 17.656-17.656V17.656C512 7.902 504.094 0 494.344 0H17.656C7.902 0 0 7.906 0 17.656V335.45c0 9.75 7.902 17.653 17.656 17.653h379.586zm0 0"
    ></path>
    <path
      fill="#c03a2b"
      d="M512 17.656V335.45c-.027 9.739-7.914 17.625-17.656 17.653H353.102V0h141.242c9.742.027 17.629 7.918 17.656 17.656zm0 0"
    ></path>
    <path fill="#f0c419" d="M158.898 0h194.207v353.102H158.898zm0 0"></path>
  </svg>
);

export { FlagBe };
