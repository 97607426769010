import { Flex } from "@otrium/core";
import styled from "@emotion/styled";
import { CloseButton } from "src/atoms/CloseButton";

const StyledSmartBanner = styled.div`
  padding: 16px 10px;
  background-color: ${({ theme }) => theme.colors.tone.white};
  bottom: -1px;
  position: fixed;
  width: 100%;
  display: flex;
  z-index: 150;
`;

const StyledSmartBannerClose = styled(CloseButton)`
  width: 20px;
  height: 20px;
  margin-left: -4px;
  padding: 0;
`;

const StyledSmartBannerContent = styled(Flex)`
  flex: 1;
  align-items: center;
`;

const StyledSmartBannerLogoWrapper = styled.div`
  height: 48px;
  width: 48px;
  margin: 0 8px 0 12px;
`;

const StyledSmartBannerTextContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  color: ${({ theme }) => theme.colors.tone.black};
`;

const StyledSmartBannerTitle = styled.div`
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.4px;
`;

const StyledSmartBannerDescription = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: normal;
`;

const StyledSmartBannerLink = styled.a`
  height: 24px;
  border-radius: 12px;
  border: none;
  background-color: ${({ theme }) => theme.colors.tone.black};
  color: ${({ theme }) => theme.colors.tone.white};
  padding: 7px 10px;
  line-height: 10px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
  vertical-align: middle;
  margin: 12px 14px;
  letter-spacing: 1px;
  cursor: pointer;
`;

export {
  StyledSmartBanner,
  StyledSmartBannerClose,
  StyledSmartBannerContent,
  StyledSmartBannerLogoWrapper,
  StyledSmartBannerTextContainer,
  StyledSmartBannerTitle,
  StyledSmartBannerDescription,
  StyledSmartBannerLink,
};
