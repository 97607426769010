import { Flex } from "@otrium/core";
import styled from "@emotion/styled";

const Wrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex-direction: row;
    width: 75%;
  }
`;

export { Wrapper };
