import { FC, ReactNode, forwardRef, RefObject } from "react";
import { Link, Button } from "./HyperLink.styled";
import { Text } from "@otrium/atoms";
import { AriaButtonProps, AriaLinkOptions, useLink } from "react-aria";
import { FocusableElement } from "@react-types/shared";
import { IColor } from "@otrium/core";

interface Props {
  icon?: ReactNode;
  children?: ReactNode;
  disabled?: boolean;
  className?: string;
  color?: string | IColor;
  hasUnderline?: boolean;
  fullWidth?: boolean;
  onClick?: () => void;
  asLink?: boolean;
  href?: string;
  variant?: string;
}

const HyperLink: FC<Props & AriaButtonProps & AriaLinkOptions> = forwardRef<
  HTMLAnchorElement & HTMLButtonElement,
  Props & AriaButtonProps & AriaLinkOptions
>(
  (
    {
      icon = null,
      className,
      hasUnderline,
      fullWidth,
      children,
      color,
      asLink,
      variant,
      href,
      target,
      rel,
      ...rest
    },
    ref
  ) => {
    const { linkProps } = useLink(
      { ...rest, isDisabled: rest.disabled },
      ref as RefObject<FocusableElement>
    );
    return (
      <>
        {asLink ? (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          /* @ts-ignore */
          <Link
            {...linkProps}
            ref={ref}
            href={href}
            target={target}
            hasUnderline={hasUnderline}
            fullWidth={fullWidth}
            className={className}
            icon={icon}
            color={color}
            data-testid="hyper-link"
          >
            {icon}
            <Text
              as="span"
              data-testid="hyper-link-content"
              variant={
                variant !== "inherit"
                  ? variant || "body.source.semibold16"
                  : undefined
              }
            >
              {children}
            </Text>
          </Link>
        ) : (
          <Button
            {...rest}
            ref={ref}
            hasUnderline={hasUnderline}
            fullWidth={fullWidth}
            className={className}
            icon={icon}
            color={color}
            data-testid="hyper-link-button"
          >
            {icon}
            <Text
              data-testid="hyper-link-button-content"
              variant={variant || "body.source.semibold16"}
            >
              {children}
            </Text>
          </Button>
        )}
      </>
    );
  }
);

HyperLink.displayName = "HyperLink";

export default HyperLink;
