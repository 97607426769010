import styled from "@emotion/styled";

const StyledSticky = styled.div<{
  height: number;
  top?: number;
  boxshadowMode?: string;
  zIndex?: string;
  isHeader?: boolean;
}>`
  width: 100%;
  &.Sticky_sticky {
    height: ${({ height }) => height}px;
  }

  &.Sticky_sticky > .Sticky__content {
    position: fixed;
    top: ${({ top }) => (top ? top : 0)}px;
    left: 0;
    z-index: ${({ theme, isHeader }) =>
      isHeader ? theme.zIndex.sticky : theme.zIndex.header};
    background: ${({ theme }) => theme.colors.tone.white};
    border-top: ${({ theme, isHeader }) =>
      !isHeader ? `1px solid ${theme.colors.primary.platinumLight}` : "none"};
    box-shadow: ${({ boxshadowMode, isHeader }) =>
      boxshadowMode === "down" && !isHeader
        ? "0px 4px 4px rgba(0, 0, 0, 0.1)"
        : "none"};
  }

  .Sticky__content {
    width: 100%;
  }
`;

export { StyledSticky };
