import { FC, SVGAttributes } from "react";

const ArrowLeftIcon: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10 4L6 8.00119L10 12" stroke="black" strokeWidth="2" />
  </svg>
);

export { ArrowLeftIcon };
