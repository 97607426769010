import styled from "@emotion/styled";
import { Flex } from "@otrium/core";

const ServiceBannerOffer = styled(Flex)`
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.fontSize14};
  letter-spacing: 0.05px;
  line-height: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.tone.white};
`;

const ServiceBannerContainer = styled(Flex)`
  align-items: center;
  position: relative;
  height: 48px;
  justify-content: space-between;
`;

const ServiceBannerLink = ServiceBannerOffer.withComponent("a");

export { ServiceBannerOffer, ServiceBannerLink, ServiceBannerContainer };
