export {
  getGenderId,
  getLocale,
  getLocaleGenderId,
  getLocaleGenderIds,
  reducer,
  setGender,
  setLocale,
} from "./duck";
export { useGenderId } from "./useGenderId";

export type Gender = "women" | "men" | "kids" | undefined;
