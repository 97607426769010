import { FC } from "react";
import Link from "next/link";
import { AlgoliaSearchSuggestion } from "src/types/Algolia";
import { Flex } from "@otrium/core";
import { Trans } from "@lingui/macro";
import {
  Highlighted,
  SuggestionType,
  Wrapper,
  DividerDot,
  Breadcrumb,
  SuggestionLink,
} from "./SuggestionItem.styled";
import { sanitize } from "isomorphic-dompurify";

interface Props {
  link: string;
  hit: AlgoliaSearchSuggestion;
  onClick: () => void;
}

const SuggestionItem: FC<Props> = ({ link, hit, onClick }) => {
  return (
    <Link href={link} passHref legacyBehavior>
      <SuggestionLink onClick={onClick} data-testid="suggestion-link">
        <Wrapper>
          <Highlighted
            dangerouslySetInnerHTML={{
              /* eslint-disable no-underscore-dangle */
              __html: sanitize(hit._highlightResult.query?.value) || "",
              /* eslint-enable no-underscore-dangle */
            }}
          />
          <SuggestionType display={["none", "none", "none", "block"]}>
            -
          </SuggestionType>
          <SuggestionType>
            {hit.type === "brand" ? (
              <Trans>Brand</Trans>
            ) : hit.type === "category" ? (
              <Trans>Category</Trans>
            ) : hit.type === "collection" ? (
              <Trans>Collection</Trans>
            ) : (
              <Trans>Information</Trans>
            )}
          </SuggestionType>
        </Wrapper>
        {hit.hasDuplicate && (
          <Flex alignItems="center">
            {hit.parent_path.map((name, i) => (
              <Flex alignItems="center" key={`breadcrumb${i}`}>
                <Breadcrumb
                  dangerouslySetInnerHTML={{ __html: sanitize(name) }}
                />
                {i < hit.parent_path.length - 1 && <DividerDot />}
              </Flex>
            ))}
          </Flex>
        )}
      </SuggestionLink>
    </Link>
  );
};

export default SuggestionItem;
