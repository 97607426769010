import { SingleProduct } from "src/types/graphql.d";
import { LineItem } from "src/types/ctgraphql.d";

enum UserType {
  INTERNAL = "internal",
  EXTERNAL = "external",
}

function getUserType(email: string, domain = "otrium.com") {
  return email.includes(domain) ? UserType.INTERNAL : UserType.EXTERNAL;
}

// NOTE: Google Tag Manager is available through Segment
const googleTagManager = {
  trackAlreadyRegistered: (): void => {
    window.dataLayer.push({ event: "already_registered" });
  },

  trackUser: (userId?: string, userEmail?: string): void => {
    window.dataLayer.push({
      event: "user data updated",
      user_id: userId,
      user_email: userEmail,
      user_type: userEmail ? getUserType(userEmail) : undefined,
    });
    window.dataLayer.push({
      event: "user_id_in_data_layer",
    });
  },

  trackHomePage: (email: string): void => {
    window.dataLayer.push({
      event: "crto_homepage",
      crto: {
        email,
      },
    });
  },

  trackProductPage: (email: string, product: SingleProduct): void => {
    window.dataLayer.push({
      event: "crto_productpage",
      crto: {
        email,
        products: [product.id],
        price: product.price,
        quantity: 1,
      },
    });
  },

  trackCartPage: (email: string, products?: LineItem[] | null): void => {
    window.dataLayer.push({
      event: "crto_basketpage",
      crto: {
        email,
        products: (products || []).map((product) => ({
          id: product.id,
          price: product.price.value.centAmount,
          quantity: product.quantity,
        })),
      },
    });
  },

  trackSearchNoResults: (query: string): void => {
    window.dataLayer.push({
      event: "search_no_result",
      search_query_no_result: query,
    });
  },
};

export { googleTagManager, UserType };
