import { FC, ReactNode, useContext } from "react";
import styled from "@emotion/styled";
import { AppCtx } from "src/contexts/app.context";
import { Footer } from "src/molecules/Footer";
import { Header } from "src/organisms/Header";
import { SmartBanner } from "src/molecules/SmartBanner";
import OptIn from "src/organisms/OptIn";
import ToasterNotification from "src/organisms/ToasterNotification";
import { SubscribeOverlay } from "src/molecules/SubscribeOverlay";
import NoSSR from "./NoSSR";

const StyledPage = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .Page__container {
    flex: 1 0 0;
  }
`;

interface Props {
  children: ReactNode;
}

const Page: FC<Props> = ({ children }) => {
  const {
    featureFlags: { smartBanner },
    isPrivateSale,
  } = useContext(AppCtx);

  return (
    <StyledPage>
      <Header isLoginFlow={false} />
      <div className="Page__container">
        {/* TODO Temporarily comented until the bug is fix on mode side. feat(PB-380): Hide the FitAnalytics Widget on PDP */}
        {/* data-fit-analytics="page-container" */}
        {children}
      </div>
      {!isPrivateSale && <Footer className="Page__footer" />}
      <OptIn />
      <NoSSR>
        <SubscribeOverlay />
      </NoSSR>
      {!isPrivateSale && <ToasterNotification />}
      <NoSSR>{smartBanner && <SmartBanner />}</NoSSR>
    </StyledPage>
  );
};

export { Page };
