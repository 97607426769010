import { FC, SVGAttributes } from "react";

const Search: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="13.3333"
      cy="13.3333"
      r="9.33333"
      stroke="black"
      strokeWidth="2"
    />
    <path
      d="M20 20L28 28"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export { Search };
