const BRAND_FACET = "brand.meta";
const BRAND_SLUG_FACET = "brand.slug";
const SIZE_FACET = "container_sizes.meta";
const PRICE_FACET = "price";
const COLOUR_FACET = "color.meta";
const AGES_FACET = "ages.meta";
const PATTERN_FACET = "pattern.meta";
const STYLE_FACET = "style.meta";
const PROMOTION_FACET = "promotion_filter.meta";
const CONSCIOUS_FACET = "brand.good_on_you_info.score_meta";
const GENDERS_FACET = "genders";
const CATEGORIES_NAME_FACET = "categories.name";
const CATEGORIES_FACET = "categories.categoryPageId_v2";
const CATEGORIES_SLUG_FACET = "categories.slug";
const BRAND_DEPARTMENTS_FACET = "brand.departments";
const LABELS_TYPE_FACET = "labels.type";
const PROMOTION_ID_FACET = "promotion_filter.id";
const IS_CLEARANCE_PRODUCT_FACET = "is_clearance_product";
const BRAND_DESIGNER_ONLY_FACET = "brand.designer_only";
const COLLECTIONS_FACET = "collections";
const BRAND_IS_PRIVATE_SALE = "brand.is_private_sale";
const IS_DELETED = "is_deleted";
const SLUG_FACET = "slug";
const DISCOUNT_FACET = "discount_buckets.meta";

export {
  BRAND_FACET,
  BRAND_SLUG_FACET,
  SLUG_FACET,
  SIZE_FACET,
  PRICE_FACET,
  COLOUR_FACET,
  AGES_FACET,
  PATTERN_FACET,
  STYLE_FACET,
  CONSCIOUS_FACET,
  PROMOTION_FACET,
  GENDERS_FACET,
  CATEGORIES_NAME_FACET,
  CATEGORIES_FACET,
  CATEGORIES_SLUG_FACET,
  BRAND_DEPARTMENTS_FACET,
  LABELS_TYPE_FACET,
  PROMOTION_ID_FACET,
  IS_CLEARANCE_PRODUCT_FACET,
  BRAND_DESIGNER_ONLY_FACET,
  COLLECTIONS_FACET,
  BRAND_IS_PRIVATE_SALE,
  IS_DELETED,
  DISCOUNT_FACET,
};
