import { LineItem } from "src/types/ctgraphql.d";

export const getCartItemAvailability = (
  item: LineItem
): {
  itemAvailableQuantity: number;
  isItemExceedsAvailability: boolean;
  isItemOutOfStock: boolean;
} => {
  const itemAvailableQuantity =
    item.variant?.availability?.channels?.results.reduce(
      (sum, stockInfo) =>
        sum + (stockInfo.availability.availableQuantity as number),
      0
    ) || 0;

  const isItemExceedsAvailability = item.quantity > itemAvailableQuantity;
  const isItemOutOfStock = itemAvailableQuantity === 0;

  return {
    itemAvailableQuantity,
    isItemExceedsAvailability,
    isItemOutOfStock,
  };
};
