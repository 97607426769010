import { useRouter } from "next/router";
import {
  FC,
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { useCart } from "src/hooks/useCart";
import { Locale } from "lib/Intl";
import { Cart } from "src/types/ctgraphql.d";
import { formatStandardPrice } from "src/utils/ct/priceHelper";
import { useMyOrders } from "src/hooks/useMyOrders";
import { useSegment } from "src/hooks/useSegment";
import {
  VisitorType,
  getShortCookie,
  getLongCookie,
  setShortCookie,
  setLongCookie,
  getVisitorTypeFromOrders,
} from "src/utils/prospect-vs-customer";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import { AppCtx, ViewedProductState } from "src/contexts/app.context";
import { useSessionChange } from "src/hooks/useSessionChange";

interface Props {
  locale: Locale;
}

const getProductCount = (cart: Cart) =>
  cart.lineItems.reduce(
    (count, item) => count + ((item.quantity as number) || 0),
    0
  );

const productPathPattern = /\/product\/\S+/g;

const isProductPath = (path: string) => productPathPattern.test(path);

/**
 * PageViewTracker component
 * `useCart` depends on AppContext so the component needs to be a child of App
 */
const PageViewTracker: FC<Props> = ({ locale }) => {
  const { viewedProductState, isPrivateSale, employeeSaleData } =
    useContext(AppCtx);
  const { data: cartData } = useCart();
  const router = useRouter();
  const { segmentPageViewed } = useSegment();
  const cartFetched = useRef(false);
  const { refetch: fetchMyOrders } = useMyOrders(1);
  const isLoggedIn = useIsLoggedIn();
  const [logProductPageView, setLogProductPageView] = useState<
    string | undefined
  >();
  const lastTrackedPath = useRef<string>();

  useSessionChange(isPrivateSale, employeeSaleData, locale);

  const setVisitorType = useCallback(() => {
    void (async () => {
      const shortCookie = getShortCookie();
      const longCookie = getLongCookie();
      if (shortCookie) {
        setShortCookie(shortCookie);
      } else if (isLoggedIn) {
        const { data } = await fetchMyOrders();
        const visitorType = getVisitorTypeFromOrders(data);

        setLongCookie(visitorType);
        setShortCookie(visitorType);
      } else if (longCookie) {
        setShortCookie(longCookie);
      } else {
        setLongCookie(VisitorType.PROSPECT);
        setShortCookie(VisitorType.PROSPECT);
      }
    })();
  }, [fetchMyOrders, isLoggedIn]);

  useEffect(() => {
    setVisitorType();
  }, [setVisitorType]);

  useEffect(() => {
    router.events.on("routeChangeComplete", setVisitorType);

    return () => {
      router.events.off("routeChangeComplete", setVisitorType);
    };
  }, [router, setVisitorType]);

  useEffect(() => {
    if (cartData?.cart !== undefined && !cartFetched.current) {
      if (isProductPath(window.location.pathname)) {
        setLogProductPageView(window.location.pathname);
      } else if (router.isReady && lastTrackedPath.current !== router.asPath) {
        lastTrackedPath.current = router.asPath;
        void segmentPageViewedCb(router.asPath);
      }

      cartFetched.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData]);

  const segmentPageViewedCb = useCallback(
    (url: string, productAvailable?: boolean) => {
      return segmentPageViewed({
        pathName: url,
        locale,
        ...(cartData.cart && {
          cartData: {
            product_count: cartData.cart
              ? getProductCount(cartData.cart)
              : undefined,
            cart_total: formatStandardPrice(cartData.cart.totalPrice),
          },
        }),
        productAvailable,
      });
    },
    [segmentPageViewed, cartData.cart, locale]
  );

  const handleRouteChange = useCallback(
    (url: string) => {
      if (lastTrackedPath.current === url) return;
      lastTrackedPath.current = url;
      if (isProductPath(url)) {
        setLogProductPageView(url);
      } else {
        void segmentPageViewedCb(url);
      }
    },
    [segmentPageViewedCb]
  );

  useEffect(() => {
    if (
      logProductPageView &&
      (viewedProductState === ViewedProductState.READY ||
        viewedProductState === ViewedProductState.ERROR)
    ) {
      void segmentPageViewedCb(
        logProductPageView,
        viewedProductState !== ViewedProductState.ERROR
      );
      setLogProductPageView(undefined);
    }
  }, [segmentPageViewedCb, logProductPageView, viewedProductState]);

  useEffect(() => {
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return <Fragment />;
};

export default PageViewTracker;
