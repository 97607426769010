import { useContext, useEffect, useState } from "react";
import { SearchIndex } from "algoliasearch";
import { AppCtx } from "src/contexts/app.context";
import { AlgoliaIndex } from "src/types/Algolia.d";
import { AlgoliaContext } from "src/contexts/algolia.context";
import getFullIndexName from "src/utils/algolia/getFullIndexName";

const useAlgoliaInitialiseIndex = ({
  indexName,
  sortBy,
  defaultSort,
}: AlgoliaIndex): SearchIndex | undefined => {
  const { isGhostDomain, locale } = useContext(AppCtx);
  const prefix = isGhostDomain ? "zz" : locale;
  const [searchIndex, setSearchIndex] = useState<SearchIndex>();
  const { client } = useContext(AlgoliaContext);

  useEffect(() => {
    setSearchIndex(
      client?.initIndex(
        getFullIndexName({ indexName, sortBy, defaultSort, prefix })
      )
    );
  }, [client, defaultSort, indexName, prefix, sortBy]);

  return searchIndex;
};

export default useAlgoliaInitialiseIndex;
