import styled from "@emotion/styled";
import { Button } from "src/atoms/Button";
import { default as NextImage } from "next/image";

const StyledProductCard = styled.div<{
  centerText?: boolean | undefined;
  isGated?: boolean | undefined;
  isLastTile?: boolean | undefined;
}>`
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    div[data-wishlist-hover="false"] svg {
      transition: opacity 0.3s;
      opacity: 0;
    }
    &:hover > div[data-wishlist-hover="false"] svg {
      opacity: 1;
    }
  }

  .ProductView__productPhotos {
    border-radius: 4px;
    border: ${({ isGated, theme }) =>
      isGated ? `1px solid ${theme.colors.tone.white}` : ""};

    img {
      filter: ${({ isLastTile }) => (isLastTile ? "blur(6px)" : "")};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
      img {
        filter: ${({ isGated }) => (isGated ? `blur(6px)` : "none")};
      }
    }

    :hover {
      border: ${({ isGated, theme }) =>
        isGated ? `1px solid ${theme.colors.primary.goldDark}` : ""};
      div {
        border-radius: 4px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    :hover {
      .ProductView__productPhotos img {
        filter: ${({ isLastTile }) => (isLastTile ? "blur(6px)" : "")};
      }
      .ProductView__loginButton {
        display: block;
      }
    }
  }
`;

const StyledCardBrand = styled.div<{
  isPlaceholder?: boolean | undefined;
}>`
  margin-top: ${({ theme }) => theme.space.space16};
  font-size: ${({ theme }) => theme.fontSizes.fontSize16};
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.tone.black};

  ${({ isPlaceholder }) =>
    isPlaceholder &&
    `
    height: 20px;
    width: 75px;
  }
  `}
`;

const StyledCardProduct = styled.div<{
  isPlaceholder?: boolean | undefined;
}>`
  font-size: ${({ theme }) => theme.fontSizes.fontSize14};
  line-height: 20px;
  text-transform: lowercase;
  color: ${({ theme }) => theme.colors.tone.black};
  letter-spacing: 0.4px;
  overflow: hidden;
  text-overflow: ellipsis;

  &:first-letter {
    text-transform: capitalize;
  }

  ${({ isPlaceholder }) =>
    isPlaceholder &&
    `
    margin-top: 4px;
    height: 17px;
    width: 180px;
  }
  `}
`;

const StyledCardPrices = styled.div<{
  isPlaceholder?: boolean | undefined;
}>`
  font-size: ${({ theme }) => theme.fontSizes.fontSize16};
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.semantic.red};

  ${({ isPlaceholder }) =>
    isPlaceholder &&
    `
    margin-top: 4px;
    height: 21px;
    width: 150px;
  }
  `}
`;

const StyledCardRegularPrice = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.fontSize16};
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin-left: ${({ theme }) => theme.space.space8};
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.tone.anthraciteLight};
`;

const StyledCardSizes = styled.div<{
  isPlaceholder?: boolean | undefined;
}>`
  font-size: ${({ theme }) => theme.fontSizes.fontSize14};
  line-height: 20px;
  color: ${({ theme }) => theme.colors.tone.anthraciteLight};
  letter-spacing: 0.4px;
  white-space: normal;

  ${({ isPlaceholder }) =>
    isPlaceholder &&
    `
    margin-top: 4px;
    height: 17px;
    width: 110px;
  }
  `}
`;

const Image = styled(NextImage)<{
  isGated?: boolean | undefined;
}>`
  object-fit: cover;
  filter: ${({ isGated }) => (isGated ? "blur(6px)" : "")};
  pointer-events: ${({ isGated }) => (isGated ? "none" : "auto")};
`;

const LoginButton = styled(Button)`
  position: absolute;
  text-wrap: nowrap;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    display: none;
  }
`;

export {
  StyledProductCard,
  StyledCardBrand,
  StyledCardProduct,
  StyledCardPrices,
  StyledCardRegularPrice,
  StyledCardSizes,
  Image,
  LoginButton,
};
