import { useMemo } from "react";
import { MultipleQueriesQuery } from "@algolia/client-search";
import {
  LOGGED_IN_USER,
  NON_EMPTY_SEARCH,
  NON_LOGGED_IN_USER,
} from "src/utils/algolia/analyticsTags";
import useAlgoliaMultipleRequests from "src/hooks/algolia/useAlgoliaMultipleRequests";
import { ShopType } from "src/types/graphql.d";
import {
  DEFAULT_ANALYTICS_TAGS,
  DEFAULT_RULE_CONTEXTS,
} from "src/utils/algolia/defaultSettings";
import { useSession } from "next-auth/client";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import {
  AlgoliaSearchProduct,
  AlgoliaSearchSuggestion,
  ContextKeySearch,
} from "src/types/Algolia.d";
import getMultipleQueriesSearchResults, {
  MultipleQueriesSearchResults,
} from "src/utils/algolia/getMultipleQueriesSearchResults";
import getFilters from "src/utils/algolia/getFilters";

interface AlgoliaProductsSearch {
  query: string;
  shopType: ShopType;
  productsIndexName: string;
  suggestionsIndexName: string;
}

interface AlgoliaProductsSearchData {
  data:
    | MultipleQueriesSearchResults<
        AlgoliaSearchProduct | AlgoliaSearchSuggestion
      >
    | undefined;
  loading: boolean;
  error: unknown;
}

const useAlgoliaProductsSearch = ({
  query,
  shopType,
  productsIndexName,
  suggestionsIndexName,
}: AlgoliaProductsSearch): AlgoliaProductsSearchData => {
  const filters = getFilters({
    gender: shopType,
  });
  const isLoggedIn = useIsLoggedIn();
  const [session] = useSession();
  const userToken = useMemo(
    () =>
      session?.user.member_id ? { userToken: session.user.member_id } : {},
    [session?.user.member_id]
  );

  const searchParameters = useMemo(
    () => ({
      filters,
      clickAnalytics: true,
      analyticsTags: [
        ...DEFAULT_ANALYTICS_TAGS,
        shopType,
        isLoggedIn ? LOGGED_IN_USER : NON_LOGGED_IN_USER,
        ...(query ? [NON_EMPTY_SEARCH] : []),
      ],
      ...userToken,
    }),
    [filters, isLoggedIn, query, shopType, userToken]
  );

  const queries: MultipleQueriesQuery[] = useMemo(
    () => [
      {
        indexName: suggestionsIndexName,
        query,
        params: {
          highlightPreTag: "<b>",
          highlightPostTag: "</b>",
          hitsPerPage: 5,
          ruleContexts: [
            ...DEFAULT_RULE_CONTEXTS,
            ContextKeySearch.searchSuggestions,
          ],
          ...searchParameters,
        },
      },
      {
        indexName: productsIndexName,
        query,
        params: {
          hitsPerPage: 6,
          ruleContexts: [
            ...DEFAULT_RULE_CONTEXTS,
            ContextKeySearch.searchProducts,
          ],
          ...searchParameters,
        },
      },
    ],
    [productsIndexName, query, searchParameters, suggestionsIndexName]
  );

  const {
    data: responseData,
    loading,
    error,
  } = useAlgoliaMultipleRequests<
    AlgoliaSearchProduct | AlgoliaSearchSuggestion
  >({
    queries,
  });

  const data = responseData
    ? getMultipleQueriesSearchResults(responseData)
    : undefined;

  return {
    data,
    loading,
    error,
  };
};
export default useAlgoliaProductsSearch;
