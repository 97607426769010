import { useEffect, useRef, useState, useCallback, useContext } from "react";
import { CSSTransition } from "react-transition-group";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { AppCtx } from "src/contexts/app.context";
import { getGenderId } from "src/modules/page";
import { Portal } from "src/atoms/Portal";
import { useSegment } from "src/hooks/useSegment";
import { Container } from "./ToasterNotification.styled";
import getDataAttrsFromSelector from "./utils";
import { sanitize } from "isomorphic-dompurify";

const SELECTORS = {
  close: "a.ot-notification__close",
  content: ".ot-notification__content",
  overlayLink: ".ot-notification__overlay-link",
} as const;

const getDataFromNotification = (
  el: HTMLDivElement | undefined
): {
  content: string | undefined;
  path: string | undefined;
} => {
  const content = el
    ?.querySelector<HTMLDivElement>(SELECTORS.content)
    ?.textContent?.trim();

  const path = el?.querySelector<HTMLAnchorElement>(
    SELECTORS.overlayLink
  )?.href;

  return {
    content,
    path,
  };
};

const ToasterNotification = (): JSX.Element => {
  const notificationSelector = "div.ot-notification";

  const { userPreferences } = useContext(AppCtx);

  const userNotification = userPreferences?.notification || "";

  const [showNotification, setShowNotification] = useState(false);
  const [notificationIsRendered, setNotificationIsRendered] = useState(false);

  const genderId = useSelector(getGenderId) as string;

  const closeBtnRef = useRef<HTMLAnchorElement | null>();
  const notificationRef = useRef<HTMLDivElement>();

  const [trackedDataID, setTrackedDataID] = useState<string>();

  const {
    segmentToasterNotificationDisplayed,
    segmentToasterNotificationDismissed,
    segmentToasterNotificationClicked,
  } = useSegment();

  const cookieName = `otrium_cookie-notification_popup_${genderId}`;
  const notificationCookieValue = Cookies.get(cookieName) || null;

  const handleNotificationLinkClicked = useCallback(() => {
    const { content, path } = getDataFromNotification(notificationRef.current);

    void segmentToasterNotificationClicked(content, path);
  }, [segmentToasterNotificationClicked]);

  // NOTE: Can't use useRef here because it doesn't notify about ref.current update in useEffect
  // Source: https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const setNotificationRef = useCallback((node: HTMLDivElement) => {
    notificationRef.current = node;

    if (node !== null) {
      setNotificationIsRendered(true);

      const link = node.querySelector<HTMLAnchorElement>(SELECTORS.overlayLink);

      if (link !== null) {
        link.onclick = handleNotificationLinkClicked;
      }
    }
  }, []);

  useEffect(() => {
    const { id: dataID } = getDataAttrsFromSelector(notificationSelector);

    if (showNotification && trackedDataID !== dataID) {
      const { content, path } = getDataFromNotification(
        notificationRef.current
      );
      void segmentToasterNotificationDisplayed(content, path);
      setTrackedDataID(dataID);
    }
  }, [showNotification, trackedDataID, segmentToasterNotificationDisplayed]);

  const handleCloseNotification = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      const notification = getDataAttrsFromSelector(notificationSelector);
      // set cookie so we don't display the same notification again
      Cookies.set(cookieName, `${notification.id}`);
      setShowNotification(false);
      const { content, path } = getDataFromNotification(
        notificationRef.current
      );
      void segmentToasterNotificationDismissed(content, path);
    },
    [cookieName, segmentToasterNotificationDismissed]
  );

  useEffect(() => {
    closeBtnRef.current = document.querySelector(
      SELECTORS.close
    ) as HTMLAnchorElement;

    if (closeBtnRef.current) {
      closeBtnRef.current.onclick = handleCloseNotification;
    }

    const dataAttributes = getDataAttrsFromSelector(notificationSelector);
    const dataId = dataAttributes.id;
    const dataGender = dataAttributes.gender;

    // cookie is not set and not matching the notification id in the parsed notification
    // and gender match the gender set by the parsed notification
    if (
      dataId !== undefined && // NOTE: 'undefined' until notification is rendered
      notificationCookieValue !== dataId &&
      (dataGender === genderId || dataId === "welcome_notification")
    ) {
      setShowNotification(true);
    }
  }, [
    userNotification,
    notificationIsRendered,
    setShowNotification,
    genderId,
    notificationCookieValue,
    handleCloseNotification,
  ]);

  return (
    <Portal selector="#portal">
      <Container show={showNotification}>
        <CSSTransition
          timeout={225}
          in={showNotification}
          appear
          classNames="roll"
        >
          <div
            dangerouslySetInnerHTML={{ __html: sanitize(userNotification) }}
            ref={setNotificationRef}
          />
        </CSSTransition>
      </Container>
    </Portal>
  );
};

export default ToasterNotification;
