import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useCallback } from "react";
import { USER_WISHLIST_META } from "src/hooks/useUserWishlistMeta";
import { UserWishlistMeta, WishlistItemType } from "src/types/graphql.d";

interface userWishlistMeta {
  userWishlistMetaData: UserWishlistMeta;
}

const ADD_TO_WISHLIST = gql`
  mutation AddToWishList($item_type: WishlistItemType!, $item_id: String!) {
    addToWishlist(input: { item_type: $item_type, item_id: $item_id }) {
      id
      item_type
      item_id
      created_at
    }
  }
`;

const useAddToWishList = ({ refetchQueries }: { refetchQueries: string[] }) => {
  const [addToWishListMutation, { loading, data }] = useMutation(
    ADD_TO_WISHLIST,
    {
      refetchQueries,
    }
  );

  const addToWishlist = useCallback(
    (item_type: WishlistItemType, item_id: string) => {
      return addToWishListMutation({
        variables: { item_type, item_id },
        optimisticResponse: true,
        update: (cache) => {
          // update the favourites cache
          if (item_type === WishlistItemType.Brand) {
            try {
              const wishlistMeta = cache.readQuery({
                query: USER_WISHLIST_META,
              }) as userWishlistMeta;
              const newItems = [
                ...(wishlistMeta.userWishlistMetaData
                  .wishlist_brand_ids as string[]),
                item_id,
              ];
              cache.writeQuery({
                query: USER_WISHLIST_META,
                data: {
                  userWishlistMetaData: {
                    ...wishlistMeta.userWishlistMetaData,
                    wishlist_brand_ids: newItems,
                  },
                },
              });
            } catch (err) {
              console.error("error adding brand to the cache", err);
            }
          }
          if (item_type === WishlistItemType.Product) {
            try {
              const wishlistMeta = cache.readQuery({
                query: USER_WISHLIST_META,
              }) as userWishlistMeta;
              cache.writeQuery({
                query: USER_WISHLIST_META,
                data: {
                  userWishlistMetaData: {
                    ...wishlistMeta.userWishlistMetaData,
                    wishlist_product_ids: (
                      wishlistMeta.userWishlistMetaData
                        .wishlist_product_ids as string[]
                    ).concat(item_id),
                    wishlist_product_count:
                      wishlistMeta.userWishlistMetaData.wishlist_product_count +
                      1,
                  },
                },
              });
            } catch (err) {
              console.error("error adding product to the cache", err);
            }
          }
        },
      });
    },
    [addToWishListMutation]
  );

  return { addToWishlist, loading, data };
};

export { useAddToWishList };
