import { FC, useState, useEffect, useMemo } from "react";
import { CloseButton } from "src/atoms/CloseButton";
import { Container } from "src/atoms/Container";
import { StyledContainer, InnerWrapper } from "./Ribbon.styled";
import { Swiper, SwiperSlide } from "swiper/react";
import { useInView } from "react-intersection-observer";
import { PromotionEventType, useSegment } from "src/hooks/useSegment";
import { AlertMessage } from "src/molecules/AlertMessage";
import { AlertBar as AlertBarType } from "src/types/graphql.d";
import { SEGMENT_PROMOTION_TYPE } from "src/segment";

interface Props {
  alertBars: AlertBarType[];
  onClick: () => void;
}

interface IState {
  bgColor: string | undefined;
  textColor: string | undefined;
}

const Ribbon: FC<Props> = ({ alertBars, onClick, ...rest }) => {
  const [ref, inView] = useInView();

  const [index, setIndex] = useState(0);
  const [trackedIndexes, setTrackedIndexes] = useState<number[]>([]);

  const { segmentPromotionClickedOrViewed } = useSegment();

  const trackingData = useMemo(
    () => ({
      name: alertBars[index]?.content,
      position: `alert-bar-${index + 1}`,
      promotionType: SEGMENT_PROMOTION_TYPE.topAlertBar,
    }),
    [index, alertBars]
  );

  useEffect(() => {
    if (inView && !trackedIndexes.includes(index)) {
      void segmentPromotionClickedOrViewed({
        ...trackingData,
        eventType: PromotionEventType.view,
      });
      setTrackedIndexes(trackedIndexes.concat(index));
    }
  }, [
    inView,
    index,
    segmentPromotionClickedOrViewed,
    trackedIndexes,
    trackingData,
  ]);

  const [settings, setSettings] = useState<IState>({
    bgColor: "",
    textColor: "",
  });

  if (!alertBars.length) {
    return null;
  }

  const handleSettings = ({ bgColor, textColor }: IState) =>
    setSettings({ bgColor, textColor });

  const onClickHandler = () =>
    void segmentPromotionClickedOrViewed({
      ...trackingData,
      eventType: PromotionEventType.click,
    });

  return (
    <StyledContainer bgColor={settings.bgColor} {...rest}>
      <Container>
        <InnerWrapper ref={ref}>
          {alertBars.length > 1 ? (
            <Swiper
              aria-orientation="vertical"
              watchSlidesVisibility
              direction="vertical"
              slidesPerView={1}
              loop={true}
              loopPreventsSlide={false}
              effect="fade"
              speed={300}
              allowTouchMove={false}
              autoplay={{ delay: 4000 }}
              // To check which index is active instead of using isVisible as it can be true even if the component is not in the viewport
              onActiveIndexChange={({ realIndex }) => setIndex(realIndex)}
            >
              {alertBars
                .filter(({ content }) => Boolean(content))
                .map((message, idx) => (
                  <SwiperSlide key={idx}>
                    {({ isVisible }: { isVisible: boolean }) => (
                      <AlertMessage
                        isVisible={isVisible}
                        message={message}
                        onAnchorClick={onClickHandler}
                        handleSettings={handleSettings}
                        onContentClick={onClick}
                      />
                    )}
                  </SwiperSlide>
                ))}
            </Swiper>
          ) : (
            <AlertMessage
              isVisible
              message={alertBars[0]}
              onAnchorClick={onClickHandler}
              handleSettings={handleSettings}
              onContentClick={onClick}
            />
          )}

          <CloseButton
            fill={settings.textColor ? settings.textColor : ""}
            onClick={onClick}
            data-testid="ribbon-close"
          />
        </InnerWrapper>
      </Container>
    </StyledContainer>
  );
};

export default Ribbon;
