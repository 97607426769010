import { FC } from "react";

export const InstaIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <title>Instagram</title>
    <defs>
      <path id="snur6thjda" d="M0 .01h31.99v31.333H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="7ndf1cr2vb" fill="#fff">
        <use xlinkHref="#snur6thjda" />
      </mask>
      <path
        fill="#FFF"
        d="M15.995.01c-4.344 0-4.889.018-6.595.094C7.698.18 6.535.445 5.518.832A7.853 7.853 0 002.685 2.64 7.64 7.64 0 00.84 5.415C.444 6.412.174 7.55.096 9.218.018 10.89 0 11.423 0 15.678c0 4.256.018 4.79.096 6.46.078 1.668.348 2.807.744 3.804a7.648 7.648 0 001.845 2.775 7.842 7.842 0 002.833 1.807c1.017.388 2.18.653 3.882.729 1.706.076 2.251.094 6.595.094 4.344 0 4.889-.018 6.595-.094 1.702-.076 2.865-.341 3.882-.729a7.85 7.85 0 002.833-1.807 7.661 7.661 0 001.845-2.775c.396-.997.666-2.136.744-3.803.077-1.672.096-2.205.096-6.46 0-4.256-.019-4.79-.096-6.46-.078-1.668-.348-2.807-.744-3.804a7.661 7.661 0 00-1.845-2.775A7.858 7.858 0 0026.472.832C25.455.445 24.292.18 22.59.104 20.884.028 20.339.01 15.995.01zm0 2.823c4.27 0 4.777.016 6.463.091 1.56.07 2.407.325 2.97.54a4.97 4.97 0 011.84 1.172c.56.548.906 1.07 1.196 1.802.219.552.48 1.381.55 2.909.078 1.652.094 2.148.094 6.331 0 4.184-.016 4.68-.093 6.332-.071 1.528-.332 2.357-.551 2.91a4.837 4.837 0 01-1.197 1.8c-.56.55-1.092.889-1.839 1.173-.563.215-1.41.47-2.97.54-1.686.075-2.192.09-6.463.09s-4.777-.015-6.463-.09c-1.56-.07-2.407-.325-2.97-.54a4.97 4.97 0 01-1.84-1.172 4.834 4.834 0 01-1.196-1.802c-.22-.552-.48-1.381-.55-2.91-.078-1.651-.094-2.147-.094-6.33 0-4.184.016-4.68.093-6.332.071-1.528.332-2.357.551-2.91.29-.73.637-1.253 1.196-1.801a4.97 4.97 0 011.84-1.172c.563-.215 1.41-.47 2.97-.54 1.686-.075 2.192-.091 6.463-.091z"
        mask="url(#7ndf1cr2vb)"
      />
      <path
        fill="#FFF"
        d="M15.995 20.901c-2.945 0-5.332-2.338-5.332-5.223 0-2.884 2.387-5.222 5.332-5.222s5.332 2.338 5.332 5.222c0 2.885-2.387 5.223-5.332 5.223zm0-13.269c-4.536 0-8.214 3.603-8.214 8.046 0 4.444 3.678 8.046 8.214 8.046 4.536 0 8.214-3.602 8.214-8.046 0-4.443-3.678-8.046-8.214-8.046zm10.458-.317c0 1.038-.86 1.88-1.92 1.88s-1.92-.842-1.92-1.88c0-1.039.86-1.88 1.92-1.88s1.92.841 1.92 1.88"
      />
    </g>
  </svg>
);
