import { FC, useContext } from "react";
import { AppCtx } from "src/contexts/app.context";
import { FlagEuRound } from "src/icons/FlagEuRound";
import { FlagGbRound } from "src/icons/FlagGbRound";
import { FlagBeRound } from "src/icons/FlagBeRound";
import { FlagDeRound } from "src/icons/FlagDeRound";
import { FlagAtRound } from "src/icons/FlagAtRound";
import { FlagDkRound } from "src/icons/FlagDkRound";
import { FlagSeRound } from "src/icons/FlagSeRound";
import { FlagPlRound } from "src/icons/FlagPlRound";
import { FlagEsRound } from "src/icons/FlagEsRound";
import { FlagFrRound } from "src/icons/FlagFrRound";
import { FlagItRound } from "src/icons/FlagItRound";
import { FlagUsRound } from "src/icons/FlagUsRound";
import { FlagNlRound } from "src/icons/FlagNlround";
import { Locale } from "lib/Intl";

interface Props {
  svgPrefixId: string;
  locale?: Locale;
}

const Flag: FC<Props> = ({ svgPrefixId, locale: overrideLocale }) => {
  const { locale: appLocale } = useContext(AppCtx);
  const locale = overrideLocale ?? appLocale;

  switch (locale) {
    case "en":
      return <FlagEuRound svgPrefixId={svgPrefixId} />;
    case "en-gb":
      return <FlagGbRound svgPrefixId={svgPrefixId} />;
    case "nl":
      return <FlagNlRound svgPrefixId={svgPrefixId} />;
    case "nl-be":
      return <FlagBeRound svgPrefixId={svgPrefixId} />;
    case "de":
      return <FlagDeRound svgPrefixId={svgPrefixId} />;
    case "de-at":
      return <FlagAtRound svgPrefixId={svgPrefixId} />;
    case "en-dk":
      return <FlagDkRound svgPrefixId={svgPrefixId} />;
    case "en-se":
      return <FlagSeRound svgPrefixId={svgPrefixId} />;
    case "pl-pl":
      return <FlagPlRound svgPrefixId={svgPrefixId} />;
    case "es-es":
      return <FlagEsRound svgPrefixId={svgPrefixId} />;
    case "fr":
      return <FlagFrRound svgPrefixId={svgPrefixId} />;
    case "it-it":
      return <FlagItRound svgPrefixId={svgPrefixId} />;
    case "en-us":
      return <FlagUsRound svgPrefixId={svgPrefixId} />;
    default:
      return null;
  }
};

export default Flag;
