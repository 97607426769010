import { FC, SVGAttributes } from "react";

const LogoSquare: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(-38 -603) translate(38 603)">
          <rect width="48" height="48" fill="#000" rx="9" />
          <path
            fill="#FFF"
            d="M19.579 37.822l-.107-.042c-.149-.056-.3-.116-.453-.179-1.174-.502-2.11-1.141-2.802-1.92-.699-.784-1.115-1.823-1.25-3.116-.135-1.293.014-2.893.445-4.8.432-1.906 1.169-4.224 2.21-6.955.792-2.074 1.592-3.947 2.402-5.617.809-1.67 1.687-3.036 2.634-4.099.947-1.062 1.999-1.752 3.155-2.07 1.136-.312 2.433-.165 3.887.44.33.142.639.292.934.447 1.13.616 1.95 1.377 2.446 2.289.615 1.13.903 2.427.862 3.89-.04 1.462-.331 3.048-.872 4.755-.54 1.708-1.161 3.481-1.862 5.319-.782 2.048-1.564 3.92-2.346 5.617-.783 1.697-1.64 3.087-2.57 4.172-.932 1.084-1.98 1.799-3.146 2.143-.976.288-2.096.217-3.355-.203-.071-.025-.14-.045-.212-.071m20.397-14.545c-.13-2.185-.634-4.204-1.51-6.056-.875-1.853-2.066-3.49-3.571-4.915-1.205-1.14-2.511-2.057-3.917-2.76-.298-.155-.6-.3-.911-.426l-.072-.027c-.03-.012-.06-.027-.09-.04-1.69-.69-3.56-1.04-5.609-1.053-2.05-.012-4.047.348-5.993 1.078-1.945.73-3.74 1.834-5.383 3.311-1.643 1.477-2.917 3.354-3.82 5.632-.827 2.09-1.189 4.199-1.082 6.328.107 2.13.577 4.135 1.41 6.016.834 1.882 1.997 3.57 3.49 5.068 1.491 1.496 3.213 2.644 5.167 3.442.042.017.084.03.126.047l.037.017c.152.062.306.117.46.172l.134.05c.063.022.127.04.191.062 1.68.563 3.401.823 5.163.77 2.095-.062 4.093-.5 5.994-1.31 1.9-.812 3.612-1.958 5.134-3.437 1.521-1.48 2.68-3.224 3.477-5.234.913-2.304 1.305-4.55 1.175-6.735"
          />
        </g>
      </g>
    </g>
  </svg>
);

export { LogoSquare };
