import { Flex } from "@otrium/core";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/css";

const animation = keyframes`
    0% {
      max-height: 0;
    }
    100% {
      max-height: 1000px;
    }
`;

const ResultsContent = styled.div<{ out?: boolean; duration?: number }>`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  max-height: ${({ out }) => (out ? 0 : "1000px")};

  transition: max-height ${({ duration }) => duration}ms ease-in-out;

  animation: ${animation} ${({ duration }) => (duration || 0) * 1.5}ms
    cubic-bezier(0.25, 0.1, 0.25, 1);

  ::-webkit-scrollbar {
    display: none;
  }
`;

const StyledSearchMenu = styled(Flex)`
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.tone.white};
  overflow: hidden;
  height: fit-content;
  max-height: 100%;
`;

export { ResultsContent, StyledSearchMenu };
