import styled from "@emotion/styled";
import { Flex } from "@otrium/core";
import { Button } from "src/atoms/Button";

const ModalWrapper = styled(Flex)`
  max-width: 100%;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-bottom: ${({ theme }) => `${theme.space.space32}`};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 600px;
  }
`;

const ContentWrapper = styled(Flex)`
  flex-direction: column;
  text-align: center;
  padding: ${({ theme }) => `${theme.space.space24}`};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding: 0;
    width: 392px;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: unset;
  margin-right: 0;
`;
const Image = styled("img")`
  object-fit: cover;
  width: 100%;
  height: 336px;
`;

export { ModalWrapper, ContentWrapper, StyledButton, Image };
